import { ethers } from 'ethers';
import React from 'react';
import { PerkCategories } from '../../_constant';
export default function Progressbar({
  fundRaisedData,
  hardCap,
  fund_releasing_token,
  fundRaisingTokenDecimal,
  fundRaisingTokenSymbol,
  perk,
}) {
  const css =
    perk == PerkCategories.GOLD
      ? 'gold'
      : perk == PerkCategories.DIAMOND
      ? 'diamond'
      : perk == PerkCategories.PLATINUM
      ? 'platinum'
      : 'standard';

  return (
    <div className={`progress progress-${css}`} style={{ height: 12 }}>
      {
        <>
          <div
            className={`progress-bar-${css}`}
            style={{
              width: `${
                fundRaisedData != undefined &&
                Number(
                  (ethers.utils.formatUnits(
                    String(fundRaisedData),
                    fundRaisingTokenDecimal
                  ) *
                    100) /
                    Number(
                      ethers.utils.formatUnits(
                        String(hardCap),
                        fundRaisingTokenDecimal
                      )
                    )
                )
              }%`,
              height: 12,
            }}
          />
        </>
      }
    </div>
  );
}
