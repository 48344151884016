import React, { useEffect, useRef, useState } from 'react';
import {
  APP_NAME_TITLE,
  BASE_URL,
  networkImagesByChain,
} from '../../_constant';
import {
  getLocksAction,
  getMyLocksServiceAction,
} from '../../redux/apiActions/api.action';
import { useAccount, useNetwork } from 'wagmi';
import { getAccount, getNetwork } from '@wagmi/core';
import { useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';
import { SyncLoader } from 'react-spinners';
import _lodash from 'lodash';
import Pagination from '../../Components/Pagination/PaginationSummary';
import config from '../../config';

export const TRowLockData = ({ lock, viewLink = '' }) => {
  const navigate = useNavigate();

  return (
    <tr>
      <td className='text-start table-padding-left'>
        <div
          className='w-max-content'
          style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'nowrap',
          }}
        >
          <img
            src={networkImagesByChain[97]} // Make sure this URL is correct
            className='img-fluid'
            alt='icon'
            width={28}
            style={{ marginRight: '16px' }} // Added margin for spacing
          />
          <div>
            <p className='fs-55 m-0 text-nowrap'>
              {JSON.parse(lock.token_info)?.name}
            </p>
            <span className='fs-6 text-nowrap'>
              {JSON.parse(lock.token_info)?.symbol}
            </span>
          </div>
        </div>
      </td>
      <td>
        {parseInt(
          ethers.utils.formatUnits(
            String(lock.total_amount.split('.')[0]),
            JSON.parse(lock.token_info)?.decimal
          )
        )}
      </td>
      <td className='text-center'>
        <button
          className='btn btn-outline-primary btn-sm'
          onClick={() => {
            navigate(viewLink);
          }}
        >
          View
        </button>
      </td>
    </tr>
  );
};

export default function LiquidityLocks() {
  const { chain } = useNetwork();

  const account = getAccount();

  const search = useRef('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);

  const [locks, setLocks] = useState([]);
  const [loading, _loading] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);
  const getLockFAction = async () => {
    try {
      _loading(true);
      const { data } = await getLocksAction({
        chain: chain?.id || config.SUPPORTED_NETWORKS[0]?.id,
        type: 'liquidity',
        search: search?.current,
        pageSize: itemsPerPage,
        currentPage: currentPage,
      });
      setLocks(data?.data);
      setTotalRecords(data?.total_records);
    } catch (error) {
    } finally {
      _loading(false);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getLockFAction();
  }, [chain?.id, account?.address, currentPage]);

  const [myLoading, _myLoading] = useState(false);
  const [myLocks, _myLocks] = useState([]);
  const [totalMylocks, _totalMyLocks] = useState([]);
  const getMyLockFAction = async () => {
    try {
      _myLoading(true);
      const { data } = await getMyLocksServiceAction({
        chain: chain?.id || config.SUPPORTED_NETWORKS[0]?.id,
        search: search?.current,
        type: 'liquidity',
        pageSize: itemsPerPage,
        currentPage: currentPage,
        address: account?.address,
      });
      _myLocks(data?.data);
      _totalMyLocks(data?.total_records);
    } catch (error) {
    } finally {
      _myLoading(false);
    }
  };

  useEffect(() => {
    getMyLockFAction();
  }, [currentPage, account?.address]);

  const handleSearchDebounced = _lodash.debounce((value) => {
    search.current = value;
    getLockFAction();
    getMyLockFAction();
  }, 500);

  const handleChangeSearch = ({ target }) => {
    handleSearchDebounced(target.value);
  };

  document.title = `${APP_NAME_TITLE} - Liquidity Locks`;

  return (
    <div className='container-fluid mt-5'>
      <div className=''>
        {/* <h4 className="card-header">Create Your Locks for Free</h4> */}
        <div className='card-body'>
          <div className='row'>
            <div className='approve_token_form'>
              <div className='col-md-12 mb-3'>
                <label htmlFor='liquidity-lock form-label'>
                  Search by LP Address...
                </label>
                <div className='form-group mt-2'>
                  <input
                    type='text'
                    className='create_token_input'
                    id='liquidity-lock'
                    name='preSaleToken'
                    onChange={handleChangeSearch}
                    // placeholder="Search by LP Address..."
                  />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'start' }}>
                <ul
                  className='nav nav-tabs mb-3 w-100'
                  id='ex1'
                  role='tablist'
                  style={{ textAlign: 'end' }}
                >
                  <li className='nav-item' role='presentation'>
                    <a
                      className='nav-link active'
                      id='ex2-tab-1'
                      data-bs-toggle='tab'
                      href='#ex2-tabs-1'
                      role='tab'
                      aria-controls='ex2-tabs-1'
                      aria-selected='true'
                    >
                      All Liq Locks
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      id='ex2-tab-2'
                      data-bs-toggle='tab'
                      href='#ex2-tabs-2'
                      role='tab'
                      aria-controls='ex2-tabs-2'
                      aria-selected='false'
                    >
                      My Liq Locks
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* <div className="col"> */}
            <div className='tab-content mt-3'>
              <div
                className='tab-pane fade show active'
                id='ex2-tabs-1'
                role='tabpanel'
                aria-labelledby='ex2-tab-1'
              >
                <div className='row'>
                  <div className='mt-1 text-center'>
                    <div className='card'>
                      <div class='table-responsive'>
                        <table class='table view_table'>
                          <thead>
                            <tr>
                              <th
                                scope='col'
                                className='col-4 text-start table-padding-left'
                              >
                                Token
                              </th>
                              <th scope='col' className='col-4'>
                                Amount
                              </th>
                              <th scope='col' className='col-4 text-center'>
                                View
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {loading ? (
                              new Array(10).fill(0).map((item, idx) => (
                                <tr key={idx}>
                                  <td className='col-4 placeholder-glow'>
                                    <span class='placeholder w-100'></span>
                                  </td>
                                  <td className='col-4 placeholder-glow'>
                                    <span class='placeholder w-100'></span>
                                  </td>
                                  <td className='col-4 placeholder-glow'>
                                    <span class='placeholder w-100'></span>
                                  </td>
                                </tr>
                              ))
                            ) : locks?.length > 0 ? (
                              locks?.map((lock, idx) => (
                                <TRowLockData
                                  key={`liquidity-lock-item-${idx}`}
                                  lock={lock}
                                  viewLink={`${BASE_URL}liquidity-lock-detail?token=${lock?.token}`}
                                />
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan='3'
                                  className='text-center'
                                  style={{ color: 'white' }}
                                >
                                  No record found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <Pagination
                        className='pagination-bar'
                        currentPage={currentPage}
                        totalCount={totalRecords}
                        pageSize={itemsPerPage}
                        onPageChange={(page) => handlePageChange(page)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col"> */}
              <div
                className='tab-pane fade'
                id='ex2-tabs-2'
                role='tabpanel'
                aria-labelledby='ex2-tab-2'
              >
                <div className='row'>
                  <div className='mt-1 text-center'>
                    <div className='card'>
                      <div class='table-responsive'>
                        <table class='table view_table'>
                          <thead>
                            <tr>
                              <th
                                scope='col'
                                className='col-4 text-start table-padding-left'
                              >
                                Token
                              </th>
                              <th scope='col' className='col-4'>
                                Amount
                              </th>
                              <th scope='col' className='col-4 text-center'>
                                View
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {myLoading ? (
                              new Array(10).fill(0).map((item, idx) => (
                                <tr key={idx}>
                                  <td className='col-4 placeholder-glow'>
                                    <span class='placeholder w-100'></span>
                                  </td>
                                  <td className='col-4 placeholder-glow'>
                                    <span class='placeholder w-100'></span>
                                  </td>
                                  <td className='col-4 placeholder-glow'>
                                    <span class='placeholder w-100'></span>
                                  </td>
                                </tr>
                              ))
                            ) : myLocks?.length > 0 ? (
                              myLocks?.map((lock, idx) => (
                                <TRowLockData
                                  key={`my-liquidity-lock-${idx}`}
                                  lock={lock}
                                  viewLink={`${BASE_URL}liquidity-lock-detail?token=${lock?.token}`}
                                />
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan='4'
                                  className='text-center'
                                  style={{ color: 'white' }}
                                >
                                  No record found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <Pagination
                        className='pagination-bar'
                        currentPage={currentPage}
                        totalCount={totalMylocks}
                        pageSize={itemsPerPage}
                        onPageChange={(page) => handlePageChange(page)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}
