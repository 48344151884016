import React from 'react';

const PresaleCardShimmer = () => {
  return (
    <div
      className='h-100'
      style={{
        padding: '2px', // snake animation padding
      }}
    >
      <div
        class='card presale_card position-relative mx-auto shadow-sm w-100 d-flex flex-column  gap-2 gap-sm-3 rounded-600 p-2 p-md-3 '
        aria-hidden='true'
      >
        {/* <img src='...' class='card-img-top' alt='...' /> */}
        <div className='d-flex gap-4 w-100'>
          <span
            class='placeholder col-12 card-top-img rounded-4'
            style={{
              maxWidth: '45%',
            }}
          ></span>
          <h5 class='card-title placeholder-glow col d-none d-md-block'>
            <span class='placeholder col-8'></span>
            <span class='placeholder col-10'></span>
            <span class='placeholder col-12'></span>
            <span class='placeholder col-12'></span>
          </h5>
        </div>

        <h5 class='card-title placeholder-glow col d-block d-md-none w-100'>
          <span class='placeholder col-8'></span>
          <span class='placeholder col-10'></span>
          <span class='placeholder col-12 mt-4'></span>
          <span class='placeholder col-12'></span>
          <span class='placeholder col-12'></span>
        </h5>

        <p class='card-text placeholder-glow m-0 p-0 mt-auto w-100'>
          <span class='placeholder col-7'></span>
          <span class='placeholder col-4'></span>
          <span class='placeholder col-4'></span>
          <span class='placeholder col-6'></span>
          <span class='placeholder col-8'></span>
        </p>
        {/* <p className='placeholder-glow gx-2'>
          <span class='placeholder placeholder-lg col-6 Softcap'>1</span>
          <span class='placeholder placeholder-lg col-6 Softcap'>1</span>
          <span class='placeholder placeholder-lg col-6 Softcap'>1</span>
          <span class='placeholder placeholder-lg col-6 Softcap'>1</span>
          <span class='placeholder placeholder-lg col-12'></span>
          <span class='placeholder placeholder-lg col-12'></span>
        </p> */}
        <p class='placeholder-glow row row-cols-2 row-cols-sm-2 gx-2 gy-2 coin-data-row p-0 w-100'>
          <div class='placeholder col'>
            <span className='placeholder col-12 opacity-0 Softcap'>1</span>
          </div>
          <div class='placeholder col'>
            <span className='placeholder col-12 opacity-0 Softcap'>1</span>
          </div>
          <div class='placeholder col'>
            <span className='placeholder col-12 opacity-0 Softcap'>1</span>
          </div>
          <div class='placeholder col'>
            <span className='placeholder col-12 opacity-0 Softcap'>1</span>
          </div>
        </p>
        <div className='d-flex align-items-end justify-content-end gap-2 placeholder-glow w-100'>
          <button
            class='btn btn-secondary disabled placeholder'
            style={{
              aspectRatio: 1,
              height: 'var(--h-presale_card_footer_btn)',
            }}
            aria-disabled='true'
          ></button>
          <div
            className='sale_end badge text-bg-primary placeholder'
            aria-disabled='true'
          >
            <span
              style={{
                opacity: 0,
              }}
            >
              1
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresaleCardShimmer;
