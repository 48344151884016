import React, { useEffect, useReducer, useState } from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import './AnimatedEllipsis.css';
import { ethers } from 'ethers';
import { REWARD_TYPE, STAKE_MANAGER, blockInvalidChar } from '../../_constant';
import {
  PostErrorLogAction,
  postStakingAction,
  tokenIsStakeAction,
} from '../../redux/apiActions/api.action';
import { BASE_URL } from '../../_constant';
import {
  useAccount,
  useContractWrite,
  useToken,
  useWaitForTransaction,
} from 'wagmi';
import { getAccount, getNetwork } from '@wagmi/core';
import { decodeEventLog, parseAbi } from 'viem';
import { ERRORMSGS, TRANSACTIONMSGS } from '../../utils/messages';
import { bscTestnet, polygonMumbai } from 'viem/chains';
import StackingManagerABI from '../../_constant/StakingManager.json';
import {
  createStakingSchema,
  projectInfoSchemaStacking,
} from '../../validators/Staking/Staking';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import config from '../../config';
import CreateLaunchContainer from '../../Components/Layouts/CreateLaunchContainer';

const stepTrack = [false, false, false, false];

const wizards = [
  {
    label: 'Token Information',
    description: 'Enter the token addresses for Staking and reward',
    index: 1,
    content: ApproveToken,
    id: 'ApproveToken',
  },
  {
    label: 'Staking Information',
    description: 'Enter the Staking information',
    index: 2,
    content: PresaleInformation,
    id: 'PresaleInformation',
  },
  {
    label: 'Project Information',
    description: 'Add project links, description',
    index: 3,
    content: ProjectInformation,
    id: 'ProjectInformation',
  },
  {
    label: 'Submit',
    description: 'Submit your Staking',
    index: 4,
    content: Confirmation,
    id: 'Submit',
  },
];

// dayjs.extend(localizedFormat);

const currentTime = dayjs(); // Get the current time

const startTime = currentTime.add(5, 'minute');
const endTime = startTime.add(1, 'day');
const estimatedDexListingTime = endTime.add(24, 'hour');

const initialData = {
  // Staking
  token: '',
  stakeToken: '',
  rewardToken: '',
  name: '',
  symbol: '',
  rewardType: '0',
  hardCap: '',
  canClaimAnyTime: true,
  transferrable: true,
  startDateTime: moment(new Date(startTime)).unix(),
  endDateTime: moment(new Date(endTime)).unix(),
  rewardRatio: '',
  minAmountToStake: '',
  minPeriodToStake: '',
  claimDateTime: moment(new Date(startTime)).unix(),
  stakingLogoUrl: '',
  rewardLogoUrl: '',
  bannerUrl: '',
  website_url: '',
  twitter: '',
  github: '',
  telegram: '',
  discord: '',
  youtube: '',
  whitelist_link: '',
  description: '',
  cover_photo: '',
  //End Staking
};

function formReducer(state, { type, value }) {
  switch (type) {
    // Stack
    case 'stakeToken': {
      return { ...state, stakeToken: value };
    }
    case 'stackTokenInfo': {
      return { ...state, stakeTokenInfo: value };
    }

    case 'rewardToken': {
      return { ...state, rewardToken: value };
    }

    case 'rewardTokenInfo': {
      return { ...state, rewardTokenInfo: value };
    }

    case 'name': {
      return { ...state, name: value };
    }

    case 'symbol': {
      return { ...state, symbol: value };
    }

    case 'rewardType': {
      return { ...state, rewardType: value };
    }

    case 'canClaimAnyTime': {
      return { ...state, canClaimAnyTime: value };
    }

    case 'hardCap': {
      return { ...state, hardCap: value };
    }

    case 'transferrable': {
      return { ...state, transferrable: value };
    }

    case 'startDateTime': {
      return { ...state, startDateTime: value };
    }

    case 'endDateTime': {
      return { ...state, endDateTime: value };
    }

    case 'rewardRatio': {
      return { ...state, rewardRatio: value };
    }

    case 'claimDateTime': {
      return { ...state, claimDateTime: value };
    }

    case 'minAmountToStake': {
      return { ...state, minAmountToStake: value };
    }

    case 'minPeriodToStake': {
      return { ...state, minPeriodToStake: value };
    }

    case 'stakingLogoUrl': {
      return { ...state, stakingLogoUrl: value };
    }

    case 'rewardLogoUrl': {
      return { ...state, rewardLogoUrl: value };
    }

    case 'bannerUrl': {
      return { ...state, bannerUrl: value };
    }

    case 'website_url': {
      return { ...state, website_url: value };
    }

    case 'twitter': {
      return { ...state, twitter: value };
    }

    case 'github': {
      return { ...state, github: value };
    }

    case 'telegram': {
      return { ...state, telegram: value };
    }

    case 'discord': {
      return { ...state, discord: value };
    }

    case 'youtube': {
      return { ...state, youtube: value };
    }

    case 'whitelist_link': {
      return { ...state, whitelist_link: value };
    }

    case 'description': {
      return { ...state, description: value };
    }

    case 'cover_photo': {
      return { ...state, cover_photo: value };
    }

    // End Stack

    case 'reset': {
      return initialData; // Reset the state to the initialData
    }

    default: {
      return state;
    }
  }
}

let toastPleasedLoginId = 'pleasedLoginId';
function CreateStack() {
  const today = dayjs();

  const [formData, setFormData] = useReducer(formReducer, initialData);

  const [progressCount, setProgressCount] = useState(1);

  function nextProgress() {
    if (progressCount < wizards.length) setProgressCount(progressCount + 1);
    if (progressCount >= wizards.length) {
      // alert("FormFilled");
    }
  }

  const resetForm = () => {
    setFormData({ type: 'reset' });
  };

  function prevProgress() {
    if (progressCount == 2) resetForm();
    if (progressCount > 1) setProgressCount(progressCount - 1);
  }

  document.title = `Create a Stake`;

  return (
    <CreateLaunchContainer>
      <form className='approve_token_form'>
        <ul id='progressbar' className='mb-5'>
          {Object.values(wizards).map((wizard, index) => (
            <li
              className={`step ${
                wizard?.index <= progressCount
                  ? 'active'
                  : progressCount == 4
                  ? 'active'
                  : ''
              }`}
              id={wizard?.id}
              key={index}
              onClick={() => {
                if (
                  wizard?.index == 2 &&
                  stepTrack[wizard?.index - 2] == true
                ) {
                  setProgressCount(wizard?.index);
                } else if (
                  wizard?.index == 3 &&
                  stepTrack[
                    wizard?.index - 2 == true &&
                      stepTrack[wizard?.index - 3] == true
                  ]
                ) {
                  setProgressCount(wizard?.index);
                } else if (
                  wizard?.index == 1 &&
                  stepTrack[wizard?.index - 1] == true
                ) {
                  setProgressCount(wizard?.index);
                }
              }}
            >
              <h5
              // className={`${wizard?.index <= progressCount|| progressCount==4 ? "" : "not_active_wizard"
              //   }`}
              >
                {wizard?.index <= progressCount || progressCount == 4
                  ? wizard?.label
                  : wizard?.label}
              </h5>
              <p
              // className={`${wizard?.index <= progressCount|| progressCount==4 ? "" : "not_active_wizard"
              // }`}
              >
                {wizard?.index <= progressCount || progressCount == 4
                  ? wizard?.description
                  : wizard?.description}
              </p>
            </li>
          ))}
        </ul>
        <div className='row'>
          <div className='col-md-12 mx-auto'>
            {Object.values(wizards).map((wizard) => (
              <section
                hidden={wizard.index !== progressCount}
                key={wizard?.index}
              >
                <wizard.content
                  {...{
                    next: nextProgress,
                    setFormData,
                    formData,
                    prev: prevProgress,
                  }}
                />
              </section>
            ))}

            <div className='tab'></div>
          </div>
        </div>
      </form>
    </CreateLaunchContainer>
  );
}

function ApproveToken({ next, prev, setFormData, formData }) {
  const { chain, chains } = getNetwork();

  const { isConnected } = useAccount();

  const supportedChainIds = config.SUPPORTED_NETWORKS.map(
    (network) => network.id
  );
  const supportedChainSymbols = config.SUPPORTED_NETWORKS.map(
    (network) => network.symbol
  );

  /// @dev Handle on invalid token address.
  const onTokenFetchError = async (err) => {
    // toast.error(err.message);
  };

  const onSuccessPresaleToken = (data) => {
    // console.log("onSuccessPresaleToken", data)
  };

  const {
    data: stackTokenInfo,
    isSuccess: isSuccessStackTokenInfo,
    isError: isErrorStackTokenInfo,
    isLoading: isLoadingStackTokenInfo,
  } = useToken({
    address: formData?.stakeToken,
    onError: onTokenFetchError,
    onSuccess: onSuccessPresaleToken,
  });

  const {
    data: rewardTokenInfo,
    isSuccess: isSuccessRewardTokenInfo,
    isError: isErrorRewardTokenInfo,
    isLoading: isLoadingRewardTokenInfo,
  } = useToken({
    address: formData?.rewardToken,
    onError: onTokenFetchError,
    onSuccess: onSuccessPresaleToken,
  });

  useEffect(() => {
    // stackTokenInfo
    setFormData({ type: 'name', value: stackTokenInfo?.name });
    setFormData({ type: 'symbol', value: stackTokenInfo?.symbol });
  }, [stackTokenInfo]);

  const validateAddress = (address) => {
    const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
    return addressRegex.test(address);
  };

  const [usingStakeContract, _usingStakeContract] = useState(false);
  const checkStakeAddressIsValidDB = async (address) => {
    if (stackTokenInfo) {
      let { data } = await tokenIsStakeAction({
        address: stackTokenInfo?.address,
      });
      if (data?.data?.avaiable == '1') {
        _usingStakeContract(true);
      }
    }
  };

  useEffect(() => {
    _usingStakeContract(false);
    checkStakeAddressIsValidDB();
  }, [stackTokenInfo]);

  const [usingStakeContractReward, _usingStakeContractReward] = useState(false);
  const checkRewardAddressIsValidDB = async (address) => {
    if (rewardTokenInfo) {
      let { data } = await tokenIsStakeAction({
        address: rewardTokenInfo?.address,
      });
      if (data?.data?.avaiable == '1') {
        _usingStakeContractReward(true);
      }
    }
  };

  useEffect(() => {
    _usingStakeContractReward(false);
    checkRewardAddressIsValidDB();
  }, [rewardTokenInfo]);

  function validateAndContinue() {
    if (usingStakeContract == true) {
      toast.error("You can't use StakePool token for staking.", {
        toastId: 'usestakeTid',
      });
    } else if (usingStakeContractReward == true) {
      toast.error("You can't use StakePool token for rewarding.", {
        toastId: 'useRewardTid',
      });
    } else {
      next();
    }
  }

  // console.log("stakeToken",formData)

  return (
    <div className='row'>
      <div className='col-md-12 mx-auto'>
        <div className='approve_token_section'>
          {/* <h3 className="text-center">Approve Token</h3> */}
          <div className='approve_token_card mt-2'>
            {/* <label>Token Address</label> */}
            <div className='material-switch d-flex align-items-center'></div>

            {/* Stacking Pool Token */}
            <input
              type='text'
              name='stakeToken'
              placeholder='Staking Token Address'
              className={`token_address_input mt-1 ${
                !stackTokenInfo &&
                isErrorStackTokenInfo &&
                formData?.stakeToken != '' &&
                'invalid'
              }`}
              defaultValue={formData?.stakeToken}
              value={formData?.stakeToken.trim()}
              onChange={({ target }) => {
                if (!isConnected) {
                  toast.error(ERRORMSGS.LOGINWITHYOURWALLET, {
                    toastId: toastPleasedLoginId,
                  });
                } else if (!supportedChainIds.includes(chain?.id)) {
                  toast.error('Wrong Network!', { toastId: 'wrongNetworkTId' });
                } else {
                  setFormData({
                    type: 'stakeToken',
                    value: target.value.trim(),
                  });
                }
              }}
              // disabled={isLoadingStackTokenInfo}
            />
            <div
              style={{
                width: '100%',
                // height: '50px'
              }}
            >
              <span className='error-msg'>
                {!stackTokenInfo &&
                isErrorStackTokenInfo &&
                formData?.stakeToken != ''
                  ? 'Invalid token address.'
                  : ''}
              </span>
            </div>

            <div className='d-flex flex-wrap justify-content-between align-items-center'>
              <div className='material-switch pull-right d-flex align-items-center'></div>
            </div>

            {/* {(isLoadingStackTokenInfo && !isErrorStackTokenInfo) && 
              <>
                <div className="ellipsis-container">
                  <div className="ellipsis-dot" />
                  <div className="ellipsis-dot" />
                  <div className="ellipsis-dot" />
                </div>
              </>
              } */}

            {stackTokenInfo && (
              <div className='token_details_section_bottom'>
                <section>
                  <ul className='text-white mt-1'>
                    <li className='d-flex flex-wrap align-items-center justify-content-between'>
                      Name<span>{stackTokenInfo?.name}</span>
                    </li>
                    <li className='d-flex flex-wrap align-items-center justify-content-between'>
                      Symbol<span>{stackTokenInfo?.symbol}</span>
                    </li>
                    <li className='d-flex flex-wrap align-items-center justify-content-between'>
                      Total Supply
                      <span>
                        {stackTokenInfo &&
                          ethers.utils
                            .formatUnits(
                              String(stackTokenInfo?.totalSupply?.value || 0),
                              Number(stackTokenInfo?.decimals || 0)
                            )
                            .toString()}
                      </span>
                    </li>
                  </ul>
                  <hr />
                </section>
              </div>
            )}
            {/* End Staking Pool */}

            {/* Start rewardToken */}
            <input
              type='text'
              name='rewardTokenInfo'
              placeholder='Reward Token Address'
              className={`token_address_input mt-1 ${
                !rewardTokenInfo &&
                isErrorRewardTokenInfo &&
                formData?.rewardToken != '' &&
                'invalid'
              }`}
              defaultValue={formData?.rewardToken}
              value={formData?.rewardToken.trim()}
              onChange={({ target }) => {
                if (!isConnected) {
                  toast.error(ERRORMSGS.LOGINWITHYOURWALLET, {
                    toastId: toastPleasedLoginId,
                  });
                } else if (!supportedChainIds.includes(chain?.id)) {
                  toast.error('Wrong Network!', { toastId: 'wrongNetworkTId' });
                } else {
                  setFormData({
                    type: 'rewardToken',
                    value: target.value.trim(),
                  });
                }
              }}
              // disabled={isLoadingStackTokenInfo}
            />
            <div
              style={{
                width: '100%',
                // height: '50px'
              }}
            >
              <span className='error-msg'>
                {!rewardTokenInfo &&
                isErrorRewardTokenInfo &&
                formData?.rewardToken != ''
                  ? 'Invalid token address.'
                  : ''}
              </span>
            </div>

            <div className='d-flex flex-wrap justify-content-between align-items-center'>
              <div className='material-switch pull-right d-flex align-items-center'></div>
            </div>

            {/* {(isLoadingStackTokenInfo && !isErrorStackTokenInfo) && 
              <>
                <div className="ellipsis-container">
                  <div className="ellipsis-dot" />
                  <div className="ellipsis-dot" />
                  <div className="ellipsis-dot" />
                </div>
              </>
              } */}

            {rewardTokenInfo && (
              <div className='token_details_section_bottom'>
                <section>
                  <ul className='text-white mt-1'>
                    <li className='d-flex flex-wrap align-items-center justify-content-between'>
                      Name<span>{rewardTokenInfo?.name}</span>
                    </li>
                    <li className='d-flex flex-wrap align-items-center justify-content-between'>
                      Symbol<span>{rewardTokenInfo?.symbol}</span>
                    </li>
                    <li className='d-flex flex-wrap align-items-center justify-content-between'>
                      Total Supply
                      <span>
                        {rewardTokenInfo &&
                          ethers.utils
                            .formatUnits(
                              String(rewardTokenInfo?.totalSupply?.value || 0),
                              Number(rewardTokenInfo?.decimals || 0)
                            )
                            .toString()}
                      </span>
                    </li>
                  </ul>
                  <hr />
                </section>
              </div>
            )}

            {((stackTokenInfo &&
              stackTokenInfo &&
              formData?.rewardToken == '') ||
              (stackTokenInfo && rewardTokenInfo)) && (
              <section>
                <button
                  className='btn btn-primary btn-lg min-w-btn mx-auto mt-4 mb-1'
                  type='button'
                  onClick={validateAndContinue}
                  style={{ textAlign: 'center' }}
                >
                  Next
                </button>
                {/* )} */}
              </section>
            )}

            {/* End rewardToken */}
          </div>
        </div>
      </div>
    </div>
  );
}

function PresaleInformation({ next, prev, setFormData, formData }) {
  function handleDateChangestartTime(dateString) {
    setFormData({
      type: 'startDateTime',
      value: moment(new Date(dateString)).unix(),
    });
  }

  function handleDateChangeEndTime(dateString) {
    setFormData({
      type: 'endDateTime',
      value: moment(new Date(dateString)).unix(),
    });
  }

  function handleDateChangeClaimAnyTime(dateString) {
    setFormData({
      type: 'claimDateTime',
      value: moment(new Date(dateString)).unix(),
    });
  }

  const {
    data: rewardTokenInfo,
    isSuccess: isSuccessRewardTokenInfo,
    isError: isErrorRewardTokenInfo,
    isLoading: isLoadingRewardTokenInfo,
  } = useToken({
    address: formData?.rewardToken,
  });

  const {
    data: stackTokenInfo,
    isSuccess: isSuccessStackTokenInfo,
    isError: isErrorStackTokenInfo,
    isLoading: isLoadingStackTokenInfo,
  } = useToken({
    address: formData?.stakeToken,
  });

  // useEffect(()=>{
  //   if(!rewardTokenInfo){
  //     console.log("inside")
  //     setFormData({ type: "rewardRatio", value: 0 });
  //     setFormData({ type: "rewardType", value: '3' });
  //     setFormData({ type: "canClaimAnyTime", value: false });
  //   }
  //  },[formData?.rewardToken])

  // Formik

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // Staking
      rewardToken: formData?.rewardToken,
      name: formData?.name,
      symbol: formData?.symbol,
      rewardType: !rewardTokenInfo ? '3' : formData?.rewardType,
      canClaimAnyTime: !rewardTokenInfo ? false : formData?.canClaimAnyTime,
      hardCap: formData?.hardCap,
      transferrable: formData?.transferrable,
      rewardRatio: !rewardTokenInfo ? 0 : formData?.rewardRatio,
      minAmountToStake: formData?.minAmountToStake,
      minPeriodToStake: formData?.minPeriodToStake,
      // claimDateTime:formData?.claimDateTime
      // End Staking
    },
    validationSchema: createStakingSchema,

    onSubmit: async (data) => {
      try {
        for (let key in data) {
          setFormData({ type: key, value: data[key] });
        }
        if (
          Number(formData?.startDateTime) <= moment(new Date(dayjs())).unix()
        ) {
          toast.error(
            'Oops, Staking start time needs to be at least later than now!',
            { toastId: 'stimeGreaterCurr' }
          );
        } else if (
          Number(formData?.endDateTime) <= Number(formData?.startDateTime)
        ) {
          toast.error('endTime should be > startTime', {
            toastId: 'end_time_>_start_time',
          });
        } else if (
          !formik.values.canClaimAnyTime &&
          rewardTokenInfo &&
          Number(formData?.claimDateTime) < moment(new Date(dayjs())).unix()
        ) {
          toast.error('Oops, Claim time needs to be at least later than now!', {
            toastId: 'claimDateGreaterCurr',
          });
        } else {
          stepTrack[1] = true;
          next();
        }
      } catch (error) {
      } finally {
      }
    },
  });

  const handleRewardTpeChange = ({ target }) => {
    setFormData({ type: 'rewardType', value: target.value });
  };

  useEffect(() => {
    // rewardRatio
    if (formData?.rewardType == 2 || formData?.rewardType == 3) {
      setFormData({ type: 'rewardRatio', value: 0 });
    }
  }, [formData?.rewardType]);

  return (
    <>
      <div className='tab'>
        <h4 className='form-card-heading'>Staking Information</h4>
        <div className='approve_token_card text-start'>
          <div className='row g-3 row-cols-1 row-cols-sm-1'>
            {/* Stacking */}

            <div className='col'>
              <label className='form-label'>Stake Name</label>

              <input
                type='text'
                name='name'
                placeholder
                onChange={formik.handleChange}
                value={formik.values.name}
                className={
                  formik.errors.name && formik.touched.name && 'invalid'
                }
              />
              {formik.errors.name && formik.touched.name ? (
                <span className='error-msg'>{formik.errors.name}</span>
              ) : null}
            </div>

            <div className='col'>
              <label className='form-label'>Stake Symbol</label>

              <input
                type='text'
                name='symbol'
                placeholder
                onChange={formik.handleChange}
                value={formik.values.symbol}
                className={
                  formik.errors.symbol && formik.touched.symbol && 'invalid'
                }
              />
              {formik.errors.symbol && formik.touched.symbol ? (
                <span className='error-msg'>{formik.errors.symbol}</span>
              ) : null}
            </div>

            {formData?.rewardToken && (
              <div className='col'>
                <label className='form-label'>Reward Type</label>
                <select
                  className='form-select whitelist-form-select'
                  name='rewardType'
                  onChange={handleRewardTpeChange}
                  value={formData?.rewardType}
                >
                  {REWARD_TYPE &&
                    Object.keys(REWARD_TYPE)?.map((rewardKey, rewardValue) => (
                      <option value={rewardValue}>{rewardKey}</option>
                    ))}
                </select>
              </div>
            )}

            {formData?.rewardToken &&
              (formData?.rewardType == 0 || formData?.rewardType == 1) && (
                <div className='col'>
                  <label className='form-label'>
                    Reward Ratio ({' '}
                    {formData?.rewardType == 0
                      ? `${rewardTokenInfo?.symbol} / ${stackTokenInfo?.symbol} *  yr`
                      : `${rewardTokenInfo?.symbol} / yr`}
                    )
                  </label>
                  <input
                    type='number'
                    name='rewardRatio'
                    onKeyDown={blockInvalidChar}
                    placeholder
                    // onChange={(e) => {
                    //   const inputValue = e.target.value;
                    //   const numericValue = inputValue.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except dot (.)
                    //   if (e.target.value.length < rewardTokenInfo?.decimals) {
                    //     formik.handleChange({
                    //       target: {
                    //         name: "rewardRatio",
                    //         value: numericValue,
                    //       },
                    //     });
                    //   }
                    // }}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const decimalPart = inputValue.split('.')[1];
                      let limitedNumericValue = inputValue;
                      if (
                        decimalPart &&
                        decimalPart.length > rewardTokenInfo?.decimals
                      ) {
                        limitedNumericValue =
                          inputValue.split('.')[0] +
                          '.' +
                          decimalPart.slice(0, rewardTokenInfo?.decimals);
                      }
                      formik.handleChange({
                        target: {
                          name: 'rewardRatio',
                          value: limitedNumericValue,
                        },
                      });
                    }}
                    onWheel={(e) => e.target.blur()}
                    value={formik.values.rewardRatio}
                    className={
                      formik.errors.rewardRatio &&
                      formik.touched.rewardRatio &&
                      'invalid'
                    }
                  />

                  {formik.errors.rewardRatio && formik.touched.rewardRatio ? (
                    <span className='error-msg'>
                      {formik.errors.rewardRatio}
                    </span>
                  ) : null}
                </div>
              )}

            {formData?.rewardToken && (
              <div className='col'>
                <div className='col-md-12 mb-2 material-switch pull-left d-flex align-items-center'>
                  <p className='mb-0 me-3'>Reward Claim any time</p>
                  <input
                    id='canClaimAnyTime'
                    name='canClaimAnyTime'
                    type='checkbox'
                    checked={formik.values.canClaimAnyTime}
                    onChange={(e) => {
                      if (!e.target.checked) {
                        // formik.setFieldValue("canClaimAnyTime", 0);
                        formik.validateForm();
                      }
                      formik.handleChange(e);
                    }}
                    value={formik.values.canClaimAnyTime}
                  />
                  <label htmlFor='canClaimAnyTime' className='label-default' />
                </div>
              </div>
            )}
            {!formik.values.canClaimAnyTime && rewardTokenInfo && (
              <>
                <div className='col'>
                  <label className='form-label'>Claim Date Time (IST)</label>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={[
                        'MobileDateTimePicker',
                        'MobileDateTimePicker',
                      ]}
                    >
                      <MobileDateTimePicker
                        className='form-control'
                        // label={'Estimated Dex Listing Date (Local)'}
                        openTo='hours'
                        name='dexlistiongrate'
                        defaultValue={endTime}
                        onChange={handleDateChangeClaimAnyTime}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </>
            )}
            <div className='col'>
              <label className='form-label'>Hard Cap</label>
              <input
                type='text'
                name='hardCap'
                placeholder
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except dot (.)
                  if (e.target.value.length < stackTokenInfo?.decimals) {
                    formik.handleChange({
                      target: {
                        name: 'hardCap',
                        value: numericValue,
                      },
                    });
                  }
                }}
                value={formik.values.hardCap}
                className={
                  formik.errors.hardCap && formik.touched.hardCap && 'invalid'
                }
              />

              {formik.errors.hardCap && formik.touched.hardCap ? (
                <span className='error-msg'>{formik.errors.hardCap}</span>
              ) : null}
            </div>

            <div className='col'>
              <label className='form-label'>Min Limit to Stake</label>
              <input
                type='number'
                name='minAmountToStake'
                onKeyDown={blockInvalidChar}
                onWheel={(e) => e.target.blur()}
                placeholder
                // onChange={(e) => {
                //   const inputValue = e.target.value;
                //   const numericValue = inputValue.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except dot (.)
                //   if (numericValue !== false) {
                //     if (e.target.value.length < stackTokenInfo?.decimals) {
                //       formik.handleChange({
                //         target: {
                //           name: "minAmountToStake",
                //           value: numericValue,
                //         },
                //       });
                //     }
                // }
                // }}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const decimalPart = inputValue.split('.')[1];
                  let limitedNumericValue = inputValue;
                  if (
                    decimalPart &&
                    decimalPart.length > stackTokenInfo?.decimals
                  ) {
                    limitedNumericValue =
                      inputValue.split('.')[0] +
                      '.' +
                      decimalPart.slice(0, stackTokenInfo?.decimals);
                  }
                  formik.handleChange({
                    target: {
                      name: 'minAmountToStake',
                      value: limitedNumericValue,
                    },
                  });
                }}
                value={formik.values.minAmountToStake}
                className={
                  formik.errors.minAmountToStake &&
                  formik.touched.minAmountToStake &&
                  'invalid'
                }
              />

              {formik.errors.minAmountToStake &&
              formik.touched.minAmountToStake ? (
                <span className='error-msg'>
                  {formik.errors.minAmountToStake}
                </span>
              ) : null}
            </div>

            <div className='col-md-12 mb-2 material-switch pull-left d-flex align-items-center'>
              <p className='mb-0 me-3'>Staking Token is transferrable or not</p>
              <input
                id='transferrable'
                name='transferrable'
                type='checkbox'
                checked={formik.values.transferrable}
                onChange={(e) => {
                  formik.handleChange(e);
                  if (!e.target.checked) {
                    // formik.setFieldValue("min_bonus_buy_amount", 0);
                    // formik.setFieldValue("bonus_received", 0)
                    // formik.setFieldValue("bonus_spots_available", 0)
                  }
                }}
                value={formik.values.transferrable}
              />

              <label htmlFor='transferrable' className='label-default' />
            </div>
            <br />
            {!formik?.values?.transferrable && (
              <div className='col'>
                <label className='form-label'>
                  Min Period to Stake (second)
                </label>
                <input
                  type='text'
                  name='minPeriodToStake'
                  placeholder
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const numericValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except dot (.)
                    formik.handleChange({
                      target: {
                        name: 'minPeriodToStake',
                        value: numericValue,
                      },
                    });
                  }}
                  value={formik.values.minPeriodToStake}
                  className={
                    formik.errors.minPeriodToStake &&
                    formik.touched.minPeriodToStake &&
                    'invalid'
                  }
                />

                {formik.errors.minPeriodToStake &&
                formik.touched.minPeriodToStake ? (
                  <span className='error-msg'>
                    {formik.errors.minPeriodToStake}
                  </span>
                ) : null}
              </div>
            )}

            <div className='col'>
              <label className='form-label'>Start Date Time (IST)</label>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={['MobileDateTimePicker', 'MobileDateTimePicker']}
                >
                  <MobileDateTimePicker
                    className='form-control'
                    // label={'Estimated Dex Listing Date (Local)'}
                    openTo='hours'
                    name='dexlistiongrate'
                    defaultValue={startTime}
                    onChange={handleDateChangestartTime}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>

            <div className='col'>
              <label className='form-label'>End Date Time (IST)</label>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={['MobileDateTimePicker', 'MobileDateTimePicker']}
                >
                  <MobileDateTimePicker
                    className='form-control'
                    // label={'Estimated Dex Listing Date (Local)'}
                    openTo='hours'
                    name='dexlistiongrate'
                    defaultValue={endTime}
                    onChange={handleDateChangeEndTime}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>

            {/* End Staking */}
          </div>
          <hr className='hr-mt-lg' />
          <div style={{ overflow: 'auto' }}>
            <div className='d-flex gap-3 py-2 justify-content-center'>
              <button
                type='button'
                // id="prevBtn"
                onclick='nextPrev(-1)'
                className='m-0 btn btn-outline-primary btn-lg text-white min-w-btn'
                onClick={prev}
              >
                Back
              </button>
              <button
                type='submit'
                // id="nextBtn"
                className='m-0 btn btn-primary btn-lg min-w-btn'
                onClick={(event) => {
                  event.preventDefault();
                  formik.handleSubmit();
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
        {/* </form> */}
      </div>
    </>
  );
}

function ProjectInformation({ next, prev, setFormData, formData }) {
  const { chain, chains } = getNetwork();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      stakingLogoUrl: '',
      rewardLogoUrl: '',
      website_url: '',
      twitter: '',
      github: '',
      telegram: '',
      discord: '',
      youtube: '',
      whitelist_link: '',
      description: '',
      cover_photo: '',
      tier: '0',
      bannerUrl: '',
    },
    validationSchema: projectInfoSchemaStacking,
    onSubmit: async (data) => {
      try {
        for (let key in data) {
          setFormData({ type: key, value: data[key] });
        }

        stepTrack[1] = true;
        next();
      } catch (error) {
      } finally {
      }
    },
  });

  return (
    <div className='tab'>
      <h4 className='form-card-heading'>Project Information</h4>
      <div className='approve_token_card text-start'>
        <div className='row row-cols-1 row-cols-sm-2 g-3 Project_Information'>
          <div className='col'>
            <label className='form-label' style={{ position: 'relative' }}>
              Staking token logo url{' '}
              {/* <i class="fa fa-info-circle">
          <span className="tooltip-text">URL must end with a supported image extension png, jpg, jpeg or gif and dimensions of exactly 50x50 pixels.</span>
              </i> */}
              <Tooltip
                arrow
                title='URL must end with a supported image extension png, jpg, jpeg or gif and dimensions of exactly 50x50 pixels.'
                placement='top-start'
                enterTouchDelay={false}
              >
                <InfoIcon style={{ marginLeft: '1px', width: '19' }} />
              </Tooltip>
              <span
                className='error-msg'
                style={{ fontSize: '1.5rem', position: 'absolute', top: -7 }}
              >
                *
              </span>{' '}
            </label>

            <div
              className={`${
                formik.errors.stakingLogoUrl && formik.touched.stakingLogoUrl
                  ? 'input_invalid'
                  : ''
              } input-group`}
            >
              <div className='input-group-text'>
                <i className='fa fa-picture-o' aria-hidden='true'></i>
              </div>
              <input
                className='form-control'
                type='text'
                name='stakingLogoUrl'
                placeholder='Ex: https://...'
                onChange={formik.handleChange}
                value={formik.values.stakingLogoUrl}
              />
            </div>

            {formik.errors.stakingLogoUrl && formik.touched.stakingLogoUrl ? (
              <span className='error-msg'>
                {formik.errors.stakingLogoUrl}
              </span>
            ) : null}
          </div>
          <div className='col'>
            <label className='form-label' style={{ position: 'relative' }}>
              Reward token logo url{' '}
              {/* <i class="fa fa-info-circle">
          <span className="tooltip-text">URL must end with a supported image extension png, jpg, jpeg or gif and dimensions of exactly 50x50 pixels.</span>
              </i>  */}
              <Tooltip
                title='URL must end with a supported image extension png, jpg, jpeg or gif and dimensions of exactly 50x50 pixels.'
                placement='top-start'
                enterTouchDelay={false}
              >
                <InfoIcon style={{ marginLeft: '1px', width: '19' }} />
              </Tooltip>
              <span
                className='error-msg'
                style={{ fontSize: '1.5rem', position: 'absolute', top: -7 }}
              >
                *
              </span>{' '}
            </label>

            <div
              className={`${
                formik.errors.rewardLogoUrl && formik.touched.rewardLogoUrl
                  ? 'input_invalid'
                  : ''
              } input-group`}
            >
              <div className='input-group-text'>
                <i className='fa fa-picture-o' aria-hidden='true'></i>
              </div>
              <input
                className='form-control'
                type='text'
                name='rewardLogoUrl'
                placeholder='Ex: https://...'
                onChange={formik.handleChange}
                value={formik.values.rewardLogoUrl}
              />
            </div>

            {formik.errors.rewardLogoUrl && formik.touched.rewardLogoUrl ? (
              <span className='error-msg'>{formik.errors.rewardLogoUrl}</span>
            ) : null}
          </div>

          <div className='col'>
            <label className='form-label'>
              Banner url
              <Tooltip
                arrow
                title='Banner url with dimensions of exactly 286x110 pixels. supported extention png, jpg, jpeg, gif, mp4, webm, ogg'
                placement='top-start'
                enterTouchDelay={false}
              >
                <InfoIcon style={{ marginLeft: '2px', width: '19' }} />
              </Tooltip>
            </label>
            <div
              className={`${
                formik.errors.bannerUrl && formik.touched.bannerUrl
                  ? 'input_invalid'
                  : ''
              } input-group`}
            >
              <div className='input-group-text'>
                <i class='fa fa-external-link' aria-hidden='true'></i>
              </div>
              <input
                className='form-control'
                type='text'
                name='bannerUrl'
                placeholder='Ex: https://...'
                onChange={formik.handleChange}
                value={formik.values.bannerUrl}
              />
            </div>

            {formik.errors.bannerUrl && formik.touched.bannerUrl ? (
              <span className='error-msg'>{formik.errors.bannerUrl}</span>
            ) : null}
          </div>

          <div className='col'>
            <label className='form-label'>Website url</label>

            <div
              className={`${
                formik.errors.website_url && formik.touched.website_url
                  ? 'input_invalid'
                  : ''
              } input-group`}
            >
              <div className='input-group-text'>
                <i class='fa fa-globe' aria-hidden='true'></i>
              </div>
              <input
                className='form-control'
                type='text'
                name='website_url'
                placeholder='Ex: https://...'
                onChange={formik.handleChange}
                value={formik.values.website_url}
              />
            </div>

            {formik.errors.website_url && formik.touched.website_url ? (
              <span className='error-msg'>{formik.errors.website_url}</span>
            ) : null}
          </div>
          <div className='col'>
            <label className='form-label'>Twitter</label>
            <div
              className={`${
                formik.errors.twitter && formik.touched.twitter
                  ? 'input_invalid'
                  : ''
              } input-group`}
            >
              <div className='input-group-text'>
                <i class='fa fa-twitter' aria-hidden='true'></i>
              </div>
              <input
                className='form-control'
                type='text'
                name='twitter'
                placeholder='Ex: https://twitter.com/...'
                onChange={formik.handleChange}
                value={formik.values.twitter}
              />
            </div>

            {formik.errors.twitter && formik.touched.twitter ? (
              <span className='error-msg'>{formik.errors.twitter}</span>
            ) : null}
          </div>
          <div className='col'>
            <label className='form-label'>Github</label>

            <div
              className={`${
                formik.errors.github && formik.touched.github
                  ? 'input_invalid'
                  : ''
              } input-group`}
            >
              <div className='input-group-text'>
                <i class='fa fa-github' aria-hidden='true'></i>
              </div>
              <input
                className='form-control'
                type='text'
                name='github'
                placeholder='Ex: https://github.com/...'
                onChange={formik.handleChange}
                value={formik.values.github}
              />
            </div>

            {formik.errors.github && formik.touched.github ? (
              <span className='error-msg'>{formik.errors.github}</span>
            ) : null}
          </div>
          <div className='col'>
            <label className='form-label'>Telegram</label>
            <div
              className={`${
                formik.errors.telegram && formik.touched.telegram
                  ? 'input_invalid'
                  : ''
              } input-group`}
            >
              <div className='input-group-text'>
                <i class='fa fa-telegram' aria-hidden='true'></i>
              </div>
              <input
                className='form-control'
                type='text'
                name='telegram'
                placeholder='Ex: https://t.me/...'
                onChange={formik.handleChange}
                value={formik.values.telegram}
              />
            </div>

            {formik.errors.telegram && formik.touched.telegram ? (
              <span className='error-msg'>{formik.errors.telegram}</span>
            ) : null}
          </div>
          <div className='col'>
            <label className='form-label'>Discord</label>
            <div
              className={`${
                formik.errors.discord && formik.touched.discord
                  ? 'input_invalid'
                  : ''
              } input-group`}
            >
              <div className='input-group-text'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='#90a3b7'
                  height='1em'
                  viewBox='0 0 640 512'
                >
                  <path d='M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z' />
                </svg>
              </div>
              <input
                className='form-control'
                type='text'
                name='discord'
                placeholder='Ex: https://discord.gg/...'
                onChange={formik.handleChange}
                value={formik.values.discord}
              />
            </div>

            {formik.errors.discord && formik.touched.discord ? (
              <span className='error-msg'>{formik.errors.discord}</span>
            ) : null}
          </div>
          <div className='col'>
            <label className='form-label'>
              Youtube{' '}
              {/* <i class="fa fa-info-circle">
            <span className="tooltip-text">Input YouTube URL or YouTube video ID. Make sure video link doesn't include a timestamp.</span> </i>
             */}
              <Tooltip
                arrow
                title="Input YouTube URL or YouTube video ID. Make sure video link doesn't include a timestamp."
                placement='top-start'
                enterTouchDelay={false}
              >
                <InfoIcon style={{ width: '19' }} />
              </Tooltip>
            </label>
            <div
              className={`${
                formik.errors.youtube && formik.touched.youtube
                  ? 'input_invalid'
                  : ''
              } input-group`}
            >
              <div className='input-group-text'>
                <i class='fa fa-youtube-play' aria-hidden='true'></i>
              </div>
              <input
                className='form-control'
                type='text'
                name='youtube'
                placeholder='Ex:https://youtube.com/watch?v=75h4tgshg3458i'
                onChange={formik.handleChange}
                value={formik.values.youtube}
              />
            </div>

            {formik.errors.youtube && formik.touched.youtube ? (
              <span className='error-msg'>{formik.errors.youtube}</span>
            ) : null}
          </div>
          <div className='col'>
            <label className='form-label'>Whitelist Link</label>
            <div
              className={`${
                formik.errors.whitelist_link && formik.touched.whitelist_link
                  ? 'input_invalid'
                  : ''
              } input-group`}
            >
              <div className='input-group-text'>
                <i class='fa fa-bolt' aria-hidden='true'></i>
              </div>
              <input
                className='form-control'
                type='text'
                name='whitelist_link'
                placeholder='Ex.https://...'
                onChange={formik.handleChange}
                value={formik.values.whitelist_link}
              />
            </div>

            {formik.errors.whitelist_link && formik.touched.whitelist_link ? (
              <span className='error-msg'>
                {formik.errors.whitelist_link}
              </span>
            ) : null}
          </div>
          <div className='col-sm-12'>
            <label className='form-label'>
              Description{' '}
              <span className='error-msg' style={{ fontSize: '1.5rem' }}>
                *
              </span>
            </label>

            <textarea
              className={`form-control ${
                formik.errors.description && formik.touched.description
                  ? 'input_invalid'
                  : ''
              }`}
              rows={3}
              id='comment'
              defaultValue={''}
              name='description'
              onChange={formik.handleChange}
              value={formik.values.description}
            />

            {formik.errors.description && formik.touched.description ? (
              <span className='error-msg'>{formik.errors.description}</span>
            ) : null}
          </div>
        </div>
        <hr />
        <div style={{ overflow: 'auto' }}>
          <div className='d-flex gap-3 py-2 justify-content-center'>
            <button
              type='button'
              // id="prevBtn"
              // onclick="nextPrev(-1)"
              onClick={prev}
              className='m-0 btn btn-outline-primary btn-lg text-white min-w-btn'
            >
              Back
            </button>
            <button
              type='submit'
              // id="nextBtn"
              className='m-0 btn btn-primary btn-lg min-w-btn'
              onClick={(event) => {
                event.preventDefault();
                formik.handleSubmit();
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Confirmation({ next, prev, setFormData, formData }) {
  const { chain, chains } = getNetwork();

  const account = getAccount();
  const supportedChainSymbols = config.SUPPORTED_NETWORKS.map(
    (network) => network.symbol
  );
  const ActiveChainSymbol = config.SUPPORTED_NETWORKS.find(
    (network) => network.id === chain?.id
  )?.symbol;

  const navigate = useNavigate();

  const [presaleInfo, _presaleInfo] = useState();

  const [creatig, _creating] = useState(false);

  /* Project Details  */
  let {
    logo_url,
    website_url,
    telegram,
    github,
    twitter,
    discord,
    youtube,
    whitelist_link,
    description,
    bannerUrl,
  } = formData;

  //For Disply Time
  const Sdate = new Date(formData?.startDateTime * 1000);
  const Edata = new Date(formData?.endDateTime * 1000);

  const ProjectDetails = {
    stakingLogoUrl: formData?.stakingLogoUrl,
    rewardLogoUrl: formData?.rewardLogoUrl,
    logoUrl: logo_url,
    bannerUrl: bannerUrl,
    websiteUrl: website_url,
    telegramUrl: telegram,
    githubUrl: github,
    twitterUrl: twitter,
    discordUrl: discord,
    youtubePresentationVideoUrl: youtube,
    whitelistContestUrl: whitelist_link,
    redditUrl: 'redditUrl',
    projectDescription: description,
  };

  const {
    data: stackTokenInfo,
    isSuccess: isSuccessStackTokenInfo,
    isError: isErrorStackTokenInfo,
    isLoading: isLoadingStackTokenInfo,
  } = useToken({
    address: formData?.stakeToken,
  });

  const {
    data: rewardTokenInfo,
    isSuccess: isSuccessRewardTokenInfo,
    isError: isErrorRewardTokenInfo,
    isLoading: isLoadingRewardTokenInfo,
  } = useToken({
    address: formData?.rewardToken,
  });

  const {
    name,
    symbol,
    transferrable,
    canClaimAnyTime,
    rewardType,
    rewardRatio,
    startDateTime,
    endDateTime,
    minAmountToStake,
    hardCap,
    stakeToken,
    rewardToken,
    minPeriodToStake,
    claimDateTime,
  } = formData;
  const stackModel = {
    transferrable,
    canClaimAnyTime: !rewardToken ? true : canClaimAnyTime,
    rewardType,
    rewardRatio: ethers.utils
      .parseUnits(String(rewardRatio || 0), rewardTokenInfo?.decimals || 0)
      .toString(),
    startDateTime,
    endDateTime,
    minAmountToStake: ethers.utils
      .parseUnits(String(minAmountToStake || 0), stackTokenInfo?.decimals || 0)
      .toString(),
    hardCap: ethers.utils
      .parseUnits(String(hardCap || 0), stackTokenInfo?.decimals || 0)
      .toString(),
    minPeriodToStake,
    claimDateTime: !rewardToken ? 0 : claimDateTime,
    stakeToken,
    rewardToken: !rewardToken
      ? '0x0000000000000000000000000000000000000000'
      : rewardToken,
  };

  const onCreatePreSaleError = (error) => {
    PostErrorLogAction({
      address: account?.address || 0,
      other_detail: { error },
    });

    console.log('Error 53', error);
    let errStr = error.toString().slice(0, 25);

    if (errStr === 'TransactionExecutionError') {
      toast.error('User Rejected Transaction');
    } else if (error.toString().includes('InvalidTimeForPreSaleToStart')) {
      toast.error('StartTime should be > currentTime', {
        toastId: 'start_time_>_current_time',
      });
    } else if (
      error
        .toString()
        .includes('PreSaleEndTimeShouldBeMoreThanOrEqualToWhiteListTimer')
    ) {
      toast.error(
        'Presale EndTime should be More Than or equal to whiteListTimer!',
        { toastId: 'end_time_morethen' }
      );
    } else if (error.toString().includes('insufficient allowance')) {
      toast.error('Insufficient allowance', {
        toastId: 'insufficient_allowace',
      });
    } else if (error.toString().includes('transfer amount exceeds balance')) {
      toast.error('Transfer amount exceeds balance!', {
        toastId: 'transfer_balance_exceeds_balance',
      });
    } else if (error.toString().includes('startTime<now')) {
      toast.error('startTime should be > now', { toastId: 'sts>NTid' });
    } else if (error.toString().includes('NoClaimTime')) {
      toast.error('Oops, Claim time needs to be at least later than now!', {
        toastId: 'claimDateGreaterCurr',
      });
    } else if (error.toString().includes('endTime<=startTime')) {
      toast.error('endTime should be > startTime', {
        toastId: 'end_time_>_start_time',
      });
    } else {
      toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG);
    }

    _creating(false);
  };

  const onSuccessWaitForTransaction = async ({ logs }) => {
    let lastIndex;
    if (chain?.id == bscTestnet.id) {
      //DynamicChain
      lastIndex = logs.length - 1;
    } else {
      lastIndex = logs.length - 2;
    }
    _creating(true);

    const { args } = decodeEventLog({
      abi: parseAbi([
        'event StakeCreated(address stakeContract, address owner)',
      ]),
      data: logs[lastIndex]?.data,
      topics: [logs[lastIndex]?.topics[0]],
    });

    _presaleInfo(args);

    if (args) {
      try {
        let bodyData = {
          ...args,
          ...stackModel,
          ...ProjectDetails,
          name: name,
          symbol: symbol,
          chain: chain?.id,
          stackTokenInfo: {
            address: stackTokenInfo.address,
            decimals: stackTokenInfo.decimals,
            name: stackTokenInfo.name,
            symbol: stackTokenInfo.symbol,
          },
          rewardTokenInfo: {
            address: rewardTokenInfo?.address || '',
            decimals: rewardTokenInfo?.decimals || '',
            name: rewardTokenInfo?.name || '',
            symbol: rewardTokenInfo?.symbol || '',
          },
        };
        const response = await postStakingAction(bodyData);

        if (response) {
          let button = document.getElementById('openSuccessModel');
          button.click();
        }
      } catch (error) {
        console.log('error: ' + error);
      } finally {
        _creating(false);
      }
    }
  };

  const STACK_MANAGER_ADDRESS = STAKE_MANAGER[chain?.network];

  const {
    data: dataCreateStake,
    isLoading: isLoadingCreateStake,
    isSuccess: isSuccessCreateStake,
    isError: isErrorCreateStake,
    write: create,
  } = useContractWrite({
    address: STACK_MANAGER_ADDRESS,
    abi: StackingManagerABI.abi,
    functionName: 'create',
    args: [name, symbol, stackModel, ProjectDetails],
    onError: onCreatePreSaleError,
  });

  const {
    data: dataWFTStacking,
    isError: isErrorWTFStaking,
    isLoading: isLoadingWFTStaking,
  } = useWaitForTransaction({
    hash: dataCreateStake?.hash,
    onSuccess: onSuccessWaitForTransaction,
  });

  const doTransaction = async () => {
    if (!account.isConnected) {
      toast.error('Please connect to wallet to create a presale!', {
        toastId: 'pcwTId',
      });
    } else if (!supportedChainSymbols.includes(ActiveChainSymbol)) {
      toast.error('Wrong Network!', { toastId: 'WrongNetworkTId' });
    } else {
      _creating(true);
      try {
        create();
      } catch (error) {
        console.log(error);
        toast.error(error.message);
        _creating(false);
      }
    }
  };

  const [descriptionDisp, _descriptionDisp] = useState(false);

  return (
    <div className='tab'>
      <h4 className='form-card-heading'>Confirmation</h4>
      <div className='approve_token_card text-start col-md-8 mx-auto'>
        <div className='row row-cols-1'>
          <div className='col confirmation_tab_section'>
            <ul>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Name <span className='text-white'>{formData?.name}</span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Symbol <span className='text-white'>{formData?.symbol}</span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Staking Token{' '}
                <span className='text-white'>{stackTokenInfo?.symbol}</span>
              </li>

              <li className='d-flex flex-wrap justify-content-between text-break'>
                Reward Token{' '}
                <span className='text-white'>{rewardTokenInfo?.symbol}</span>
              </li>

              <li className='d-flex flex-wrap justify-content-between text-break'>
                Reward Type{' '}
                <span className='text-white'>
                  {Object.keys(REWARD_TYPE).find(
                    (key) => REWARD_TYPE[key] === formData?.rewardType
                  )}
                </span>
              </li>

              {(formData?.rewardType == '0' || formData?.rewardType == '1') && (
                <li className='d-flex flex-wrap justify-content-between text-break'>
                  Reward Ratio (year){' '}
                  <span className='text-white'>{formData?.rewardRatio}</span>
                </li>
              )}

              <li className='d-flex flex-wrap justify-content-between text-break'>
                Hard Cap <span className='text-white'>{formData?.hardCap}</span>
              </li>

              <li className='d-flex flex-wrap justify-content-between text-break'>
                Min Limit to Stake{' '}
                <span className='text-white'>{formData?.minAmountToStake}</span>
              </li>

              <li className='d-flex flex-wrap justify-content-between text-break'>
                Transferrable{' '}
                <span className='text-white'>
                  {formData?.transferrable == true ? 'Yes' : 'No'}
                </span>
              </li>

              <li className='d-flex flex-wrap justify-content-between text-break'>
                Min Period to stake{' '}
                <span className='text-white'>{formData?.minPeriodToStake}</span>
              </li>

              <li className='d-flex flex-wrap justify-content-between text-break'>
                Start Date Time (IST){' '}
                <span className='text-white'>
                  {moment(Sdate).format('YYYY-MM-DD HH:mm')}
                </span>
              </li>

              <li className='d-flex flex-wrap justify-content-between text-break'>
                End Date Time (IST){' '}
                <span className='text-white'>
                  {moment(Edata).format('YYYY-MM-DD HH:mm')}
                </span>
              </li>

              <li className='d-flex flex-wrap justify-content-between text-break'>
                Staking token LOGO URL{' '}
                <span className='text-white'>{formData?.stakingLogoUrl}</span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Reward token LOGO URL{' '}
                <span className='text-white'>{formData?.rewardLogoUrl}</span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Website{' '}
                <span className='text-white'>{formData?.website_url}</span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Twitter <span className='text-white'>{formData?.twitter}</span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Telegram{' '}
                <span className='text-white'>{formData?.telegram}</span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Github <span className='text-white'>{formData?.github}</span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Discord <span className='text-white'>{formData?.discord}</span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Youtube <span className='text-white'>{formData?.youtube}</span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Whitelist Link{' '}
                <span className='text-white'>{formData?.whitelist_link}</span>
              </li>

              <li className='d-flex flex-wrap justify-content-between text-break'>
                Banner URL
                <span className='text-white'>{formData?.bannerUrl}</span>
              </li>

              <li className='d-flex flex-wrap justify-content-between text-break'>
                Description
                <span>
                  <a href className='text-white'>
                    Read{' '}
                    {descriptionDisp ? (
                      <i
                        className='fa fa-chevron-left ms-2'
                        aria-hidden='true'
                        onClick={() => {
                          _descriptionDisp(false);
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    ) : (
                      <i
                        className='fa fa-chevron-right ms-2'
                        aria-hidden='true'
                        onClick={() => {
                          _descriptionDisp(true);
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    {/* <i
                      className="fa fa-chevron-right ms-2"
                      aria-hidden="true"
                      onClick={()=>{_descriptionDisp(true)}}
                    /> */}
                  </a>
                </span>
              </li>
              {descriptionDisp && (
                <li className='d-flex flex-wrap justify-content-between text-break'>
                  <span>{formData?.description}</span>
                </li>
              )}
            </ul>
          </div>
        </div>
        <hr />
        <div style={{ overflow: 'auto' }}>
          <div className='d-flex gap-3 py-2 justify-content-center'>
            <button
              type='button'
              // id="prevBtn"
              onClick={prev}
              className='m-0 btn btn-outline-primary btn-lg text-white'
              disabled={creatig || isLoadingCreateStake || isLoadingWFTStaking}
            >
              Back
            </button>
            <button
              type='button'
              // id="nextBtn"
              // onclick="nextPrev(1)"
              className='m-0 btn btn-primary btn-lg'
              // data-bs-toggle="modal"
              // data-bs-target="#listedSuccessfullyModal"
              disabled={creatig || isLoadingCreateStake || isLoadingWFTStaking}
              onClick={() => {
                doTransaction();
              }}
            >
              {creatig || isLoadingCreateStake || isLoadingWFTStaking
                ? 'Creating...'
                : 'Complete'}
            </button>
          </div>
        </div>
      </div>
      <button
        type='button'
        hidden={true}
        id='openSuccessModel'
        name='openSuccessModel'
        class='m-0'
        data-bs-toggle='modal'
        data-bs-target='#listedSuccessfullyModal'
      >
        Next
      </button>
      {/* The Modal */}
      <div
        className='modal'
        id='listedSuccessfullyModal'
        data-bs-backdrop='static'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            {/* Modal body */}
            <div className='modal-body p-2 p-md-5 text-center'>
              <img
                src='images/listed_successfully.svg'
                alt='listed successfully'
              />
              <h3 className='my-3'>Good Job!</h3>
              <p>Your project is now listed!</p>
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labor.</p> */}
              <button
                type='button'
                className='btn btn-primary'
                data-bs-dismiss='modal'
                onClick={() => {
                  navigate(
                    `${BASE_URL}stake?stakeContract=${presaleInfo?.stakeContract}&chainId=${chain?.id}`
                  );
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateStack;
