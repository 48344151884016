import React from 'react';

const Container = ({ children, className = '', ...props }) => {
  return (
    <div {...props} className={`container-fluid py-2 py-sm-3  ${className}`}>
      {children}
    </div>
  );
};

export default Container;
