import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import {
  useAccount,
  useContractRead,
  useContractWrite,
  useNetwork,
  useWaitForTransaction,
} from 'wagmi';
import * as Yup from 'yup';
import { SUCCESSMSGS, TRANSACTIONMSGS } from '../../utils/messages';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';
import { PRESALE_MANAGER_LIMIT_ADDRESSESS, SALE_TYPE } from '../../_constant';
import PreSaleManagerWithLimit from '../../_constant/PreSaleManagerWithLimit.json';
import PreSaleManagerEth from '../../_constant/PreSaleManagerEth.json';
import SimpleToken from '../../_constant/SimpleToken.json';
import { fetchBalance } from '@wagmi/core';

import PreSale from '../../_constant/PreSale.json';
import FairLaunch from '../../_constant/FairLaunch.json';
import { delay, formatToken } from '../../utils';
import { getAccount } from '@wagmi/core';
import {
  PostErrorLogAction,
  cancelPresaleAction,
  deleteContributionAction,
  getInvestDetailsAction,
  getPresaleDetailAction,
  postInvestAction,
  saveBlockTransactionsAction,
  updatePresaleAction,
} from '../../redux/apiActions/api.action';
import { blockInvalidChar } from '../../_constant';
import config from '../../config';

export function BuyPresale({
  presaleDetails,
  getInvestDetailsFunc,
  referchFundraise,
  refetchInFBC,
}) {
  const [isDepositing, setIsDepositing] = useState(false);
  const { chain, chains } = useNetwork();
  const PRESALE_MANAGER_ADDRESS =
    PRESALE_MANAGER_LIMIT_ADDRESSESS[chain?.network];

  const { isDisconnected, isConnected, address } = useAccount();

  const account = getAccount();

  let [balance, _balance] = useState();

  const getBalane = async () => {
    try {
      if (account?.address && isConnected) {
        const balance = await fetchBalance({
          address: address || undefined,
        });
        _balance(balance);
      }
    } catch (err) {
      _balance(0);
    }
  };

  useEffect(() => {
    getBalane();
  }, [address, chain]);

  const [dynamicAmountPass, setdynamicAmount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  let dynamicDecimal;
  let minBuy;
  let maxBuy;
  let amountValidateSchema;
  if (
    presaleDetails?.minBuy &&
    presaleDetails?.maxBuy &&
    presaleDetails?.fund_releasing_token
  ) {
    dynamicDecimal =
      presaleDetails?.fund_releasing_token === 'BNB' ||
      presaleDetails?.fund_releasing_token === 'MATIC'
        ? 18
        : presaleDetails?.custom_fund_token_decimal;
    minBuy = ethers.utils
      .formatUnits(String(presaleDetails?.minBuy), dynamicDecimal)
      .toString();
    maxBuy = ethers.utils
      .formatUnits(String(presaleDetails?.maxBuy), dynamicDecimal)
      .toString();

    amountValidateSchema = Yup.object().shape({
      amount: Yup.number()
        .min(minBuy, `Amount should be greater than or equal to ${minBuy}`)
        .max(maxBuy, `Amount should be less than or equal to ${maxBuy}`),
      // .required('Amount is required'),
    });
  }

  const formik = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: amountValidateSchema,
    onSubmit: async (data) => {
      try {
        setIsSubmitting(true);

        const buyTokenResponse = await fetchBalance({
          address: address,
          token: data.buyToken,
        });

        const tokenBalance = buyTokenResponse.data?.value || 0n;

        let dynamicAmount =
          presaleDetails?.buyWithETH == 'true'
            ? ethers.utils.parseUnits(String(data.amount), 18).toString()
            : ethers.utils
                .parseUnits(
                  String(data.amount),
                  Number(presaleDetails?.custom_fund_token_decimal)
                )
                .toString();
        if (dynamicAmount != undefined) {
          // console.log(BigInt(dynamicAmount), tokenBalance, buyTokenResponse, 'dynamicAmoount')
          // if(BigInt(dynamicAmount) > tokenBalance){
          //     formik.setFieldError('amount', 'Insufficient balance.');
          //     return;
          // }
          if (presaleDetails.buyToken === config.HLPR_ADDRESS) {
            if (investorLimit === 0n) {
              formik.setFieldError(
                'amount',
                `You are unable to buy as your buy limit is 0.`
              );
              return;
            } else if (BigInt(dynamicAmount) > investorLimit) {
              formik.setFieldError(
                'amount',
                `Amount should be less than or equal to buy limit (${Number(
                  data.amount
                ).toFixed(1)} <= ${ethers.utils.formatUnits(
                  investorLimit,
                  18
                )}).`
              );
              return;
            }
          }
          setdynamicAmount(dynamicAmount);
          if (presaleDetails?.buyWithETH == 'true') {
            investIntoPreSaleEth();
          } else {
            investIntoPreSaleNotEth();
          }
        }
      } catch (error) {
        console.log('Error', error);
        setIsSubmitting(false);
      }
    },
  });

  const onApproveError = (error) => {
    PostErrorLogAction({
      address: account?.address || 0,
      other_detail: { error },
    });
    console.log('1 Error', error);

    let errStr = error.toString().slice(0, 25);
    if (errStr === 'TransactionExecutionError') {
      toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT);
    } else if (
      error.toString().slice(0, 46) ===
      'ContractFunctionExecutionError: The total cost'
    ) {
      toast.error(TRANSACTIONMSGS.INSUFFICIENT_FUND);
    } else if (error.toString().includes('MaxBuyLimitExceeded')) {
      toast.error(TRANSACTIONMSGS.MAXBUYLIMITEXCEED);
    } else if (error.toString().includes('HardCapLimitExceeded')) {
      toast.error(TRANSACTIONMSGS.HARDCAPLIMITEXCEED);
    } else if (
      error.toString().includes('ERC20: transfer amount exceeds balance')
    ) {
      toast.error(
        `Insufficient ${presaleDetails?.custom_fund_raising_token_symbol}`
      );
    } else if (error.toString().includes('OnlyForLimitHolders')) {
      toast.error(`Only for limit holders.`);
    } else if (error.toString().includes('LimitExceed')) {
      toast.error(`Buy limit exceed.`);
    } else {
      toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG);
    }
    setIsSubmitting(false);
  };

  const investDataNotEthArgs = [
    presaleDetails?.preSale,
    presaleDetails?.custom_fund_token_decimal != undefined &&
    presaleDetails?.buyWithETH &&
    formik?.values.amount
      ? presaleDetails?.buyWithETH == 'true'
        ? ethers.utils.parseUnits(String(formik?.values?.amount), 18).toString()
        : ethers.utils
            .parseUnits(
              String(formik?.values.amount),
              Number(presaleDetails?.custom_fund_token_decimal)
            )
            .toString()
      : '',
  ];

  const {
    data: investDataNotEth,
    isLoading: isInvestNotEthLoading,
    write: investIntoPreSaleNotEth,
  } = useContractWrite({
    address: PRESALE_MANAGER_ADDRESS,
    abi: PreSaleManagerWithLimit.abi,
    functionName: 'investIntoPreSale',
    args: investDataNotEthArgs,
    // args: [presaleDetails?.preSale, (presaleDetails?.custom_fund_token_decimal != undefined && presaleDetails?.buyWithETH && formik?.values.amount) ? presaleDetails?.buyWithETH == "true" ? ethers.utils.parseUnits(String(formik?.values?.amount), 18).toString() : ethers.utils.parseUnits(String(formik?.values.amount), Number(presaleDetails?.custom_fund_token_decimal)).toString() : '',],
    // value:
    onError: onApproveError,
    onSuccess: async ({ hash }) => {
      try {
        await saveBlockTransactionsAction({
          action: 'investIntoPreSale',
          network_type: chain?.id,
          hash: hash,
          other_detail: {},
          address_out: address,
          args: investDataNotEthArgs,
        });
      } catch (error) {
        console.log(error, 'Error saving block transaction');
        PostErrorLogAction({
          address: account?.address || 0,
          other_detail: { error },
        });
      }
    },
  });

  const investDataEthArgs = [presaleDetails?.preSale];

  const {
    data: investDataEth,
    isLoading: isInvestLoading,
    write: investIntoPreSaleEth,
  } = useContractWrite({
    address: PRESALE_MANAGER_ADDRESS,
    abi: PreSaleManagerEth.abi,
    functionName: 'investIntoPreSale',
    args: investDataEthArgs,
    // args: [presaleDetails?.preSale],
    value:
      presaleDetails?.custom_fund_token_decimal != undefined &&
      presaleDetails?.buyWithETH &&
      formik?.values.amount
        ? presaleDetails?.buyWithETH == 'true'
          ? ethers.utils
              .parseUnits(String(formik?.values?.amount), 18)
              .toString()
          : ethers.utils
              .parseUnits(
                String(formik?.values.amount),
                Number(presaleDetails?.custom_fund_token_decimal)
              )
              .toString()
        : '',
    onError: onApproveError,
    onSuccess: async ({ hash }) => {
      try {
        await saveBlockTransactionsAction({
          hash: hash,
          action: 'investIntoPreSale',
          address_out: address,
          args: investDataEthArgs,
          network_type: chain?.id,
          other_detail: {},
        });
      } catch (error) {
        console.log(error, 'Error saving block transaction');
        PostErrorLogAction({
          address: account?.address || 0,
          other_detail: { error },
        });
      }
    },
  });

  const {
    isError,
    isLoading,
    refetch: refetchInvestorContribution,
  } = useContractRead({
    address: config.SUPPORTED_NETWORKS.some(
      (network) => network.id === chain?.id
    )
      ? presaleDetails?.preSale
      : undefined,
    abi: SALE_TYPE.isPresaleOrPrivateSale(presaleDetails?.saleType) ? PreSale.abi : FairLaunch.abi,
    functionName: 'investorContribution',
    args: [address],
  });

  const {
    data: investorLimit,
    isLoading: isLoadingInvestorLimit,
    refetch: refetchInvestorLimit,
  } = useContractRead({
    address: PRESALE_MANAGER_ADDRESS,
    abi: PreSaleManagerWithLimit.abi,
    functionName: 'investorLimits',
    args: [address],
  });
  // console.log(investorLimit, 'investorLimits')

  const {
    isError: ReceiveTokensisError,
    isLoading: ReceiveTokensisLoading,
    refetch: refetchInvestorReceivedTokens,
  } = useContractRead({
    address: config.SUPPORTED_NETWORKS.some(
      (network) => network.id === chain?.id
    )
      ? presaleDetails?.preSale
      : undefined,
    abi: SALE_TYPE.isPresaleOrPrivateSale(presaleDetails?.saleType) ? PreSale.abi : FairLaunch.abi,
    functionName: 'investorReceivedTokens',
    args: [address],
  });

  const onSuccessWaitForTransaction = async (receipt) => {
    setIsDepositing(true);
    refetchAllowance();
    refetchInvestorLimit();
    refetchInFBC();
    try {
      if (SALE_TYPE.isPresaleOrPrivateSale(presaleDetails?.saleType)) {
        const { data: dataContri } = await refetchInvestorContribution();
        const { data: dataReceiv } = await refetchInvestorReceivedTokens();

        // if (dataContri && dataReceiv) {
        //   // let body = { preSale: presaleDetails?.preSale, MyContribution: formatToken(dataContri, presaleDetails?.custom_fund_token_decimal), address: account.address,chain:chain?.id, receivedTokens: formatToken(dataReceiv, presaleDetails?.token_decimal) }
        //   let body = {
        //     preSale: presaleDetails?.preSale,
        //     MyContribution: String(dataContri),
        //     address: account.address,
        //     chain: chain?.id,
        //     receivedTokens: String(dataReceiv),
        //     hash: receipt.transactionHash,
        //   };
        //   /*
        //                 not calling from frontend to prevent security issue
        //             */
        //   // let response = await postInvestAction(body)
        //   // if (response) {
        //   setTimeout(() => {
        //     getInvestDetailsFunc();
        //   }, 2000);

        //   referchFundraise();
        //   // }
        // }
      } else {
        const { data: dataContri } = await refetchInvestorContribution();
        // // const { data: dataReceiv } = await refetchInvestorReceivedTokens()
        // if (dataContri) {
        //   // let body = { preSale: presaleDetails?.preSale, MyContribution: formatToken(dataContri, presaleDetails?.custom_fund_token_decimal), address: account.address,chain:chain?.id, receivedTokens: formatToken(0, presaleDetails?.token_decimal) }
        //   let body = {
        //     preSale: presaleDetails?.preSale,
        //     MyContribution: String(dataContri),
        //     address: account.address,
        //     chain: chain?.id,
        //     receivedTokens: String(0),
        //     hash: receipt.transactionHash,
        //   };
        //   // let response = await postInvestAction(body)
        //   // if (response) {
        //   setTimeout(() => {
        //     getInvestDetailsFunc();
        //   }, 2000);

        //   referchFundraise();
        //   // }
        // }
      }

      let body = {
        preSale: presaleDetails?.preSale,
        address: account.address,
        hash: receipt.transactionHash,
      };

      await delay(4000);
      let response = await postInvestAction(body);
      if (response) {
        getInvestDetailsFunc();
        referchFundraise();
      }
      formik.setFieldValue('amount', '');
      formik.setFieldTouched({});
      formik.setErrors({});
      toast.success(SUCCESSMSGS.INVESTED_CONTRIBUTION_SUCCESS);
    } catch (err) {
      console.log(err, 'error');
    } finally {
      setIsDepositing(false);
    }
  };

  const {
    data,
    isError: isErrorWaitForTrans,
    isLoading: isLoadingWaitForTrans,
  } = useWaitForTransaction({
    hash: investDataEth?.hash,
    onSuccess: onSuccessWaitForTransaction,
  });

  const {
    data: DataNotEth,
    isError: isErrorWaitForTransNotEth,
    isLoading: isLoadingWaitForTransNotEth,
  } = useWaitForTransaction({
    hash: investDataNotEth?.hash,
    onSuccess: onSuccessWaitForTransaction,
  });

  // Check for The Not Eth
  const onErrorAllowance = (error) => {
    PostErrorLogAction({
      address: account?.address || 0,
      other_detail: { error },
    });
    console.log('onErrorAllowance', error);
  };

  const onSuccessAllowance = (data) => {
    // console.log("onSuccessAllowance data", data)
  };

  const {
    data: isAllowanceData,
    isError: isErrorAllowance,
    isLoading: isLoadingAllowance,
    refetch: refetchAllowance,
  } = useContractRead({
    address:
      presaleDetails?.buyToken != undefined
        ? presaleDetails?.buyToken
        : undefined,
    abi: SimpleToken.abi,
    functionName: 'allowance',
    args: [account.address, presaleDetails?.preSale],
    onSuccess: onSuccessAllowance,
    onError: onErrorAllowance,
  });

  const onSuccessApprove = (data) => {
    refetchAllowance();
  };

  const {
    data: approvedData,
    write: approveToken,
    isLoading: isLoadingApprove,
    isSuccess: isApprovedSuccess,
  } = useContractWrite({
    address:
      presaleDetails?.buyToken != undefined
        ? presaleDetails?.buyToken
        : undefined,
    abi: SimpleToken.abi,
    functionName: 'approve',
    args: [
      presaleDetails?.preSale,
      ethers.utils
        .parseUnits(String(formik.values.amount || 0), dynamicDecimal || 0)
        .toString(),
    ],
    onError: onApproveError,
    onSuccess: onSuccessApprove,
  });

  const onSuccessWaitForTransactionApprove = (data) => {
    // toast.success(SUCCESSMSGS.APPROVED_SUCCESS)
    // refetchAllowance()
  };

  const onApproveReceipt = (receipt) => {
    toast.success(SUCCESSMSGS.APPROVED_SUCCESS);
    refetchAllowance();
  };

  // Waiting for tx to mine.
  const { isLoading: isLoadingWaitForTranasaction } = useWaitForTransaction({
    hash: approvedData?.hash,
    onSettled: onApproveReceipt,
    onSuccess: onSuccessWaitForTransactionApprove,
  });

  function isNumberKey(value) {
    value = value ? value : '';
    // alert(value)
    var validNumber = new RegExp(/^[0-9]*(\.[0-9]*)?$/);
    if (validNumber.test(value)) {
      return value;
    } else {
      return false;
    }
  }
  let btn_buy =
    presaleDetails.chain != chain?.id ||
    formik.errors.amount ||
    formik.values.amount == '' ||
    isInvestLoading ||
    isInvestNotEthLoading ||
    isLoadingWaitForTrans ||
    isLoadingWaitForTransNotEth
      ? null
      : 'btn_buy';
  let Approve =
    presaleDetails.chain != chain?.id ||
    formik.errors.amount ||
    formik.values.amount == '' ||
    isInvestLoading ||
    isInvestNotEthLoading ||
    isLoadingWaitForTrans ||
    isLoadingWaitForTransNotEth ||
    isLoadingApprove ||
    isLoadingWaitForTranasaction
      ? null
      : 'btn_buy';
  return (
    <>
      <p className='text-center text-sm-end mb-4 text-white fs-55 fw-normal'>
        Contribute
      </p>
      <div className='d-flex gap-2 align-items-stretch'>
        <div className=''>
          <div className='contribute_amount_input'>
            <input
              type='number'
              onWheel={(e) => e.target.blur()}
              onKeyDown={blockInvalidChar}
              name='amount'
              placeholder='Amount'
              className={`btn-border-blue no-arrow ${
                formik.errors.amount && 'invalid'
              }`}
              // onChange={formik.handleChange}
              value={formik.values.amount}
              // onChange={(e) => {
              //     const inputValue = isNumberKey(e.target.value);
              //     // const numericValue = inputValue.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except dot (.)
              //     const numericValue = inputValue; // Remove non-numeric characters except dot (.)
              //     if (numericValue !== false) {
              //         if (e.target.value.length < 21) {
              //             formik.handleChange({
              //                 target: {
              //                     name: "amount",
              //                     value: numericValue,
              //                 },
              //             });
              //         }
              //     }

              // }}
              onChange={(e) => {
                const inputValue = e.target.value;
                const decimalPart = inputValue.split('.')[1];
                let limitedNumericValue = inputValue;
                if (decimalPart && decimalPart.length > 18) {
                  limitedNumericValue =
                    inputValue.split('.')[0] + '.' + decimalPart.slice(0, 18);
                }
                formik.handleChange({
                  target: {
                    name: 'amount',
                    value: limitedNumericValue,
                  },
                });
              }}
              disabled={
                isInvestLoading ||
                isInvestNotEthLoading ||
                isLoadingWaitForTrans ||
                isLoadingWaitForTransNotEth ||
                isLoadingApprove ||
                isLoadingWaitForTranasaction
              }
            />
          </div>
          {formik.errors.amount && (
            <p className='error-msg m-0 mt-1' style={{ lineHeight: '20px' }}>
              {formik.errors.amount}
            </p>
          )}
        </div>
        <>
          {presaleDetails?.buyWithETH == 'false' ? (
            <>
              {isAllowanceData == undefined ||
              Number(
                ethers.utils
                  .formatUnits(
                    String(isAllowanceData || 0),
                    Number(presaleDetails?.custom_fund_token_decimal || 0)
                  )
                  .toString()
              ) < Number(formik.values.amount) ? (
                <button
                  className={`${Approve} btn btn-primary btn-lg rounded-3 d-flex align-items-center justify-content-center`}
                  type='button'
                  style={{
                    height: '46.2px',
                  }}
                  disabled={
                    presaleDetails.chain != chain?.id ||
                    formik.errors.amount ||
                    formik.values.amount == '' ||
                    isInvestLoading ||
                    isInvestNotEthLoading ||
                    isLoadingWaitForTrans ||
                    isLoadingWaitForTransNotEth ||
                    isLoadingApprove ||
                    isLoadingWaitForTranasaction
                  }
                  onClick={approveToken}
                  // onClick={() => investIntoPreSale()}
                >
                  {isLoadingApprove || isLoadingWaitForTranasaction
                    ? 'Approving..'
                    : 'Approve'}
                </button>
              ) : (
                <button
                  className={`${btn_buy} btn btn-primary btn-lg rounded-3 d-flex align-items-center justify-content-center`}
                  type='button'
                  style={{
                    height: '46.2px',
                  }}
                  disabled={
                    presaleDetails.chain != chain?.id ||
                    formik.errors.amount ||
                    formik.values.amount == '' ||
                    isInvestLoading ||
                    isInvestNotEthLoading ||
                    isLoadingWaitForTrans ||
                    isLoadingWaitForTransNotEth ||
                    isDepositing
                  }
                  onClick={(event) => {
                    event.preventDefault();
                    formik.handleSubmit();
                  }}
                  // onClick={() => investIntoPreSale()}
                >
                  {isInvestLoading ||
                  isInvestNotEthLoading ||
                  isLoadingWaitForTrans ||
                  isLoadingWaitForTransNotEth ||
                  isDepositing
                    ? 'Depositing...'
                    : 'Buy'}
                </button>
              )}
            </>
          ) : (
            <button
              className={`${btn_buy} btn btn-primary btn-lg rounded-3 d-flex align-items-center justify-content-center`}
              type='button'
              style={{
                height: '46.2px',
              }}
              disabled={
                presaleDetails.chain != chain?.id ||
                formik.errors.amount ||
                formik.values.amount == '' ||
                isInvestLoading ||
                isInvestNotEthLoading ||
                isLoadingWaitForTrans ||
                isLoadingWaitForTransNotEth ||
                isDepositing
              }
              onClick={(event) => {
                event.preventDefault();
                formik.handleSubmit();
              }}
              // onClick={() => investIntoPreSale()}
            >
              {isInvestLoading ||
              isInvestNotEthLoading ||
              isLoadingWaitForTrans ||
              isLoadingWaitForTransNotEth
                ? 'Depositing...'
                : 'Buy'}
            </button>
          )}
        </>
      </div>
      {presaleDetails.buyToken === config.HLPR_ADDRESS && (
        // <div className="text-danger mb-2"> Note: Your investor limit is currently set to 0, preventing any purchases at this time.</div>
        <div className='text-info mb-4'>
          {' '}
          Note: Buy Amount should be less than or equal to buy limit.
        </div>
      )}
      {balance?.formatted == '0' && presaleDetails?.chain == chain?.id && (
        <div className='custom-alert mt-3'>
          <div className='custom-alert-icon'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              aria-hidden='true'
              role='img'
              width='1em'
              height='1em'
              preserveAspectRatio='xMidYMid meet'
              viewBox='0 0 24 24'
            >
              <g id='iconifyReact297'>
                <g id='iconifyReact298'>
                  <path
                    id='iconifyReact299'
                    fill='rgb(255, 193, 7)'
                    d='M22.56 16.3L14.89 3.58a3.43 3.43 0 0 0-5.78 0L1.44 16.3a3 3 0 0 0-.05 3A3.37 3.37 0 0 0 4.33 21h15.34a3.37 3.37 0 0 0 2.94-1.66a3 3 0 0 0-.05-3.04ZM12 17a1 1 0 1 1 1-1a1 1 0 0 1-1 1Zm1-4a1 1 0 0 1-2 0V9a1 1 0 0 1 2 0Z'
                  />
                </g>
              </g>
            </svg>
          </div>
          <div className='custom-alert-message text-center'>
            You have no funds!
          </div>
        </div>
      )}

      {config.HLPR_ADDRESS === presaleDetails?.buyToken && (
        <ul className='presale_address_ui'>
          <li className='d-flex justify-content-between'>
            <p>Buy Limit</p>
            <p>{ethers.utils.formatUnits(investorLimit, 18)} HLPR</p>
          </li>
          <li style={{ display: 'none' }}></li>
        </ul>
      )}
    </>
  );
}
