const Chip = ({ children, onClose = null }) => {
  let className = `badge d-flex align-items-center justify-content-between gap-1`;
  // if (variant === 'outlined') {
  //   className += ` text-bg-outlined-${color}`;
  // } else {
  //   className += ` text-bg-${color}`;
  // }
  return (
    <div
      className={`${className}`}
      style={{
        padding: '7px 12px',
        borderRadius: '13px',
        color: 'white',
        border: '1px solid rgba(128, 129, 149, 0.3)',
      }}
    >
      <span
        className='fs-7 fw-normal'
        style={{
          // fontWeight: '400',
          // lineHeight: '21px',
        }}
      >
        {children}
      </span>
      {onClose && (
        <img
          onClick={onClose}
          className='icon pointer'
          alt='cross'
          src='images/icon/cross-circle.svg'
        />
      )}
    </div>
  );
};

export default Chip;
