import { useState } from 'react';
import Chip from './Chip';

const Filter = ({
  label = 'Filter by',
  data = [],
  onSelect,
  className = '',
  renderOption = null,
  id = 'collapseOne',
  isSearchBar = false,
}) => {
  const [options, setOptions] = useState(data);
  const [inputSearch, setInputSearch] = useState('');

  const handleSearch = (value) => {
    const input = value.toLowerCase();
    const result = data.filter((item) =>
      String(item.label).toLowerCase().includes(input)
    );
    setOptions(result);
  };

  return (
    <div className={`accordion cfilter ${className}`} id='accordionExample'>
      <div className='accordion-item'>
        <h2 className='accordion-header'>
          <button
            className='accordion-button text-muted fs-7 fw-normal'
            type='button'
            style={{
              // fontSize: '14px',
              lineHeight: '21px',
              // fontWeight: 600,
            }}
            data-bs-toggle='collapse'
            data-bs-target={`#${id}`}
            aria-expanded='true'
            aria-controls={`${id}`}
          >
            {label}
          </button>
        </h2>
        <div
          id={`${id}`}
          className='accordion-collapse collapse show'
          data-bs-parent='#accordionExample'
        >
          <div className='accordion-body p-0 mt-2'>
            {/* filter search */}

            {isSearchBar && (
              <div className='filter_searchbar'>
                <input
                  type='text'
                  placeholder='Search'
                  name='search2'
                  onChange={(e) => {
                    const value = e.target.value;
                    setInputSearch(value);
                    handleSearch(value);
                  }}
                  value={inputSearch}
                />
                <div
                  className='searc_btn d-flex justify-content-center align-items-center'
                  onClick={() => handleSearch(inputSearch)}
                >
                  <img
                    className='icon img-fluid'
                    alt='search'
                    src='images/icon/search.svg'
                  />
                </div>
              </div>
            )}

            {/* fitler dropdown item */}
            {renderOption ? (
              options.map((option) => renderOption(option))
            ) : (
              <div className='mt-3'>
                {options.map((option, i) => (
                  <div
                    key={`${label}-option-${i}`}
                    className='option px-2 mt-2 d-flex align-items-center justify-content-between'
                    onClick={() => onSelect(option)}
                  >
                    <div
                      className={`d-flex align-items-start gap-2`}
                      // className={`d-flex align-items-start gap-2 ${
                      //   option.iconURL ? 'ms-3' : ''
                      // }`}
                    >
                      {option.iconURL && (
                        <img
                          alt='network'
                          src={option.iconURL}
                          className='me-1 img-fluid'
                          style={{
                            width: '32px',
                            aspectRatio: 1,
                          }}
                        />
                      )}
                      <div
                        style={{
                          marginTop: option.subLabel ? '-4px' : '0px',
                        }}
                      >
                        <label
                          className='fieldlabels fs-7 fw-medium'
                          style={
                            {
                              // fontSize: '14px',
                              // fontWeight: option.subLabel ? 600 : 500,
                              // lineHeight: '21px',
                            }
                          }
                        >
                          {option.label}
                        </label>
                        {option.subLabel && (
                          <label
                            className='d-block fs-9 fw-normal'
                            style={{
                              // fontSize: '10px',
                              // fontWeight: 400,
                              lineHeight: '15px',
                              color: 'var(--clr-filter-network-sub-heading)',
                            }}
                          >
                            {option.subLabel}
                          </label>
                        )}
                      </div>
                    </div>

                    {!option.subLabel && (
                      <div className='option_count'>7854</div>
                    )}
                  </div>
                ))}
                {options.length === 0 && (
                  <div className='text-center text-gray'>No results found</div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
