import React from 'react';

const PresaleDetailShimmer = () => {
  return (
    <div className='row mt-4'>
      <div className='col-12 col-lg'>
        <div className='tnbc_custom_card'>
          <div className='custom_card'>
            <div class='card custom_card p-4' aria-hidden='true'>
              <div className='d-flex flex-wrap flex-md-nowrap gap-4 mb-4'>
                <span class='placeholder col-12 card-top-img rounded-4 presale-detail-banner-image-section'></span>
                <h5 class='card-title placeholder-glow col-12 col-md-8'>
                  <span class='placeholder col-8'></span>
                  <span class='placeholder col-10'></span>
                  <span class='placeholder col-12'></span>
                  <span class='placeholder col-12'></span>
                </h5>
              </div>
              <div class='card-text placeholder-glow row row-cols-3 g-1 my-5'>
                <div className='col'>
                  <span class='placeholder col-12 ratio ratio-21x9'></span>
                </div>
                <div className='col'>
                  <span class='placeholder col-12 ratio ratio-21x9'></span>
                </div>
                <div className='col'>
                  <span class='placeholder col-12 ratio ratio-21x9'></span>
                </div>
                <div className='col'>
                  <span class='placeholder col-12 ratio ratio-21x9'></span>
                </div>
                <div className='col'>
                  <span class='placeholder col-12 ratio ratio-21x9'></span>
                </div>
                <div className='col'>
                  <span class='placeholder col-12 ratio ratio-21x9'></span>
                </div>
                <div className='col'>
                  <span class='placeholder col-12 ratio ratio-21x9'></span>
                </div>
                <div className='col'>
                  <span class='placeholder col-12 ratio ratio-21x9'></span>
                </div>
                <div className='col'>
                  <span class='placeholder col-12 ratio ratio-21x9'></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 col-md-12 col-lg-auto mx-auto'>
        <div className='presale_detail_right mx-auto'>
          <div className='custom_card mb-4 p-4 placeholder-glow'>
            <span className='placeholder col-12'></span>
            <span className='placeholder col-12'></span>
            <span className='placeholder col-12 h-25 my-2 ratio ratio-1x1'></span>
            <span className='placeholder col-12'></span>
            <span className='placeholder col-12'></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresaleDetailShimmer;
