import { ethers } from 'ethers';
import {
  BASE_URL,
  PerkCategories,
  SALE_TYPE,
  networkImagesByChain,
} from '../../_constant';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { formatUnits } from 'viem';
import PersaleCountDown from '../../Components/CountDown/PersaleCountDown';
import { useNavigate } from 'react-router-dom';
import Countdown from '../../Components/CountDown/CountDown';

export default function QuickView({ presales, fundRaisedData, loading }) {
  console.log(presales, 'presales');
  const [currentUnixTime, setCurrentUnixTime] = useState(moment().unix());
  const navigate = useNavigate();
  return (
    <div className='col mb-4' id='#quickview'>
      {/* {!loading && ( */}
      <div class='card'>
        <div className='table-responsive'>
          <table class='table view_table mb-0'>
            <thead>
              <tr>
                {/* <th></th> */}
                <th
                  style={{
                    minWidth: '150px',
                  }}
                >
                  Name
                </th>
                <th>Hc</th>
                <th>Coin</th>
                <th>Estimate MC</th>
                {/* <th>BuyTax</th> */}
                {/* <th>SellTax</th> */}
                <th>KYC/Audit/Vetted</th>
                <th>Status</th>
                <th>Links</th>
                {/* <th>TG Status</th> */}
                {/* <th>TW Status</th> */}
                <th>CountDown</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                new Array(10).fill(0).map((item, idx) => (
                  <tr key={idx}>
                    <td colSpan={10} className='placeholder-glow'>
                      <span class='placeholder w-100'></span>
                    </td>
                  </tr>
                ))
              ) : presales && presales.length > 0 ? (
                presales.map((data, key) => {
                  let currentRound;

                  if (data?.endTimes) {
                    data?.endTimes?.split(',')?.forEach((endTime, index) => {
                      if (!currentRound && endTime >= currentUnixTime) {
                        currentRound = {
                          roundNumber: index + 1,
                          startTime: data.startTimes.split(',')[index],
                          endTime,
                        };
                        return;
                      }
                    });
                  }
                  const startTime = Number(currentRound?.startTime || 0);
                  const endTime = Number(currentRound?.endTime || 0);
                  console.log(startTime, endTime, 'startTime');
                  // const startTime = Number(data?.startTime);
                  // const endTime = Number(data?.endTime);

                  let classNameD = 'sale_upcoming';
                  let statusText = 'Upcoming';
                  let startEndIn = 'TBA';

                  if (
                    currentUnixTime >= startTime &&
                    endTime > currentUnixTime
                  ) {
                    classNameD = 'sale_live';
                    statusText = 'Sale Live';
                    startEndIn = 'Sale Ends In';
                  }

                  if (data?.isCanceled == 1) {
                    statusText = 'Sale Cancelled';
                    classNameD = 'sale_upcoming';
                  } else if (data?.setSaleTime == 0) {
                    statusText = 'TBA';
                  } else if (data?.isFinalized == 1) {
                    statusText = 'Listed on dex';
                    startEndIn = 'Listing Time';
                    classNameD = 'listed_on_dex';
                  } else if (
                    currentUnixTime >= startTime &&
                    endTime > currentUnixTime
                  ) {
                    classNameD = 'sale_live';
                    statusText = 'Sale Live';
                    startEndIn = 'Sale Ends In';
                  } else if (currentUnixTime < startTime) {
                    classNameD = 'sale_upcoming';
                    statusText = 'Upcoming';
                    startEndIn = 'Sale Start In';
                  } else if (currentUnixTime > endTime) {
                    statusText = 'Sale Ended';
                    classNameD = 'sale_end';
                    startEndIn = 'Sale Ended';
                  }

                  let progressbarFair;
                  if (fundRaisedData != undefined) {
                    if (
                      data?.isHardCap == 1 &&
                      data?.hardCap != undefined &&
                      fundRaisedData != undefined
                    ) {
                      const fundRaised = Number(
                        ethers.utils.formatUnits(
                          fundRaisedData[key]?.result || 0,
                          data?.custom_fund_token_decimal
                        )
                      );
                      const hardCap_format = Number(
                        ethers.utils.formatUnits(
                          data?.hardCap,
                          data?.custom_fund_token_decimal
                        )
                      );
                      progressbarFair = (fundRaised * 100) / hardCap_format;
                      // _progressBar(progress)
                    } else if (
                      BigInt(fundRaisedData[key]?.result) >
                      BigInt(data?.softCap)
                    ) {
                      // eslint-disable-line no-undef
                      const fundRaised = Number(
                        ethers.utils.formatUnits(
                          fundRaisedData[key]?.result || 0,
                          data?.custom_fund_token_decimal
                        )
                      );
                      progressbarFair = (fundRaised * 100) / fundRaised;
                      // _progressBar(progress)
                    } else {
                      const fundRaised = Number(
                        ethers.utils.formatUnits(
                          fundRaisedData[key]?.result || 0,
                          data?.custom_fund_token_decimal
                        )
                      );
                      const softCap_format = Number(
                        ethers.utils.formatUnits(
                          data?.softCap,
                          data?.custom_fund_token_decimal
                        )
                      );
                      progressbarFair = (fundRaised * 100) / softCap_format;
                      // _progressBar(progress)
                    }
                  }

                  const preogressBar =
                  SALE_TYPE.isPresaleOrPrivateSale(presales?.saleType)
                      ? fundRaisedData != undefined &&
                        Number(
                          (ethers.utils.formatUnits(
                            String(fundRaisedData[key]?.result),
                            data?.custom_fund_token_decimal
                          ) *
                            100) /
                            Number(
                              ethers.utils.formatUnits(
                                String(data?.hardCap),
                                data?.custom_fund_token_decimal
                              )
                            )
                        )
                      : progressbarFair;

                  return (
                    <tr>
                      {/* <td>
                          <img
                            src={networkImagesByChain[data?.chain]}
                            className="img-fluid"
                            alt="icon"
                            width={28}
                          />
                        </td> */}
                      <td>
                        <div className='d-flex align-items-center justify-content-start'>
                          <img
                            src={networkImagesByChain[data?.chain]}
                            className='img-fluid me-2'
                            alt='icon'
                            width={28}
                          />
                          <span className='fs-6 fw-medium'>
                            {data?.sale_without_token == 1 && startTime == '0'
                              ? data?.sale_title
                              : data?.launch_stealth_sale == true
                              ? data?.sale_title
                              : data?.name}
                          </span>
                        </div>
                      </td>
                      <td>
                        {ethers.utils.formatUnits(
                          String(data?.hardCap),
                          data?.custom_fund_token_decimal
                        )}
                      </td>
                      <td>BNB</td>
                      <td>{Number(data?.estimated_mc).toFixed(2)}</td>
                      {/* <td>-</td> */}
                      {/* <td>-</td> */}
                      <td>
                        <ul className='d-flex gap-2'>
                          {data?.perk != PerkCategories.STANDARD ? (
                            <>
                              <li>
                                <img
                                  src='images/presale/tick.svg'
                                  className=''
                                  alt='icon'
                                  width={25}
                                />
                              </li>
                              <li>
                                <img
                                  src='images/presale/tick.svg'
                                  className=''
                                  alt='icon'
                                  width={25}
                                />
                              </li>
                            </>
                          ) : (
                            <>
                              <li>
                                <img
                                  src='images/presale/close.svg'
                                  className=''
                                  alt='icon'
                                  width={25}
                                />
                              </li>
                              <li>
                                <img
                                  src='images/presale/close.svg'
                                  className=''
                                  alt='icon'
                                  width={25}
                                />
                              </li>
                            </>
                          )}
                          {data?.isTeamVesting == 1 ? (
                            <li>
                              <img
                                src='images/presale/tick.svg'
                                className=''
                                alt='icon'
                                width={25}
                              />
                            </li>
                          ) : (
                            <li>
                              <img
                                src='images/presale/close.svg'
                                className=''
                                alt='icon'
                                width={25}
                              />
                            </li>
                          )}
                          {/* <li>
                              <img
                                src="images/presale/close.svg"
                                className="img-fluid"
                                alt="icon"
                                width={25}
                              />
                            </li>
                            <li>
                              <img
                                src="images/presale/close.svg"
                                className="img-fluid"
                                alt="icon"
                                width={25}
                              />
                            </li> */}
                          {/* <li>
                              <img
                                src="images/presale/tick.svg"
                                className="img-fluid"
                                alt="icon"
                                width={25}
                              />
                            </li> */}
                        </ul>
                      </td>
                      <td>
                        {fundRaisedData != undefined &&
                        Number(
                          ethers.utils.formatUnits(
                            String(fundRaisedData[key]?.result),
                            Number(data?.custom_fund_token_decimal)
                          )
                        ) <
                          Number(
                            ethers.utils.formatUnits(
                              String(data?.softCap),
                              Number(data?.custom_fund_token_decimal)
                            )
                          ) &&
                        currentUnixTime > endTime &&
                        data?.isCanceled == 0 &&
                        data?.setSaleTime == 1 ? (
                          <span className='fs-8 fw-normal text-nowrap'>Sale Failed</span>
                        ) : Number(moment(new Date()).unix()) >=
                          Number(startTime) ? (
                          <>
                            <span className='fs-8 fw-normal text-nowrap'>
                              {preogressBar.toFixed(2) || 0}%
                            </span>
                          </>
                        ) : (
                          <span className='fs-8 fw-normal text-nowrap'>Upcoming</span>
                        )}
                        <div
                          className='progress mt-1'
                          style={{
                            backgroundColor: 'rgb(33, 43, 54)',
                            height: '4px',
                            maxWidth: '160px',
                          }}
                        >
                          <div
                            className='progress-bar progress-bar-info text-danger'
                            role='progressbar'
                            aria-valuenow={90}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            style={{
                              width: `${
                                Number(moment(new Date()).unix()) >=
                                Number(startTime)
                                  ? Number(preogressBar || 0)
                                  : 0
                              }%`,
                              overflow: 'visible',
                            }}
                          ></div>
                        </div>
                      </td>
                      <td>
                        <ul
                          className='d-flex justify-content-center gap-2'
                          style={{ cursor: 'pointer' }}
                        >
                          {data?.telegramUrl != '' && (
                            <li
                              onClick={() => {
                                window.open(data?.telegramUrl);
                              }}
                            >
                              <img
                                src='images/presale/telegram.svg'
                                alt='icon'
                                className='icon'
                                width={30}
                              />
                            </li>
                          )}
                          {data?.twitterUrl != '' && (
                            <li
                              onClick={() => {
                                window.open(data?.twitterUrl);
                              }}
                            >
                              <img
                                src='images/presale/twitter.svg'
                                alt='icon'
                                className='icon'
                                width={30}
                              />
                            </li>
                          )}
                          {data?.websiteUrl != '' && (
                            <li
                              onClick={() => {
                                window.open(data?.websiteUrl);
                              }}
                            >
                              <img
                                src='images/presale/wordwide.svg'
                                alt='icon'
                                className='icon'
                                width={30}
                              />
                            </li>
                          )}
                          {/* <li>
                                <img
                                  src="images/presale/group.png"
                                  className="img-fluid"
                                  alt="icon"
                                  width={25}
                                />
                              </li> */}
                        </ul>
                      </td>
                      {/* <td>-</td> */}
                      {/* <td>-</td> */}
                      <td>
                        {fundRaisedData != undefined &&
                        Number(
                          ethers.utils.formatUnits(
                            String(fundRaisedData[key]?.result),
                            Number(data?.custom_fund_token_decimal)
                          )
                        ) <
                          Number(
                            ethers.utils.formatUnits(
                              String(data?.softCap),
                              Number(data?.custom_fund_token_decimal)
                            )
                          ) &&
                        currentUnixTime > endTime &&
                        data?.isCanceled == 0 &&
                        data?.setSaleTime == 1 ? ( //eslint-disable-line no-undef
                          <span className='badge text-bg-danger'>Failed</span>
                        ) : data?.isCanceled === 1 ? (
                          <span className='badge text-bg-light'>Cancelled</span>
                        ) : data?.isFinalized === 1 &&
                          moment(new Date()).unix() >
                            data?.estimatedDexListingTime ? (
                          <span className='badge text-bg-secondary'>
                            Listed
                          </span>
                        ) : data?.setSaleTime === 1 ? (
                          <>
                            {Number(moment(new Date()).unix()) <
                              data?.estimatedDexListingTime &&
                            data?.isFinalized == 1 ? (
                              <span>
                                <Countdown
                                  unixTime={data?.estimatedDexListingTime}
                                />
                              </span>
                            ) : (
                              <>
                                {Number(moment(new Date()).unix()) >=
                                  Number(startTime) &&
                                Number(moment(new Date()).unix()) <
                                  Number(endTime) ? (
                                  // <PersaleCountDown unixTime={data?.endTime} />
                                  <span className='badge text-bg-success'>
                                    Live
                                  </span>
                                ) : Number(moment(new Date()).unix()) <
                                  Number(startTime) ? (
                                  <Countdown unixTime={startTime} />
                                ) : (
                                  <span className='badge text-bg-success'>
                                    Ended
                                  </span>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          'Upcoming'
                        )}
                      </td>
                      <td>
                        <button
                          // className='btn btn-outline-primary btn-sm'
                          className='btn badge'
                          onClick={() => {
                            navigate(
                              `${BASE_URL}presale?presale_id=${data?.preSale}&chainId=${data?.chain}`
                            );
                          }}
                        >
                          View
                        </button>
                        {/* <a href={`${BASE_URL}presale?presale_id=${data?.preSale}&chainId=${data?.chain}`} target="_blank">
                            View
                          </a> */}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr
                //  className="d-flex justify-content-center w-100 fs-7"
                >
                  <td colSpan={10} className='text-center'>
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* )} */}
    </div>
  );
}
