import React from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  PostErrorLogAction,
  cancelPresaleAction,
  deleteContributionAction,
  getInvestDetailsAction,
  getPresaleDetailAction,
  postInvestAction,
  updatePresaleAction,
  updatePresaleRDexLRAction,
} from '../../redux/apiActions/api.action';
import { useState } from 'react';
import { ethers } from 'ethers';
import queryString from 'query-string';
import {
  useNetwork,
  useAccount,
  useContractWrite,
  useContractRead,
  useWaitForTransaction,
  useBalance,
  useContractReads,
} from 'wagmi';
import { switchNetwork, fetchBalance } from '@wagmi/core';
import {
  PRESALE_MANAGER_LIMIT_ADDRESSESS,
  PRESALE_STATUS,
  PerkCategories,
  SALE_TYPE,
  blockInvalidChar,
  networkImages,
  networkImagesByChain,
  networkLinks,
} from '../../_constant';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import PreSale from '../../_constant/PreSale.json';
import FairLaunch from '../../_constant/FairLaunch.json';
import Web3 from 'web3';
import YouTube, { YouTubeProps } from 'react-youtube';
import { useMemo } from 'react';
import Countdown from '../../Components/CountDown/CountDown';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { readContract } from '@wagmi/core';
import PreSaleManager from '../../_constant/PreSaleManager.json';
import PreSaleManagerEth from '../../_constant/PreSaleManagerEth.json';
import { getAccount } from '@wagmi/core';
import { formatToken } from '../../utils';
import { SUCCESSMSGS, TRANSACTIONMSGS } from '../../utils/messages';
import { TokenomicsPieChart } from './TokenomicsPieChart';
import { ManagePool } from './ManagePool';
import { TokenInfo } from './TokenInfo';
import { useConnect } from 'wagmi';
import {
  projectInfoSchema,
  updateProjectDetailsSchema,
} from '../../validators/Presale/presale';
import discordSvg from './discord.svg';
import { BuyPresale } from './BuyPresale';
import { BASE_URL } from '../../_constant';
import { Claim } from './Claim';
import { VestingScheduke } from './VestingSchedule';
import { useWeb3Modal } from '@web3modal/react';
import CnbtPresaleDetails from './CnbtPresaleDetails';
import CnbtFairLaunchDetails from './CnbtFairLauchDetails';
import { ManagePoolFair } from './ManagePoolFair';
import { SyncLoader } from 'react-spinners';
import Banner from './Banner';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import config from '../../config';
import SubHeader from '../../Components/header/SubHeader';
import AllSocialLinks from '../../Components/presale-details/AllSocialLinks';
import CrossGrid from '../../Components/CrossGrid';
import PresaleDetailShimmer from '../../Components/shimmers/PresaleDetailShimmer';
import Container from '../../Components/Layouts/Container';

export default function PresaleDetails() {
  const { chain, chains } = useNetwork();

  const account = getAccount();

  const currentUnixTime = moment().unix();

  const PRESALE_MANAGER_ADDRESS =
    PRESALE_MANAGER_LIMIT_ADDRESSESS[chain?.network];

  const { isDisconnected, isConnected, address } = useAccount();

  const navigate = useNavigate();
  // console.log(navigate())

  const [presaleDetails, setPresaleDetails] = useState([]);

  const [loading, setLoading] = useState(true);

  const { search } = window.location;
  const { presale_id } = queryString.parse(search);

  const [tokenForPresale, setTokenForPresale] = useState();
  const [bonusSpotLeft, setBonusSpotLeft] = useState(0);

  const [tokenForLiqidity, setTokenForLiquidity] = useState();

  const [tflFairm, _tflFair] = useState();

  const [Sdate, setSdate] = useState(0);
  const [Edata, setEdata] = useState(0);
  const [EdexDate, setEdexDate] = useState(0);

  const [videoId, setVideoId] = useState('');

  const not_custom_then_symbol = config.SUPPORTED_NETWORKS.find(
    (network) => network.id == presaleDetails?.chain
  )?.symbol;
  const fundRaisingTokenDecimal = presaleDetails?.custom_fund_token_decimal;
  const fundRaisingTokenSymbol =
    presaleDetails?.fund_releasing_token == 'Custom' ||
    presaleDetails?.fund_releasing_token == 'BUSD' ||
    presaleDetails?.fund_releasing_token == 'USDT'
      ? presaleDetails?.custom_fund_raising_token_symbol
      : not_custom_then_symbol || '';

  const getPresaleFunc = async () => {
    try {
      setLoading(true);
      const { data } = await getPresaleDetailAction({ id: presale_id });

      // calculate tokenfor Presale

      const hardCap = BigInt(data?.hardCap); // eslint-disable-line no-undef
      // const rate = BigInt(data?.preSaleRate);  // eslint-disable-line no-undef

      let amountActual = 0n;
      data.rounds.forEach((round) => {
        const rate = BigInt(round.preSaleRate);
        amountActual +=
          (hardCap * rate) /
          BigInt(10) ** BigInt(data?.custom_fund_token_decimal); // eslint-disable-line no-undef
      });
      // for(let i = 0; i < data?.preSaleRates.split(",").length; i++) {
      //   const rate = BigInt(data?.preSaleRates.split(',')[i]);
      //   amountActual += (hardCap * rate) / BigInt(10) ** BigInt(data?.custom_fund_token_decimal); // eslint-disable-line no-undef
      // }
      // const amountActual =
      //   (hardCap * rate) / BigInt(10) ** BigInt(data?.custom_fund_token_decimal); // eslint-disable-line no-undef
      const amountFormatted = ethers.utils.formatUnits(
        amountActual.toString(),
        data?.token_decimal
      );
      setTokenForPresale(amountFormatted);

      // calculate Tokens For Liquidity
      const dexLiquidityPercentage = BigInt(data?.dexLiquidityPercentage); // eslint-disable-line no-undef
      const dexListingRate = BigInt(data?.dexListingRate); // eslint-disable-line no-undef
      const amountActualToLiq =
        (hardCap * dexListingRate * dexLiquidityPercentage) /
        BigInt(10) ** BigInt(Number(data?.custom_fund_token_decimal) + 2); // eslint-disable-line no-undef

      const amountFormattedToLiq = ethers.utils.formatUnits(
        amountActualToLiq.toString(),
        Number(data?.token_decimal)
      );

      if (SALE_TYPE.isPresaleOrPrivateSale(data?.saleType)) {
        setTokenForLiquidity(amountFormattedToLiq);
      } else {
        const fundRaisingTokenDecimal = data?.custom_fund_token_decimal;
        const fairLaunchTokenForLiq =
          (BigInt(data?.preSaleAmount) *
            BigInt(data?.preSaleRatePercentage) *
            BigInt(data?.dexLiquidityPercentage)) /
          BigInt(10000); // eslint-disable-line no-undef
        const parsefFair = ethers.utils
          .formatUnits(
            String(BigInt(fairLaunchTokenForLiq)),
            Number(data?.token_decimal || 0)
          )
          .toString(); // eslint-disable-line no-undef
        _tflFair(parsefFair);
        // setTokenForLiquidity(parsefFair)
        // setTokenForLiquidity()
      }
      // presaledata store main state
      setPresaleDetails(data);

      // Dates
      setSdate(new Date(data?.startTime * 1000));
      setEdata(new Date(data?.endTime * 1000));
      setEdexDate(new Date(data?.estimatedDexListingTime * 1000));

      const videoId = extractVideoId(data?.youtubePresentationVideoUrl);

      setVideoId(videoId);
    } catch (error) {
      console.log(error, 'error');
      // alert("error")
      navigate('/404');
    } finally {
      setLoading(false);
    }
  };

  const getPresaleFuncWithoutLoading = async () => {
    const { data } = await getPresaleDetailAction({ id: presale_id });

    // calculate tokenfor Presale

    const hardCap = BigInt(data?.hardCap); // eslint-disable-line no-undef
    // const rate = BigInt(data?.preSaleRate);  // eslint-disable-line no-undef
    // const amountActual =
    //   (hardCap * rate) / BigInt(10) ** BigInt(data?.custom_fund_token_decimal); // eslint-disable-line no-undef

    let amountActual = 0n;
    data.rounds.forEach((round) => {
      const rate = BigInt(round.preSaleRate);
      console.log(rate, 'rate');
      amountActual +=
        (hardCap * rate) /
        BigInt(10) ** BigInt(data?.custom_fund_token_decimal); // eslint-disable-line no-undef
    });

    const amountFormatted = ethers.utils.formatUnits(
      amountActual.toString(),
      data?.token_decimal
    );
    console.log(amountFormatted, 'ammount from getPresaleFuncWithoutLoading');
    setTokenForPresale(amountFormatted);

    // calculate Tokens For Liquidity
    const dexLiquidityPercentage = BigInt(data?.dexLiquidityPercentage); // eslint-disable-line no-undef
    const dexListingRate = BigInt(data?.dexListingRate); // eslint-disable-line no-undef
    const amountActualToLiq =
      (hardCap * dexListingRate * dexLiquidityPercentage) /
      BigInt(10) ** BigInt(Number(data?.custom_fund_token_decimal) + 2); // eslint-disable-line no-undef

    const amountFormattedToLiq = ethers.utils.formatUnits(
      amountActualToLiq.toString(),
      Number(data?.token_decimal)
    );
    setTokenForLiquidity(amountFormattedToLiq);

    // presaledata store main state
    setPresaleDetails(data);

    // Dates
    setSdate(new Date(data?.startTime * 1000));
    setEdata(new Date(data?.endTime * 1000));
    setEdexDate(new Date(data?.estimatedDexListingTime * 1000));

    const videoId = extractVideoId(data?.youtubePresentationVideoUrl);

    setVideoId(videoId);
  };

  useEffect(() => {
    getPresaleFunc();
  }, []);

  const handleSwitchNetwork = async () => {
    try {
      if (presaleDetails?.chain != chain?.id) {
        // _isConfirm({id:chain,isConfirming:true})
        const network = await switchNetwork({
          chainId: Number(presaleDetails?.chain),
        });
      }
    } catch (error) {
      console.log('error', error);
      // _isConfirm({id:'',isConfirming:false})
    }
  };

  const roundData = useMemo(() => {
    if (!presaleDetails?.rounds) return {};
    let currentRoundName = 'Round 1';
    const currentRound = presaleDetails?.rounds.find((round, index) => {
      if (round.endTime >= currentUnixTime) {
        currentRoundName = `Round ${index + 1}`;
        return round;
      }
    });
    return {
      rounds: presaleDetails?.rounds || [],
      currentRound: { ...currentRound, name: currentRoundName },
    };
  }, [presaleDetails]);

  const startTime = Number(roundData?.currentRound?.startTime || 0);
  const endTime = Number(roundData?.currentRound?.endTime || 0);
  let classNameD = 'sale_upcoming';
  let statusText = 'Upcoming';
  let startEndIn = 'TBA';

  if (presaleDetails?.is_approved === PRESALE_STATUS.pending) {
    classNameD = 'sale_upcoming';
    statusText = 'Pending';
    startEndIn = `Waiting for approval`;
  } else if (presaleDetails?.is_approved === PRESALE_STATUS.rejected) {
    classNameD = 'sale_end';
    statusText = 'Sale Rejected';
    startEndIn = `Rejected`;
  } else if (presaleDetails?.isCanceled == 1) {
    statusText = 'Sale cancelled';
  } else if (presaleDetails?.setSaleTime == 0) {
    statusText = 'TBA';
  } else if (presaleDetails?.isFinalized == 1) {
    if (currentUnixTime >= presaleDetails?.estimatedDexListingTime) {
      if (presaleDetails?.listOnDex) {
        statusText = 'Listed on dex';
        classNameD = 'listed_on_dex';
      } else {
        statusText = 'Finalized';
        classNameD = 'listed_on_dex';
      }
    } else {
      statusText = 'LIQ Unlocked';
      classNameD = 'liq_unlocked';
    }
  } else if (currentUnixTime >= startTime && endTime > currentUnixTime) {
    classNameD = 'sale_live';
    statusText = 'Sale Live';
    startEndIn = `${roundData.currentRound?.name} Sale Ends In`;
  } else if (currentUnixTime < startTime) {
    classNameD = 'sale_upcoming';
    statusText = 'Upcoming';
    startEndIn = `${roundData.currentRound?.name} Sale Start In`;
  } else if (currentUnixTime > endTime) {
    statusText = 'Sale Ended';
    classNameD = 'sale_end';
    startEndIn = 'Sale Ended';
  }

  const onPlayerReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const opts = {
    height: '350',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      quality: 'highres',
    },
  };

  function extractVideoId(url) {
    const regExp =
      /^(?:(?:https?:)?\/\/)?(?:(?:www|m)\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/|v\/|youtu\.be\/|\/v\/|user\/[^#]*#([^\/]*?\/)*)?([^\?&"'>]+)/i;
    const match = url.match(regExp);

    if (match && match[2]) {
      return match[2];
    }

    return null;
  }

  // const [amount,_amount]=useState()
  // const [amoutError,setAmountError] =useState(false)

  // let onChangeAmount=({target})=>{
  //   let {value}=target;
  //   if(!value){
  //     setAmountError(false)
  //     _amount(value)
  //   }else{
  //     let dynamicDecimal = presaleDetails?.fund_releasing_token=="BNB"?18:presaleDetails?.custom_fund_token_decimal;
  //     let minBuy = ethers.utils.formatUnits(String(presaleDetails?.minBuy), dynamicDecimal).toString()
  //     let maxBuy = ethers.utils.formatUnits(String(presaleDetails?.maxBuy), dynamicDecimal).toString()
  //     if(value>= minBuy && value<= maxBuy){
  //       setAmountError(false)
  //       _amount(value)
  //     }else{
  //       setAmountError(true)
  //       _amount(value)
  //     }
  //   }

  // }

  async function getInvestDetailsFunc() {
    if (presaleDetails?.preSale) {
      let body = {
        preSale: presaleDetails.preSale,
        address: address,
        chain: chain?.id,
      };
      let { data } = await getInvestDetailsAction(body);
      setInvestDetails(data);
    }
  }

  // console.log("dynamicAmountPass",dynamicAmountPass)

  // useEffect(() => {
  //   if (dynamicAmountPass !== 0) {
  //     alert("calle")
  //     if(presaleDetails?.buyWithETH=="true") {
  //       investIntoPreSaleEth();
  //     }else{

  //     }
  //   }
  // }, [dynamicAmountPass]);

  // investCOnt = custom_fund_token_decimal

  // receiveTOken = token_decimal

  // console.log("investorContribution",formatToken(investorContribution,presaleDetails?.custom_fund_token_decimal))
  // console.log("investorReceivedTokens",formatToken(investorReceivedTokens,dynamicDecimal))

  // To get the investorsForBonusCount
  const onErrorForBonusCount = (error) => {
    console.log('onErrorForBonusCount', error);
    PostErrorLogAction({
      address: account?.address || 0,
      other_detail: { error },
    });
  };

  const inSuccessForBonusCount = (data) => {
    setBonusSpotLeft(
      Number(presaleDetails?.noOfBonusEligibleInvestors - Number(data))
    );
  };

  const {
    data: investData,
    isError: isErrorInvestorForBonusCount,
    isLoading: isLoadingInvestorForBonusCount,
    refetch: refetchInFBC,
  } = useContractRead({
    address: config.SUPPORTED_NETWORKS.some(
      (network) => network.id === chain?.id
    )
      ? presaleDetails?.preSale
      : undefined,
    abi: SALE_TYPE.isPresaleOrPrivateSale(presaleDetails?.saleType) ? PreSale.abi : FairLaunch.abi,
    functionName: 'investorsForBonusCount',
    onError: onErrorForBonusCount,
    onSuccess: inSuccessForBonusCount,
  });

  let [investDetails, setInvestDetails] = useState();

  useEffect(() => {
    getInvestDetailsFunc();
  }, [presaleDetails, address]);

  const onemergencyWithdrawError = (error) => {
    PostErrorLogAction({
      address: account?.address || 0,
      other_detail: { error },
    });
    console.log('10 Error', error);
    let errStr = error.toString().slice(0, 25);
    if (errStr === 'TransactionExecutionError') {
      toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT);
    } else {
      toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG);
    }
  };

  const onSuccessEmw = async (data) => {
    // Api call
    let response = await deleteContributionAction({
      preSale: presaleDetails?.preSale,
      address: address,
    });
    if (response) {
      getInvestDetailsFunc();
      toast.success(SUCCESSMSGS.EMERGENCY_WITHDRAWAL_SUCCESS);
    }
  };

  /**
   * todo : Emergency withdraw contribution
   */
  const {
    data: dataEmgcyWithDraw,
    isLoading: isLoadingEmgcyWithDraw,
    write: emergencyWithdraw,
  } = useContractWrite({
    address: PRESALE_MANAGER_ADDRESS,
    abi: PreSaleManager.abi,
    functionName: 'emergencyWithdrawContribution',
    args: [presaleDetails?.preSale],
    onError: onemergencyWithdrawError,
    onSuccess: onSuccessEmw,
  });

  const onSuccesEmwWft = (receipt) => {
    // console.log("receipt", receipt)
    updateDexAndPresaleRateDB();
    referchFundraise();
  };

  const onSettledEmwWft = (receipt) => {
    referchFundraise();
    // updateDexAndPresaleRateDB()
  };

  const {
    data: dataEmwWft,
    isError: isErrorEmWithWft,
    isLoading: isLoadingEmwWft,
  } = useWaitForTransaction({
    hash: dataEmgcyWithDraw?.hash,
    onSettled: onSettledEmwWft,
    onSuccess: onSuccesEmwWft,
  });

  const [progreeBar, _progressBar] = useState(0);

  const onSuccessFundRaised = (data) => {
    if (presaleDetails?.hardCap) {
      const fundRaised = ethers.utils.formatUnits(
        String(data || 0),
        Number(fundRaisingTokenDecimal)
      );
      const hardCap = ethers.utils.formatUnits(
        String(presaleDetails?.hardCap || 0),
        Number(fundRaisingTokenDecimal)
      );
      const progress = (fundRaised * 100) / hardCap;
      _progressBar(progress);
    } else {
    }
  };

  const {
    data: isDataFundRaised,
    isError: isErrorFundRaised,
    isLoading: isLoadingFundRaised,
    refetch: referchFundraise,
  } = useContractRead({
    address: presaleDetails.preSale,
    abi: SALE_TYPE.isPresaleOrPrivateSale(presaleDetails?.saleType) ? PreSale.abi : FairLaunch.abi,
    functionName: 'fundRaised',
    onSuccess: onSuccessFundRaised,
    chainId: presaleDetails?.chain,
  });

  // Update presale Details
  const formikUpdatePool = useFormik({
    enableReinitialize: true,
    initialValues: {
      logoUrl: presaleDetails?.logoUrl,
      websiteUrl: presaleDetails?.websiteUrl,
      twitterUrl: presaleDetails?.twitterUrl,
      githubUrl: presaleDetails?.githubUrl,
      telegramUrl: presaleDetails?.telegramUrl,
      discordUrl: presaleDetails?.discordUrl,
      youtubePresentationVideoUrl: presaleDetails?.youtubePresentationVideoUrl,
      whitelistContestUrl: presaleDetails?.whiteListContestUrl,
      projectDescription: presaleDetails?.projectDescription,
      bannerUrl: presaleDetails?.bannerUrl,
    },
    validationSchema: updateProjectDetailsSchema,
    onSubmit: async (data) => {
      try {
        var button = document.getElementById('prevBtnCloseUpdate');
        button.click();
        updatePresale();
      } catch (error) {
      } finally {
      }
    },
  });

  const connectNetwork = () => {};

  const onErrorUpdatePresale = (error) => {
    PostErrorLogAction({
      address: account?.address || 0,
      other_detail: { error },
    });
    console.log('11 Error', error);
    let errStr = error.toString().slice(0, 25);
    if (errStr === 'TransactionExecutionError') {
      toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT);
    } else {
      toast.err(TRANSACTIONMSGS.SOMETHINGWENTWRONG);
    }
  };

  const onSuccessUpdatePresale = async () => {
    let updateDb = {
      logoUrl: formikUpdatePool.values?.logoUrl,
      websiteUrl: formikUpdatePool.values?.websiteUrl,
      twitterUrl: formikUpdatePool.values?.twitterUrl,
      githubUrl: formikUpdatePool.values?.githubUrl,
      telegramUrl: formikUpdatePool.values?.telegramUrl,
      discordUrl: formikUpdatePool.values?.discordUrl,
      youtubePresentationVideoUrl:
        formikUpdatePool.values?.youtubePresentationVideoUrl,
      whitelistContestUrl: formikUpdatePool.values?.whitelistContestUrl || '',
      projectDescription: formikUpdatePool.values?.projectDescription,
      bannerUrl: formikUpdatePool.values?.bannerUrl,
      address: address,
      preSale: presaleDetails?.preSale,
    };

    let response = await updatePresaleAction(updateDb);
    if (response) {
      toast.success(SUCCESSMSGS.PRESALE_UPDATED);
      getPresaleFuncWithoutLoading();
    }
  };

  const {
    data: dataUpdatePresale,
    isLoading: isLoadingUpdatePresale,
    isSuccess: isSuccessUpdatePresale,
    isError: isErrorUpdatePresale,
    write: updatePresale,
  } = useContractWrite({
    address: PRESALE_MANAGER_ADDRESS,
    abi: PreSaleManager.abi,
    functionName: 'updateProjectDetails',
    args: [
      presaleDetails?.preSale,
      {
        logoUrl: formikUpdatePool.values?.logoUrl,
        websiteUrl: formikUpdatePool.values?.websiteUrl,
        twitterUrl: formikUpdatePool.values?.twitterUrl,
        githubUrl: formikUpdatePool.values?.githubUrl,
        telegramUrl: formikUpdatePool.values?.telegramUrl,
        discordUrl: formikUpdatePool.values?.discordUrl,
        youtubePresentationVideoUrl:
          formikUpdatePool.values?.youtubePresentationVideoUrl,
        whitelistContestUrl: formikUpdatePool.values?.whitelistContestUrl,
        projectDescription: formikUpdatePool.values?.projectDescription,
        bannerUrl: formikUpdatePool.values?.bannerUrl,
      },
    ],
    onError: onErrorUpdatePresale,
    onSuccess: onSuccessUpdatePresale,
  });

  const onErrorCancelPresale = (error) => {
    PostErrorLogAction({
      address: account?.address || 0,
      other_detail: { error },
    });
    console.log('12 Error', error);
    let errStr = error.toString().slice(0, 25);
    if (errStr === 'TransactionExecutionError') {
      toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT);
    } else {
      toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG);
    }
  };

  const onSuccessCancelPresale = async () => {
    let bodyData = { preSale: presaleDetails?.preSale, address: address };
    let response = await cancelPresaleAction(bodyData);
    if (response) {
      var button = document.getElementById('prevBtnClose');
      button.click();

      toast.success(SUCCESSMSGS.PRESALE_CANCELED);
      getPresaleFuncWithoutLoading();
    }
  };

  const {
    data: dataCancelPresale,
    isLoading: isLoadingCancelPresale,
    isSuccess: isSuccessCancelPresale,
    isError: isErrorCancelPresale,
    write: cancelPresaleWrite,
  } = useContractWrite({
    address: PRESALE_MANAGER_ADDRESS,
    abi: PreSaleManager.abi,
    functionName: 'cancelPreSale',
    args: [presaleDetails?.preSale],
    onError: onErrorCancelPresale,
    onSuccess: onSuccessCancelPresale,
  });

  const onErrorStealthCancelPresale = (error) => {
    // console.log("onErrorStealthCancelPresale", error)
  };

  const onSuccessStealthCancelPresale = () => {};

  const {
    data: dataCancelStealthPresale,
    isLoading: isLoadingCancelStealthPresale,
    isSuccess: isSuccessCancelStealthPresale,
    isError: isErrorCancelStealthPresale,
    write: cancelPresaleStealthWrite,
  } = useContractWrite({
    address: PRESALE_MANAGER_ADDRESS,
    abi: PreSaleManager.abi,
    functionName: 'cancelStealthPreSale',
    args: [presaleDetails?.preSale],
    onError: onErrorCancelPresale,
    onSuccess: onSuccessCancelPresale,
  });

  const cancelPresale = () => {
    var button = document.getElementById('prevBtnClose');
    button.click();
    cancelPresaleWrite();
  };

  const cancelPresaleStealth = () => {
    var button = document.getElementById('prevBtnClose');
    button.click();
    cancelPresaleStealthWrite();
  };

  // Live Sale Status Update
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [targetReached, setTargetReached] = useState(false);
  const [targetEnd, setTargetEnd] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000); // Update the current time every second (adjust as needed)

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  useEffect(() => {
    if (
      !targetReached &&
      currentTime >= roundData?.currentRound?.startTime &&
      currentTime < roundData?.currentRound?.endTime
    ) {
      setTargetReached(true);
      // Call your function here
      getPresaleFuncWithoutLoading();
    }
  }, [currentTime, targetReached, presaleDetails]);

  useEffect(() => {
    if (!targetEnd && currentTime >= roundData?.currentRound?.endTime) {
      setTargetEnd(true);
      // Call your function here
      getPresaleFuncWithoutLoading();
    }
  }, [currentTime, targetEnd, presaleDetails]);
  // End Time end

  const onSuccessWithdraw = async (data) => {
    // Api call
    let response = await deleteContributionAction({
      preSale: presaleDetails?.preSale,
      address: address,
    });
    if (response) {
      getInvestDetailsFunc();
      toast.success(SUCCESSMSGS.WITHDTAWAL_SUCCESS);
    }
  };

  const {
    data: dataWithdraw,
    isLoading: isLoadingWithdraw,
    write: withdraw,
  } = useContractWrite({
    address: PRESALE_MANAGER_ADDRESS,
    abi: PreSaleManager.abi,
    functionName: 'withdrawRefundContribution',
    args: [presaleDetails?.preSale],
    onError: onemergencyWithdrawError,
    onSuccess: onSuccessWithdraw,
  });

  const {
    data: data_Presale_Dex,
    isError: isErrorPresale_Dex,
    isLoading: isLoading_Presale_Dex,
    refetch: refetchPresale_Dex,
  } = useContractReads({
    contracts: [
      {
        address: config.SUPPORTED_NETWORKS.some(
          (network) => network.id === chain?.id
        )
          ? presaleDetails?.preSale
          : undefined,
        abi: FairLaunch.abi,
        functionName: 'currentPreSaleRate',
      },
      {
        address: config.SUPPORTED_NETWORKS.some(
          (network) => network.id === chain?.id
        )
          ? presaleDetails?.preSale
          : undefined,
        abi: FairLaunch.abi,
        functionName: 'currentDEXRate',
      },
    ],
  });

  const updateDexAndPresaleRateDB = async () => {
    let refetch_data_Presale_Dex = await refetchPresale_Dex();
    refetch_data_Presale_Dex = refetch_data_Presale_Dex?.data;

    if (
      !SALE_TYPE.isPresaleOrPrivateSale(presaleDetails?.saleType) &&
      refetch_data_Presale_Dex
    ) {
      let data = {
        presaleOwner: account.address,
        preSale: presaleDetails?.preSale,
        preSaleRate: String(refetch_data_Presale_Dex[0].result),
        dexListingRate: String(refetch_data_Presale_Dex[1].result),
      };
      await updatePresaleRDexLRAction(data);
      getPresaleFuncWithoutLoading();
    }
  };

  useEffect(() => {
    let title =
      presaleDetails?.sale_without_token == 1 &&
      roundData?.currentRound?.startTime == '0'
        ? presaleDetails?.sale_title
        : presaleDetails?.launch_stealth_sale == true
        ? presaleDetails?.sale_title
        : presaleDetails?.name;
    document.title = title ? `${title} Presale` : 'Presale page';
    console.log(title, 'title');
  }, [presaleDetails]);

  // const [progressBarFair,_progressBarFair]=useState(0)
  // useEffect(()=>{
  //   alert("hello",isDataFundRaised)
  //   if(presaleDetails && isDataFundRaised){
  //     if (presaleDetails?.isHardCap==1 && presaleDetails?.hardCap!=undefined) {
  //       const fundRaised = Number(formatUnits(isDataFundRaised, presaleDetails?.token_decimals));
  //       const hardCap = Number(formatUnits(presaleDetails?.hardCap, presaleDetails?.custom_fund_token_decimal));
  //       const progress = (fundRaised * 100) / hardCap;
  //       _progressBarFair(progress)
  //     }
  //     else if(BigInt(isDataFundRaised) > BigInt(presaleDetails?.softCap)){  // eslint-disable-line no-undef
  //           const fundRaised = Number(formatUnits(isDataFundRaised, presaleDetails?.token_decimals));
  //           const progress = (fundRaised * 100) / fundRaised;
  //           _progressBarFair(progress)
  //     } else{
  //           const fundRaised = Number(formatUnits(isDataFundRaised, presaleDetails?.token_decimals));
  //           const softCap = Number(formatUnits(presaleDetails?.softCap, presaleDetails?.custom_fund_token_decimal));
  //           const progress = (fundRaised * 100) / softCap;
  //           _progressBarFair(progress)
  //     }
  //   }
  // },[presaleDetails])

  // console.log("progressBarFair",progressBarFair)

  const Walletconnect = useMemo(() => {
    return (
      //     <>
      //       <WagmiConfig client={wagmiClient}>
      <HomePage presale_chain={presaleDetails?.chain} />
      //       </WagmiConfig>

      //       <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
      //     </>
    );
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container id='presale_details'>
        {/* <div className='row mt-2'> */}
        {/* <div className='d-flex align-items-center tnbc_back_section'>
                <div
                  onClick={() => {
                    navigate(-1) ? navigate(-1) : navigate(BASE_URL);
                  }}
                  // href={BASE_URL}
                  className='d-flex align-items-baseline  me-2'
                  style={{ cursor: 'pointer' }}
                >
                  <i className='fa fa-arrow-left' aria-hidden='true' />
                  <p className='mb-0 ms-2'>Back</p>
                </div>
              </div> */}
        <SubHeader isBack className='mt-2' />
        {/* </div> */}
        {/* <PresaleDetailShimmer /> */}
        
        {loading ? (
          <PresaleDetailShimmer />
        ) : (
          <div className='row mt-4'>
            <div className='col-12 col-lg'>
              <div className='tnbc_custom_card'>
                <div className='custom_card'>
                  <div className='d-flex flex-wrap flex-md-nowrap gap-4 p-4'>
                    <div
                      className='card-top-img tnbc-card-top-img presale-detail-banner-image-section'
                      // style={{
                      //   backgroundImage: `url(${presaleDetails.bannerUrl != ""
                      //     ? presaleDetails?.bannerUrl
                      //     : "https://testnet.gempad.app/static/bg4.png"
                      //     })`,
                      // }}
                    >
                      <Banner url={presaleDetails.bannerUrl} />

                      <div className='position-absolute card_second_section_img position'>
                        <img
                          src={presaleDetails?.logoUrl || `${BASE_URL}/images/stablz_stablz_icon.png`}
                          // src={'images/icon/p_icon.png'}
                          // className="img-fluid"
                          alt='logo'
                          onError={(event) => {
                            event.target.src =
                              BASE_URL + `images/stablz_stablz_icon.png`;
                            event.onerror = null;
                          }}
                        />
                        <span>
                          <img
                            src={networkImagesByChain[presaleDetails?.chain]}
                            // className="img-fluid"
                            alt='logo'
                            width={28}
                          />
                        </span>
                      </div>
                    </div>
                    <div
                      style={{ position: 'relative' }}
                      className='d-flex justify-content-between align-items-start mt-2 flex-grow-1'
                    >
                      <div className='card_second_section_text card-inner-page_section_text w-100'>
                        <div className='d-flex align-items-start justify-content-between'>
                          <h5 className='text-wrap'>
                            {presaleDetails?.sale_without_token == 1 &&
                            roundData?.currentRound?.startTime == '0'
                              ? presaleDetails?.sale_title
                              : presaleDetails?.launch_stealth_sale == true
                              ? presaleDetails?.sale_title
                              : presaleDetails?.name}
                          </h5>
                          <AllSocialLinks
                            twitterUrl={presaleDetails?.twitterUrl}
                            telegramUrl={presaleDetails?.telegramUrl}
                            githubUrl={presaleDetails?.githubUrl}
                            websiteUrl={presaleDetails?.websiteUrl}
                            discordUrl={presaleDetails?.discordUrl}
                          />
                        </div>
                        <p
                          className='mt-1 fs-6 fw-normal text-gray2'
                          style={{
                            whiteSpace: 'break-spaces',
                            lineHeight: '24px',
                          }}
                        >
                          {presaleDetails?.projectDescription}
                        </p>
                        {/* presale address */}
                        <div>
                          <p className='m-0 mb-2 fw-medium fs-6 text-white'>
                            Presale Address
                            <span className='text-danger ms-1 fw-normal'>
                              (Do not send BNB directly to the presale address!)
                            </span>
                          </p>
                          <div className='div_media_screen w_mob_presale_address d-flex justify-content-start align-items-center gap-2 flex-wrap'>
                            <p
                              className='max-w-address mb-0 text-truncate text-primary media_screen fw-medium fs-55'
                              style={{
                                cursor: 'pointer',
                                lineHeight: '27px',
                              }}
                              onClick={() =>
                                window.open(
                                  `${
                                    networkLinks[presaleDetails?.chain]
                                  }/address/${presaleDetails?.preSale}`
                                )
                              }
                              onMouseEnter={(e) => {
                                e.target.style.textDecoration = 'underline';
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.textDecoration = 'none';
                              }}
                            >
                              {presaleDetails?.preSale}
                            </p>
                            <CopyToClipboard
                              text={presaleDetails?.preSale}
                              onCopy={() => toast.success('Copied!')}
                            >
                              <img
                                src='images/copy_icon.svg'
                                className='icon'
                                alt='copy'
                              />
                            </CopyToClipboard>
                          </div>
                        </div>
                      </div>
                      {/* <div
                          style={{
                            position: 'absolute',
                            top: '0px',
                            right: '0px',
                          }}
                        >
                          <div className='card_second_section_img position'>
                            <img
                              src={presaleDetails?.logoUrl}
                              alt='logo'
                              onError={(event) => {
                                event.target.src =
                                  'images/stablz_stablz_icon.png';
                                event.onerror = null;
                              }}
                            />
                            <span>
                              <img
                                src={
                                  networkImagesByChain[presaleDetails?.chain]
                                }
                                alt='icon'
                                width={28}
                              />
                            </span>
                          </div>
                        </div> */}
                    </div>
                  </div>

                  <div className='px-4'>
                    {/* youtube */}
                    {/* {
                        presaleDetails?.youtubePresentationVideoUrl != "" &&
                        <div className="text-center">
                          <YouTube videoId={videoId} opts={opts} onReady={onPlayerReady} />
                        </div>
                      } */}
                    {presaleDetails?.youtubePresentationVideoUrl != '' && (
                      <div className='css-1on8bub mt-4'>
                        <div className='video-responsive'>
                          <iframe
                            width='853'
                            height='480'
                            src={`https://www.youtube.com/embed/${videoId}`}
                            frameBorder='0'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen
                            title='Embedded youtube'
                          />
                        </div>
                      </div>
                    )}

                    {/* presael info */}
                    <CrossGrid className='presale-custom-table my-4'>
                      <CrossGrid.Item
                        label='Sale Type'
                        value={
                          presaleDetails?.launch_stealth_sale == 1
                            ? `Stealth ${presaleDetails?.saleType}`
                            : presaleDetails?.sale_without_token == 1
                            ? 'Without Token'
                            : presaleDetails?.saleType
                        }
                      />
                      <CrossGrid.Item
                        label='Total Supply'
                        value={
                          presaleDetails?.launch_stealth_sale == 1
                            ? presaleDetails?.supply
                            : presaleDetails?.supply &&
                              presaleDetails?.token_decimal &&
                              ethers.utils
                                .formatUnits(
                                  String(presaleDetails?.supply),
                                  Number(presaleDetails?.token_decimal)
                                )
                                .toString()
                        }
                      />
                      <CrossGrid.Item
                        label='Tokens For Presale'
                        value={
                          SALE_TYPE.isPresaleOrPrivateSale(presaleDetails?.saleType)
                            ? tokenForPresale
                            : presaleDetails?.preSaleAmount &&
                              ethers.utils
                                .formatUnits(
                                  String(presaleDetails?.preSaleAmount),
                                  Number(presaleDetails?.token_decimal)
                                )
                                .toString()
                        }
                      />

                      {!!presaleDetails?.listOnDex && (
                        <CrossGrid.Item
                          label='Tokens For Liquidity'
                          value={
                            SALE_TYPE.isPresaleOrPrivateSale(presaleDetails?.saleType)
                              ? tokenForLiqidity
                              : tflFairm
                          }
                        />
                      )}

                      <CrossGrid.Item
                        label='Soft Cap'
                        value={`${ethers.utils.formatUnits(
                          String(presaleDetails?.softCap || 0),
                          Number(fundRaisingTokenDecimal)
                        )}
                            ${fundRaisingTokenSymbol}`}
                      />
                      <CrossGrid.Item
                        label='Hard Cap'
                        value={`${
                          presaleDetails?.isHardCap == 1
                            ? ethers.utils.formatUnits(
                                String(presaleDetails?.hardCap || 0),
                                Number(fundRaisingTokenDecimal)
                              )
                            : '∞'
                        } ${fundRaisingTokenSymbol}`}
                      />
                      <CrossGrid.Item
                        label='Unused Tokens'
                        value={
                          presaleDetails?.burnTokens == 'true'
                            ? 'Burn'
                            : 'Refund'
                        }
                      />

                      {presaleDetails.listOnDex ? (
                        <>
                          <CrossGrid.Item
                            label='Listing Rate'
                            value={ethers.utils
                              .formatUnits(
                                String(presaleDetails?.dexListingRate),
                                Number(presaleDetails?.token_decimal)
                              )
                              .toString()}
                          />
                          <CrossGrid.Item
                            label='Liquidity Percent'
                            value={`${presaleDetails?.dexLiquidityPercentage}%`}
                          />
                          <CrossGrid.Item
                            label='Liquidity Lock Time Period'
                            value={presaleDetails?.liquidityLockupDays}
                          />
                          <CrossGrid.Item
                            label='what DEX will be used to add liquidity'
                            value={'PancakeSwap'}
                          />
                        </>
                      ) : (
                        <CrossGrid.Item label='Presale Fund Receiver'>
                          <div className='d-flex align-items-center justify-content-start gap-2'>
                            <CrossGrid.Value>
                              {presaleDetails.presaleFundReceiver}
                            </CrossGrid.Value>
                            <CopyToClipboard
                              text={presaleDetails?.presaleFundReceiver}
                              onCopy={() => toast.success('Copied!')}
                            >
                              <img
                                src='images/copy_icon.svg'
                                className='icon z-1'
                                alt='copy'
                              />
                            </CopyToClipboard>
                          </div>
                        </CrossGrid.Item>
                      )}
                      {presaleDetails?.rounds && (
                        <>
                          {presaleDetails?.rounds?.map((round, index) => (
                            <CrossGrid.Item key={`round-${index}`}>
                              <CrossGrid.Label>
                                Round {index + 1} {index === 0 && '(Default)'}
                              </CrossGrid.Label>
                              {/* <div className='d-flex flex-wrap justify-content-between'> */}
                              <CrossGrid.Label className='mt-4'>
                                Start Time (IST)
                              </CrossGrid.Label>
                              <CrossGrid.Value>
                                {!presaleDetails?.setSaleTime ||
                                !round?.startTime
                                  ? 'TBA'
                                  : moment(
                                      new Date(Number(round?.startTime) * 1000)
                                    ).format('YYYY-MM-DD HH:mm')}
                              </CrossGrid.Value>
                              {/* </div> */}
                              {/* <div className='d-flex flex-wrap justify-content-between'> */}
                              <CrossGrid.Label className='mt-4'>
                                End Time (IST)
                              </CrossGrid.Label>
                              <CrossGrid.Value>
                                {!presaleDetails?.setSaleTime || !round.endTime
                                  ? 'TBA'
                                  : moment(
                                      new Date(Number(round.endTime) * 1000)
                                    ).format('YYYY-MM-DD HH:mm')}
                              </CrossGrid.Value>
                              {/* </div> */}
                              {/* <div className='d-flex flex-wrap justify-content-between'> */}
                              <CrossGrid.Label className='mt-4'>
                                Rate
                              </CrossGrid.Label>
                              <CrossGrid.Value>
                                {ethers.utils
                                  .formatUnits(
                                    String(round.preSaleRate || 0),
                                    Number(presaleDetails?.token_decimal)
                                  )
                                  .toString()}
                              </CrossGrid.Value>
                            </CrossGrid.Item>
                          ))}
                        </>
                      )}

                      <CrossGrid.Item
                        label='Estimated Finalize Time (IST)'
                        value={
                          presaleDetails?.estimatedDexListingTime == '0'
                            ? 'TBA'
                            : moment(EdexDate).format('YYYY-MM-DD HH:mm')
                        }
                      />

                      {presaleDetails?.isbonusSale == 1 && (
                        <>
                          <CrossGrid.Item
                            label='Min Bonus Buy Amount'
                            value={`${ethers.utils.formatUnits(
                              String(presaleDetails?.minBonusBuyAmount),
                              Number(fundRaisingTokenDecimal)
                            )}${' '}
                                ${fundRaisingTokenSymbol}`}
                          />

                          <CrossGrid.Item
                            label='Bonus Received(%)'
                            value={`${presaleDetails?.bonusReceivedPercentage} %`}
                          />

                          <CrossGrid.Item
                            label='Bonus Spots Available'
                            value={`${presaleDetails?.noOfBonusEligibleInvestors} People`}
                          />
                        </>
                      )}

                      {/*  */}
                      {presaleDetails?.isPreSaleVesting == 1 && (
                        <>
                          <CrossGrid.Item
                            label='First token release'
                            value={presaleDetails?.firstReleasePercent_presale}
                          />

                          <CrossGrid.Item
                            label='Each token percent'
                            value={
                              presaleDetails?.releaseCyclePercentage_presale
                            }
                          />

                          <CrossGrid.Item
                            label='Each token period'
                            value={presaleDetails?.releaseCycleDays_presale}
                          />

                          <CrossGrid.Item
                            label='Cliff(days)'
                            value={`${presaleDetails?.firstReleaseAfter_presale} days`}
                          />
                        </>
                      )}
                    </CrossGrid>
                  </div>
                </div>
                {/*  */}

                <div className='my-4'>
                  {SALE_TYPE.isPresaleOrPrivateSale(presaleDetails?.saleType) ? (
                    <CnbtPresaleDetails
                      presaleDetails={presaleDetails}
                      currentRound={roundData?.currentRound}
                      startEndIn={startEndIn}
                      progreeBar={progreeBar}
                      isDataFundRaised={isDataFundRaised}
                      investDetails={investDetails}
                      getInvestDetailsFunc={getInvestDetailsFunc}
                      referchFundraise={referchFundraise}
                      chainId={presaleDetails?.chain}
                      bonusSpotLeft={bonusSpotLeft}
                      loading={loading}
                      withdraw={withdraw}
                      isLoadingWithdraw={isLoadingWithdraw}
                      emergencyWithdraw={emergencyWithdraw}
                      isLoadingEmgcyWithDraw={isLoadingEmgcyWithDraw}
                      refetchInFBC={refetchInFBC}
                    />
                  ) : (
                    <CnbtFairLaunchDetails
                      presaleDetails={presaleDetails}
                      startEndIn={startEndIn}
                      progreeBar={progreeBar}
                      isDataFundRaised={isDataFundRaised}
                      investDetails={investDetails}
                      getInvestDetailsFunc={getInvestDetailsFunc}
                      referchFundraise={referchFundraise}
                      chainId={presaleDetails?.chain}
                      bonusSpotLeft={bonusSpotLeft}
                      loading={loading}
                      withdraw={withdraw}
                      isLoadingWithdraw={isLoadingWithdraw}
                      emergencyWithdraw={emergencyWithdraw}
                      isLoadingEmgcyWithDraw={isLoadingEmgcyWithDraw}
                      getPresaleFuncWithoutLoading={
                        getPresaleFuncWithoutLoading
                      }
                      updateDexAndPresaleRateDB={updateDexAndPresaleRateDB}
                      refetchInFBC={refetchInFBC}
                    />
                  )}
                </div>
              </div>
              {/* <div className='row'> */}
              {presaleDetails?.isTeamVesting == 1 && (
                <VestingScheduke presaleDetails={presaleDetails} />
              )}
              {presaleDetails?.isWhiteList == 'true' &&
                presaleDetails?.preSaleOwner == account.address && (
                  // <div className='col-md-12'>
                  <div className='custom_card mb-4 p-4'>
                    <h5>Whitelisted Addresses</h5>
                    <hr />
                    <ul className='presale_address_ui mt-2 '>
                      {presaleDetails?.whitelisted
                        ?.split(',')
                        ?.map((addr, key) => {
                          return (
                            <li
                              className='d-flex justify-content-between'
                              key={key}
                            >
                              {addr}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  // </div>
                )}
              {presaleDetails?.preSaleOwner == account.address &&
                chain?.id == presaleDetails?.chain && (
                  // <div className='col-md-12'>
                  <div className='custom_card mb-4 p-4'>
                    <h5 className='form-card-heading text-start'>Contributors</h5>
                    <hr />
                    <ul className='presale_address_ui mt-2'>
                      {investDetails?.contributors?.map((contributor, key) => {
                        return (
                          <li
                            className='d-flex justify-content-between'
                            key={key}
                          >
                            {contributor.address}
                            <p>
                              {parseFloat(contributor.contribution)}{' '}
                              {fundRaisingTokenSymbol}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  // </div>
                )}
              {/* </div> */}
            </div>
            <div className='col-12 col-md-12 col-lg-auto mx-auto'>
              <div className='presale_detail_right  mx-auto'>
                {/* {presaleDetails?.chain != chain?.id && isConnected == true ? (
                  <div className='custom_card text-center connect_network_presale h-auto mb-4'>
                    <button
                      type='button'
                      className='btn text-white'
                      onClick={() => {
                        handleSwitchNetwork();
                      }}
                    >
                      <span>Switch Network to</span>
                      <span className='ms-2 me-1'>
                        <img
                          onClick={() => {
                            handleSwitchNetwork();
                          }}
                          src={
                            networkImages[
                              chains.find(
                                (network) => network.id == presaleDetails?.chain
                              )?.network
                            ]
                          }
                          alt='icon'
                          width='25'
                        />
                      </span>
                      <span className='text-blue'>
                        {
                          config.SUPPORTED_NETWORKS.find(
                            (network) => network.id === presaleDetails?.chain
                          )?.switch_show_name
                        }
                      </span>
                    </button>
                  </div>
                ) : //   <div className="custom_card text-center connect_network_presale">
                //   <button
                //     type="button"
                //     className="btn text-white"
                //     onClick={() => {
                //       connectNetwork();
                //     }}
                //   >
                //     <span>
                //       Connect Network to
                //     </span>
                //     <span className="ms-2 me-1">
                //       <img
                //         onClick={() => { connectNetwork() }}
                //         src={networkImages["bsc-testnet"]}
                //         alt="icon"
                //         width="25"
                //       />
                //     </span>
                //     <span className="text-blue">BSC Testnet</span>
                //   </button>
                // </div>
                // isConnected != true && Walletconnect
                null} */}
                {presaleDetails?.isWhiteList == 'true' &&
                currentUnixTime <
                  moment
                    .unix(roundData?.rounds[0].startTime)
                    .add(Number(presaleDetails?.whiteListTimer), 'minutes')
                    .unix() ? (
                  <>
                    <section>
                      <div className='custom_card mb-4 p-4'>
                        <h5 className='text-center'>
                          {currentTime > roundData?.rounds[0].startTime
                            ? 'Public Sale Start In : '
                            : 'Whitelist Sale Starts In : '}
                          <div className='d-flex justify-content-center'>
                            {presaleDetails?.setSaleTime == 0 ? (
                              'TBA'
                            ) : (
                              <Countdown
                                unixTime={
                                  currentTime < roundData?.rounds[0].startTime
                                    ? Number(roundData?.rounds[0].startTime)
                                    : moment
                                        .unix(roundData?.rounds[0].startTime)
                                        .add(
                                          Number(
                                            presaleDetails?.whiteListTimer
                                          ),
                                          'minutes'
                                        )
                                        .unix()
                                }
                              />
                            )}
                          </div>
                        </h5>
                        <div>
                          <div className='text-center mt-1'>
                            <div className='text-center mt-2'>
                              <span>
                                {presaleDetails?.whitelisted
                                  ?.split(',')
                                  ?.includes(account.address)
                                  ? 'You are whitelisted'
                                  : "You aren't whitelisted"}
                              </span>
                              <br />
                            </div>
                            <span>
                              {presaleDetails?.whitelisted != null &&
                              presaleDetails?.whitelisted !== ''
                                ? presaleDetails?.whitelisted
                                    ?.replace(/,$/, '')
                                    ?.split(',').length
                                : 0}{' '}
                              whitelisted addresses.
                            </span>
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                ) : (
                  ''
                )}

                <div>
                  {!!presaleDetails?.is_approved ===
                    PRESALE_STATUS.approved && (
                    <div className='contribute_r_section mt-4'>
                      <TokenInfo
                        estimatedDexListingTime={
                          presaleDetails?.estimatedDexListingTime
                        }
                        preSaleToken={presaleDetails?.preSaleToken}
                        launch_stealth_sale={
                          presaleDetails?.launch_stealth_sale
                        }
                        setSaleTime={presaleDetails?.setSaleTime}
                        isFinalized={presaleDetails?.isFinalized}
                        presale_chain={presaleDetails?.chain}
                        buyToken={presaleDetails?.buyToken}
                        isCanceled={presaleDetails?.isCanceled}
                        listOnDex={presaleDetails.listOnDex}
                      />
                    </div>
                  )}

                  {/* <div className='row'> */}
                  {presaleDetails?.sale_without_token != 1 &&
                    presaleDetails?.setSaleTime != 0 && (
                      // <div className='col-xs-12 col-sm-12 col-md-6 col-lg-12 col-xl-12'>
                      <div className='custom_card mb-4 py-4'>
                        <h6 className='px-3 py-2 fs-6 fw-normal text-muted'>
                          Tokenomics - Supply Distribution
                        </h6>
                        <hr />
                        <div className='px-3'>
                          <TokenomicsPieChart presaleDetails={presaleDetails} />
                        </div>
                      </div>
                      // {/* </div> */}
                    )}
                  {/* <div className='col-xs-12 col-sm-6 col-md-6 col-lg-12 col-xl-12'> */}
                  {(presaleDetails?.preSaleOwner == address ||
                    presaleDetails?.stealth_wallet == address) &&
                    presaleDetails?.chain == chain?.id &&
                    presaleDetails?.isCanceled == 0 && (
                      <div className='card px-0 mb-4 text-center'>
                        <h7>Manage Pool</h7>
                        <hr />
                        {SALE_TYPE.isPresaleOrPrivateSale(presaleDetails?.saleType) ? (
                          <ManagePool
                            preSale={presaleDetails?.preSale}
                            isCanceled={presaleDetails?.isCanceled}
                            isLoadingUpdatePresale={isLoadingUpdatePresale}
                            isLoadingCancelPresale={isLoadingCancelPresale}
                            isLoadingCancelStealthPresale={
                              isLoadingCancelStealthPresale
                            }
                            liquidityLockupDays={
                              presaleDetails?.liquidityLockupDays
                            }
                            launch_stealth_sale={
                              presaleDetails?.launch_stealth_sale
                            }
                            presaleDetails={presaleDetails}
                            getPresaleFuncWithoutLoading={
                              getPresaleFuncWithoutLoading
                            }
                            isDataFundRaised={isDataFundRaised}
                            fundRaisingTokenDecimal={fundRaisingTokenDecimal}
                          />
                        ) : (
                          <ManagePoolFair
                            preSale={presaleDetails?.preSale}
                            isCanceled={presaleDetails?.isCanceled}
                            isLoadingUpdatePresale={isLoadingUpdatePresale}
                            isLoadingCancelPresale={isLoadingCancelPresale}
                            isLoadingCancelStealthPresale={
                              isLoadingCancelStealthPresale
                            }
                            liquidityLockupDays={
                              presaleDetails?.liquidityLockupDays
                            }
                            launch_stealth_sale={
                              presaleDetails?.launch_stealth_sale
                            }
                            presaleDetails={presaleDetails}
                            getPresaleFuncWithoutLoading={
                              getPresaleFuncWithoutLoading
                            }
                            isDataFundRaised={isDataFundRaised}
                            fundRaisingTokenDecimal={fundRaisingTokenDecimal}
                          />
                        )}
                      </div>
                    )}
                  {/* </div> */}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>

      {/* Update Pool Model */}
      <div className='paresale_module'>
        <div
          className='modal fade '
          id='updatePoolModal'
          tabIndex='99999'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              {/* Modal Header */}
              <div className='modal-header'>
                <h5 className='text-center modal-title'>
                  Update Presale Information
                </h5>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  hidden={false}
                />
              </div>
              {/* Modal body */}
              <div className='modal-body'>
                <div
                  id='myDropdown'
                  // className={`dropdown-content px-2 py-4 ${showCreate ? 'show' : 'hide'}`}
                  className='dropdown-content'
                >
                  <div className='row g-3'>
                    <div className='col'>
                      <label
                        className='form-label '
                        style={{ position: 'relative' }}
                      >
                        Logo url
                        {/* <i class="fa fa-info-circle">
                          <span className="tooltip-text">URL must end with a supported image extension png, jpg, jpeg or gif and dimensions of exactly 50x50 pixels.</span>
                        </i>  */}
                        <Tooltip
                          arrow
                          title='URL must end with a supported image extension png, jpg, jpeg or gif and dimensions of exactly 50x50 pixels.'
                          placement='top-start'
                          enterTouchDelay={false}
                        >
                          <InfoIcon
                            style={{ marginLeft: '2px', width: '19' }}
                          />
                        </Tooltip>
                        <span
                          className='text-danger'
                          style={{
                            fontSize: '1.5rem',
                            position: 'absolute',
                            top: -7,
                          }}
                        >
                          *
                        </span>{' '}
                      </label>
                      <div
                        className={`${
                          formikUpdatePool.errors.logoUrl &&
                          formikUpdatePool.touched.logoUrl
                            ? 'input_invalid'
                            : ''
                        } input-group`}
                      >
                        <div className='input-group-text'>
                          <i className='fa fa-picture-o' aria-hidden='true'></i>
                        </div>
                        <input
                          type='text'
                          className={`form-control`}
                          name='logoUrl'
                          placeholder='Ex: https://...'
                          onChange={formikUpdatePool.handleChange}
                          value={formikUpdatePool.values.logoUrl}
                          aria-describedby='basic-addon1'
                        />
                      </div>

                      {formikUpdatePool.errors.logoUrl &&
                      formikUpdatePool.touched.logoUrl ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.logoUrl}
                        </span>
                      ) : null}
                    </div>

                    <div className='col-12'>
                      <label
                        className='form-label'
                        style={{ position: 'relative' }}
                      >
                        Banner url
                        {/* <i class="fa fa-info-circle">
                      <span className="tooltip-text">Banner url with dimensions of exactly 286x110 pixels.</span>
                        </i>   */}
                        <Tooltip
                          arrow
                          title='Banner url with dimensions of exactly 286x110 pixels. supported extention png, jpg, jpeg, gif, mp4, webm, ogg'
                          placement='top-start'
                          enterTouchDelay={false}
                        >
                          <InfoIcon
                            style={{ marginLeft: '2px', width: '19' }}
                          />
                        </Tooltip>
                      </label>
                      <div
                        className={`${
                          formikUpdatePool.errors.bannerUrl &&
                          formikUpdatePool.touched.bannerUrl
                            ? 'input_invalid'
                            : ''
                        } input-group`}
                      >
                        <div className='input-group-text'>
                          <i className='fa fa-picture-o' aria-hidden='true'></i>
                        </div>
                        <input
                          type='text'
                          className={`form-control`}
                          name='bannerUrl'
                          placeholder='Ex: https://...'
                          onChange={formikUpdatePool.handleChange}
                          value={formikUpdatePool.values.bannerUrl}
                          aria-describedby='basic-addon1'
                        />
                      </div>
                      {formikUpdatePool.errors.bannerUrl &&
                      formikUpdatePool.touched.bannerUrl ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.bannerUrl}
                        </span>
                      ) : null}
                    </div>

                    <div className='col-12'>
                      <label
                        className='form-label'
                        style={{ position: 'relative' }}
                      >
                        Website url
                      </label>
                      <div
                        className={`${
                          formikUpdatePool.errors.websiteUrl &&
                          formikUpdatePool.touched.websiteUrl
                            ? 'input_invalid'
                            : ''
                        } input-group`}
                      >
                        <div className='input-group-text'>
                          <i className='fa fa-globe' aria-hidden='true'></i>
                        </div>
                        <input
                          type='text'
                          className={`form-control autofill`}
                          name='websiteUrl'
                          placeholder='Ex: https://...'
                          onChange={formikUpdatePool.handleChange}
                          value={formikUpdatePool.values.websiteUrl}
                          aria-describedby='basic-addon1'
                        />
                      </div>

                      {formikUpdatePool.errors.websiteUrl &&
                      formikUpdatePool.touched.websiteUrl ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.websiteUrl}
                        </span>
                      ) : null}
                    </div>

                    <div className='col-12'>
                      <label
                        className='form-label'
                        style={{ position: 'relative' }}
                      >
                        Twitter
                      </label>
                      <div
                        className={`${
                          formikUpdatePool.errors.twitterUrl &&
                          formikUpdatePool.touched.twitterUrl
                            ? 'input_invalid'
                            : ''
                        } input-group`}
                      >
                        <div className='input-group-text'>
                          <i className='fa fa-twitter' aria-hidden='true'></i>
                        </div>
                        <input
                          type='text'
                          className={`form-control`}
                          name='twitterUrl'
                          placeholder='Ex: https://twitter.com/...'
                          onChange={formikUpdatePool.handleChange}
                          value={formikUpdatePool.values.twitterUrl}
                          aria-describedby='basic-addon1'
                        />
                      </div>
                      {formikUpdatePool.errors.twitterUrl &&
                      formikUpdatePool.touched.twitterUrl ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.twitterUrl}
                        </span>
                      ) : null}
                    </div>

                    <div className='col-12'>
                      <label
                        className='form-label'
                        style={{ position: 'relative' }}
                      >
                        Github
                      </label>
                      <div
                        className={`${
                          formikUpdatePool.errors.githubUrl &&
                          formikUpdatePool.touched.githubUrl
                            ? 'input_invalid'
                            : ''
                        } input-group`}
                      >
                        <div className='input-group-text'>
                          <i className='fa fa-github' aria-hidden='true'></i>
                        </div>
                        <input
                          type='text'
                          className={`form-control`}
                          name='githubUrl'
                          placeholder='Ex: https://github.com/...'
                          onChange={formikUpdatePool.handleChange}
                          value={formikUpdatePool.values.githubUrl}
                          aria-describedby='basic-addon1'
                        />
                      </div>
                      {formikUpdatePool.errors.githubUrl &&
                      formikUpdatePool.touched.githubUrl ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.githubUrl}
                        </span>
                      ) : null}
                    </div>

                    <div className='col-12'>
                      <label
                        className='form-label'
                        style={{ position: 'relative' }}
                      >
                        Telegram
                      </label>
                      <div
                        className={`${
                          formikUpdatePool.errors.telegramUrl &&
                          formikUpdatePool.touched.telegramUrl
                            ? 'input_invalid'
                            : ''
                        } input-group`}
                      >
                        <div className='input-group-text'>
                          <i className='fa fa-telegram' aria-hidden='true'></i>
                        </div>
                        <input
                          type='text'
                          className={`form-control`}
                          name='telegramUrl'
                          placeholder='Ex: https://t.me/...'
                          onChange={formikUpdatePool.handleChange}
                          value={formikUpdatePool.values.telegramUrl}
                          aria-describedby='basic-addon1'
                        />
                      </div>
                      {formikUpdatePool.errors.telegramUrl &&
                      formikUpdatePool.touched.telegramUrl ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.telegramUrl}
                        </span>
                      ) : null}
                    </div>

                    <div className='col-12'>
                      <label
                        className='form-label'
                        style={{ position: 'relative' }}
                      >
                        Discord
                      </label>
                      <div
                        className={`${
                          formikUpdatePool.errors.discordUrl &&
                          formikUpdatePool.touched.discordUrl
                            ? 'input_invalid'
                            : ''
                        } input-group`}
                      >
                        <div className='input-group-text'>
                          {/* <span className="input-group-text" id="basic-addon1"> */}
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='#90a3b7'
                            height='1em'
                            viewBox='0 0 640 512'
                          >
                            <path d='M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z' />
                          </svg>
                          {/* </span> */}
                        </div>
                        <input
                          type='text'
                          name='discordUrl'
                          className={`form-control`}
                          placeholder='Ex: https://discord.gg/...'
                          onChange={formikUpdatePool.handleChange}
                          value={formikUpdatePool.values.discordUrl}
                          aria-describedby='basic-addon1'
                        />
                      </div>
                      {formikUpdatePool.errors.discordUrl &&
                      formikUpdatePool.touched.discordUrl ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.discordUrl}
                        </span>
                      ) : null}
                    </div>

                    <div className='col-12'>
                      <label className='form-label'>
                        Youtube{' '}
                        {/* <i class="fa fa-info-circle">
                    <span className="tooltip-text">Input YouTube URL or YouTube video ID. Make sure video link doesn't include a timestamp.</span> </i> */}
                        <Tooltip
                          arrow
                          title="Input YouTube URL or YouTube video ID. Make sure video link doesn't include a timestamp."
                          placement='top-start'
                          enterTouchDelay={false}
                        >
                          <InfoIcon style={{ width: '19' }} />
                        </Tooltip>
                      </label>
                      <div
                        className={`${
                          formikUpdatePool.errors.youtubePresentationVideoUrl &&
                          formikUpdatePool.touched.youtubePresentationVideoUrl
                            ? 'input_invalid'
                            : ''
                        } input-group`}
                      >
                        <div className='input-group-text'>
                          <i
                            className='fa fa-youtube-play'
                            aria-hidden='true'
                          ></i>
                        </div>
                        <input
                          type='text'
                          className={`form-control autofill`}
                          name='youtubePresentationVideoUrl'
                          placeholder='Ex:https://youtube.com/watch?v=75h4tgshg3458i'
                          onChange={formikUpdatePool.handleChange}
                          value={
                            formikUpdatePool.values.youtubePresentationVideoUrl
                          }
                          aria-describedby='basic-addon1'
                        />
                      </div>
                      {formikUpdatePool.errors.youtubePresentationVideoUrl &&
                      formikUpdatePool.touched.youtubePresentationVideoUrl ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.youtubePresentationVideoUrl}
                        </span>
                      ) : null}
                    </div>

                    <div className='col-12'>
                      <label
                        className='form-label'
                        style={{ position: 'relative' }}
                      >
                        Whitelist Link
                      </label>
                      <div
                        className={`${
                          formikUpdatePool.errors.whitelistContestUrl &&
                          formikUpdatePool.touched.whitelistContestUrl
                            ? 'input_invalid'
                            : ''
                        } input-group`}
                      >
                        <div className='input-group-text'>
                          <i className='fa fa-bolt' aria-hidden='true'></i>
                        </div>
                        <input
                          type='text'
                          className={`form-control autofill`}
                          name='whitelistContestUrl'
                          placeholder='Ex.https://...'
                          onChange={formikUpdatePool.handleChange}
                          value={formikUpdatePool.values.whitelistContestUrl}
                          aria-describedby='basic-addon1'
                        />
                      </div>
                      {formikUpdatePool.errors.whitelistContestUrl &&
                      formikUpdatePool.touched.whitelistContestUrl ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.whitelistContestUrl}
                        </span>
                      ) : null}
                    </div>

                    <div className='col-sm-12'>
                      <label className='form-label'>
                        Description{' '}
                        <span
                          className='text-danger'
                          style={{ fontSize: '1.5rem' }}
                        >
                          *
                        </span>
                      </label>

                      <textarea
                        className={`form-control autofill ${
                          formikUpdatePool.errors.projectDescription &&
                          formikUpdatePool.touched.projectDescription &&
                          'input_invalid'
                        }`}
                        rows={3}
                        id='comment'
                        defaultValue={''}
                        name='projectDescription'
                        onChange={formikUpdatePool.handleChange}
                        value={formikUpdatePool.values.projectDescription}
                      />

                      {formikUpdatePool.errors.projectDescription &&
                      formikUpdatePool.touched.projectDescription ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.projectDescription}
                        </span>
                      ) : null}
                    </div>

                    <div style={{ overflow: 'auto' }}>
                      <div className='d-flex gap-3 py-2 justify-content-end'>
                        <button
                          type='button'
                          id='prevBtnCloseUpdate'
                          // onclick="nextPrev(-1)"
                          // onClick={prev}
                          className='btn btn-danger m-0 btn-lg'
                          data-bs-dismiss='modal'
                          disabled={isLoadingUpdatePresale}
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          id='nextBtn'
                          className='btn btn-primary m-0 btn-lg'
                          disabled={isLoadingUpdatePresale}
                          onClick={(event) => {
                            event.preventDefault();
                            formikUpdatePool.handleSubmit();
                          }}
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Cancel Model */}
      <div className='modal' id='cancelPresaleModal'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            {/* Modal Header */}
            <div className='modal-header'>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                hidden={false}
              />
            </div>
            {/* Modal body */}
            <div className='modal-body'>
              <div id='myDropdown' className='dropdown-content'>
                <h5 className='text-center mb-4'>Are you sure?</h5>
                <div className='row row-cols-1 row-cols-sm-2'>
                  <div className='col'>
                    <label className='form-label'>
                      This pool will be cancelled.
                    </label>
                  </div>
                  <div style={{ overflow: 'auto' }}>
                    <div className='d-flex gap-3 pt-4 py-2 justify-content-center'>
                      <button
                        type='button'
                        id='prevBtnClose'
                        className='btn btn-danger btn-lg m-0'
                        data-bs-dismiss='modal'
                        disabled={
                          isLoadingCancelPresale ||
                          isLoadingCancelStealthPresale
                        }
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        id='nextBtn'
                        className='btn btn-primary btn-lg m-0'
                        disabled={
                          isLoadingCancelPresale ||
                          isLoadingCancelStealthPresale
                        }
                        onClick={() => {
                          presaleDetails?.launch_stealth_sale == 0
                            ? cancelPresale()
                            : cancelPresaleStealth();
                        }}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function HomePage({ presale_chain }) {
  // return <Web3Button />;
  const { isDisconnected, isConnected, address, isConnecting } = useAccount();
  const { open, close, isOpen } = useWeb3Modal();

  // return <button class="btn btn-primery btn-connect" disabled={isOpen} onClick={() => { open() }}>{isOpen && !isConnected ? 'Connecting...' : isConnected ? `${address.slice(0, 4)}...${address.slice(-4)}` : 'Connect'}</button>

  return (
    <div className='custom_card text-center connect_network_presale h-auto mb-4'>
      <button
        type='button'
        className='btn text-white'
        onClick={() => {
          open();
        }}
      >
        <span>Connect Network to</span>
        <span className='ms-2 me-1'>
          <img
            onClick={() => {
              open();
            }}
            src={
              networkImages[
                config.SUPPORTED_NETWORKS.find(
                  (network) => network.id == presale_chain
                )?.network
              ]
            }
            alt='icon'
            width='25'
          />
        </span>
        <span className='text-blue'>
          {
            config.SUPPORTED_NETWORKS.find(
              (network) => network.id === presale_chain
            )?.switch_show_name
          }
        </span>
      </button>
    </div>
  );
}
