import React from "react";
import { useAccount, useContractRead } from "wagmi";
import Presale from "../../_constant/PreSale.json";
import FairLaunch from "../../_constant/FairLaunch.json";
import { PRESALE_MANAGER_ADDRESS, SALE_TYPE } from "../../_constant";
import { ethers } from "ethers";
import moment from "moment";
import { PostErrorLogAction } from "../../redux/apiActions/api.action";

export function VestingScheduke({presaleDetails}){
    const { address } = useAccount();
    const onSuccessLiqidityAddsOn = (data)=>{
        // console.log("onSuccessLiqidityAddsOn",data)
    }
    const onErrorLiqidityAddsOn = (error)=>{
        console.log("onErrorLiqidityAddsOn",error);
        PostErrorLogAction({ address: address || 0, other_detail: { error } });
    }

    const { data: dataLiqidity, isError: isErrorAllowance, isLoading: isLoadingAllowance, refetch: refetchAllowance } = useContractRead({
        address: presaleDetails?.preSale,
        abi: SALE_TYPE.isPresaleOrPrivateSale(presaleDetails?.saleType) ? Presale.abi:FairLaunch.abi,
        functionName: "liquidityAddedOn",
        onSuccess: onSuccessLiqidityAddsOn,
        onError: onErrorLiqidityAddsOn
      })

      function generateVestingItems() {
            const vestingItems = [];
            let vestingAmount = presaleDetails?.vestingAmount;
            let remainingPercentage = 100;
          
            // First release
            const firstReleasePercent = presaleDetails?.firstReleasePercent;
            const firstReleaseAmount = (BigInt(vestingAmount) * BigInt(firstReleasePercent)) / BigInt(100); //eslint-disable-line no-undef
            remainingPercentage -= firstReleasePercent;
            vestingItems.push(
              <li className="d-flex justify-content-between" key="first-release">
                <h6>{presaleDetails?.firstReleaseAfter} days after presale finalize</h6>
                <p className="text-bold">{ ethers.utils.formatUnits(String(firstReleaseAmount), Number(presaleDetails?.token_decimal)) } {presaleDetails?.token_symbol}</p>
              </li>
            );
          
            // Subsequent releases
            const releaseCyclePercentage = presaleDetails?.releaseCyclePercentage;
            const releaseCycleDays = presaleDetails?.releaseCycleDays;
            while (remainingPercentage > 0) {
            //   const releaseAmount = vestingAmount * (releaseCyclePercentage / 100);
            let releaseAmount;
                if(releaseCyclePercentage > remainingPercentage){
                  releaseAmount = (BigInt(vestingAmount) * BigInt(remainingPercentage)) / BigInt(100); //eslint-disable-line no-undef
                remainingPercentage -= remainingPercentage;
                }
                else {
                releaseAmount = (BigInt(vestingAmount) * BigInt(releaseCyclePercentage)) / BigInt(100); //eslint-disable-line no-undef
                remainingPercentage -= releaseCyclePercentage;
                }

            //   remainingPercentage -= releaseCyclePercentage;
              vestingItems.push(
                <li className="d-flex justify-content-between" key={`release-${remainingPercentage}`}>
                  <h6>{releaseCycleDays} days after</h6>
                  <p className="text-bold">{ethers.utils.formatUnits(String(releaseAmount), Number(presaleDetails?.token_decimal))} {presaleDetails?.token_symbol}</p>
                </li>
              );
            }
          
            return vestingItems;
      }


      function generateVestingItemsFilalized() {
        const vestingItems = [];

        let liquidity_adds_on_timestamp = Number(dataLiqidity);  //liqidityAddOn 
        let a_days_second = 86400;

        const first_release = ((Number(presaleDetails?.firstReleaseAfter)*a_days_second)+Number(presaleDetails?.liquidity_adds_on_timestamp)) * 1000
        const Sdate = new Date(first_release);
        let vestingAmount = presaleDetails?.vestingAmount;
        let remainingPercentage = 100;
        // First release
        const firstReleasePercent = presaleDetails?.firstReleasePercent;
        const firstReleaseAmount = (BigInt(vestingAmount) * BigInt(firstReleasePercent))/BigInt(100); //eslint-disable-line no-undef


        remainingPercentage -= firstReleasePercent;
        vestingItems.push(
          <li className="d-flex justify-content-between" key="first-release">
            <h6>{moment(Sdate).format("YYYY-MM-DD HH:mm")}</h6>
            <p className="text-bold">{ ethers.utils.formatUnits(String(firstReleaseAmount), Number(presaleDetails?.token_decimal)) } {presaleDetails?.token_symbol}</p>
          </li>
        );
      
        // Subsequent releases
        const releaseCyclePercentage = presaleDetails?.releaseCyclePercentage;
        const releaseCycleDays = presaleDetails?.releaseCycleDays;

        
        
        let counter_liq = 1;
        while (remainingPercentage > 0) {
        //   const releaseAmount = vestingAmount * (releaseCyclePercentage / 100);

        let rel_cycle_days_iSecond = ((releaseCycleDays * a_days_second) * counter_liq*1000)+first_release;
        const Sdate = new Date(rel_cycle_days_iSecond);
        let releaseAmount;
        if(releaseCyclePercentage > remainingPercentage){
          releaseAmount = (BigInt(vestingAmount) * BigInt(remainingPercentage)) / BigInt(100);  //eslint-disable-line no-undef
          remainingPercentage -= remainingPercentage;
          }
          else {
          releaseAmount = (BigInt(vestingAmount) * BigInt(releaseCyclePercentage)) / BigInt(100);  //eslint-disable-line no-undef
          remainingPercentage -= releaseCyclePercentage;
          }


        //   remainingPercentage -= releaseCyclePercentage;
          vestingItems.push(
            <li className="d-flex justify-content-between" key={`release-${remainingPercentage}`}>
                        <h6>  {moment(Sdate).format("YYYY-MM-DD HH:mm")}</h6>
            <p className="text-bold">{ethers.utils.formatUnits(String(releaseAmount), Number(presaleDetails?.token_decimal))} {presaleDetails?.token_symbol}</p>
            </li>
          );
          counter_liq++;
        }
      
        return vestingItems;
  }
      



return(
    // <div className="col-md-12">
    <div className="custom_card mb-4 p-4 vesting_schedule">
      <h5 className="form-card-heading text-start">Team Token Vesting Schedule:</h5>
      <hr />
      <ul className="presale_address_ui mt-2">
              <li className="d-flex justify-content-between">
                <h6>Release Date</h6>
                <p className="text-bold">Amount</p>
              </li>
              {/* <li className="d-flex justify-content-between">
                   {presaleDetails?.firstReleaseAfter}{" "}days after presale finalize
                <p className="text-bold"> {ethers.utils.formatUnits(String(presaleDetails?.vestingAmount), Number(presaleDetails?.fund_releasing_token == 'Custom' || presaleDetails.fund_releasing_token == 'BUSD' ? presaleDetails?.custom_fund_token_decimal : 18)) * presaleDetails?.firstReleasePercent}</p>
              </li> */}

{Number(presaleDetails?.liquidity_adds_on_timestamp)==0 ?generateVestingItems():generateVestingItemsFilalized() }
      </ul>
    </div>
  // </div>
)
}