import { useFormik } from "formik";
import CancelStack from "./CancelStack";
import UpdateStack from "./UpdateStack";
import * as Yup from "yup"
import { STAKE_MANAGER, blockInvalidChar, isNumberKey } from "../../_constant";
import { getAccount, getNetwork } from "@wagmi/core"
import StackingManagerABI from "../../_constant/StakingManager.json";
import { toast } from "react-toastify";
import { SUCCESSMSGS, TRANSACTIONMSGS } from "../../utils/messages";
import { useContractRead, useContractWrite, useWaitForTransaction } from "wagmi";
import { ethers } from "ethers";
import SimpleToken from "../../_constant/SimpleToken.json";
import { PostErrorLogAction, depositeWithdrawalStakAction, updateLastDRewardAction } from "../../redux/apiActions/api.action";
import WithdrawStake from "./WithdrawStack";
import moment from "moment";
import StackPoolABI from "../../_constant/StakePool.json";

export default function ManageStack({stakeDetails,isLoadingUpdatePresale,isLoadingWFTUpdate,getPresaleFuncWithoutLoading}) {
   const { chain, chains } = getNetwork()
   const STACK_MANAGER_ADDRESS = STAKE_MANAGER[chain?.network]

  //  const account = getAccount()

  const account = getAccount()

   const { data: balanceOf } = useContractRead({
    /// PreSale token address
    // @ts-ignore
    address: stakeDetails?.rewardToken,
    abi: SimpleToken.abi,
    functionName: "balanceOf",
    args: [account.address],
    // onError: onTokenFetchError,
  });


  

    const formikReward = useFormik({
        enableReinitialize: true,
        initialValues: {
          reward_amount: ""
        },
        validationSchema: Yup.object().shape({
            reward_amount: Yup.number().positive("Reward amount should be positive!!").moreThan(0,"Reward amount should be greater than 0!").max(ethers.utils
              .formatUnits(String(balanceOf || 0),Number(JSON.parse(stakeDetails?.rewardTokenInfo)?.decimals || 0)), "Not enough balance to deposit reward. Try with small amount.")
        }),
        onSubmit: async (data) => {
          try {
            
            if(data?.reward_amount==""){
              return toast.error("Reward amount is required",{toastId:'rairTid'})
            }

            if(Number(ethers.utils
              .formatUnits(String(isAllowanceData || 0),Number(JSON.parse(stakeDetails?.rewardTokenInfo)?.decimals || 0))
              .toString()) < Number(data.reward_amount) ){
                approveToken(
                  {
                    address:stakeDetails?.rewardToken,
                    args:[stakeDetails?.stakeContract,String(ethers.utils.parseUnits(String(data?.reward_amount),Number(JSON.parse(stakeDetails?.rewardTokenInfo)?.decimals || 0)))]
                  })
              }else{
                depositeReward({args: [stakeDetails?.stakeContract, ethers.utils
                  .parseUnits(String(formikReward.values?.reward_amount), Number(JSON.parse(stakeDetails?.rewardTokenInfo)?.decimals || 0))
                  .toString()],})
              }
      
          } catch (error) {
          } finally {
          }
        },
      });



      const { data: isAllowanceData, isError: isErrorAllowance, isLoading: isLoadingAllowance, refetch: refetchAllowance } = useContractRead({
        address: stakeDetails?.rewardToken,
        abi: SimpleToken.abi,
        functionName: "allowance",
        args: [account.address, stakeDetails?.stakeContract],
        // onSuccess: onSuccessAllowance,
        // onError: onErrorAllowance
    })

  

      const onErrorDepositeReward = (error) => {
        console.log("error",error);
        PostErrorLogAction({ address: account?.address || 0, other_detail: { error } });
        let errStr = error.toString().slice(0, 25)
        if (errStr === "TransactionExecutionError") {
          toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT)
        }else if(error.toString().includes("insufficient allowance")){
          toast.error("insufficient allowance",{toastId:'insuffAlloTid'})
        }
        else if(error.toString().includes("No reward stake pool")){
          toast.error("No reward stake pool!")
        }
      }

      const onSuccessCancelPresale = ()=>{

      }

      const { data: dataReward, isLoading: isLoadingReward, isSuccess: isSuccessReward, isError: isErrorReward, write: depositeReward } = useContractWrite({
        address: STACK_MANAGER_ADDRESS,
        abi: StackingManagerABI.abi,
        functionName: 'depositRewards',
        onError: onErrorDepositeReward,
        onSuccess: onSuccessCancelPresale,
      })
      

      const { data: totalRewardsDistributed,refetch:refetchTotalRewDis } = useContractRead({
        /// PreSale token address
        // @ts-ignore
        address: stakeDetails?.stakeContract,
        abi: StackPoolABI.abi,
        functionName: "totalRewardsDistributed",
        // args: [stakeDetails?.stakeContract],
        watch: true,
        // onError: onTokenFetchError,
      });
      


      const { data: lastDistributedTime,refetch:refetchLastDisTime } = useContractRead({
        /// PreSale token address
        // @ts-ignore
        address: stakeDetails?.stakeContract,
        abi: StackPoolABI.abi,
        functionName: "lastDistributeTime",
        // args: [stakeDetails?.stakeContract],
        watch: true,
        // onError: onTokenFetchError,
      });


      
      const onSuccessWFTDepositeReward = async()=>{
          // Deposite Reward api call
          let bodyData  = {stakeContract:stakeDetails?.stakeContract,reward_amount:formikReward?.values?.reward_amount,address:account?.address}
          let response = await depositeWithdrawalStakAction(bodyData)

          let total_rewards_distributed = await refetchTotalRewDis() 
          total_rewards_distributed = total_rewards_distributed.data;

          let refetched_last_distribute_time = await refetchLastDisTime()
          refetched_last_distribute_time = refetched_last_distribute_time.data;
          
          let bodyDataUpdateDetails = {total_rewards_distributed:Number(total_rewards_distributed),stakeContract:stakeDetails?.stakeContract,last_distribute_time:Number(refetched_last_distribute_time),address:account?.address}
          let response1 = await updateLastDRewardAction(bodyDataUpdateDetails)
          if(response){
            toast.success(SUCCESSMSGS.STACK_DEPOSITE_REWARD,{toastId:'depositedDeReward'})
            getPresaleFuncWithoutLoading()
            formikReward.resetForm()
          } 
      }

    const {isLoading:isLoadingWFTDepositeReward} = useWaitForTransaction({
      hash: dataReward?.hash,
      // onSettled: onApproveReceipt,
      onSuccess:onSuccessWFTDepositeReward
    });

      const onApproveError = async (error) => {
        console.log("Error 73", error);
        PostErrorLogAction({ address: account?.address || 0, other_detail: { error } });
        let errStr = error.toString().slice(0,53)
        if(errStr === "TransactionExecutionError: User rejected the request."){
          toast.error(TRANSACTIONMSGS.REJECTEDAPPROVING)
        }else{
        toast.error('Failed in approving!');
        }
      
      };


      const onSuccessApprove  = ()=>{

      }


         // Approve Token
    const {
      data: approvedData,
      write: approveToken,
      isLoading:isLoadingApprove,
      isSuccess: isApprovedSuccess,
    } = useContractWrite({
      /// PreSale token address
      // @ts-ignore
      abi: SimpleToken.abi,
      functionName: "approve",
      onError: onApproveError,
      onSuccess:onSuccessApprove
    });


       
    const onSuccessWaitForTransaction = (data)=>{
      // console.log("onSuccessWaitForTransaction",data)
      }
        /// Any api call
      const onApproveReceipt = async (data, err) => {
          if (err) {
          // console.error("Err", err);
          } else {
          // Any API call or additional logic can be placed here
          toast.success(SUCCESSMSGS.APPROVED_SUCCESS)
           depositeReward({args: [stakeDetails?.stakeContract, ethers.utils
              .parseUnits(String(formikReward.values?.reward_amount), Number(JSON.parse(stakeDetails?.rewardTokenInfo)?.decimals || 0))
              .toString()],})
          }
      };



    const {isLoading:isLoadingWFTApprove} = useWaitForTransaction({
      hash: approvedData?.hash,
      onSettled: onApproveReceipt,
      onSuccess:onSuccessWaitForTransaction
    });


    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 gap-2">

                    {
                      moment.unix() > stakeDetails?.endDateTime  && 
                        <WithdrawStake stakeDetails={stakeDetails}/>
                    }
                    
                    <UpdateStack isLoadingUpdatePresale={isLoadingUpdatePresale} isLoadingWFTUpdate={isLoadingWFTUpdate}/>
                    <CancelStack stakeDetails={stakeDetails} getPresaleFuncWithoutLoading={getPresaleFuncWithoutLoading}/>
                   
                   {
                    stakeDetails?.rewardToken != "0x0000000000000000000000000000000000000000" &&
                    <>
                    <div className="form-group mt-4">
                        <div className="contribute_amount_input">
                        <input
                        type="text"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={blockInvalidChar}
                        placeholder="Reward Amount"
                        id="reward_amount-lock"
                        name="reward_amount"
                        // onChange={formikReward.handleChange}
                        className={formikReward.errors.reward_amount &&
                          formikReward.touched.reward_amount && 'invalid'}
                        value={formikReward.values.reward_amount}
                        onChange={(e) => {
                          const inputValue = isNumberKey(e.target.value);
                          // const numericValue = inputValue.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except dot (.)
                          const numericValue = inputValue; // Remove non-numeric characters except dot (.)
                          if (numericValue !== false) {
                              if (e.target.value.length < 21) {
                                  formikReward.handleChange({
                                      target: {
                                          name: "reward_amount",
                                          value: numericValue,
                                      },
                                  });
                              }
                          }


                      }}

                        />
                    </div>
                    {formikReward.errors.reward_amount &&
                            formikReward.touched.reward_amount ? (
                              <span className="error-msg">
                                {formikReward.errors.reward_amount}
                              </span>
                            ) : null}
                    </div>
                    <button type="submit" className="btn btn-success btn-block mt-4 btn_managepool" 
                      disabled={isLoadingApprove || isLoadingWFTApprove || isLoadingReward || isLoadingWFTDepositeReward}
                       onClick={(event) => {
                        event.preventDefault();
                        formikReward.handleSubmit();
                      }}
                    > {isLoadingApprove|| isLoadingWFTApprove?`Approving ${JSON.parse(stakeDetails?.rewardTokenInfo)?.symbol}...`:isLoadingReward||isLoadingWFTDepositeReward?`Depositing ${JSON.parse(stakeDetails?.rewardTokenInfo)?.symbol}...`:'Deposite Reward'}</button>
                    </>
                   }
                   
                    </div>
                </div>
            </div>
        </>
    )
}