import React from 'react';
import {
  BASE_URL,
  networkImages,
  networkImagesByChain,
  NO_REWARD,
  REWARD_TYPE,
} from '../../_constant';
import Banner from '../PresaleDetails/Banner';
import { ethers } from 'ethers';
import { Link } from 'react-router-dom';
import moment from 'moment';

const StackingPoolCard = ({ data, statusText, classNameD }) => {
  return (
    <div className={`card custom_card_design staking_pool_card`}>
      <Link
        to={`${BASE_URL}stake?stakeContract=${data?.stakeContract}&chainId=${data?.chain}`}
      >
        <div className={`custom_card card p-0 h-100`}>
          {/* <Link to={`${BASE_URL}presale?presale_id=${data?.preSale}&chainId=${data?.chain}`}> */}
          <div className='d-flex gap-3 gap-sm-4 p-3 pb-0 w-100 align-items-start justify-content-start'>
            <div
              className='card-top-img stablz-card-top-img'
              // style={{
              //     backgroundImage: `url(${data?.bannerUrl != "" ? data?.bannerUrl : "https://testnet.gempad.app/static/bg4.png"})`,
              // }}
            >
              {
                // data?.bannerUrl &&
                <Banner url={data?.bannerUrl} />
              }
              <div className='p-3'>
                <div className='audi_kyc_btn'></div>
              </div>
            </div>
            <div className='w-100'>
              <div className='card_second_section d-flex justify-content-between align-items-center'>
                <div className='card_second_section_text'>
                  <h5>Stake {data?.name}</h5>
                  <p className='mb-2 text-white fs-6'>
                    Stake {data?.symbol}{' '}
                    {data?.rewardToken != NO_REWARD &&
                      `to Earn ${JSON.parse(data.rewardTokenInfo)?.symbol}`}
                  </p>
                </div>
                <div></div>
                <div className='position'>
                  <img
                    width={30}
                    src={networkImagesByChain[data?.chain]}
                    // className="img-fluid"
                    alt='logo'
                  />
                </div>
              </div>
              <div className='compare_coin d-flex align-items-center mt-2'>
                <img
                  // className="img-fluid"
                  src={data?.stakingLogoUrl}
                  onError={(e) => {
                    e.target.src = networkImages['bsc-testnet'];
                  }}
                  alt='stakinglogo'
                />

                {data?.rewardToken != NO_REWARD && (
                  <>
                    <div className='px-3'>
                      <i
                        className='fa fa-angle-right'
                        style={{ fontSize: 36 }}
                      />
                    </div>

                    <img
                      src={data?.rewardLogoUrl}
                      onError={(e) => {
                        e.target.src = networkImages['bsc-testnet'];
                      }}
                      alt='rewardlogo'
                    />
                  </>
                )}
              </div>

              {/* status */}
              <div className='pt-3'>
                <div className={classNameD}>{statusText}</div>
              </div>
            </div>
          </div>
          {/* </Link> */}
          <hr />
          <div className={`px-3`}>
            <div className='row row-cols-1 row-cols-sm-1 gx-1 gy-1 pb-3'>
              <div className='col'>
                <ul className='presale_address_ui ul_li_bordernone'>
                  <li className='d-flex justify-content-between'>
                    <span className='text-dark-200 fw-normal fs-6'>
                      Reward Type:
                    </span>
                    <span className='text-primary-500 fw-semibold fs-6'>
                      {Object.keys(REWARD_TYPE).find(
                        (key) => REWARD_TYPE[key] == data?.rewardType
                      )}
                    </span>
                  </li>
                  {data?.rewardType != REWARD_TYPE['Only if balance'] &&
                    data?.rewardType != REWARD_TYPE['No Reward'] && (
                      <li className='d-flex justify-content-between'>
                        <span className='text-dark-200 fw-normal fs-6'>
                          Reward Ratio (year):
                        </span>
                        <span className='text-primary-500 fw-semibold fs-6'>
                          {ethers.utils
                            .formatUnits(
                              String(data?.rewardRatio || 0),
                              JSON.parse(data.rewardTokenInfo)?.decimals || 0
                            )
                            .toString()
                            ?.replace(/\.?0*$/, '')}
                        </span>
                      </li>
                    )}
                  <li className='d-flex justify-content-between'>
                    <span className='text-dark-200 fw-normal fs-6'>
                      Hard Cap:
                    </span>
                    <span className='text-primary-500 fw-semibold fs-6'>
                      {/* {data?.hardCap} */}
                      {
                        Intl.NumberFormat('en', {
                          notation: 'compact',
                        }).format(
                          ethers.utils.formatUnits(
                            String(data?.hardCap || 0),
                            JSON.parse(data.stackTokenInfo)?.decimals || 0
                          )
                        )
                        // .toString()?.replace(/\.?0*$/, '')
                      }
                    </span>
                  </li>
                  <li className='d-flex justify-content-between'>
                    <span className='text-dark-200 fw-normal fs-6'>
                      Total Staked
                    </span>
                    <span className='text-primary-500 fw-semibold fs-6'>
                      {Number(data?.total_staked)
                        .toFixed(5)
                        ?.replace(/\.?0*$/, '')}
                    </span>
                  </li>
                  <li className='d-flex justify-content-between'>
                    <span className='text-dark-200 fw-normal fs-6'>
                      Start Time
                    </span>
                    <span className='text-primary-500 fw-semibold fs-6'>
                      {moment(new Date(data?.startDateTime * 1000)).format(
                        'YYYY-MM-DD HH:mm'
                      )}
                    </span>
                  </li>
                  <li className='d-flex justify-content-between'>
                    <span className='text-dark-200 fw-normal fs-6'>
                      End Time
                    </span>
                    <span className='text-primary-500 fw-semibold fs-6'>
                      {moment(new Date(data?.endDateTime * 1000)).format(
                        'YYYY-MM-DD HH:mm'
                      )}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            {/* <hr /> */}
            <div className='row'>
              <div className='col'>
                <div className='sale_status d-flex justify-content-between align-items-start'>
                  <div></div>
                </div>
              </div>
            </div>
          </div>

          {/* {showAdditionalData[key] && */}

          {/* } */}
        </div>
      </Link>
    </div>
  );
};

StackingPoolCard.Shimmer = () => {
  return (
    <div
      class='card custom_card p-0 mx-auto'
      aria-hidden='true'
    >
      <div className='d-flex align-items-start gap-4 mb-4 p-3'>
        {/* <div> */}
        <span
          class='placeholder col-12 card-top-img rounded-4'
          style={{
            maxWidth: '45%',
          }}
        ></span>
        {/* </div> */}
        <h5 className='card-title placeholder-glow col'>
          <span className='placeholder col-8'></span>
          <span className='placeholder col-10'></span>
          <span className='placeholder col-12'></span>
          <div className='col-12'>
            <div
              className='my-3 sale_end badge placeholder'
              aria-disabled='true'
            >
              <span
                style={{
                  opacity: 0,
                }}
              >
                1
              </span>
            </div>
          </div>
        </h5>
      </div>
      <p className='placeholder-glow p-3'>
        <span className='placeholder col-12'></span>
        <span className='placeholder col-12'></span>
        <span className='placeholder col-12'></span>
        <span className='placeholder col-12'></span>
        <span className='placeholder col-12'></span>
        <span className='placeholder col-12'></span>
      </p>
    </div>
  );
};

export default StackingPoolCard;
