// export const INFURAID ='e717cb93316376cf535223e01336cd09'

import config from '../config';

export const BASE_URL = config.project_base_path;

export const gasFeesForToken = {
  'Simple Token': 700000,
  'Standard Token': 5100000,
  'Reflection Token': 6500000,
};

// // development (testnet)
// export const USDT_ADDRESS = '0x3F6194dfa98569AeA86aefe1e4f1c4Df54e42355'; // testnet
// export const HLPR_ADDRESS = '0x0F6ffdD80e270F1ACD7A9baD73E7E1b7cE921Cd7'; // testnet

// // production (mainet)
// export const USDT_ADDRESS = '0x55d398326f99059ff775485246999027b3197955'; // mainnet
// export const HLPR_ADDRESS = '0x501cb2cd15198a4853bf8944b04c2053410a912b'; // mainnet

export const tokenList = {
  SIMPLE_TOKEN: 'Simple Token',
  STANDARD_TOKEN: 'Standard Token',
  REFLECTION_TOKEN: 'Reflection Token',
  DIVIDENT_TOKEN: 'Dividend Token',
};

export const feeManagerTicket = {
  'Simple Token': 0,
  'Standard Token': 1,
  'Reflection Token': 2,
  'Dividend Token': 3,
};

export const createMenusLeft = {
  Presale: `${BASE_URL}create-presale`,
  'Special Sale': `${BASE_URL}create-special`,
  'Hyper Launch': `${BASE_URL}create-hyper-launch`,
  'Fair Launch': `${BASE_URL}create-fair-launch`,
};

export const createMenusRight = {
  Token: `${BASE_URL}create-token`,
  'Staking Pool': `${BASE_URL}create-stake`,
  Lock: `${BASE_URL}create-lock`,
  Airdrop: `${BASE_URL}airdrop`,
};

export const createMenu = {
  Presale: `${BASE_URL}create-presale`,
  Token: `${BASE_URL}create-token`,
  'Special Sale': `${BASE_URL}create-special`,
  'Staking Pool': `${BASE_URL}create-stake`,
  'Hyper Launch': `${BASE_URL}create-hyper-launch`,
  Lock: `${BASE_URL}create-lock`,
  'Fair Launch': `${BASE_URL}create-fair-launch`,
  Airdrop: `${BASE_URL}airdrop`,
};

export const networkImages = {
  arbitrum: `${BASE_URL}images/icon/arbi_logo.png`,
  homestead:
    'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
  maticmum:
    'https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png?1624446912',
  dogechain:
    'https://assets.coingecko.com/coins/images/26828/small/dogechain.jpeg?1660292741',
  'bsc-testnet': `${BASE_URL}images/icon/binance_logo.svg`,
  bsc: `${BASE_URL}images/icon/binance_logo.svg`,
  matic:
    'https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png?1624446912',
};

export const networkImagesByChain = {
  arbitrum: 'images/icon/arbi_logo.png',
  homestead:
    'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
  80001:
    'https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png?1624446912',
  dogechain:
    'https://assets.coingecko.com/coins/images/26828/small/dogechain.jpeg?1660292741',
  97: `${BASE_URL}images/icon/binance_logo.svg`,
  56: `${BASE_URL}images/icon/binance_logo.svg`,
};

export const networkLinks = {
  97: 'https://testnet.bscscan.com',
  56: 'https://bscscan.com',
  80001: 'https://mumbai.polygonscan.com',
};

export const listedOnDexLinks = {
  PancakeSwap:
    'https://pancakeswap.finance/swap?chain=bscTestnet&inputCurrency',
};

export const spenderAddress = '0x4aFB865c8Aa63A3DFBB3843cf0a293B3Db088542';

export const PRESALE_MANAGER_ADDRESS =
  '0xcd18c22Aa1EfAe8666E592C666ab351F3F8B1dC4';

export const PRESALE_MANAGER_ADDRESSESS = {
  // "bsc-testnet":"0x7321e0c76a842Cc8388cEb19c15719326d5b5E74",
  // "maticmum":"0x894eC87cFb4dc6222DaEb4010B0a49C8F2a4F71f"
  'bsc-testnet': '0x2C235f6585c6A22E782B18E782e20162f0aAc70D',
  bsc: '0xfFE535748b4346EfDb1d22826ccBc6ff55d80b34',
};

export const PRESALE_MANAGER_LIMIT_ADDRESSESS = {
  'bsc-testnet': '0x2C235f6585c6A22E782B18E782e20162f0aAc70D',
  bsc: '0xfFE535748b4346EfDb1d22826ccBc6ff55d80b34',
};

export const AIRDROP_CONTRACT_ADDRESSS =
  '0x03d28b274FbB9D9c480fa69E7236eB4e30b853f3';

export const FEE_MANAGER_ADDRESSESS = {
  'bsc-testnet': '0x1eB56D3442B51A933f0366DAf9a0627C7b0a1A6D',
  bsc: '0x90aE54Feb5015375B1eF3558296737244eD97eFd',
  // "maticmum": "0xf7Ba22cf80caD8738eF9A1a85E309e1D567683aF"
};

export const LOCKERS_ADDRESSESS = {
  'bsc-testnet': '0x4272437af83218F1dBC324B886A3A50bDB9626B5',
  maticmum: '0x5CA22Bdaed1848e9AA050AE61F0FF905C8cA2F4d',
};

export const STAKE_MANAGER = {
  'bsc-testnet': '0xc384089F02184555d0EcC156726c5e5e8A1E145B',
  maticmum: '0xFEb2EADe9eA6c9dBE8bc31621F3D61D46b3c114E',
};

export const blockInvalidChar = (e) =>
  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

export const validateAddress = (address) => {
  const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
  return addressRegex.test(address);
};

export function isNumberKey(value) {
  value = value ? value : '';
  // alert(value)
  var validNumber = new RegExp(/^[0-9]*(\.[0-9]*)?$/);
  if (validNumber.test(value)) {
    return value;
  } else {
    return false;
  }
}

export function convertScientificToDecimal(scientificNotation) {
  const stringRepresentation = scientificNotation.toString();
  const match = /^-?\d+(?:\.(\d+))?[eE]([+-]?\d+)$/.exec(stringRepresentation);

  if (!match) {
    return scientificNotation; // Return unchanged if not in scientific notation
  }

  const decimalPlaces = Math.max(0, -parseInt(match[2], 10));
  const decimal = scientificNotation.toFixed(decimalPlaces);

  return decimal;
}

export const PerkCategories = {
  STANDARD: 0,
  GOLD: 1,
  PLATINUM: 2,
  DIAMOND: 3,
};

export const SALE_TYPE = {
  PRESALE: 'Presale',
  PRIVATE: 'Private Sale',
  FAIR: 'Fair Launch',
  HYPER: 'Hyper Launch',
  isPresaleOrPrivateSale: (saleType) =>
    [SALE_TYPE.PRESALE, SALE_TYPE.PRIVATE].includes(saleType),
};


export const PRESALE_STATUS = {
  pending: 0,
  approved: 1,
  rejected: 2,
};

export const REWARD_TYPE = {
  'Percent Reward': '0',
  'Fixed Reward': '1',
  'Only if balance': '2',
  'No Reward': '3',
};

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyATSQX4ljzxPa7cm9De6fyf6rocggbjuKs',
  authDomain: 'launchpad-51630.firebaseapp.com',
  projectId: 'launchpad-51630',
  storageBucket: 'launchpad-51630.appspot.com',
  messagingSenderId: '838375522646',
  appId: '1:838375522646:web:1afc7de1e35e68cf3256f7',
  measurementId: 'G-D71FH2XKFJ',
};

export const NO_REWARD = '0x0000000000000000000000000000000000000000';

export const FIREBASE_KEY_PAIR =
  'BEbZmxnlgBrDLwJsXVvdo4VnN4kfNy9KW_n5GRmZ3Zc62Rp614HMZ-VPY0v_BdCy8S5fU-b7XPbE_Iu-nOiLqUU';

export const APP_NAME_TITLE = 'HelperPad.io';

export const PURCHASE_METHODS = [
  {
    id: 'payment_1',
    title: ['100% HLPR tokens.'],
    value: 1,
  },
  {
    id: 'payment_2',
    title: ['50% cashback', '50% HLPR tokens.'],
    value: 2,
  },
  {
    id: 'payment_3',
    title: [`25% cashback`, `50% HLPR tokens`, `25% scanner's earning wallet`],
    value: 3,
  },
  {
    id: 'payment_4',
    title: [`50% HLPR tokens`, `50% scanner's earning wallet.`],
    value: 4,
  },
];

export const PACKAGES = {
  1: {
    package_id: 1,
    title: '$100',
    description:
      'The users who purchased this subscription can buy $100 worth of tokens per project/coin listed on Launchpad.',
    validity: '6 Months',
    class_name: 'first-plan',
    detail_card_class: '',
  },
  2: {
    package_id: 2,
    title: '$200',
    description:
      'The users who purchased this subscription can buy $250 worth of tokens per project/coin listed on Launchpad.',
    validity: '6 Months',
    class_name: 'second-plan',
    detail_card_class: 'plan-details-card-center',
  },
  3: {
    package_id: 3,
    title: '$400',
    description:
      'The users who purchased this subscription can buy $500 worth of tokens per project/coin listed on Launchpad.',
    validity: '6 Months',
    class_name: 'third-plan',
    detail_card_class: '',
  },
};

export const HLPR_CONTRACT_ADDRESSES = {
  'bsc-testnet': '0x501Cb2Cd15198A4853bF8944b04c2053410A912B',
  maticmum: '0x61632d27ea8A983ccd5F105299bF48b2D62e1054',
};

export const HLPR_MANAGENR_ADDRESS =
  '0xF139c9760Bc194f09Fa6C2397b9eC9FDFD728b57';

// export const SUPPORTED_NETWORKS=[
//   {
//     id:56,
//     decimals:18,
//     name:'BNB',
//     symbol:'BNB',
//     switch_show_name:'Bsc Mainnet',
//     network:'bsc',
//   },
// // {
// //   id:97,
// //   decimals:18,
// //   name:'BNB',
// //   symbol:'BNB',
// //   switch_show_name:'Bsc Testnet',
// //   network:'bsc-testnet',
// // },
// // {
// //   id:80001,
// //   decimals:18,
// //   name:'MATIC',
// //   symbol:'MATIC',
// //   switch_show_name:'Polygon Mumbai',
// //   network:'maticmum',

// // }
// ]
export const SCANNER_V2_URL = 'https://mlm.pixelsoftwares.com/scanner_v2';

export const presaleTabs = [
  {
    id: 'quick_view',
    label: 'Quick View',
  },
  {
    id: 'all_products',
    label: 'All Presales',
  },
  {
    id: 'my_contribution',
    label: 'My Contribution',
  },
  {
    id: 'my_favorites',
    label: 'My Favorites',
  },
  {
    id: 'myalarms',
    label: 'My Alarms',
  },
  {
    id: 'my_created_presale',
    label: 'My Created Presales',
  },
];

export const specialSaleTabs = [
  {
    id: 'all_products',
    label: 'All Presales',
  },
  {
    id: 'my_contribution',
    label: 'My Contribution',
  },
  {
    id: 'my_favorites',
    label: 'My Favorites',
  },
  {
    id: 'myalarms',
    label: 'My Alarms',
  },
  {
    id: 'my_created_presale',
    label: 'My Created Presales',
  },
];

export const presaleFilterByOptions = [
  {
    label: 'Upcoming',
    value: 'upcoming',
  },
  {
    label: 'Live',
    value: 'live',
  },
  {
    label: 'Listed',
    value: 'listed',
  },
  {
    label: 'Audit',
    value: 'audit',
  },
  {
    label: 'Stealth',
    value: 'stealth',
  },
  {
    label: 'Gold',
    value: 'gold',
  },
  {
    label: 'Platinum',
    value: 'platinum',
  },
  { label: 'Diamond', value: 'diamond' },
];

export const specialSaleFilterByOptions = [
  {
    label: 'Upcoming',
    value: 'upcoming',
  },
  {
    label: 'Live',
    value: 'live',
  },
  {
    label: 'Claim Allowed',
    value: 'allowed',
  },
  {
    label: 'Audit',
    value: 'audit',
  },
  {
    label: 'Gold',
    value: 'gold',
  },
  {
    label: 'Platinum',
    value: 'platinum',
  },
  { label: 'Diamond', value: 'diamond' },
];
