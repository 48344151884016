import React from 'react';

const AllSocialLinks = ({
  twitterUrl,
  telegramUrl,
  githubUrl,
  websiteUrl,
  discordUrl,
}) => {
  return (
    <ul className='ms-4 presale_social_icons_section d-flex align-items-center gap-2'>
      {twitterUrl && (
        <li
          onClick={() => {
            window.open(twitterUrl);
          }}
        >
          <span className='text-primary fs-5'>
            <img
              src='images/presale/twitter.svg'
              alt='icon'
              className='icon'
              width={25}
            />
          </span>
        </li>
      )}
      {telegramUrl && (
        <li
          onClick={() => {
            window.open(telegramUrl);
          }}
        >
          <span className='text-primary fs-5'>
            <img
              src='images/presale/telegram.svg'
              alt='icon'
              className='icon'
              width={25}
            />
          </span>
        </li>
      )}
      {githubUrl && (
        <li
          onClick={() => {
            window.open(githubUrl);
          }}
        >
          <span className='text-primary fs-5'>
            <img src='images/presale/github.svg' alt='icon' className='icon' />
            {/* <i className='fa fa-github' aria-hidden='true' /> */}
          </span>
        </li>
      )}
      {websiteUrl && (
        <li
          onClick={() => {
            window.open(websiteUrl);
          }}
        >
          <span className='text-primary fs-5'>
            <img
              src='images/presale/wordwide.svg'
              alt='icon'
              className='icon'
              width={25}
            />
          </span>
        </li>
      )}

      {discordUrl && (
        <img
          src='images/presale/discord.svg'
          alt='Discord'
          height={25}
          width={25}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            window.open(discordUrl);
          }}
        />
      )}
    </ul>
  );
};

export default AllSocialLinks;
