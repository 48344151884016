import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ethers } from 'ethers';
import moment from 'moment';
import {
  BASE_URL,
  networkImagesByChain,
  PerkCategories,
  PRESALE_STATUS,
  SALE_TYPE,
} from '../../_constant';
import PersaleCountDown from '../CountDown/PersaleCountDown';
import Progressbar from '../../Pages/Presale/Progressbar';
import FairPorgressbar from '../../Pages/Presale/FairProgressbar';
import { PresaleProgInfo } from '../../Pages/Presale/PresaleProgInfo';
import { FairProgInfo } from '../../Pages/Presale/FairProgInfo';
import Banner from '../../Pages/PresaleDetails/Banner';
import { Tooltip } from '@mui/material';
import { FavouriteAction } from '../../redux/apiActions/api.action';
import { useAccount, useNetwork } from 'wagmi';
import { toast } from 'react-toastify';

const PresaleCard = ({
  hoverClass = '',
  fundRaisedData,
  fundRaisingTokenDecimal,
  fundRaisingTokenSymbol,
  currentUnixTime,
  startTime,
  endTime,
  showAdditionalData,
  startEndIn,
  index,
  classNameD,
  statusText,
  toggleAdditionalData,
  onChangeValue,
  isAlarmChecked,
  setRemindStatus,
  data,
}) => {
  const [isFav, setIsFav] = useState(data?.isFavourite || false);
  // console.log(isFav, 'isFav---');
  const account = useAccount();
  const { chain } = useNetwork();
  // const toggleHeartColor = (toggleIcon) => {
  //   const heartIcon = document.getElementById(toggleIcon);
  //   // Toggle the color style
  //   heartIcon.style.color = heartIcon.style.color === 'red' ? '' : 'red';
  // };
  const FavouriteFunction = async (id, ido) => {
    if (account.isConnected == false) {
      toast.error('Login first', { toastId: 'loginFirstTid' });
    } else {
      // toggleHeartColor(`heart-button-${id}`);
      let body = {
        presale_id: id,
        wallet: account.address,
        chain: chain?.id,
        ido: ido,
      };
      await FavouriteAction(body).then((response) => {
        toast.success(response?.message || response?.data?.message);
        // getPresaleFunction();
      });
      setIsFav(!isFav);
    }
  };
  // return (
  //   <div className='presale_card'>
  //     <div className='presale_card_inner'>

  //     </div>
  //   </div>
  // )
  return (
    <div
      className={`card presale_card position-relative custom_card_design ${hoverClass}`}
    >
      {/* audit vetted kyc */}
      <div
        className='position-absolute'
        style={{
          top: 0,
          right: '15px',
        }}
      >
        <div className='audi_kyc_btn d-flex gap-1 gap-sm-2'>
          {data?.isTeamVesting == 1 && (
            <button
              type='btn'
              className='audit-btn fs-6'
              onClick={(e) => {
                if (data?.vetted != '' && data?.vetted != null) {
                  e.preventDefault();
                  window.open(data?.vetted);
                }
              }}
            >
              Vetted
            </button>
          )}
          {data?.perk != PerkCategories.STANDARD && (
            <>
              <button
                type='btn'
                className='audit-btn fs-6'
                onClick={(e) => {
                  if (data?.audit != '' && data?.audit != null) {
                    e.preventDefault();
                    window.open(data?.audit);
                  }
                }}
              >
                Audit
              </button>
              <button
                type='btn'
                className='kyc-btn fs-6'
                onClick={(e) => {
                  if (data?.kyc != '' && data?.kyc != null) {
                    e.preventDefault();
                    window.open(data?.kyc);
                  }
                }}
              >
                KYC+
              </button>
            </>
          )}
        </div>
      </div>
      <Link
        to={`${BASE_URL}presale?presale_id=${data?.preSale}&chainId=${data?.chain}`}
      >
        <div className='snake-animation'>
          <div
            className={`card shadow-sm p-0 w-100 d-flex flex-column  gap-2 gap-sm-3 rounded-600  p-2 p-md-3 ${
              showAdditionalData[index] ? 'hide' : ''
            }`}
          >
            {/* <Link to={`${BASE_URL}presale?presale_id=${data?.preSale}&chainId=${data?.chain}`}> */}
            <div
              className='d-flex flex-column flex-md-row w-100 align-items-start justify-content-start gap-2'
              // style={{
              //   gap: '1.8rem',
              // }}
            >
              <div
                className='card-top-img stablz-card-top-img'
                // style={{
                //   backgroundImage: `url(${data?.bannerUrl != "" ? data?.bannerUrl : "https://testnet.gempad.app/static/bg4.png"})`,
                // }}
              >
                <Banner url={data.bannerUrl} />
                {/*  */}

                <div className='card_second_section_img position-absolute d-none d-md-flex'>
                  <img
                    src={data?.logoUrl || `${BASE_URL}/images/stablz_stablz_icon.png`}
                    // src={'images/icon/p_icon.png'}
                    className='img-fluid'
                    alt='logo'
                    onError={(event) => {
                      event.target.src =
                        BASE_URL + `images/stablz_stablz_icon.png`;
                      event.onerror = null;
                    }}
                  />
                  <span>
                    <img
                      src={networkImagesByChain[data?.chain]}
                      // className="img-fluid"
                      alt='logo'
                      width={28}
                    />
                  </span>
                </div>
              </div>
              <div className='mt-2 mt-sm-3 w-100'>
                <div className='card_second_section_text mb-1'>
                  <div className='d-flex justify-content-start align-items-center mb-2 mb-md-0 ps-2 pt-2 ps-sm-0 pt-sm-0'>
                    <div className='position-relative top-0 start-0 card_second_section_img d-md-none'>
                      <img
                        src={data?.logoUrl || `${BASE_URL}/images/stablz_stablz_icon.png`}
                        // src={'images/icon/p_icon.png'}
                        className='img-fluid'
                        alt='logo'
                        onError={(event) => {
                          event.target.src =
                            BASE_URL + `images/stablz_stablz_icon.png`;
                          event.onerror = null;
                        }}
                      />
                      <span>
                        <img
                          src={networkImagesByChain[data?.chain]}
                          // className="img-fluid"
                          alt='logo'
                          width={28}
                        />
                      </span>
                    </div>
                    <div className='ps-3 ps-sm-1 ps-md-4'>
                      <h5 className=''>
                        {data?.sale_without_token == 1 &&
                        data?.startTimes == '0'
                          ? data?.sale_title
                          : data?.launch_stealth_sale == true
                          ? data?.sale_title
                          : data?.name}
                      </h5>
                      <p className='mb-0 mb-md-2 text-white fs-6'>
                        {data?.sale_without_token == 1
                          ? `Without Token ${
                            SALE_TYPE.isPresaleOrPrivateSale(data?.saleType)
                                ? ''
                                : data?.saleType == SALE_TYPE.HYPER
                                ? '(Hyper)'
                                : '(Fair)'
                            }`
                          : data?.launch_stealth_sale == 1
                          ? `Stealth ${data?.saleType}`
                          : data?.saleType}{' '}
                        - Max:{' '}
                        {/* {web3.utils.fromWei(
                String(data?.data?.maxBuy),
                "ether"
              )}{" "} */}
                        {SALE_TYPE.isPresaleOrPrivateSale(data?.saleType)
                          ? ethers.utils.formatUnits(
                              String(data?.maxBuy),
                              fundRaisingTokenDecimal
                            )
                          : data?.isMaxBuy == 1
                          ? ethers.utils.formatUnits(
                              String(data?.maxBuy),
                              fundRaisingTokenDecimal
                            )
                          : '∞'}{' '}
                        {fundRaisingTokenSymbol}
                      </p>
                    </div>
                  </div>

                  {/* countdown */}
                  {data.is_approved === PRESALE_STATUS.approved ? (
                    <div
                      className='mt-4 mt-md-0'
                      // className='ps-0 ps-md-2'
                    >
                      {fundRaisedData != undefined &&
                      Number(
                        ethers.utils.formatUnits(
                          String(fundRaisedData[index]?.result),
                          Number(fundRaisingTokenDecimal)
                        )
                      ) <
                        Number(
                          ethers.utils.formatUnits(
                            String(data?.softCap),
                            Number(fundRaisingTokenDecimal)
                          )
                        ) &&
                      currentUnixTime > endTime &&
                      data?.isCanceled == 0 &&
                      data?.setSaleTime == 1 ? ( //eslint-disable-line no-undef
                        <>
                          {/* Sale Failed */}
                          <PersaleCountDown
                            className='m-auto ms-md-auto'
                            gap={2}
                            isEndedCountDown={true}
                          />
                        </>
                      ) : startEndIn == 'Sale Ended' ? (
                        <>
                          {/* <div>Sale Ended</div> */}
                          <PersaleCountDown
                            className='m-auto ms-md-auto'
                            gap={2}
                            isEndedCountDown={true}
                          />
                        </>
                      ) : (
                        <>
                          {data?.isCanceled != 1 &&
                            // <p className='mb-1 fs-6'>
                            //   {startEndIn == 'TBA'
                            //     ? 'Sale Starts In'
                            //     : startEndIn}
                            // </p>
                            null}
                          {data?.isCanceled === 1 ? (
                            <>
                              {/* Cancelled */}
                              <PersaleCountDown
                                className='m-auto ms-md-auto'
                                gap={2}
                                isEndedCountDown={true}
                              />
                            </>
                          ) : data?.isFinalized === 1 &&
                            moment(new Date()).unix() >
                              data?.estimatedDexListingTime ? (
                            <>
                              {/* {moment(
                                data?.estimatedDexListingTime * 1000
                              ).format('DD-MM-YYYY')} */}
                              <PersaleCountDown
                                className='m-auto ms-md-auto'
                                gap={2}
                                isEndedCountDown={true}
                              />
                            </>
                          ) : data?.setSaleTime === 1 ? (
                            <>
                              {Number(moment(new Date()).unix()) <
                                data?.estimatedDexListingTime &&
                              data?.isFinalized == 1 ? (
                                <span>
                                  <PersaleCountDown
                                    className='m-auto ms-md-auto'
                                    gap={2}
                                    unixTime={data?.estimatedDexListingTime}
                                  />
                                </span>
                              ) : (
                                <>
                                  <PersaleCountDown
                                    className='m-auto ms-md-auto'
                                    gap={2}
                                    unixTime={
                                      Number(moment(new Date()).unix()) >=
                                      Number(startTime)
                                        ? endTime
                                        : startTime
                                    }
                                  />
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {data?.isCanceled === 0 && (
                                <p className='mb-1'>
                                  {/* {startEndIn} - // not showing */}
                                  </p>
                              )}
                              {startEndIn === 'Sale Ended' && (
                                <div>Sale Ended</div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  ) : data?.is_approved === PRESALE_STATUS.rejected ? (
                    <div className='text-center'>Rejected</div>
                  ) : (
                    <div className='text-center'>Waiting for approval</div>
                  )}

                  {data.isWhiteList == 'true' &&
                  data?.whitelistContestUrl != '' ? (
                    <>
                      <div className='d-flex align-items-center gap-1'>
                        <button
                          className='white_list_button'
                          onClick={(event) => {
                            event.preventDefault();
                            window.open(data?.whitelistContestUrl);
                          }}
                        >
                          whitelist
                        </button>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={`w-100 mt-auto`}>
              {/* <div className='card_second_section d-flex justify-content-between align-items-end'> */}
              {/* <div className='card_second_section_text'>
                <h5>
                  {data?.sale_without_token == 1 &&
                  data?.startTimes == '0'
                    ? data?.sale_title
                    : data?.launch_stealth_sale ==
                      true
                    ? data?.sale_title
                    : data?.name}
                </h5>
                <p>
                  {data?.sale_without_token == 1
                    ? `Without Token ${
                        SALE_TYPE.isPresaleOrPrivateSale(data?.saleType)
                          ? ''
                          : data?.saleType ==
                            SALE_TYPE.HYPER
                          ? '(Hyper)'
                          : '(Fair)'
                      }`
                    : data?.launch_stealth_sale == 1
                    ? `Stealth ${data?.saleType}`
                    : data?.saleType}{' '}
                  - Max:{' '}
                  {SALE_TYPE.isPresaleOrPrivateSale(data?.saleType)
                    ? ethers.utils.formatUnits(
                        String(data?.maxBuy),
                        fundRaisingTokenDecimal
                      )
                    : data?.isMaxBuy == 1
                    ? ethers.utils.formatUnits(
                        String(data?.maxBuy),
                        fundRaisingTokenDecimal
                      )
                    : '∞'}{' '}
                  {fundRaisingTokenSymbol}
                </p>
              </div> */}
              {/* <div className='card_second_section_img position'>
                <img
                  src={data?.logoUrl}
                  // className="img-fluid"
                  alt='logo'
                  onError={(event) => {
                    event.target.src =
                      BASE_URL +
                      `images/stablz_stablz_icon.png`;
                    event.onerror = null;
                  }}
                />
                <span>
                  <img
                    src={
                      networkImagesByChain[
                        data?.chain
                      ]
                    }
                    // className="img-fluid"
                    alt='logo'
                    width={28}
                  />
                </span>
              </div> */}
              {/* </div> */}
              <div className='progress_bar'>
                <div className='d-flex justify-content-between align-items-baseline'>
                  <p className='mb-0 fs-6'>Progress</p>
                  <p className='mb-0 fs-6'>
                    {fundRaisedData != undefined &&
                    fundRaisedData[index]?.result
                      ? Number(
                          (ethers.utils.formatUnits(
                            String(fundRaisedData[index]?.result || 0),
                            Number(fundRaisingTokenDecimal)
                          ) *
                            100) /
                            Number(
                              ethers.utils.formatUnits(
                                String(data?.hardCap || 0),
                                Number(fundRaisingTokenDecimal)
                              )
                            )
                        )
                      : 0}
                    %
                  </p>
                </div>
                {/* <div
          className="progress"
          style={{ height: 10 }}
        > 
          <div
            className="progress-bar"
            style={{
              width: `${fundRaisedData != undefined && Number(formatEther(fundRaisedData[index]?.result) * 100 / Number(formatEther(data?.hardCap)))}%`,
              height: 10,
            }}
          />
        </div> */}
                <div
                  style={{
                    margin: '0.6rem 0',
                  }}
                >
                  {SALE_TYPE.isPresaleOrPrivateSale(data?.saleType) ? (
                    <Progressbar
                      fundRaisedData={
                        fundRaisedData != undefined &&
                        fundRaisedData[index]?.result
                          ? fundRaisedData[index]?.result
                          : 0
                      }
                      hardCap={data?.hardCap}
                      fund_releasing_token={data?.fund_releasing_token}
                      fundRaisingTokenDecimal={fundRaisingTokenDecimal}
                      fundRaisingTokenSymbol={fundRaisingTokenSymbol}
                      perk={data?.perk}
                    />
                  ) : (
                    <FairPorgressbar
                      fundRaisedData={
                        fundRaisedData != undefined &&
                        fundRaisedData[index]?.result
                          ? fundRaisedData[index]?.result
                          : 0
                      }
                      hardCap={data?.hardCap}
                      softCap={data?.softCap}
                      token_decimals={data?.token_decimals}
                      custom_fund_token_decimal={
                        data?.custom_fund_token_decimal
                      }
                      isHardCap={data?.isHardCap}
                      fundRaisingTokenDecimal={fundRaisingTokenDecimal}
                      fundRaisingTokenSymbol={fundRaisingTokenSymbol}
                      fund_releasing_token={data?.fund_releasing_token}
                      perk={data?.perk}
                    />
                  )}
                </div>

                {SALE_TYPE.isPresaleOrPrivateSale(data?.saleType) ? (
                  <PresaleProgInfo
                    fundRaisedData={
                      fundRaisedData != undefined
                        ? fundRaisedData[index]?.result
                        : 0
                    }
                    fund_releasing_token={data?.fund_releasing_token}
                    fundRaisingTokenDecimal={fundRaisingTokenDecimal}
                    fundRaisingTokenSymbol={fundRaisingTokenSymbol}
                    hardCap={data?.hardCap}
                  />
                ) : (
                  <FairProgInfo
                    fundRaisedData={
                      fundRaisedData != undefined
                        ? fundRaisedData[index]?.result
                        : 0
                    }
                    fund_releasing_token={data?.fund_releasing_token}
                    fundRaisingTokenDecimal={fundRaisingTokenDecimal}
                    fundRaisingTokenSymbol={fundRaisingTokenSymbol}
                    hardCap={data?.hardCap}
                    isHardCap={data?.isHardCap}
                    softCap={data?.softCap}
                  />
                )}

                {/* <div className="d-flex justify-content-between align-items-center">
          <p>

            {
              fundRaisedData != undefined && ethers.utils.formatUnits(String(fundRaisedData[index]?.result), Number(data?.fund_releasing_token == 'Custom' || fundRaisingTokenDecimal)) || 0
            }
            {" "}
            {
              fundRaisingTokenSymbol
            }

          </p>
          <p>
            {
              ethers.utils.formatUnits(String(data?.hardCap), Number(fundRaisingTokenDecimal))
            }
            {" "}
            {
              fundRaisingTokenSymbol
            }
          </p>
        </div> */}
              </div>
              <div className='row row-cols-2 row-cols-sm-2 gx-2 gy-2 coin-data-row'>
                <div className='col'>
                  <div className='Softcap fs-6'>
                    <p
                      style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                      className='justify-content-between d-flex  align-items-center flex-wrap fs-6'
                    >
                      Softcap
                      <span className='fs-6'>
                        {' '}
                        {Intl.NumberFormat('en', {
                          notation: 'compact',
                        }).format(
                          ethers.utils.formatUnits(
                            String(data?.softCap),
                            Number(fundRaisingTokenDecimal)
                          )
                        )}{' '}
                        {fundRaisingTokenSymbol}
                      </span>
                    </p>
                  </div>
                </div>
                <div className='col'>
                  <div className='Softcap'>
                    <p
                      style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                      className='justify-content-between d-flex align-items-center flex-wrap fs-6'
                    >
                      Hardcap
                      <span className='fs-6'>
                        {/* {web3.utils.fromWei(
                    String(data?.data?.hardCap),
                    "ether"
                  )}{" "} */}
                        {data?.isHardCap == 0 ? (
                          <img
                            src='images/icon/infinity.png'
                            alt='infinity'
                            className='img-fluid me-2'
                          />
                        ) : (
                          `${Intl.NumberFormat('en', {
                            notation: 'compact',
                          }).format(
                            ethers.utils.formatUnits(
                              String(data?.hardCap),
                              Number(fundRaisingTokenDecimal)
                            )
                          )} `
                        )}
                        {/* {" "} */}
                        {fundRaisingTokenSymbol}
                      </span>
                    </p>
                  </div>
                </div>
                {/* {!!data?.listOnDex && ( */}
                <>
                  <div className='col'>
                    <div className='Softcap'>
                      <p
                        style={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                        className='justify-content-between d-flex align-items-center flex-wrap fs-6'
                      >
                        Liquidity
                        <span className='fs-6'>
                          {data?.dexLiquidityPercentage}%
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='Softcap'>
                      <p
                        style={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                        className='justify-content-between d-flex align-items-center flex-wrap fs-6'
                      >
                        Locked
                        {/* <Tooltip
                            title={`${data?.liquidityLockupDays} Days`}
                            placement='top-start'
                            enterTouchDelay={false}
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          > */}
                        <span className='fs-6'>
                          {data?.liquidityLockupDays?.length > 13
                            ? `${data?.liquidityLockupDays.slice(
                                0,
                                3
                              )}...${data?.liquidityLockupDays.slice(-2)}`
                            : data?.liquidityLockupDays}{' '}
                          Days
                        </span>
                        {/* </Tooltip> */}
                      </p>
                    </div>
                  </div>
                </>
                {/* // )} */}
              </div>
              {/* <hr /> */}
              {/* <div className='row'>
              <div className='col'> */}
            </div>

            <div className='sale_status d-flex justify-content-end align-items-center gap-2 gap-sm-3 w-100'>
              <div className='card_notification d-flex align-items-center gap-1'>
                {/* <button className="map-point-sm"
                      onClick={(event) => {
                        event.preventDefault();
                        toggleAdditionalData(index)
                      }}

                    >
                      <i class="fa fa-bell" aria-hidden="true"></i>
                    </button> */}
                <button
                  // disabled={activeTab == 'my_favorites'}
                  onClick={(event) => {
                    event.preventDefault();
                    FavouriteFunction(
                      data?.presale_id,
                      data?.isFavourite == '1' ? false : true
                    );
                  }}
                  style={{
                    border: isFav
                      ? '1px solid var(--clr-danger)'
                      : '1px solid #303A46',
                  }}
                >
                  {/* {console.log(data?.isFavourite, data?.presale_id, 'isFabo')} */}
                  {isFav ? (
                    <i
                      className='fa fa-heart'
                      aria-hidden='true'
                      style={{
                        color: 'var(--clr-danger)',
                      }}
                      // id={`heart-button-${data?.presale_id}`}
                    />
                  ) : (
                    <i
                      className='fa fa-heart-o'
                      aria-hidden='true'
                      style={{
                        color: '#808195',
                      }}
                      // id={`heart-button-${data?.presale_id}`}
                    />
                  )}
                </button>
              </div>
              <div className={classNameD}>{statusText}</div>
            </div>
            {/* </div>
            </div> */}

            {/* {showAdditionalData[index] && */}

            {/* } */}
          </div>
        </div>
      </Link>
      <div
        className={`remind_section ${
          showAdditionalData[index] ? 'show' : 'hide'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        {/* <div className={`remind_section ${showAdditionalData[index] ? 'show' : 'hide'}`}> */}
        <div className='map-container'>
          <div className='inner-basic division-details'>
            <div className='darwin'>
              <button
                className='map-point-sm'
                onClick={(event) => {
                  event.preventDefault();
                  toggleAdditionalData(index);
                }}
                data-show='.darwin'
              >
                <i className='fa fa-arrow-left me-2' aria-hidden='true' />
                Back
              </button>
              <div className='d-flex align-items-center justify-content-center remind_section_mian_height'>
                <div className='remind_section_mian text-center'>
                  <div className='remind_section'>
                    <h4 className='text-white'>REMIND ME</h4>

                    <div className='mt-3'>
                      <input
                        type='checkbox'
                        name={`options${data.presale_id}`}
                        onChange={(e) => {
                          e.preventDefault();
                          onChangeValue(30, data?.preSale, data?.presale_id);
                        }}
                        className='btn-check'
                        id={`30min5${index}`}
                        // checked={alarms.filter((dta) => {return dta?.address == data?.preSale && dta?.time == '30'&& (getCheckedValueById(`presale${index}`)=='on'? dta.status=='presale': dta?.status=='listing') }).length > 0}
                        checked={isAlarmChecked(data?.preSale, index, '30')}
                      />
                      <label
                        className='btn btn-secondary'
                        htmlFor={`30min5${index}`}
                      >
                        30min
                      </label>
                      <input
                        type='checkbox'
                        onChange={(e) => {
                          e.preventDefault();
                          onChangeValue(15, data?.preSale, data?.presale_id);
                        }}
                        className='btn-check'
                        name={`options${data.presale_id}`}
                        id={`15min${index}`}
                        checked={isAlarmChecked(data?.preSale, index, '15')}
                      />
                      <label
                        className='btn btn-secondary'
                        htmlFor={`15min${index}`}
                      >
                        15min
                      </label>
                      <input
                        type='checkbox'
                        onChange={(e) => {
                          e.preventDefault();
                          onChangeValue(5, data?.preSale, data?.presale_id);
                        }}
                        className='btn-check'
                        name={`options${data.presale_id}`}
                        id={`5min${index}`}
                        checked={isAlarmChecked(data?.preSale, index, '5')}
                      />
                      <label
                        className='btn btn-secondary'
                        htmlFor={`5min${index}`}
                      >
                        5min
                      </label>
                      {/* setRemindStatus? */}
                    </div>
                  </div>
                  <div className='before_section mt-5'>
                    <h4 className='text-white'>BEFORE</h4>
                    <div className='mt-3'>
                      <input
                        type='radio'
                        className='btn-check'
                        // name="options55"
                        name={`options5${index}`}
                        id={`presale${index}`}
                        autoComplete='off'
                        onChange={() => {
                          setRemindStatus('presale');
                        }}
                        defaultChecked
                        // checked={remindStatus=='presale'}  //remove (unique selection)
                      />
                      <label
                        className='btn btn-secondary'
                        htmlFor={`presale${index}`}
                      >
                        Presale
                      </label>
                      <input
                        type='radio'
                        className='btn-check'
                        name={`options5${index}`}
                        id={`dexlisting${index}`}
                        onChange={() => {
                          setRemindStatus('listing');
                        }}
                        //  id="dexlisting5"
                        autoComplete='off'
                        // checked={remindStatus=='listing'}  //remove (unique selection)
                      />
                      <label
                        className='btn btn-secondary'
                        htmlFor={`dexlisting${index}`}
                      >
                        Dex Listing
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='ptown5 hide'></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PresaleCard);
