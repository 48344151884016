import { ethers } from 'ethers';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useToken } from 'wagmi';
import { getCurrentBnbPriceAction } from '../../redux/apiActions/api.action';
import routerAddresses from '../../_constant/routerAddresses.json';
import { getAccount, getNetwork } from '@wagmi/core';
import { APP_NAME_TITLE } from '../../_constant';
import config from '../../config';

function PresaleCalc() {
  document.title = `${APP_NAME_TITLE} - Presale Calculator`;

  const { chain, chains } = getNetwork();

  const ActiveChainSymbol = config.SUPPORTED_NETWORKS.find(
    (network) => network.id === chain?.id
  )?.symbol;
  const _DB_FUND_TOKEN_IN =
    config.SUPPORTED_NETWORKS[ActiveChainSymbol] || 'Custom';

  const pairTokens = routerAddresses[chain?.id]?.pairTokens;
  const [bnbPrice, _bnbPrice] = useState(0);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fundRaiseToken: _DB_FUND_TOKEN_IN,
      custom_fund_raising_token: '',
      hard_cap: 0,
      total_raised_exp: 0,
      total_supply: 0,
      pool_vesting_amt: 0,
      presale_rate: 0,
      dex_listing_rate: 0,
      liq_per_on_dex: 0,
      bonus: 0,
      token_for_presale: 0,
      token_for_liquidity: 0,
      token_need_for_presale: 0,
      supply_you_will_use: 0,
      estimate_market_cap: 0,
    },
    onSubmit: async (data) => {
      let hardCap = ethers.utils.formatUnits(
        String(data.hard_cap || 0),
        Number(
          data?.fund_releasing_token == 'Custom' ? preSaleToken?.decimals : 18
        )
      );
      let dex_liquidity = data?.liq_per_on_dex;
      let pre_sale_rate = data?.presale_rate;
      let dex_listing_rate = data?.dex_listing_rate;
      let maximum_buy = 'no';
      let bonus_sale = data?.bonus;
      let bonus_received = '';
      let bonus_spots_available = '';
      let token_team_vesting_tokens = data?.pool_vesting_amt;

      const token_for_presale =
        Number(data.total_raised_exp) * Number(data.presale_rate) +
        (Number(data.total_raised_exp) *
          Number(data.presale_rate) *
          Number(data.bonus)) /
          100;
      const token_for_liquidity =
        Number(data.total_raised_exp) * Number(data.dex_listing_rate);

      const token_need_for_presale =
        Number(data.hard_cap) * Number(data.presale_rate) +
        (Number(data?.hard_cap) *
          Number(data.liq_per_on_dex) *
          Number(data.dex_listing_rate)) /
          100 +
        (Number(data.hard_cap) *
          Number(data.presale_rate) *
          Number(data.bonus)) /
          100 +
        Number(data.pool_vesting_amt);

      const supply_you_will_use =
        Number(data.hard_cap) &&
        Number(data.presale_rate) &&
        Number(data.total_supply)
          ? (Number(token_need_for_presale) * 100) / Number(data.total_supply)
          : 0;

      const estimate_market_cap = hardCap * bnbPrice;

      formik.setFieldValue('token_for_presale', token_for_presale);
      formik.setFieldValue('token_for_liquidity', token_for_liquidity);
      formik.setFieldValue('token_need_for_presale', token_need_for_presale);
      formik.setFieldValue('supply_you_will_use', supply_you_will_use);
      formik.setFieldValue('estimate_market_cap', estimate_market_cap);
    },
  });

  const {
    data: preSaleToken,
    isSuccess: isTokenFetched,
    isError: isErrorTokenFetch,
  } = useToken({
    address: formik.values.custom_fund_raising_token,
    // onError: onTokenFetchError,
    // onSuccess: onSuccessPresaleToken,
  });

  const curretBNBPrice = async () => {
    if (formik?.values?.fundRaiseToken != 'Custom') {
      const data = await getCurrentBnbPriceAction({
        symbol: formik?.values?.fundRaiseToken,
      });
      _bnbPrice(data?.data?.price || 0);
    } else if (
      formik?.values?.fundRaiseToken == 'Custom' &&
      preSaleToken?.symbol
    ) {
      const data = await getCurrentBnbPriceAction({
        symbol: preSaleToken?.symbol,
      });
      _bnbPrice(data?.data?.price || 0);
    }
    // else if(){

    // }
  };
  useEffect(() => {
    curretBNBPrice();
  }, [
    formik?.values?.custom_fund_raising_token,
    formik?.values?.fundRaiseToken,
  ]);

  return (
    <>
      <div className='approve_token_card mb-3'>
        <div className='row gy-3'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='form-label' htmlFor='fundRaiseToken'>
                Fund Raising Token
              </label>
              <select
                className='form-select whitelist-form-select'
                id='fundRaiseToken'
                name='fundRaiseToken'
                onChange={formik.handleChange}
                onBlur={formik.handleSubmit}
              >
                {pairTokens &&
                  Object.keys(pairTokens)?.map((tokenKey) => (
                    <option>{tokenKey}</option>
                  ))}
                <option value='Custom'>Custom</option>
              </select>
            </div>
          </div>
          {formik.values.fundRaiseToken === 'Custom' && (
            <div className='col-12 col-md-6'>
              {/* <div className="approve_token_card"> */}
              <div className='form-group'>
                <label
                  className='form-label'
                  htmlFor='Custom Fund Raising Token'
                >
                  Custom Fund Raising Token
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='custom_fund_token'
                  name='custom_fund_raising_token'
                  onChange={formik.handleChange}
                  value={formik.values.custom_fund_raising_token}
                  onBlur={formik.handleSubmit}
                />
                {/* </div> */}
              </div>
              {!isTokenFetched &&
                formik.values.custom_fund_raising_token != '' && (
                  <p className='error-msg'>Invalid Token Address</p>
                )}
              {preSaleToken && (
                <div className='mt-1'>
                  <div className='form-group'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className=''>
                        <p className='text-white fw-semibold mb-0'>
                          Name: {preSaleToken.symbol}
                        </p>
                      </div>
                      <div className=''>
                        <p className='text-white fw-semibold mb-0'>
                          Symbol: {preSaleToken.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className='col-12 col-md-6'>
            <div className='form-group'>
              <label className='form-label' htmlFor='hardcap'>
                Hardcap
              </label>
              <input
                type='text'
                className='form-control'
                id='hard_cap'
                name='hard_cap'
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/[^0-9.]/g, '');
                  formik.handleChange({
                    target: {
                      name: 'hard_cap',
                      value: numericValue,
                    },
                  });
                  formik.handleSubmit();
                }}
                value={formik.values.hard_cap}
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='form-label' htmlFor='total_raised_exp'>
                Total Raised Expectation
              </label>
              <input
                type='text'
                className='form-control'
                id='total_raised_exp'
                name='total_raised_exp'
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/[^0-9.]/g, '');
                  formik.handleChange({
                    target: {
                      name: 'total_raised_exp',
                      value: numericValue,
                    },
                  });
                  formik.handleSubmit();
                }}
                value={formik.values.total_raised_exp}
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='form-label' htmlFor='pool_vesting_amt'>
                Total Supply
              </label>
              <input
                type='text'
                className='form-control'
                id='total_supply'
                name='total_supply'
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/[^0-9.]/g, '');
                  formik.handleChange({
                    target: {
                      name: 'total_supply',
                      value: numericValue,
                    },
                  });
                  formik.handleSubmit();
                }}
                value={formik.values.total_supply}
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='form-label' htmlFor='pool_vesting_amt'>
                Pool Vesting Amount
              </label>
              <input
                type='text'
                className='form-control'
                id='pool_vesting_amt'
                name='pool_vesting_amt'
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/[^0-9.]/g, '');
                  formik.handleChange({
                    target: {
                      name: 'pool_vesting_amt',
                      value: numericValue,
                    },
                  });
                  formik.handleSubmit();
                }}
                value={formik.values.pool_vesting_amt}
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='form-label' htmlFor='presale_rate'>
                Presale Rate
              </label>
              <input
                type='text'
                className='form-control'
                id='presale_rate'
                name='presale_rate'
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/[^0-9.]/g, '');
                  formik.handleChange({
                    target: {
                      name: 'presale_rate',
                      value: numericValue,
                    },
                  });
                  formik.handleSubmit();
                }}
                value={formik.values.presale_rate}
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='form-label' htmlFor='dex_listing_rate'>
                Dex Listing Ratee
              </label>
              <input
                type='text'
                className='form-control'
                id='dex_listing_rate'
                name='dex_listing_rate'
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/[^0-9.]/g, '');
                  formik.handleChange({
                    target: {
                      name: 'dex_listing_rate',
                      value: numericValue,
                    },
                  });
                  formik.handleSubmit();
                }}
                value={formik.values.dex_listing_rate}
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='form-label' htmlFor='liq_per_on_dex'>
                Liquidity Percentage on Dex
              </label>
              <input
                type='text'
                className='form-control'
                id='liq_per_on_dex'
                name='liq_per_on_dex'
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/[^0-9.]/g, '');
                  formik.handleChange({
                    target: {
                      name: 'liq_per_on_dex',
                      value: numericValue,
                    },
                  });
                  formik.handleSubmit();
                }}
                value={formik.values.liq_per_on_dex}
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='form-label' htmlFor='bonus'>
                Bonus % (if Bonus Sale)
              </label>
              <input
                type='text'
                className='form-control'
                id='bonus'
                name='bonus'
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/[^0-9.]/g, '');
                  formik.handleChange({
                    target: {
                      name: 'bonus',
                      value: numericValue,
                    },
                  });
                  formik.handleSubmit();
                }}
                value={formik.values.bonus}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <hr /> */}

      <>
        {formik.values.total_supply > 0 &&
          (Number(formik.values.token_need_for_presale) <=
          Number(formik.values.total_supply) ? (
            <div className={`alert alert-success mb-3`} role='alert'>
              It's work
            </div>
          ) : (
            <div className={`alert alert-danger mb-3`} role='alert'>
              It doesn't work!
            </div>
          ))}
      </>

      {/* <div
        className={`alert alert-${
          formik.values.token_need_for_presale <= formik.values.total_supply
            ? 'success'
            : 'danger'
        } mt-3`}
        role='alert'
      >
        {formik.values.token_need_for_presale <= formik.values.total_supply
          ? `It's work`
          : `It doesn't work!`}
      </div> */}

      <div className='approve_token_card'>
        <div className='row gy-3'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='form-label' htmlFor='token_need_for_presale'>
                Tokens needed for your presale
              </label>
              <input
                type='text'
                className='form-control'
                id='token_need_for_presale'
                name='token_need_for_presale'
                value={formik.values.token_need_for_presale}
                disabled
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='form-label' htmlFor='supply_you_will_use'>
                % of total supply you will use
              </label>
              <input
                type='text'
                className='form-control'
                id='supply_you_will_use'
                value={formik.values.supply_you_will_use}
                name='supply_you_will_use'
                disabled
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='form-label' htmlFor='token_for_presale'>
                Tokens for Presale
              </label>
              <input
                type='text'
                className='form-control'
                id='token_for_presale'
                name='token_for_presale'
                value={formik.values.token_for_presale}
                disabled
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='form-label' htmlFor='hardcap'>
                Raise Token for Liquidity
              </label>
              <input
                type='text'
                className='form-control'
                id='hardcap'
                disabled
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='form-label' htmlFor='token_for_liquidity'>
                Tokens for Liquidity:
              </label>
              <input
                type='text'
                className='form-control'
                id='token_for_liquidity'
                name='token_for_liquidity'
                value={formik.values.token_for_liquidity}
                disabled
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='form-label' htmlFor='hardcap'>
                Total Raise Token you'll get to your wallet:
              </label>
              <input
                type='text'
                className='form-control'
                id='hardcap'
                disabled
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='form-label' htmlFor='estimate_market_cap'>
                Estimated Market Cap
              </label>
              <input
                type='text'
                className='form-control'
                id='estimate_market_cap'
                name='estimate_market_cap'
                value={formik.values.estimate_market_cap}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PresaleCalc;
