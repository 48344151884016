import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../_constant';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const SubHeader = ({
  title,
  isBack = false,
  children,
  className = '',
  marginBottom = 3,
  style = {},
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={`d-flex justify-content-start align-items-center tnbc_back_section mb-${marginBottom} ${className}`}
      style={style}
    >
      {isBack && (
        <div
          onClick={() => {
            navigate(-1) ? navigate(-1) : navigate(BASE_URL);
          }}
          // href={BASE_URL}
          className='d-flex align-items-center icon'
          style={{ cursor: 'pointer' }}
        >
          <ArrowBackIosIcon fontSize='small' />
          {/* <i className='fa fa-arrow-left' aria-hidden='true' /> */}
          <p className='mb-0 ms-2'>Back</p>
        </div>
      )}
      {title && (
        <h2 className='ff-roboto text-white fw-semibold fs-1'>{title}</h2>
      )}
      {children}
    </div>
  );
};

export default SubHeader;
