import { getNetwork, switchNetwork } from '@wagmi/core';
import React, { useEffect, useState } from 'react';
import { networkImages } from '../../_constant';
import { useAccount, useNetwork } from 'wagmi';

const SwitchNetworkModal = () => {
  const { isDisconnected, isConnected } = useAccount();
  const { chain, chains } = getNetwork();
  const [isConfirm, _isConfirm] = useState({ id: '', isConfirming: false });
  const [currentNetwork, _currentNetwork] = useState();
  const [acceptChains, _acceptChains] = useState();
  const { chain: current_chain } = useNetwork();

  const handleSwitchNetwork = async (data) => {
    try {
      if (chain?.id != data && isConfirm.isConfirming == false) {
        _isConfirm({ id: data, isConfirming: true });
        const network = await switchNetwork({
          chainId: data,
        });
        const closeButton = document.getElementById('btn-close-switch-network');
        closeButton.click();
        _currentNetwork(network?.id);
        _isConfirm({ id: '', isConfirming: false });
      }
    } catch (error) {
      _isConfirm({ id: '', isConfirming: false });
    }
  };

  useEffect(() => {
    _acceptChains(chains);
    let ethClient = chain;
    _acceptChains(ethClient);
  }, [currentNetwork, isDisconnected, isConnected, current_chain]);

  return (
    <div className='modal' id='switchNetworksModal'>
      <div
        className='modal-dialog'
        style={{
          maxWidth: '628px',
        }}
      >
        <div className='modal-content'>
          {/* Modal Header */}
          <div className='modal-header'>
            <h5 className='modal-title text-white'>Switch Networks</h5>
            <button
              type='button'
              className='btn-close'
              id='btn-close-switch-network'
              data-bs-dismiss='modal'
            />
          </div>
          {/* Modal body */}
          <div className='modal-body'>
            <ul className='gap-2 d-grid'>
              {chains &&
                chains?.map((data) => {
                  return (
                    <li key={data?.id} style={{ cursor: 'pointer' }}>
                      <a
                        href
                        className={`d-flex align-items-center justify-content-between ${
                          data?.id == chain?.id ? 'active' : ''
                        }`}
                        onClick={() => {
                          handleSwitchNetwork(data?.id);
                        }}
                      >
                        <div className='d-flex align-items-center'>
                          <img
                            src={networkImages[data.network]}
                            className='img-fluid me-2'
                            alt='icon'
                            width={28}
                          />
                          <h6
                            className='text-white fs-5 fw-medium'
                            style={{
                              lineHeight: '30px',
                            }}
                          >
                            {data?.name}
                          </h6>
                        </div>
                        <div className='d-flex align-items-center gap-2'>
                          {data?.id == chain?.id ? (
                            <>
                              <span className={`connected-status`} />
                              <p
                                className='mb-0 text-white fs-5 fw-medium'
                                style={{
                                  lineHeight: '30px',
                                }}
                              >
                                {'Connected'}
                              </p>
                            </>
                          ) : (
                            ''
                          )}
                          {isConfirm['isConfirming'] ? (
                            <>
                              <span
                                className={
                                  isConfirm['id'] == data.id &&
                                  'confirm-wallet-status'
                                }
                              />
                              <p className='mb-0 text-white'>
                                {isConfirm['id'] == data.id &&
                                  'Confirm in your wallet'}
                              </p>
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </a>
                    </li>
                  );
                })}

              {/* <li>
              <a href className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <img src="images/icon/binance_logo.png" className="img-fluid me-2" alt="icon" width={28} />
                  <h6 className="text-white">BNB Smart Chain</h6>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <p className="mb-0 text-white">Confirm in Wallet</p>
                  <span className="confirm-wallet-status" />
                </div>
              </a>
            </li> */}
              {/* <li>
              <a href className="d-flex align-items-center justify-content-between" onClick={() => handleChangeNetwork(2000)}>
                <div className="d-flex align-items-center">
                  <img src="images/icon/binance_logo.png" className="img-fluid me-2" alt="icon" width={28} />
                  <h6 className="text-white">Arbitrum One</h6>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <span className="connected-status" />
                </div>
              </a>
            </li> */}
              {/* <li>
              <a href className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <img src="images/icon/binance_logo.png" className="img-fluid me-2" alt="icon" width={28} />
                  <h6 className="text-white">Core DAO</h6>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <span className="connected-status" />
                </div>
              </a>
            </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwitchNetworkModal;
