import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { ethers } from 'ethers';
import {
  useNetwork,
  useAccount,
  useContractWrite,
  useContractRead,
  useWaitForTransaction,
  useBalance,
} from 'wagmi';
import moment from 'moment';
import Countdown from '../../Components/CountDown/CountDown';
import { getAccount } from '@wagmi/core';
import { BuyPresale } from './BuyPresale';
import { Claim } from './Claim';
import { SyncLoader } from 'react-spinners';
import { PerkCategories, PRESALE_STATUS } from '../../_constant';
import config from '../../config';
import PersaleCountDown from '../../Components/CountDown/PersaleCountDown';
import ConnectOrSwitchNetwork from './ConnectOrSwitchNetwork';
import CrossGrid, { CrossGridItem } from '../../Components/CrossGrid';

function CnbtPresaleDetails({
  presaleDetails,
  startEndIn,
  progreeBar,
  isDataFundRaised,
  investDetails,
  getInvestDetailsFunc,
  referchFundraise,
  chainId,
  bonusSpotLeft,
  loading,
  withdraw,
  isLoadingWithdraw,
  emergencyWithdraw,
  isLoadingEmgcyWithDraw,
  refetchInFBC,
  currentRound,
}) {
  const { chain, chains } = useNetwork();
  const account = getAccount();

  const { isDisconnected, isConnected, address } = useAccount();

  const not_custom_then_symbol = config.SUPPORTED_NETWORKS.find(
    (network) => network.id == presaleDetails?.chain
  )?.symbol;

  const currentUnixTime = moment().unix();
  const fundRaisingTokenDecimal =
    presaleDetails?.fund_releasing_token == 'Custom' ||
    presaleDetails?.fund_releasing_token == 'BUSD' ||
    presaleDetails?.fund_releasing_token == 'USDT'
      ? presaleDetails?.custom_fund_token_decimal
      : 18;
  const fundRaisingTokenSymbol =
    presaleDetails?.fund_releasing_token == 'Custom' ||
    presaleDetails?.fund_releasing_token == 'BUSD' ||
    presaleDetails?.fund_releasing_token == 'USDT'
      ? presaleDetails?.custom_fund_raising_token_symbol
      : not_custom_then_symbol || '';

  const css =
    presaleDetails.perk == PerkCategories.GOLD
      ? 'gold'
      : presaleDetails.perk == PerkCategories.DIAMOND
      ? 'diamond'
      : presaleDetails.perk == PerkCategories.PLATINUM
      ? 'platinum'
      : 'standard';

  return (
    <div className=''>
      <div className='custom_card'>
        <div className='p-4'>
          {presaleDetails?.is_approved === PRESALE_STATUS.approved ? (
            <>
              <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start flex-wrap'>
                {/* left - counter */}
                <div className='mx-auto mx-sm-0'>
                  {presaleDetails?.setSaleTime == 1 &&
                    Number(currentUnixTime) < Number(currentRound?.endTime) &&
                    presaleDetails?.isCanceled == 0 && (
                      <p
                        className='mb-4 text-white text-center fs-55 fw-normal'
                        style={{ lineHeight: '27px' }}
                      >
                        {' '}
                        {startEndIn}:
                      </p>
                    )}
                  {presaleDetails?.setSaleTime == 1 ? (
                    <span>
                      {presaleDetails?.isCanceled == 1 ? (
                        'Cancelled'
                      ) : // presaleDetails?.isFinalized == 1 &&

                      // currentUnixTime >=  presaleDetails?.estimatedDexListingTime ?
                      // <p>LIQ Unlocked</p>
                      // :
                      Number(moment(new Date()).unix()) >
                          presaleDetails?.estimatedDexListingTime &&
                        presaleDetails?.isFinalized === 1 ? (
                        <p>
                          {presaleDetails?.listOnDex
                            ? 'Listed on Dex'
                            : 'Finalized'}
                        </p>
                      ) : startEndIn == 'Sale Ended' ? (
                        <div>Sale Ended</div>
                      ) : (
                        <PersaleCountDown
                          style={{
                            minWidth: '300px',
                          }}
                          gap={3}
                          unixTime={
                            Number(moment(new Date()).unix()) >=
                            Number(currentRound?.startTime)
                              ? currentRound?.endTime
                              : Number(moment(new Date()).unix()) <
                                currentRound?.startTime
                              ? currentRound?.startTime
                              : ''
                          }
                        />
                      )}
                    </span>
                  ) : presaleDetails?.isCanceled == 1 ? (
                    'Presale Cancelled'
                  ) : (
                    'TBA'
                  )}
                </div>
                {/* right - switch network */}
                <div className='mx-auto mx-sm-0'>
                  <ConnectOrSwitchNetwork
                    requiredChain={presaleDetails?.chain}
                  />
                  {moment(new Date()).unix() >=
                    Number(currentRound?.startTime) &&
                  moment(new Date()).unix() <= Number(currentRound?.endTime) ? (
                    (presaleDetails?.isWhiteList === 'true' &&
                      presaleDetails?.whitelisted
                        ?.split(',')
                        .includes(account.address)) ||
                    (presaleDetails?.isWhiteList === 'false' &&
                      presaleDetails?.chain == chain?.id &&
                      presaleDetails?.isCanceled == 0) ? (
                      <BuyPresale
                        presaleDetails={presaleDetails}
                        getInvestDetailsFunc={getInvestDetailsFunc}
                        referchFundraise={referchFundraise}
                        refetchInFBC={refetchInFBC}
                      />
                    ) : null
                  ) : null}
                </div>
              </div>

              <div className='progress_bar mt-5'>
                <div className='d-flex justify-content-between align-items-baseline'>
                  <p className='mb-0'>Progress</p>
                  <p className='mb-0'>{progreeBar}%</p>
                </div>
                <div className={`progress progress-${css} my-2`} style={{ height: 16 }}>
                  <div
                    className={`progress-bar progress-bar-${css}`}
                    style={{ width: `${progreeBar}%`, height: 16 }}
                  />
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                  <p>
                    {isDataFundRaised
                      ? ethers.utils.formatUnits(
                          String(isDataFundRaised),
                          fundRaisingTokenDecimal
                        )
                      : '0.0'}{' '}
                    {fundRaisingTokenSymbol}
                  </p>
                  <p>
                    {' '}
                    {presaleDetails?.softCap &&
                      ethers.utils.formatUnits(
                        String(presaleDetails?.hardCap),
                        fundRaisingTokenDecimal
                      )}{' '}
                    {fundRaisingTokenSymbol}
                  </p>
                </div>
              </div>
              {/* {moment(new Date()).unix() >= Number(currentRound?.startTime) &&
              moment(new Date()).unix() <= Number(currentRound?.endTime) ? (
                (presaleDetails?.isWhiteList === 'true' &&
                  presaleDetails?.whitelisted
                    ?.split(',')
                    .includes(account.address)) ||
                (presaleDetails?.isWhiteList === 'false' &&
                  presaleDetails?.chain == chain?.id &&
                  presaleDetails?.isCanceled == 0) ? (
                  <BuyPresale
                    presaleDetails={presaleDetails}
                    getInvestDetailsFunc={getInvestDetailsFunc}
                    referchFundraise={referchFundraise}
                    refetchInFBC={refetchInFBC}
                  />
                ) : null
              ) : null} */}
              {presaleDetails?.isWhiteList == 'true' &&
              currentUnixTime >
                moment
                  .unix(presaleDetails?.startTime)
                  .add(Number(presaleDetails?.whiteListTimer), 'minutes')
                  .unix() &&
              !presaleDetails?.whitelisted
                ?.split(',')
                .includes(account.address) &&
              moment(new Date()).unix() <= Number(presaleDetails?.endTime) &&
              presaleDetails?.chain == chain?.id &&
              presaleDetails?.isCanceled == 0 ? (
                <BuyPresale
                  presaleDetails={presaleDetails}
                  getInvestDetailsFunc={getInvestDetailsFunc}
                  referchFundraise={referchFundraise}
                  refetchInFBC={refetchInFBC}
                />
              ) : (
                ''
              )}
            </>
          ) : presaleDetails?.is_approved === PRESALE_STATUS.rejected ? (
            <div>
              <div className='text-center text-danger fs-5 fw-bold mb-2'>
                Sale Rejected
              </div>
              {!presaleDetails?.isCanceled && (
                <p className='text-white fw-bold text-center'>
                  Your sale has been rejected. Please cancel the presale to
                  retrieve your tokens.
                </p>
              )}
            </div>
          ) : (
            <div className='text-center'>Waiting for approval</div>
          )}

          <CrossGrid col={2}>
            <CrossGridItem>
              <CrossGrid.Label>Minimum Buy</CrossGrid.Label>
              <CrossGrid.Value>
                {ethers.utils
                  .formatUnits(
                    String(presaleDetails?.minBuy || 0),
                    presaleDetails?.fund_releasing_token === 'BNB' ||
                      presaleDetails?.fund_releasing_token == 'MATIC'
                      ? 18
                      : presaleDetails?.custom_fund_token_decimal || 0
                  )
                  .toString()}{' '}
                {fundRaisingTokenSymbol}
              </CrossGrid.Value>
            </CrossGridItem>
            <CrossGridItem>
              <CrossGrid.Label>Maximum Buy</CrossGrid.Label>
              <CrossGrid.Value>
                {ethers.utils
                  .formatUnits(
                    String(presaleDetails?.maxBuy || 0),
                    fundRaisingTokenDecimal || 0
                  )
                  .toString()}{' '}
                {fundRaisingTokenSymbol}
              </CrossGrid.Value>
            </CrossGridItem>
            <CrossGridItem>
              <CrossGrid.Label>Total Contributors</CrossGrid.Label>
              <CrossGrid.Value>
                {investDetails?.totalRecord || 0}
              </CrossGrid.Value>
            </CrossGridItem>
            <CrossGridItem>
              <CrossGrid.Label>AVG Contribution</CrossGrid.Label>
              <CrossGrid.Value>
                {investDetails?.totalRecord == 0
                  ? '0'
                  : parseFloat(investDetails?.averageMyContribution) || 0}{' '}
                {fundRaisingTokenSymbol}
              </CrossGrid.Value>
            </CrossGridItem>
            <CrossGridItem>
              <CrossGrid.Label>
                Presale Rate per 1 {fundRaisingTokenSymbol}
              </CrossGrid.Label>
              <CrossGrid.Value>
                {currentRound?.preSaleRate &&
                  presaleDetails?.token_decimal &&
                  Number(
                    ethers.utils.formatUnits(
                      String(currentRound?.preSaleRate),
                      Number(presaleDetails?.token_decimal)
                    )
                  ).toFixed(2)}{' '}
                {presaleDetails?.name} / {fundRaisingTokenSymbol}
              </CrossGrid.Value>
            </CrossGridItem>
            {!!presaleDetails?.listOnDex && (
              <CrossGridItem>
                <CrossGrid.Label>
                  Listing Rate per 1 {fundRaisingTokenSymbol}
                </CrossGrid.Label>
                <CrossGrid.Value>
                  {presaleDetails?.dexListingRate &&
                    presaleDetails?.token_decimal &&
                    Number(
                      ethers.utils.formatUnits(
                        String(presaleDetails?.dexListingRate),
                        Number(presaleDetails?.token_decimal)
                      )
                    ).toFixed(2)}{' '}
                  {presaleDetails?.name} / {fundRaisingTokenSymbol}
                </CrossGrid.Value>
              </CrossGridItem>
            )}
            {presaleDetails?.isbonusSale == 1 && (
              <>
                <CrossGridItem>
                  <CrossGrid.Label>Min Bonus Buy Amount</CrossGrid.Label>
                  <CrossGrid.Value>
                    {/* {presaleDetails?.minBonusBuyAmount}  */}
                    {presaleDetails?.minBonusBuyAmount &&
                      presaleDetails?.custom_fund_token_decimal &&
                      ethers.utils
                        .formatUnits(
                          String(presaleDetails?.minBonusBuyAmount),
                          Number(presaleDetails?.custom_fund_token_decimal)
                        )
                        .toString()}{' '}
                    {fundRaisingTokenSymbol}
                  </CrossGrid.Value>
                </CrossGridItem>
                <CrossGridItem>
                  <CrossGrid.Label>Bonus Received</CrossGrid.Label>
                  <CrossGrid.Value>
                    {presaleDetails?.bonusReceivedPercentage} %
                  </CrossGrid.Value>
                </CrossGridItem>
                <CrossGridItem>
                  <CrossGrid.Label>Bonus Spots Available</CrossGrid.Label>
                  <CrossGrid.Value>
                    {presaleDetails?.noOfBonusEligibleInvestors}
                  </CrossGrid.Value>
                </CrossGridItem>
                <CrossGridItem>
                  <CrossGrid.Label>Bonus Spots Left</CrossGrid.Label>
                  <CrossGrid.Value>{bonusSpotLeft || 0}</CrossGrid.Value>
                </CrossGridItem>
              </>
            )}

            <CrossGridItem>
              <CrossGrid.Label>My Contribution</CrossGrid.Label>
              <CrossGrid.Value>
                {parseFloat(investDetails?.MyContribution) || 0}{' '}
                {fundRaisingTokenSymbol}
              </CrossGrid.Value>
            </CrossGridItem>

            <CrossGridItem>
              <CrossGrid.Label className='mb-0'>
                My Reserved Tokens
              </CrossGrid.Label>
              <CrossGrid.Value className='mb-0'>
                {parseFloat(investDetails?.ReceiveTokens) || 0}{' '}
                {presaleDetails?.token_symbol}{' '}
              </CrossGrid.Value>
            </CrossGridItem>
          </CrossGrid>

          {/* <ul className='presale_address_ui'>
            <li className='d-flex justify-content-between'>
              <p>Minimum Buy</p>
              <p>
                {ethers.utils
                  .formatUnits(
                    String(presaleDetails?.minBuy || 0),
                    presaleDetails?.fund_releasing_token === 'BNB' ||
                      presaleDetails?.fund_releasing_token == 'MATIC'
                      ? 18
                      : presaleDetails?.custom_fund_token_decimal || 0
                  )
                  .toString()}{' '}
                {fundRaisingTokenSymbol}
              </p>
            </li>
            <li className='d-flex justify-content-between'>
              <p>Maximum Buy</p>
              <p>
                {ethers.utils
                  .formatUnits(
                    String(presaleDetails?.maxBuy || 0),
                    fundRaisingTokenDecimal || 0
                  )
                  .toString()}{' '}
                {fundRaisingTokenSymbol}
              </p>
            </li>
            <li className='d-flex justify-content-between'>
              <p>Total Contributors</p>
              <p>{investDetails?.totalRecord || 0}</p>
            </li>
            <li className='d-flex justify-content-between'>
              <p>AVG Contribution</p>
              <p>
                {investDetails?.totalRecord == 0
                  ? '0'
                  : parseFloat(investDetails?.averageMyContribution) || 0}{' '}
                {fundRaisingTokenSymbol}
              </p>
            </li>
            <li className='d-flex justify-content-between'>
              <p>Presale Rate per 1 {fundRaisingTokenSymbol}</p>
              <p>
                {currentRound?.preSaleRate &&
                  presaleDetails?.token_decimal &&
                  Number(
                    ethers.utils.formatUnits(
                      String(currentRound?.preSaleRate),
                      Number(presaleDetails?.token_decimal)
                    )
                  ).toFixed(2)}{' '}
                {presaleDetails?.name} / {fundRaisingTokenSymbol}
              </p>
            </li>
            {!!presaleDetails?.listOnDex && (
              <li className='d-flex justify-content-between'>
                <p>Listing Rate per 1 {fundRaisingTokenSymbol}</p>
                <p>
                  {presaleDetails?.dexListingRate &&
                    presaleDetails?.token_decimal &&
                    Number(
                      ethers.utils.formatUnits(
                        String(presaleDetails?.dexListingRate),
                        Number(presaleDetails?.token_decimal)
                      )
                    ).toFixed(2)}{' '}
                  {presaleDetails?.name} / {fundRaisingTokenSymbol}
                </p>
              </li>
            )}
            {presaleDetails?.isbonusSale == 1 && (
              <>
                <li className='d-flex justify-content-between'>
                  <p>Min Bonus Buy Amount</p>
                  <p>
                    {presaleDetails?.minBonusBuyAmount &&
                      presaleDetails?.custom_fund_token_decimal &&
                      ethers.utils
                        .formatUnits(
                          String(presaleDetails?.minBonusBuyAmount),
                          Number(presaleDetails?.custom_fund_token_decimal)
                        )
                        .toString()}{' '}
                    {fundRaisingTokenSymbol}
                  </p>
                </li>
                <li className='d-flex justify-content-between'>
                  <p>Bonus Received</p>
                  <p>{presaleDetails?.bonusReceivedPercentage} %</p>
                </li>
                <li className='d-flex justify-content-between'>
                  <p>Bonus Spots Available</p>
                  <p>{presaleDetails?.noOfBonusEligibleInvestors}</p>
                </li>
                <li className='d-flex justify-content-between'>
                  <p>Bonus Spots Left</p>
                  <p>{bonusSpotLeft || 0}</p>
                </li>
              </>
            )}

            <li className='d-flex justify-content-between pb-0'>
              <p>My Contribution</p>
              <p>
                {parseFloat(investDetails?.MyContribution) || 0}{' '}
                {fundRaisingTokenSymbol}
              </p>
            </li>
          </ul> */}
          {/* <ul>
            <li className='d-flex justify-content-between'>
              <p className='mb-0'>My Reserved Tokens</p>
              <p className='mb-0'>
                {parseFloat(investDetails?.ReceiveTokens) || 0}{' '}
                {presaleDetails?.token_symbol}{' '}
              </p>
            </li>
          </ul> */}
          {investDetails?.MyContribution != 0 &&
            loading == false &&
            investDetails != undefined && (
              <ul>
                {presaleDetails?.isCanceled == 1 && (
                  <button
                    className='btn emergency_with_btn mt-2 mx-auto'
                    onClick={() => {
                      withdraw();
                    }}
                    disabled={isLoadingWithdraw}
                  >
                    {' '}
                    {isLoadingWithdraw ? (
                      <SyncLoader color='#3498DB' size={10} />
                    ) : (
                      'Withdraw'
                    )}
                  </button>
                )}
                {/* :
                     currentUnixTime < currentRound?.endTime && isDataFundRaised !== BigInt(presaleDetails.hardCap) &&
                     <button 
                     className="btn emergency_with_btn mt-2 mx-auto"
                       onClick={() => { emergencyWithdraw() }}
                       disabled={isLoadingEmgcyWithDraw}
                     > {isLoadingEmgcyWithDraw ? <SyncLoader color="#3498DB" size={10}/> : 'Emergency Withdraw'}</button>
                 } */}
              </ul>
            )}
          {isDataFundRaised &&
            fundRaisingTokenDecimal &&
            investDetails?.MyContribution != 0 &&
            presaleDetails?.isFinalized == 1 &&
            ethers.utils.formatUnits(
              String(isDataFundRaised),
              fundRaisingTokenDecimal
            ) >=
              Number(
                ethers.utils.formatUnits(
                  String(presaleDetails?.softCap || 0),
                  Number(fundRaisingTokenDecimal)
                )
              ) &&
            investDetails?.isClaimed == 0 && (
              <Claim
                preSale={presaleDetails?.preSale}
                presaleDetails={presaleDetails}
                getInvestDetailsFunc={getInvestDetailsFunc}
              />
            )}
        </div>
      </div>
    </div>
  );
}

export default CnbtPresaleDetails;
