import { ethers } from "ethers";
import { SALE_TYPE } from "../_constant";

export const calculateNeededPool = (hard_cap,dex_liquidity,pre_sale_rate,dex_listing_rate,maximum_buy, bonus_sale,bonus_received ,bonus_spots_available,token_team_vesting_tokens)=>{
    let max_buy = Number(maximum_buy)
    
    let eligableInvestor = bonus_sale ? Number(bonus_spots_available) : 0
    
    let vestingAmount = Number(token_team_vesting_tokens)

      const tokenLiquidityPercent = (hard_cap * dex_liquidity) / 100;
    
      const _tokensNeededForPreSale = (pre_sale_rate * hard_cap);
    
      const _tokensNeedForLiquidity = (dex_listing_rate * tokenLiquidityPercent);
    
      const _tokensNeedForBonus = (max_buy *
        bonus_received *
        eligableInvestor *
        pre_sale_rate) / 100;
    
      const _requiredTokenAmount = (_tokensNeededForPreSale +
        _tokensNeedForLiquidity +
        _tokensNeedForBonus +
        vestingAmount);
    
    return _requiredTokenAmount;
    };


export const calculateNeedPoolFairLaunchPresale = (presaleAmount,dex_listing_rate,dex_liquidity,vestingAmount,token_decimal,bonus_received,saleType)=>{


  if(presaleAmount!=undefined && dex_listing_rate!=undefined && dex_liquidity!=undefined && vestingAmount!=undefined && bonus_received!=undefined  && !SALE_TYPE.isPresaleOrPrivateSale(saleType)){

    presaleAmount = BigInt(presaleAmount)  //eslint-disable-line no-undef
    dex_listing_rate = BigInt(dex_listing_rate)  //eslint-disable-line no-undef
    vestingAmount = BigInt(vestingAmount)  //eslint-disable-line no-undef
    dex_liquidity = BigInt(dex_liquidity)  //eslint-disable-line no-undef
    bonus_received = BigInt(bonus_received)  //eslint-disable-line no-undef
    
    const _tokensForFairSale = presaleAmount; //eslint-disable-line no-undef
    const _tokensForDex = (presaleAmount *  dex_listing_rate* dex_liquidity) / BigInt(10000); //eslint-disable-line no-undef
    const _tokensForBonus = (presaleAmount * bonus_received) / BigInt(100); //eslint-disable-line no-undef
    const _requiredTokenAmount = _tokensForFairSale + _tokensForDex + _tokensForBonus + vestingAmount;  //eslint-disable-line no-undef
        const parsedRequiredToken = ethers.utils
     .formatUnits(String((_requiredTokenAmount)), Number(token_decimal || 0))
     .toString()
    return parsedRequiredToken;
  }else{
    return 0
  }

}