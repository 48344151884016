import React from 'react';
import Container from './Container';

const CreateLaunchContainer = ({ children }) => {
  return (
    <Container>
      <div
        className='card'
        style={{
          padding: '6rem 10px',
        }}
        // className='mx-auto w-100'
        // style={{
        //   maxWidth: '1046px',
        // }}
      >
        <div className='row'>
          <div className='col-xxl-9 col-lg-10 col-md-12 mx-auto'>
            {children}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CreateLaunchContainer;
