import React from 'react';
import { BASE_URL } from '../../_constant';

export default function Banner({ url }) {
  const isVideo = url.endsWith('.mp4');
  const isWebm = url.endsWith('.webm');
  const isOgg = url.endsWith('.ogg');
  const isImage = url.match(/\.(jpg|jpeg|png|gif||webp)$/);

  if (!url || (!isVideo && !isImage && !isWebm && !isOgg)) {
    return (
      <img
        src={BASE_URL + 'images/bg2.png'}
        alt='Banner'
        className='banner'
        style={{
          backgroundSize: 'cover',
          width: '100%',
          // aspectRatio: '16/9',
          // aspectRatio: '1/1',
          // minWidth: '110px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'relative',
        }}
        // width="296"
        // height="110"
      />
    );
  }

  if (isVideo) {
    return (
      <video
        style={{ objectFit: 'cover', width: '100%', height: '100%' }}
        src={url}
        autoPlay
        loop
        muted
        controls={false}
        // crossorigin="anonymous"
      />
    );
  } else {
    return (
      <img
        src={url}
        alt='Banner'
        className='banner'
        style={{
          backgroundSize: 'cover',
          width: '100%',
          // aspectRatio: '16/9',
          // aspectRatio: '1/1',
          // minWidth: '110px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'relative',
        }}
      />
    );
  }
}
