import { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import StackingHeader from './StackingHeader';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  APP_NAME_TITLE,
  BASE_URL,
  NO_REWARD,
  REWARD_TYPE,
  networkImages,
  networkImagesByChain,
} from '../../_constant';
import { getStacksAction } from '../../redux/apiActions/api.action';
import { getAccount, watchNetwork, getNetwork } from '@wagmi/core';
import moment from 'moment';
import { ethers } from 'ethers';
import Pagination from '../../Components/Pagination/Pagination';
import PaginationOver from '../../Components/Pagination/PaginationOverload';
import { useAccount } from 'wagmi';
import { SyncLoader } from 'react-spinners';
import Banner from '../PresaleDetails/Banner';
import config from '../../config';
import Chip from '../../Components/Chip';
import Filter from '../../Components/Filter';
import SaleLayout from '../../Components/Layouts/SaleLayout';
import StackingPoolCard from './StackingPoolCard';
import Container from '../../Components/Layouts/Container';

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = 'transparent';
  if (isFocused) bg = 'transparent';
  if (isActive) bg = 'transparent';

  const style = {
    alignItems: 'center',
    backgroundColor: bg,
    color: 'inherit',
    display: 'flex ',
    width: '100%',
    zIndex: 999,
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type='checkbox' className='select_checkbox' checked={isSelected} />
      {children}
    </components.Option>
  );
};

// const allOptions = [
//     { value: "97", label: "tBNB" },
//     // { value: "80001", label: "Matic" }
// ];

const allOptions = config.ACTIVE_CHAINS.map((chain) => ({
  value: chain.id,
  label: chain.nativeCurrency.symbol,
}));

const allOptionsStatus = [
  { value: '1', label: 'Live' },
  { value: '0', label: 'Cancelled' },
];

export default function StackingPool() {
  const [loading, _loading] = useState(false);
  const [activeTab, _activeTab] = useState('all_products');

  const [selectedValues, setSelectedValues] = useState(
    allOptions.map((item) => item.value)
  );
  const [selectedValuesStatus, setSelectedValuesStatus] = useState(
    allOptionsStatus.map((item) => item.value)
  );

  function setShowTab(tab) {
    // _search("")
    _activeTab(tab);
    // handleChange(undefined, undefined, undefined, undefined, tab)
    // setCurrentPage(1)
  }

  const { address } = useAccount();

  const [reward, setReward] = useState('no');
  const [status, setStatus] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);

  const location = useLocation();

  const [currentNetwork, _currentNetwork] = useState(selectedValues);
  const [currentStatus, _currentStatus] = useState(selectedValuesStatus);

  const account = getAccount();
  const { chain } = getNetwork();

  const [stacks, setStacks] = useState([]);

  const [totalData, setTotalData] = useState(0);

  const getStack = async () => {
    const urlSearchParams = new URLSearchParams(location.search);
    const page = urlSearchParams.get('page') || '1';
    const status = urlSearchParams.get('status') || '0,1';
    const reward = urlSearchParams.get('reward') || 'no';
    const networks = urlSearchParams.get('networks') || selectedValues;
    const activatedTab = urlSearchParams.get('tab') || 'all_products';

    setCurrentPage(Number(page));
    setStatus(status);
    _activeTab(activatedTab);
    setReward(reward);
    _currentNetwork(networks);
    _currentStatus(currentStatus);
    let activeInfo = { activeTab: activatedTab };

    _loading(true);

    let params = {
      wallet: account?.address,
      chain_id: selectedValues || chain?.id,
      reward: reward,
      status: status,
      pageSize: itemsPerPage,
      currentPage: page,
    };
    let mergedParams = Object.assign(activeInfo, params);
    const data = await getStacksAction(mergedParams);
    setStacks(data?.data?.data);
    setTotalData(data?.data?.totalResult?.total);
    _loading(false);
  };
  // useEffect(() => {
  //     _loading(true)
  //     setTimeout(() => {
  //         _loading(false)
  //     }, 3000)
  // }, [])
  useEffect(() => {
    getStack();
  }, [
    location.search,
    activeTab,
    address,
    chain?.id,
    reward,
    selectedValues,
    selectedValuesStatus,
  ]);

  const [currentUnixTime, setCurrentUnixTime] = useState(moment().unix());

  const navigate = useNavigate();

  const handleChange = (page, status, rewardChange, network, tab) => {
    const searchParams = new URLSearchParams();
    searchParams.set('page', page || currentPage);
    searchParams.set('status', status || currentStatus);
    searchParams.set('reward', rewardChange || reward);
    searchParams.set('networks', network || currentNetwork);
    searchParams.set('tab', tab || activeTab);
    navigate(`${BASE_URL}stakes?${searchParams.toString()}`, { replace: true });
  };

  const handleChangeStatus = (page, status, reward, network, tab) => {
    const searchParams = new URLSearchParams();
    searchParams.set('page', page || currentPage);
    searchParams.set('status', status || currentStatus);
    searchParams.set('reward', reward || reward);
    searchParams.set('networks', network || currentNetwork);
    searchParams.set('tab', tab || activeTab);
    navigate(`${BASE_URL}stakes?${searchParams.toString()}`, { replace: true });
  };

  function setShowTab(tab) {
    _activeTab(tab);
    handleChange(undefined, undefined, undefined, undefined, tab);
  }

  const handleFilterChange = ({ target }) => {
    setStatus(target.value);
    handleChange(undefined, target.value, undefined, undefined);
  };

  const handleFilterRewardChange = ({ target }) => {
    setReward(target?.value);
    handleChange(undefined, undefined, target.value, undefined, undefined);
  };

  const handlePageChange = (page) => {
    // setCurrentPage(page);
    handleChange(page, undefined, undefined, undefined, undefined);
    setCurrentPage(page);
  };

  // const [openFilter, setOpenFilter] = useState(false);

  document.title = `${APP_NAME_TITLE} -  Stake Pools`;

  return (
    <Container>
      <SaleLayout
        title='Staking Pools'
        activeTab={activeTab}
        tabs={[
          {
            id: 'all_products',
            label: 'All Stake Pools',
          },
          {
            id: 'my_staked',
            label: 'My Staked Pools',
          },
          {
            id: 'my_created_stack',
            label: 'My Created Stake Pools',
          },
        ]}
        onTabClick={setShowTab}
        // toggleFilter={() => {
        //   setOpenFilter(!openFilter);
        // }}
        renderFilter={
          !['quick_view', 'myalarms'].includes(activeTab) && (
            <>
              <div
              // className={`${
              //   openFilter ? 'presale-filter-model' : ''
              // } filter-container card px-0 my-4 d-none d-xl-block`}
              >
                {/* heading */}
                {/* <div className='px-3 d-flex justify-content-between align-items-start'>
                  <h6 className='fs-6 ff-poppins mb-4'>Filters</h6>
                  <button
                    type='button'
                    onClick={() => setOpenFilter(false)}
                    className='btn-close btn-close-white d-block d-xl-none'
                    aria-label='Close'
                  ></button>
                </div> */}
                {/* applied filters */}
                <div className='px-3'>
                  <h6 className='fs-6 fw-medium ff-poppins text-gray'>
                    Applied filters
                  </h6>
                  <div className='mt-2 d-flex flex-wrap gap-2 align-items-center justify-content-start'>
                    {reward && reward !== 'no' && (
                      <Chip
                        variant='outlined'
                        color='dark-300'
                        onClose={() => {
                          setReward('no');
                          handleChange(undefined, 'no', undefined, undefined);
                        }}
                      >
                        {Object.keys(REWARD_TYPE).find(
                          (key) => REWARD_TYPE[key] === reward
                        )}
                      </Chip>
                    )}
                    {selectedValuesStatus &&
                      allOptionsStatus
                        .filter((item) =>
                          selectedValuesStatus.includes(item.value)
                        )
                        .map((item) => (
                          <Chip
                            key={item.value}
                            variant='outlined'
                            color='dark-300'
                            onClose={() => {
                              const filteredValues =
                                selectedValuesStatus.filter(
                                  (value) => value !== item.value
                                );
                              setSelectedValuesStatus(filteredValues);
                              handleChange(
                                undefined,
                                filteredValues,
                                undefined,
                                undefined
                              );
                            }}
                          >
                            {item.label}
                          </Chip>
                        ))}

                    {selectedValues?.map((value) => (
                      <Chip
                        variant='outlined'
                        color='dark-300'
                        onClose={() => {
                          // setSortBy('no');
                          const filteredValues = selectedValues.filter(
                            (item) => item !== value
                          );
                          console.log(selectedValues, filteredValues, 's f');
                          setSelectedValues(filteredValues);
                          handleChange(
                            undefined,
                            undefined,
                            undefined,
                            filteredValues
                          );
                        }}
                      >
                        {
                          allOptions.find((option) => option.value === value)
                            .label
                        }
                      </Chip>
                    ))}
                  </div>
                </div>

                <hr />
                {activeTab != 'my_contribution' && (
                  <>
                    <div className='px-3'>
                      <Filter
                        id='status'
                        label='Status'
                        data={allOptionsStatus}
                        onSelect={(option) => {
                          const data = new Set([
                            ...selectedValuesStatus,
                            option.value,
                          ]);
                          setSelectedValuesStatus(Array.from(data));
                          handleChange(
                            undefined,
                            Array.from(data),
                            undefined,
                            undefined
                          );
                        }}
                      />
                    </div>

                    <hr />

                    <div className='px-3'>
                      <Filter
                        id='filterBy'
                        label='Filter by'
                        data={[
                          {
                            label: 'All status',
                            value: 'no',
                          },
                          ...Object.keys(REWARD_TYPE).map((label, value) => ({
                            label,
                            value: String(value),
                          })),
                        ]}
                        onSelect={(option) => {
                          setReward(option.value);
                          handleChange(
                            undefined,
                            undefined,
                            option.value,
                            undefined
                          );
                        }}
                      />
                    </div>
                    <hr />
                  </>
                )}
                <div className='px-3'>
                  <Filter
                    id='network'
                    label='Network'
                    data={allOptions}
                    onSelect={(option) => {
                      if (!selectedValues.includes(option.value)) {
                        const data = new Set([...selectedValues, option.value]);
                        setSelectedValues(Array.from(data));
                        handleChange(
                          undefined,
                          undefined,
                          undefined,
                          Array.from(data)
                        );
                      }
                    }}
                    // renderOption={(option) => {
                    //   return (
                    //     <div>
                    //       <div className='d-flex gap-3 align-items-center'>
                    //         <input type='checkbox' className='select_checkbox'
                    //         checked={}
                    //         />
                    //         {option.label}
                    //       </div>
                    //     </div>
                    //   );
                    // }}
                  />
                </div>
              </div>
            </>
          )
        }
      >
        {/* Tab panes */}
        <div className='tab-content'>
          <div className='tab-pane active' id='allproducts'>
            <div
              className={`mb-4 row row-cols-1 ${
                activeTab != 'quick_view'
                  ? 'row-cols-xxxl-3 row-cols-xxl-2 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1 g-3'
                  : ''
              }`}
            >
              {loading ? (
                // <div className='d-flex justify-content-center w-100 fs-7'>
                //   <SyncLoader color='#3498DB' />
                // </div>
                new Array(10).fill(0).map((item, idx) => (
                  <div
                    className='col mb-4'
                    key={`stackingpoolcardshimmer${idx}`}
                  >
                    <StackingPoolCard.Shimmer />
                  </div>
                ))
              ) : stacks?.length > 0 ? (
                stacks?.map((data) => {
                  const startTime = Number(data?.startDateTime);
                  const endTime = Number(data?.endDateTime);

                  let classNameD = 'sale_upcoming badge text-bg-secondary';
                  let statusText = 'Upcoming';
                  let startEndIn = 'TBA';

                  if (data?.isCancel == 1) {
                    statusText = 'Sale cancelled';
                  } else if (
                    currentUnixTime >= startTime &&
                    endTime > currentUnixTime
                  ) {
                    classNameD = 'sale_live badge text-bg-secondary';
                    statusText = 'Sale Live';
                    // startEndIn = "Sale Ends In";
                  } else if (currentUnixTime < startTime) {
                    classNameD = 'sale_upcoming badge text-bg-secondary';
                    statusText = 'Upcoming';
                    // startEndIn = "Sale Start In";
                  } else if (currentUnixTime > endTime) {
                    statusText = 'Sale Ended';
                    classNameD = 'sale_end badge text-bg-secondary';
                    // startEndIn = "Sale Ended"
                  }
                  return (
                    <div className='col mb-4'>
                      <StackingPoolCard
                        data={data}
                        classNameD={classNameD}
                        statusText={statusText}
                      />
                    </div>
                  );
                  // return (
                  //   <div className='col'>
                  //     <div className={`card custom_card_design`}>
                  //       <Link
                  //         to={`${BASE_URL}stake?stakeContract=${data?.stakeContract}&chainId=${data?.chain}`}
                  //       >
                  //         <div className={`custom_card h-100`}>
                  //           {/* <Link to={`${BASE_URL}presale?presale_id=${data?.preSale}&chainId=${data?.chain}`}> */}
                  //           <div className='d-flex gap-4 p-3 w-100 align-items-start justify-content-start'>
                  //             <div
                  //               className='card-top-img stablz-card-top-img'
                  //               // style={{
                  //               //     backgroundImage: `url(${data?.bannerUrl != "" ? data?.bannerUrl : "https://testnet.gempad.app/static/bg4.png"})`,
                  //               // }}
                  //             >
                  //               {
                  //                 // data?.bannerUrl &&
                  //                 <Banner url={data?.bannerUrl} />
                  //               }
                  //               <div className='p-3'>
                  //                 <div className='audi_kyc_btn'></div>
                  //               </div>
                  //             </div>
                  //             <div className='w-100'>
                  //               <div className='card_second_section d-flex justify-content-between align-items-end'>
                  //                 <div className='card_second_section_text'>
                  //                   <h5> Stake {data?.name}</h5>
                  //                   <p>
                  //                     Stake {data?.symbol}{' '}
                  //                     {data?.rewardToken != NO_REWARD &&
                  //                       `to Earn ${
                  //                         JSON.parse(data.rewardTokenInfo)
                  //                           ?.symbol
                  //                       }`}
                  //                   </p>
                  //                 </div>
                  //                 <div></div>
                  //                 <div className='position'>
                  //                   <img
                  //                     width={30}
                  //                     src={networkImagesByChain[data?.chain]}
                  //                     // className="img-fluid"
                  //                     alt='logo'
                  //                   />
                  //                 </div>
                  //               </div>
                  //               <div className='compare_coin d-flex align-items-center mt-2'>
                  //                 <img
                  //                   // className="img-fluid"
                  //                   src={data?.stakingLogoUrl}
                  //                   onError={(e) => {
                  //                     e.target.src =
                  //                       networkImages['bsc-testnet'];
                  //                   }}
                  //                   alt='stakinglogo'
                  //                 />

                  //                 {data?.rewardToken != NO_REWARD && (
                  //                   <>
                  //                     <div className='px-3'>
                  //                       <i
                  //                         className='fa fa-angle-right'
                  //                         style={{ fontSize: 36 }}
                  //                       />
                  //                     </div>

                  //                     <img
                  //                       src={data?.rewardLogoUrl}
                  //                       onError={(e) => {
                  //                         e.target.src =
                  //                           networkImages['bsc-testnet'];
                  //                       }}
                  //                       alt='rewardlogo'
                  //                     />
                  //                   </>
                  //                 )}
                  //               </div>

                  //               {/* status */}
                  //               <div className='pt-3'>
                  //                 <div className={classNameD}>
                  //                   {statusText}
                  //                 </div>
                  //               </div>
                  //             </div>
                  //           </div>
                  //           {/* </Link> */}
                  //           <hr />
                  //           <div className={`px-3`}>
                  //             <div className='row row-cols-1 row-cols-sm-1 gx-1 gy-1 pb-3'>
                  //               <div className='col'>
                  //                 <ul className='presale_address_ui ul_li_bordernone'>
                  //                   <li className='d-flex justify-content-between'>
                  //                     <span className='text-gray fw-light'>
                  //                       <strong>Reward Type:</strong>
                  //                     </span>
                  //                     <span className='text-primary font-weight-bold'>
                  //                       <strong>
                  //                         {Object.keys(REWARD_TYPE).find(
                  //                           (key) =>
                  //                             REWARD_TYPE[key] ==
                  //                             data?.rewardType
                  //                         )}
                  //                       </strong>
                  //                     </span>
                  //                   </li>
                  //                   {data?.rewardType !=
                  //                     REWARD_TYPE['Only if balance'] &&
                  //                     data?.rewardType !=
                  //                       REWARD_TYPE['No Reward'] && (
                  //                       <li className='d-flex justify-content-between'>
                  //                         <span className='text-gray fw-light'>
                  //                           <strong>
                  //                             Reward Ratio (year):
                  //                           </strong>
                  //                         </span>
                  //                         <span className='text-primary font-weight-bold'>
                  //                           <strong>
                  //                             {ethers.utils
                  //                               .formatUnits(
                  //                                 String(
                  //                                   data?.rewardRatio || 0
                  //                                 ),
                  //                                 JSON.parse(
                  //                                   data.rewardTokenInfo
                  //                                 )?.decimals || 0
                  //                               )
                  //                               .toString()
                  //                               ?.replace(/\.?0*$/, '')}
                  //                           </strong>
                  //                         </span>
                  //                       </li>
                  //                     )}
                  //                   <li className='d-flex justify-content-between'>
                  //                     <span className='text-gray fw-light'>
                  //                       <strong>Hard Cap:</strong>
                  //                     </span>
                  //                     <span className='text-primary font-weight-bold'>
                  //                       <strong>
                  //                         {/* {data?.hardCap} */}
                  //                         {
                  //                           Intl.NumberFormat('en', {
                  //                             notation: 'compact',
                  //                           }).format(
                  //                             ethers.utils.formatUnits(
                  //                               String(data?.hardCap || 0),
                  //                               JSON.parse(
                  //                                 data.stackTokenInfo
                  //                               )?.decimals || 0
                  //                             )
                  //                           )
                  //                           // .toString()?.replace(/\.?0*$/, '')
                  //                         }
                  //                       </strong>
                  //                     </span>
                  //                   </li>
                  //                   <li className='d-flex justify-content-between'>
                  //                     <span className='text-gray fw-light'>
                  //                       <strong>Total Staked</strong>
                  //                     </span>
                  //                     <span className='text-primary font-weight-bold'>
                  //                       <strong>
                  //                         {Number(data?.total_staked)
                  //                           .toFixed(5)
                  //                           ?.replace(/\.?0*$/, '')}
                  //                       </strong>
                  //                     </span>
                  //                   </li>
                  //                   <li className='d-flex justify-content-between'>
                  //                     <span className='text-gray fw-light'>
                  //                       <strong>Start Time</strong>
                  //                     </span>
                  //                     <span className='text-primary font-weight-bold'>
                  //                       <strong>
                  //                         {moment(
                  //                           new Date(
                  //                             data?.startDateTime * 1000
                  //                           )
                  //                         ).format('YYYY-MM-DD HH:mm')}
                  //                       </strong>
                  //                     </span>
                  //                   </li>
                  //                   <li className='d-flex justify-content-between'>
                  //                     <span className='text-gray fw-light'>
                  //                       <strong>End Time</strong>
                  //                     </span>
                  //                     <span className='text-primary font-weight-bold'>
                  //                       <strong>
                  //                         {moment(
                  //                           new Date(data?.endDateTime * 1000)
                  //                         ).format('YYYY-MM-DD HH:mm')}
                  //                       </strong>
                  //                     </span>
                  //                   </li>
                  //                 </ul>
                  //               </div>
                  //             </div>
                  //             {/* <hr /> */}
                  //             <div className='row'>
                  //               <div className='col'>
                  //                 <div className='sale_status d-flex justify-content-between align-items-start'>
                  //                   <div></div>
                  //                 </div>
                  //               </div>
                  //             </div>
                  //           </div>

                  //           {/* {showAdditionalData[key] && */}

                  //           {/* } */}
                  //         </div>
                  //       </Link>
                  //     </div>
                  //   </div>
                  // );
                })
              ) : (!account?.isConnected && activeTab == 'my_created_stack') ||
                (!account?.isConnected && activeTab == 'my_staked') ? (
                <div className='d-flex justify-content-center w-100 fs-7'>
                  Please Login with your wallet!
                </div>
              ) : (
                <div className='d-flex justify-content-center w-100 fs-7'>
                  No Data
                </div>
              )}
            </div>

            {!loading && stacks.length > 0 && totalData > itemsPerPage && (
              <Pagination
                className='pagination-bar'
                currentPage={currentPage}
                totalCount={totalData}
                pageSize={itemsPerPage}
                onPageChange={(page) => handlePageChange(page)}
              />
            )}

            {!loading && stacks.length == 0 && currentPage != 1 && (
              <>
                <PaginationOver
                  className='pagination-bar'
                  currentPage={currentPage}
                  totalCount={totalData}
                  pageSize={itemsPerPage}
                  onPageChange={(page) => handlePageChange(page)}
                />
              </>
            )}
          </div>
        </div>
      </SaleLayout>
    </Container>
  );
}
