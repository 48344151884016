import React, { useEffect, useReducer, useState } from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Web3 from 'web3';
import SimpleToken from '../../_constant/SimpleToken.json';
import PreSaleManager from '../../_constant/PreSaleManager.json';
import FeeManager from '../../_constant/FeeManager.json';
import { useNavigate } from 'react-router-dom';
import { useWeb3Modal } from '@web3modal/react';
import { toast } from 'react-toastify';
import moment from 'moment';
import './AnimatedEllipsis.css';
import {
  presaleInfoSchema,
  projectInfoSchema,
} from '../../validators/Presale/presale';
import { constants, ethers } from 'ethers';
import routerAddresses from '../../_constant/routerAddresses.json';
import {
  FEE_MANAGER_ADDRESSESS,
  PRESALE_MANAGER_ADDRESSESS,
  blockInvalidChar,
  validateAddress,
} from '../../_constant';
import {
  PostErrorLogAction,
  PostPresaleAction,
  getCurrentBnbPriceAction,
} from '../../redux/apiActions/api.action';
import { BASE_URL } from '../../_constant';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {
  useAccount,
  useContractRead,
  useContractReads,
  useContractWrite,
  useToken,
  useWaitForTransaction,
} from 'wagmi';
import { readContract } from '@wagmi/core';
import { useNetwork, erc20ABI } from 'wagmi';
import { getAccount, getNetwork } from '@wagmi/core';
// import { PRESALE_MANAGER_ADDRESS } from "../../_constant";
import { TransactionReceipt, decodeEventLog, parseAbi } from 'viem';
import {
  ERRORMSGS,
  METAMASKERRORS,
  SUCCESSMSGS,
  TRANSACTIONMSGS,
} from '../../utils/messages';
import { bscTestnet, polygonMumbai } from 'viem/chains';
import { presaleFairLaunchInfoSchema } from '../../validators/Presale/hyperLaunch';
import FairLaunch from '../../_constant/FairLaunch.json';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { SyncLoader } from 'react-spinners';
import config from '../../config';
import CreateLaunchContainer from '../../Components/Layouts/CreateLaunchContainer';

const stepTrack = [false, false, false, false];

const wizards = [
  {
    label: 'Approve Token',
    description: 'Enter the token address and approve',
    index: 1,
    content: ApproveToken,
    id: 'ApproveToken',
  },
  {
    label: 'Presale Information',
    description: 'Enter the Presale Information',
    index: 2,
    content: PresaleInformation,
    id: 'PresaleInformation',
  },
  {
    label: 'Project Information',
    description: 'Add Project links, description and select tier',
    index: 3,
    content: ProjectInformation,
    id: 'ProjectInformation',
  },
  {
    label: 'Submit',
    description: 'Submit your presale',
    index: 4,
    content: Confirmation,
    id: 'Submit',
  },
];

// dayjs.extend(localizedFormat);

const currentTime = dayjs(); // Get the current time
// const startTime = currentTime.subtract(4, 'hour').subtract(30, 'minute'); // Subtract 4 hours and 30 minutes from the current time
// const endTime = startTime.add(1, 'day'); // Add 1 day to the startTime

const startTime = currentTime.add(5, 'minute');
const endTime = startTime.add(1, 'day');
const estimatedDexListingTime = endTime.add(24, 'hour');

const initialData = {
  token: '',
  token_supply: '',
  isValidToken: false,
  pre_sale_amount: '',
  dex_listing_rate: '',
  dex_liquidity: '',
  liquidity_lookup: '',
  soft_cap: '',
  hard_cap: '',
  minimum_buy: '',
  maximum_buy: '',
  start_date: moment(new Date(startTime)).unix(),
  end_date: moment(new Date(endTime)).unix(),
  estimated_dex_listing_date: moment(new Date(estimatedDexListingTime)).unix(),
  presale_type: 'Public',
  whitelist_timer: '',
  add_team_token_vesting: false,
  add_presale_vesting: false,
  logo_url: '',
  website_url: '',
  twitter: '',
  github: '',
  telegram: '',
  discord: '',
  youtube: '',
  whitelist_link: '',
  description: '',
  cover_photo: '',
  tier: '0',
  router: '',
  buyWithETH: true,
  fund_releasing_token: '',
  custom_fund_raising_token: '',
  buyToken: '',
  dynamicDecimal: '',
  token_team_vesting_tokens: 0,
  first_token_release: 0,
  first_token_release_after_listing: 0,
  team_token_released_each_cycle: 0,
  vesting_period_each_cycle: 0,
  set_sale_time: true,
  anti_sniper_protection: true,
  bonus_sale: false,
  min_bonus_buy_amount: 0,
  bonus_received: 0,
  bonus_spots_available: 0,
  first_token_release_presale: 0,
  toke_released_each_cycle_presale: 0,
  vesting_period_each_cycle_presale: 0,
  cliff: 0,
  sale_without_token: false,
  sale_title: '',
  launch_stealth_sale: false,
  stealth_wallet: '',
  approvedToken: 0,
  burnTokens: false,
  perk_fees: [],
  isHardCap: false,
  isMaxBuy: false,
  isBurn: true,
  current_token_price: 0,
  max_contributors: '',
  redditUrl: '',
};

function formReducer(state, { type, value }) {
  switch (type) {
    case 'token': {
      return { ...state, token: value };
    }

    case 'token_name': {
      return { ...state, token_name: value };
    }

    case 'token_symbol': {
      return { ...state, token_symbol: value };
    }

    case 'token_supply': {
      return { ...state, token_supply: value };
    }

    case 'token_decimal': {
      return { ...state, token_decimal: value };
    }

    case 'isValidToken': {
      return { ...state, isValidToken: value };
    }

    case 'pre_sale_amount': {
      return { ...state, pre_sale_amount: value };
    }

    case 'dex_listing_rate': {
      return { ...state, dex_listing_rate: value };
    }

    case 'dex_liquidity': {
      return { ...state, dex_liquidity: value };
    }

    case 'liquidity_lookup': {
      return { ...state, liquidity_lookup: value };
    }

    case 'soft_cap': {
      return { ...state, soft_cap: value };
    }

    case 'hard_cap': {
      return { ...state, hard_cap: value };
    }

    case 'minimum_buy': {
      return { ...state, minimum_buy: value };
    }

    case 'maximum_buy': {
      return { ...state, maximum_buy: value };
    }

    case 'start_date': {
      return { ...state, start_date: value };
    }

    case 'end_date': {
      return { ...state, end_date: value };
    }

    case 'estimated_dex_listing_date': {
      return { ...state, estimated_dex_listing_date: value };
    }

    case 'presale_type': {
      return { ...state, presale_type: value };
    }

    case 'whitelist_timer': {
      return { ...state, whitelist_timer: value };
    }

    case 'logo_url': {
      return { ...state, logo_url: value };
    }

    case 'website_url': {
      return { ...state, website_url: value };
    }

    case 'twitter': {
      return { ...state, twitter: value };
    }

    case 'github': {
      return { ...state, github: value };
    }

    case 'telegram': {
      return { ...state, telegram: value };
    }

    case 'discord': {
      return { ...state, discord: value };
    }

    case 'youtube': {
      return { ...state, youtube: value };
    }

    case 'whitelist_link': {
      return { ...state, whitelist_link: value };
    }

    case 'description': {
      return { ...state, description: value };
    }

    case 'cover_photo': {
      return { ...state, cover_photo: value };
    }

    case 'tier': {
      return { ...state, tier: value };
    }

    case 'router': {
      return { ...state, router: value };
    }

    case 'buyWithETH': {
      return { ...state, buyWithETH: value };
    }

    case 'fund_releasing_token': {
      return { ...state, fund_releasing_token: value };
    }

    case 'custom_fund_raising_token': {
      return { ...state, custom_fund_raising_token: value };
    }

    case 'buyToken': {
      return { ...state, buyToken: value };
    }

    case 'bannerUrl': {
      return { ...state, bannerUrl: value };
    }

    case 'dynamicDecimal': {
      return { ...state, dynamicDecimal: value };
    }

    case 'custom_fund_raising_token_name': {
      return { ...state, custom_fund_raising_token_name: value };
    }

    case 'custom_fund_raising_token_symbol': {
      return { ...state, custom_fund_raising_token_symbol: value };
    }

    case 'set_sale_time': {
      return { ...state, set_sale_time: value };
    }

    case 'anti_sniper_protection': {
      return { ...state, anti_sniper_protection: value };
    }

    case 'bonus_sale': {
      return { ...state, bonus_sale: value };
    }

    case 'add_team_token_vesting': {
      return { ...state, add_team_token_vesting: value };
    }

    case 'add_presale_vesting': {
      return { ...state, add_presale_vesting: value };
    }

    case 'min_bonus_buy_amount': {
      return { ...state, min_bonus_buy_amount: value };
    }

    case 'bonus_received': {
      return { ...state, bonus_received: value };
    }

    case 'bonus_spots_available': {
      return { ...state, bonus_spots_available: value };
    }

    case 'token_team_vesting_tokens': {
      return { ...state, token_team_vesting_tokens: value };
    }

    case 'first_token_release': {
      return { ...state, first_token_release: value };
    }

    case 'first_token_release_after_listing': {
      return { ...state, first_token_release_after_listing: value };
    }

    case 'team_token_released_each_cycle': {
      return { ...state, team_token_released_each_cycle: value };
    }

    case 'vesting_period_each_cycle': {
      return { ...state, vesting_period_each_cycle: value };
    }

    case 'first_token_release_presale': {
      return { ...state, first_token_release_presale: value };
    }

    case 'toke_released_each_cycle_presale': {
      return { ...state, toke_released_each_cycle_presale: value };
    }

    case 'vesting_period_each_cycle_presale': {
      return { ...state, vesting_period_each_cycle_presale: value };
    }

    case 'cliff': {
      return { ...state, cliff: value };
    }

    case 'sale_without_token': {
      return { ...state, sale_without_token: value };
    }

    case 'sale_title': {
      return { ...state, sale_title: value };
    }

    case 'launch_stealth_sale': {
      return { ...state, launch_stealth_sale: value };
    }

    case 'stealth_wallet': {
      return { ...state, stealth_wallet: value };
    }

    case 'approvedToken': {
      return { ...state, approvedToken: value };
    }

    case 'burnTokens': {
      return { ...state, burnTokens: value };
    }

    case 'perk_fees': {
      return { ...state, perk_fees: value };
    }

    case 'isHardCap': {
      return { ...state, isHardCap: value };
    }

    case 'isMaxBuy': {
      return { ...state, isMaxBuy: value };
    }

    case 'isBurn': {
      return { ...state, isBurn: value };
    }

    case 'current_token_price': {
      return { ...state, current_token_price: value };
    }

    case 'max_contributors': {
      return { ...state, max_contributors: value };
    }

    case 'redditUrl': {
      return { ...state, redditUrl: value };
    }

    case 'reset': {
      return initialData; // Reset the state to the initialData
    }

    default: {
      return state;
    }
  }
}

let toastPleasedLoginId = 'pleasedLoginId';
function CreateHypersLaunch() {
  const today = dayjs();
  const handleScrollToTop = () => {
    window.scrollTo({ top: 100, behavior: 'smooth' }); // Scrolls to the top smoothly
  };
  const [formData, setFormData] = useReducer(formReducer, initialData);

  const [progressCount, setProgressCount] = useState(1);

  function nextProgress() {
    handleScrollToTop();
    if (progressCount < wizards.length) setProgressCount(progressCount + 1);
    if (progressCount >= wizards.length) {
      // alert("FormFilled");
    }
  }

  const resetForm = () => {
    setFormData({ type: 'reset' });
  };

  function prevProgress() {
    handleScrollToTop();
    if (progressCount == 2) resetForm();
    if (progressCount > 1) setProgressCount(progressCount - 1);
  }

  document.title = 'Create Presale';

  return (
    <CreateLaunchContainer>
      <form className='approve_token_form'>
        <ul id='progressbar' className='mb-5'>
          {Object.values(wizards).map((wizard, index) => (
            <li
              className={`step ${
                wizard?.index <= progressCount
                  ? 'active'
                  : progressCount == 4
                  ? 'active'
                  : ''
              }`}
              id={wizard?.id}
              key={index}
              onClick={() => {
                if (
                  wizard?.index == 2 &&
                  stepTrack[wizard?.index - 2] == true
                ) {
                  setProgressCount(wizard?.index);
                } else if (
                  wizard?.index == 3 &&
                  stepTrack[
                    wizard?.index - 2 == true &&
                      stepTrack[wizard?.index - 3] == true
                  ]
                ) {
                  setProgressCount(wizard?.index);
                } else if (
                  wizard?.index == 1 &&
                  stepTrack[wizard?.index - 1] == true
                ) {
                  setProgressCount(wizard?.index);
                }
              }}
            >
              <h5
              // className={`${
              //   wizard?.index <= progressCount || progressCount == 4
              //     ? ''
              //     : 'not_active_wizard'
              // }`}
              >
                {wizard?.index <= progressCount || progressCount == 4
                  ? wizard?.label
                  : wizard?.label}
              </h5>
              <p
              // className={`${
              //   wizard?.index <= progressCount || progressCount == 4
              //     ? ''
              //     : 'not_active_wizard'
              // }`}
              >
                {wizard?.index <= progressCount || progressCount == 4
                  ? wizard?.description
                  : wizard?.description}
              </p>
            </li>
          ))}
        </ul>
        <div className='row'>
          <div className='col-md-12 mx-auto'>
            {Object.values(wizards).map((wizard) => (
              <section
                hidden={wizard.index !== progressCount}
                key={wizard?.index}
              >
                <wizard.content
                  {...{
                    next: nextProgress,
                    setFormData,
                    formData,
                    prev: prevProgress,
                  }}
                />
              </section>
            ))}

            <div className='tab'></div>
          </div>
        </div>
      </form>
    </CreateLaunchContainer>
  );
}

function ApproveToken({ next, prev, setFormData, formData }) {
  const { chain, chains } = getNetwork();
  const PRESALE_MANAGER_ADDRESS = PRESALE_MANAGER_ADDRESSESS[chain?.network];

  const account = getAccount();

  const { isConnected } = useAccount();

  const navigate = useNavigate();

  const [errors, setErrors] = useState(true);

  // const [tokenInfo, setTokenInfo] = useState(null);

  // const [isApproved, setIsApproved] = useState(false);

  // const [approving, _approving] = useState(false);

  // async function isValid() {
  //   const errors = {};
  //   if (!formData?.token || tokenInfo == null) {
  //     errors.token = "Invalid token";
  //   }

  //   setErrors(errors);
  //   return Boolean(!Object.keys(errors).length);
  // }

  function validateAndContinue() {
    // if (isValid()) {
    //   stepTrack[0] = true;
    // }
    // isValid() && next();
    next();
  }

  // useEffect(() => {
  //   isValid();
  // }, [formData, tokenInfo]);

  /// @dev Handle on invalid token address.
  const onTokenFetchError = async (err) => {
    // toast.error(err.message);
  };

  const onSuccessPresaleToken = (data) => {
    // console.log("onSuccessPresaleToken", data)
  };

  const {
    data: preSaleToken,
    isSuccess: isTokenFetched,
    isLoading: isLoadingPresale,
    isFetching: isFetchingPresale,
    isError: isErrorPresale,
  } = useToken({
    address: formData?.token,
    onError: onTokenFetchError,
    onSuccess: onSuccessPresaleToken,
  });

  // Allowance
  const onErrorAllowance = (error) => {
    PostErrorLogAction({
      address: account?.address || 0,
      other_detail: { error },
    });
    console.log('onErrorAllowance', error);
    setErrors(true);
  };
  const onSuccessAllowance = (data) => {
    setErrors(false);
    setFormData({
      type: 'approvedToken',
      value: ethers.utils
        .formatUnits(String(data), preSaleToken?.decimals || 0)
        .toString(),
    });
    setFormData({ type: 'token_name', value: preSaleToken.name });
    setFormData({ type: 'token_symbol', value: preSaleToken.symbol });
    setFormData({
      type: 'token_supply',
      value: String(preSaleToken.totalSupply.value),
    });
    setFormData({ type: 'token_decimal', value: preSaleToken.decimals });
  };

  const {
    data: isAllowanceData,
    isError: isErrorAllowance,
    isLoading: isLoadingAllowance,
    refetch: refetchAllowance,
  } = useContractRead({
    address: formData?.token != '' ? formData?.token : undefined,
    abi: SimpleToken.abi,
    functionName: 'allowance',
    args: [account.address, PRESALE_MANAGER_ADDRESS],
    onSuccess: onSuccessAllowance,
    onError: onErrorAllowance,
  });

  const { data: balanceOfToken } = useContractRead({
    /// PreSale token address
    // @ts-ignore
    address: formData?.token != '' ? formData?.token : undefined,
    abi: SimpleToken.abi,
    functionName: 'balanceOf',
    args: [account.address],
    onError: onTokenFetchError,
  });

  /**
   * *:Approve Method starting
   */

  /// Any api call
  const onApproveReceipt = async (data, err) => {
    if (err) {
      console.error('Err', err);
    } else {
      // Any API call or additional logic can be placed here
      refetchAllowance();
      next();
    }
  };

  /// @dev Handle on metamask popup rejection.
  const onApproveError = async (error) => {
    PostErrorLogAction({
      address: account?.address || 0,
      other_detail: { error },
    });
    console.log('Error 64', error);
    let errStr = error.toString().slice(0, 53);
    if (errStr === 'TransactionExecutionError: User rejected the request.') {
      toast.error(TRANSACTIONMSGS.REJECTEDAPPROVING);
    } else {
      toast.error('Failed in approving!');
    }
  };

  if (isTokenFetched) {
    // console.log("data",preSaleToken);
    // setFormData({ type: "token_name", value: preSaleToken.name });
    // setFormData({ type: "token_symbol", value: preSaleToken.symbol });
    // setFormData({ type: "token_supply", value: Number(preSaleToken.totalSupply.value) });
    // setFormData({ type: "token_decimal", value: preSaleToken.decimals });
  }

  /// Setup for getting balance of connected wallet address.
  const { data: balanceOf } = useContractRead({
    /// PreSale token address
    // @ts-ignore
    address: formData?.token,
    abi: SimpleToken.abi,
    functionName: 'balanceOf',
    args: [account.address],
    onError: onTokenFetchError,
  });

  const onSuccessApprove = (data) => {
    // refetchAllowance()
    // setFormData({type:'approvedToken',value:formatEther(data)})
  };

  /// Setup for approval.
  const {
    data: approvedData,
    write: approveToken,
    isLoading: isLoadingApprove,
    isSuccess: isApprovedSuccess,
  } = useContractWrite({
    /// PreSale token address
    // @ts-ignore
    address: formData?.token,
    abi: SimpleToken.abi,
    functionName: 'approve',
    args: [PRESALE_MANAGER_ADDRESS, balanceOf?.valueOf()],
    onError: onApproveError,
    onSuccess: onSuccessApprove,
  });

  const onSuccessWaitForTransaction = (data) => {
    // console.log("onSuccessWaitForTransaction",data)
  };

  // Waiting for tx to mine.
  const { isLoading: isLoadingWaitForTranasaction } = useWaitForTransaction({
    hash: approvedData?.hash,
    onSettled: onApproveReceipt,
    onSuccess: onSuccessWaitForTransaction,
  });

  /**
   * *:End of Approve Method
   */

  // async function approveToken() {
  //   const accounts = await web3.eth.getAccounts();
  //   const account = accounts[0];

  //   const tokenContract = new web3.eth.Contract(
  //     SimpleToken.abi,
  //     formData?.token
  //   );
  //   const approvalAmount =ethers.utils
  //   .parseUnits(String(formData.token_supply||0), 18)
  //   .toString(); // Total Supply

  //   // Check if the token has already been approved
  //   if (!isApproved && !approving) {
  //     try {
  //       _approving(true);

  //       // Customize the approval logic here
  //       await tokenContract.methods
  //         .approve(spenderAddress, approvalAmount)
  //         .send({
  //           from: account, // Set the address from which you want to send the approval transaction
  //         });
  //         next()
  //       // Update tokenInfo state or perform other actions after successful approval
  //     } catch (error) {
  //       // Handle errors during approval
  //     } finally {
  //       _approving(false);
  //     }
  //   }
  // }

  const handeChangeWithoutToken = ({ target }) => {
    if (target.checked) {
      setFormData({ type: 'sale_without_token', value: true });
      setFormData({ type: 'token_name', value: '$coin' });
      setFormData({ type: 'token_symbol', value: '$coin' });
      setFormData({ type: 'token_supply', value: 0 });
      setFormData({ type: 'token_decimal', value: 18 });
      next();
    } else {
      setFormData({ type: 'sale_without_token', value: false });
      setFormData({ type: 'token_name', value: '' });
      setFormData({ type: 'token_symbol', value: '' });
      setFormData({ type: 'token_supply', value: '' });
      setFormData({ type: 'token_decimal', value: '' });
    }
  };

  const hadleLauchAStealthSale = ({ target }) => {
    if (target.checked) {
      setFormData({ type: 'launch_stealth_sale', value: true });
      setFormData({ type: 'token_name', value: '$coin' });
      setFormData({ type: 'token_symbol', value: '$coin' });
      setFormData({ type: 'token_decimal', value: 18 });
      next();
    } else {
      setFormData({ type: 'launch_stealth_sale', value: false });
      setFormData({ type: 'token_name', value: '' });
      setFormData({ type: 'token_symbol', value: '' });
      setFormData({ type: 'token_decimal', value: '' });
    }
  };

  const validateAddress = (address) => {
    const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
    return addressRegex.test(address);
  };

  const supportedChainIds = config.SUPPORTED_NETWORKS.map(
    (network) => network.id
  );
  const supportedChainSymbols = config.SUPPORTED_NETWORKS.map(
    (network) => network.symbol
  );

  return (
    <div className='row'>
      <div className='col-md-12 mx-auto'>
        <div className='approve_token_section'>
          <h3 className='form-card-heading'>Approve Token</h3>
          <div className='approve_token_card'>
            {/* <label>Token Address</label> */}
            <div className='material-switch d-flex align-items-center'>
              <p className='mb-0 me-3 fs-57 fw-normal'>Launch a Stealth Sale</p>
              <input
                id='someSwitchOptionDefault'
                name='isMarketingFeeInPairToken'
                type='checkbox'
                disabled={isLoadingApprove}
                // onChange={formik.handleChange}
                // value={formik.values.isMarketingFeeInPairToken}
                // disabled={deploying}
                checked={formData?.launch_stealth_sale}
                onChange={(e) => {
                  // if (!isConnected) {
                  //   toast.error(ERRORMSGS.LOGINWITHYOURWALLET, { toastId: toastPleasedLoginId })
                  // }else if(chain?.id != bscTestnet.id && chain?.id != polygonMumbai.id){
                  //   toast.error(ERRORMSGS.WRONG_NETWORK,{toastId:'wrongNetworkTId'})
                  // }
                  // else {
                  hadleLauchAStealthSale(e);
                  // }
                }}
              />
              <label
                htmlFor='someSwitchOptionDefault'
                className='label-default'
              />

              <p className='mb-0 me-3 fs-57 fw-normal'>
                Launch a Sale without Token
              </p>
              <input
                id='someSwitchOptionDefault1'
                name='isMarketingFeeInPairToken1'
                type='checkbox'
                disabled={isLoadingApprove}
                //  onChange={formik.handleChange}
                //  value={formik.values.isMarketingFeeInPairToken}
                // onChange={formik.handleChange}
                // value={formik.values.isMarketingFeeInPairToken}
                // disabled={deploying}
                checked={formData?.sale_without_token}
                onChange={(e) => {
                  // if (!isConnected) {
                  //   toast.error(ERRORMSGS.LOGINWITHYOURWALLET, { toastId: toastPleasedLoginId })
                  // }else if(chain?.id != bscTestnet.id && chain?.id != polygonMumbai.id){
                  //   toast.error(ERRORMSGS.WRONG_NETWORK,{toastId:'wrongNetworkTId'})
                  // }
                  //  else {
                  handeChangeWithoutToken(e);
                  // }
                }}
              />
              <label
                htmlFor='someSwitchOptionDefault1'
                className='label-default'
              />
            </div>

            <input
              type='text'
              name='presalerate'
              placeholder='Token Address'
              className={`token_address_input mt-4 ${
                isErrorAllowance && formData?.token != ''
                  ? 'invalid'
                  : Number(balanceOfToken) == 0
                  ? 'invalid'
                  : ''
              }`}
              defaultValue={formData?.token}
              value={formData?.token.trim()}
              onChange={({ target }) => {
                if (!isConnected) {
                  toast.error(ERRORMSGS.LOGINWITHYOURWALLET, {
                    toastId: toastPleasedLoginId,
                  });
                } else if (!supportedChainIds.includes(chain?.id)) {
                  toast.error('Wrong Network!', { toastId: 'wrongNetworkTId' });
                } else {
                  setFormData({ type: 'token', value: target.value.trim() });
                }
              }}
              disabled={isLoadingApprove}
            />
            {(isLoadingPresale || isFetchingPresale) &&
              !isErrorAllowance &&
              validateAddress(formData?.token) && (
                <div className='mt-2'>
                  <SyncLoader color='#3498DB' size={10} />
                </div>
              )}
            <div
              style={{
                width: '100%',
                // height: '50px'
              }}
            >
              <span className='error-msg'>
                {isErrorAllowance && formData?.token != ''
                  ? 'Invalid token'
                  : Number(balanceOfToken) == 0
                  ? 'Not balance'
                  : ''}
              </span>
            </div>

            <div className='d-flex flex-wrap justify-content-between align-items-center'>
              {/* <div className='material-switch pull-right d-flex align-items-center'> */}
              {/* <p className="mb-0 me-3">Launch Stealth Sale</p>
                <input
                  id="someSwitchOptionDefault"
                  name="someSwitchOption001"
                  type="checkbox"
                /> */}
              {/* <label
                  htmlFor="someSwitchOptionDefault"
                  className="label-default"
                /> */}
              {/* </div> */}
              <button
                className='btn btn-outline-primary btn-lg text-white btn-with-icon'
                type='button'
                onClick={() => {
                  navigate(`${BASE_URL}create-token`);
                }}
                disabled={isLoadingApprove || isLoadingWaitForTranasaction}
              >
                <img
                  src='images/icon/create_icon.svg'
                  alt='Create'
                  className='img-fluid me-2 me-md-3'
                />
                Create Token
              </button>
            </div>

            {/* {(isLoadingAllowance && !errors) ? (
              <>
                <div className="ellipsis-container">
                  <div className="ellipsis-dot" />
                  <div className="ellipsis-dot" />
                  <div className="ellipsis-dot" />
                </div>
              </>
            ) : ( */}
            <div className='token_details_section_bottom'>
              <section
                hidden={
                  Number(isAllowanceData) != 0 || Number(balanceOfToken) == 0
                }
              >
                <hr />

                <label>Token Details</label>
                <ul className='text-white mt-1'>
                  <li className='d-flex flex-wrap align-items-center justify-content-between'>
                    Name<span>{preSaleToken?.name}</span>
                  </li>
                  <li className='d-flex flex-wrap align-items-center justify-content-between'>
                    Symbol<span>{preSaleToken?.symbol}</span>
                  </li>
                  <li className='d-flex flex-wrap align-items-center justify-content-between'>
                    Total Supply
                    <span>
                      {preSaleToken &&
                        ethers.utils
                          .formatUnits(
                            String(preSaleToken?.totalSupply?.value || 0),
                            Number(preSaleToken?.decimals || 0)
                          )
                          .toString()}
                    </span>
                  </li>
                </ul>
              </section>
              <section
                hidden={
                  isAllowanceData == undefined ||
                  isErrorAllowance ||
                  Number(balanceOfToken) == 0
                }
              >
                <hr className='hr-mt-lg border-thin' />
                {Number(isAllowanceData) <= 0 ? (
                  <button
                    className='btn btn-primary btn-lg min-w-btn d-flex justify-content-center ms-auto'
                    type='button'
                    onClick={() => {
                      approveToken();
                    }}
                    disabled={isLoadingApprove || isLoadingWaitForTranasaction}
                    style={{
                      width: '206px',
                    }}
                  >
                    {isLoadingApprove || isLoadingWaitForTranasaction
                      ? 'Approving...'
                      : 'Approve'}
                  </button>
                ) : (
                  <button
                    className='btn btn-primary btn-lg min-w-btn d-flex justify-content-center ms-auto'
                    type='button'
                    onClick={validateAndContinue}
                  >
                    Next
                  </button>
                )}
              </section>
            </div>
            {/* )} */}
          </div>
        </div>
      </div>
    </div>
  );
}

function PresaleInformation({ next, prev, setFormData, formData }) {
  const { chain, chains } = useNetwork();
  const ActiveChainSymbol = config.SUPPORTED_NETWORKS.find(
    (network) => network.id === chain?.id
  )?.symbol;
  const supportedChainSymbols = config.SUPPORTED_NETWORKS.map(
    (network) => network.symbol
  );

  useEffect(() => {
    if (pairTokens && formData?.fund_releasing_token != 'Custom') {
      setFormData({ type: 'buyToken', value: pairTokens[ActiveChainSymbol] });
      setFormData({ type: 'fund_releasing_token', value: ActiveChainSymbol });
    }
  }, [chain, formData?.launch_stealth_sale, formData?.sale_without_token]);

  useEffect(() => {
    setFormData({
      type: 'fund_releasing_token',
      value: supportedChainSymbols?.includes(ActiveChainSymbol)
        ? ActiveChainSymbol
        : 'Custom',
    });
  }, []);

  const routerAddress = routerAddresses[chain?.id]?.routerAddress;
  const pairTokens = routerAddresses[chain?.id]?.pairTokens;

  // console.log("pairTokens.WBNB",pairTokens.WBNB)

  // setFormData({"buyToken":pairTokens.WBNB})

  const [value, setValue] = React.useState(dayjs(new Date()));

  const [errors, setErrors] = useState(null);

  const [requiredToken, setRequiredToken] = useState(0);
  const [endTimeState, setEndtimeState] = useState(endTime);
  function handleDateChangeStart(dateString) {
    setFormData({
      type: 'start_date',
      value: moment(new Date(dateString)).unix(),
    });
  }
  function handleDateChangeEnd(dateString) {
    setEndtimeState(dateString);
    setFormData({
      type: 'end_date',
      value: moment(new Date(dateString)).unix(),
    });
  }

  function handleDateChangeEstDexList(dateString) {
    setFormData({
      type: 'estimated_dex_listing_date',
      value: moment(new Date(dateString)).unix(),
    });
  }

  const [customFunTokenName, _customFunTokenName] = useState('');
  const handleDecimal = async () => {
    let { fund_releasing_token, buyToken } = formData;
    try {
      if (supportedChainSymbols.includes(fund_releasing_token)) {
        setFormData({ type: 'dynamicDecimal', value: 18 });
        setFormData({
          type: 'custom_fund_raising_token_name',
          value: fund_releasing_token,
        });
        setFormData({
          type: 'custom_fund_raising_token_symbol',
          value: fund_releasing_token,
        });
        _customFunTokenName('BNB');
        return true;
      } else if (
        fund_releasing_token == 'BUSD' ||
        fund_releasing_token == 'USDT' ||
        fund_releasing_token == 'Custom'
      ) {
        const data = await readContract({
          address: buyToken,
          abi: SimpleToken.abi,
          functionName: 'decimals',
        });

        const dataName = await readContract({
          address: buyToken,
          abi: SimpleToken.abi,
          functionName: 'name',
        });

        const dataSymbol = await readContract({
          address: buyToken,
          abi: SimpleToken.abi,
          functionName: 'symbol',
        });

        _customFunTokenName(dataName);
        setFormData({ type: 'dynamicDecimal', value: data });
        setFormData({
          type: 'custom_fund_raising_token_name',
          value: dataName,
        });
        setFormData({
          type: 'custom_fund_raising_token_symbol',
          value: dataSymbol,
        });

        return true;
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleDecimal();
  }, [
    formData?.fund_releasing_token,
    formData?.custom_fund_raising_token,
    formData?.buyToken,
  ]);

  const [tokenErr, _tokenErr] = useState(false);
  const [tokenErrMsg, _tokenErrMsg] = useState('');
  const [selectedOption, setSelectedOption] = useState('Public');
  const [WhitelistTimerError, setWhitelistTimerError] = useState();
  const [WhitelistTimer, setWhitelistTimer] = useState('');

  // Formik

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pre_sale_amount: formData?.pre_sale_amount,
      dex_listing_rate: formData?.dex_listing_rate,
      dex_liquidity: formData?.dex_liquidity,
      liquidity_lookup: formData?.liquidity_lookup,
      soft_cap: formData?.soft_cap,
      hard_cap: formData?.hard_cap,
      minimum_buy: formData?.minimum_buy,
      maximum_buy: formData?.maximum_buy,
      presale_type: formData?.presale_type,
      whitelist_timer: formData?.whitelist_timer,
      router: routerAddress,
      buyWithETH: formData?.buyWithETH,
      set_sale_time: formData?.set_sale_time,
      anti_sniper_protection: formData?.anti_sniper_protection,
      bonus_sale: formData?.bonus_sale,
      add_team_token_vesting: formData?.add_team_token_vesting,
      add_presale_vesting: formData?.add_presale_vesting,
      min_bonus_buy_amount: formData?.min_bonus_buy_amount,
      bonus_received: formData?.bonus_received,
      bonus_spots_available: formData?.bonus_spots_available,
      token_team_vesting_tokens: formData?.token_team_vesting_tokens,
      first_token_release: formData?.first_token_release,
      first_token_release_after_listing:
        formData?.first_token_release_after_listing,
      team_token_released_each_cycle: formData?.team_token_released_each_cycle,
      vesting_period_each_cycle: formData?.vesting_period_each_cycle,
      first_token_release_presale: formData?.first_token_release_presale,
      cliff: formData?.cliff,
      toke_released_each_cycle_presale:
        formData?.toke_released_each_cycle_presale,
      vesting_period_each_cycle_presale:
        formData?.vesting_period_each_cycle_presale,
      stealth_wallet: formData?.stealth_wallet,
      launch_stealth_sale: formData?.launch_stealth_sale,
      isHardCap: formData?.isHardCap,
      isMaxBuy: formData?.isMaxBuy,
      isBurn: formData?.isBurn,
      max_contributors: formData?.max_contributors,
      // burnTokens:formData?.burnTokens
    },
    validationSchema: presaleFairLaunchInfoSchema,

    onSubmit: async (data) => {
      try {
        for (let key in data) {
          if (key != 'buyWithETH') {
            setFormData({ type: key, value: data[key] });
          }
        }
        stepTrack[1] = true;
        const isValid = await handleDecimal();
        if (isValid) {
          if (
            (formData?.sale_without_token || formData?.launch_stealth_sale) &&
            (!formData.sale_title || formData.sale_title.trim() === '')
          ) {
            toast.error('Oops, Stealth & TBA Launch should have a title!', {
              toastId: 'Opps_stealth',
            });
          } else if (
            formData?.launch_stealth_sale &&
            formData?.token_supply <= 0
          ) {
            toast.error('Total supply should be greater than 0!', {
              toastId: 'total_supply',
            });
          } else if (
            formData?.start_date < moment(new Date(dayjs())).unix() &&
            formData?.sale_without_token == false &&
            formik.values?.set_sale_time == true
          ) {
            toast.error('StartTime should be > CurrentTime', {
              toastId: 'start_time_>_current_time',
            });
          } else if (
            Number(formData?.end_date) < Number(formData?.start_date) &&
            formData?.sale_without_token == false &&
            formik.values?.set_sale_time == true
          ) {
            toast.error('endTime should be > startTime', {
              toastId: 'end_time_>_start_time',
            });
          } else if (
            Number(formData?.estimated_dex_listing_date) <
              moment(new Date(endTimeState.add('1', 'day'))).unix() &&
            formData?.sale_without_token == false &&
            formik.values?.set_sale_time == true
          ) {
            // next();
            toast.error('estimated dex listing Date >=  endTime + 24 hours', {
              toastId: 'estimated_dex_listing_>_=_24',
            });
          } else if (
            Number(formData?.approvedToken) < Number(requiredToken) &&
            formData?.launch_stealth_sale == false &&
            formData?.sale_without_token == false
          ) {
            toast.error(
              `${Number(requiredToken) - Number(formData?.approvedToken)} ${
                formData?.token_name
              } needed more to create a pool!`,
              { toastId: 'needMoreTId' }
            );
          } else if (
            formData?.launch_stealth_sale == true &&
            Number(formData?.token_supply < Number(requiredToken))
          ) {
            toast.error('Total supply should be more than required amount!', {
              toastId: 'tsmraTid',
            });
          } else if (selectedOption != 'Public') {
            if (WhitelistTimer == '') {
              setWhitelistTimerError('Whitelist timer is required');
            } else if (!tokenErr) {
              next();
            }
          } else if (!tokenErr) {
            // }else{
            next();
            setWhitelistTimerError('');
          }
        } else {
          toast.error('Oops, Invalid fund raise token address!', {
            toastId: 'invalid_fund',
          });
        }
      } catch (error) {
      } finally {
      }
    },
  });

  // Start Time & endTime

  useEffect(() => {
    let hard_cap = Number(formik.values.hard_cap);

    let dex_liquidity = Number(formik.values.dex_liquidity);
    let pre_sale_amount = Number(formik.values.pre_sale_amount);
    let dex_listing_rate = Number(formik.values.dex_listing_rate);

    let max_buy = Number(formik.values.maximum_buy);

    if (
      !formik.errors.dex_liquidity &&
      !formik.errors.pre_sale_amount &&
      formik.values.dex_listing_rate > 1 &&
      formik.values.dex_listing_rate < 100 &&
      !formik.errors.dex_listing_rate &&
      !formik.errors.bonus_received &&
      !formik.errors.token_team_vesting_tokens
    ) {
      let bonus_received = formik.values.bonus_sale
        ? Number(formik.values.bonus_received)
        : 0;

      let eligableInvestor = formik.values.bonus_sale
        ? Number(formik.values.bonus_spots_available)
        : 0;

      let vestingAmount = ethers.utils
        .parseUnits(
          String(formik.values.token_team_vesting_tokens || 0),
          Number(formData?.token_decimal || 0)
        )
        .toString();

      const presaleAmount = ethers.utils
        .parseUnits(
          String(pre_sale_amount || 0),
          Number(formData?.token_decimal || 0)
        )
        .toString();

      const requiredTokenAmount = () => {
        const _tokensForFairSale = BigInt(presaleAmount); //eslint-disable-line no-undef
        const _tokensForDex =
          (BigInt(presaleAmount) *
            BigInt(dex_listing_rate) *
            BigInt(dex_liquidity)) /
          BigInt(10000); //eslint-disable-line no-undef
        const _tokensForBonus =
          (BigInt(presaleAmount) * BigInt(bonus_received)) / BigInt(100); //eslint-disable-line no-undef
        const _requiredTokenAmount =
          BigInt(_tokensForFairSale) +
          BigInt(_tokensForDex) +
          BigInt(_tokensForBonus) +
          BigInt(vestingAmount); //eslint-disable-line no-undef

        const parsedRequiredToken = ethers.utils
          .formatUnits(
            String(_requiredTokenAmount),
            Number(formData?.token_decimal || 0)
          )
          .toString();

        setRequiredToken(parsedRequiredToken);
      };
      requiredTokenAmount();
    }
  }, [
    formik.values,
    // formik.values.hard_cap,
    // formik.values.dex_liquidity,
    // formik.values.pre_sale_amount,
    // formik.values.dex_listing_rate,
    // formik.values.maximum_buy,
    // formik.values.bonus_received,
    // formik.values.bonus_spots_available,
    // formik.values.token_team_vesting_tokens,
    // formik.values.bonus_sale
  ]);

  const handleChange = (e) => {
    setFormData({ type: 'buyToken', value: '' });
    setFormData({ type: 'fund_releasing_token', value: e?.target?.value });

    // formik.handleChange(e);
    if (supportedChainSymbols.includes(e.target.value)) {
      setFormData({ type: 'buyWithETH', value: true });
      setFormData({ type: 'buyToken', value: pairTokens[e.target.value] });
    } else if (e.target.value == 'BUSD' || e.target.value == 'USDT') {
      setFormData({ type: 'buyWithETH', value: false });
      setFormData({ type: 'buyToken', value: pairTokens[[e.target.value]] });
    } else {
      setFormData({ type: 'buyWithETH', value: false });
      setFormData({
        type: 'buyToken',
        value: formData?.custom_fund_raising_token,
      });
    }
  };

  const handleChangeCustomRaising = ({ target }) => {
    if (formData.fund_releasing_token == 'Custom') {
      setFormData({
        type: 'buyToken',
        value: target.value,
      });

      setFormData({
        type: 'custom_fund_raising_token',
        value: target.value,
      });
      handleDecimal();
    }
  };

  const popperSx = {
    '& .MuiPaper-root': {
      border: '1px solid black',
      padding: 2,
      marginTop: 1,
      backgroundColor: 'rgba(120, 120, 120, 0.2)',
    },
    '& .MuiCalendarPicker-root': {
      backgroundColor: 'rgba(45, 85, 255, 0.4)',
    },
    '& .PrivatePickersSlideTransition-root': {},
    '& .MuiPickersDay-dayWithMargin': {
      color: 'rgb(229,228,226)',
      backgroundColor: 'rgba(50, 136, 153)',
    },
    '& .MuiTabs-root': { backgroundColor: 'rgba(120, 120, 120, 0.4)' },
  };

  //     useEffect(()=>{
  //       setFormData({
  //     type: "start_date",
  //     value: moment(new Date(startTime)).unix(),
  //   });
  //   setFormData({
  //     type: "end_date",
  //     value: moment(new Date(endTime)).unix(),
  //   });
  // },[])

  const handleChangeSaleTitle = ({ target }) => {
    setFormData({ type: 'sale_title', value: target.value });
  };

  const handleChangeTotalSupply = ({ target }) => {
    // setFormData({ type: "token_supply", value: target.value });
    const inputValue = target.value;
    const isValidNumber = /^\d{0,59}$/.test(inputValue);
    if (inputValue <= 0) {
      _tokenErr(true);
      _tokenErrMsg('Total supply should be greater than 0!');
      setFormData({ type: 'token_supply', value: target.value });
    } else if (isValidNumber) {
      setFormData({ type: 'token_supply', value: target.value });
      _tokenErrMsg('');
      _tokenErr(false);
    } else {
      // toast.error("Total supply should be less than or equal to 1e59")
      _tokenErr(true);
      _tokenErrMsg('Total supply should be less than or equal to 1e59');
      setFormData({ type: 'token_supply', value: target.value });
    }
  };

  const handleChangeBurnTokens = ({ target }) => {
    setFormData({ type: 'burnTokens', value: target.value });
  };

  const {
    data: customTokenInfo,
    isSuccess: isTokenFetched,
    isError: isErrorToken,
    isLoading: isLoadingToken,
    isFetching: isFetchingToken,
  } = useToken({
    address: formData?.buyToken,
  });

  return (
    <>
      <div className='tab'>
        <h4 className='form-card-heading'>Presale Information</h4>
        <div className='approve_token_card text-start'>
          <div className='row g-3 row-cols-1 row-cols-sm-2'>
            {/* Sale Title */}

            {(formData.sale_without_token == true ||
              formData?.launch_stealth_sale == true) && (
              <div
                className='col'
                // hidden={formData.sale_without_token != true && formData?.launch_stealth_sale != true}
              >
                <label className='form-label'>Sale Title</label>

                <input
                  type='text'
                  name='sale_title '
                  placeholder
                  onChange={handleChangeSaleTitle}
                  value={formData.sale_title}
                />
              </div>
            )}

            {formData?.launch_stealth_sale != true ? (
              <></>
            ) : (
              <>
                <div className='col'>
                  <label className='form-label'>Total Supply</label>
                  <input
                    type='number'
                    name='token_supply'
                    className={tokenErrMsg && 'invalid'}
                    onKeyDown={blockInvalidChar}
                    placeholder
                    onChange={(e) => {
                      handleChangeTotalSupply(e);
                    }}
                    onWheel={(e) => e.target.blur()}
                    value={formData.token_supply}
                  />
                  {tokenErr && (
                    <>
                      <span className='error-msg'>{tokenErrMsg}</span>
                    </>
                  )}
                </div>
              </>
            )}

            {formData?.sale_without_token && <div className='col'></div>}

            <div className='col'>
              <label className='form-label'>Fund Raising Token</label>

              <select
                className='form-select whitelist-form-select'
                name='fund_releasing_token'
                onChange={handleChange}
                value={formData.fund_releasing_token}
              >
                {pairTokens &&
                  Object.keys(pairTokens)?.map((tokenKey) => (
                    <option>{tokenKey}</option>
                  ))}

                {/* <option>BNB</option>
                <option>BUSD</option> */}
                <option value={'Custom'}>Custom</option>
              </select>
            </div>
            <div
              className={`col`}
              hidden={formData.fund_releasing_token != 'Custom'}
            >
              <label className='form-label'>Custom Fund Raising Token</label>

              <input
                type='text'
                name='custom_fund_raising_token'
                placeholder
                onChange={handleChangeCustomRaising}
                value={formData.custom_fund_raising_token}
              />
            </div>

            {/* Custom Fund Raising Token  */}
            {(isLoadingToken || isFetchingToken) &&
              validateAddress(formData?.buyToken) && (
                <div className='row w-100 m-0 p-0'>
                  <SyncLoader color='#3498DB' size={10} />
                </div>
              )}

            {formData.fund_releasing_token === 'Custom' &&
              customTokenInfo?.name &&
              customTokenInfo?.symbol && (
                <div className='row w-100 m-0 p-0'>
                  <div className='col'>
                    <p className='text-white fw-semibold mb-0'>
                      Name: {customTokenInfo.name}
                    </p>
                  </div>
                  <div className='col'>
                    <p className='text-white fw-semibold mb-0'>
                      Symbol: {customTokenInfo.symbol}
                    </p>
                  </div>
                </div>
              )}

            <div className='col'>
              <label className='form-label'>Presale Amount</label>

              <input
                type='number'
                name='pre_sale_amount'
                onKeyDown={blockInvalidChar}
                placeholder
                onWheel={(e) => e.target.blur()}
                // onChange={formik.handleChange}
                // onChange={(e) => {
                //   const inputValue = e.target.value;
                //   const numericValue = inputValue.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except dot (.)
                //   if (e.target.value.length <= formData?.token_decimal+2) {
                //     formik.handleChange({
                //       target: {
                //         name: "pre_sale_amount",
                //         value: numericValue,
                //       },
                //     });
                //   }
                // }}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const decimalPart = inputValue.split('.')[1];
                  let limitedNumericValue = inputValue;
                  if (
                    decimalPart &&
                    decimalPart.length > formData?.token_decimal
                  ) {
                    limitedNumericValue =
                      inputValue.split('.')[0] +
                      '.' +
                      decimalPart.slice(0, formData?.token_decimal);
                  }
                  formik.handleChange({
                    target: {
                      name: 'pre_sale_amount',
                      value: limitedNumericValue,
                    },
                  });
                }}
                value={formik.values.pre_sale_amount}
                className={
                  formik.errors.pre_sale_amount &&
                  formik.touched.pre_sale_amount &&
                  'invalid'
                }
              />
              {formik.errors.pre_sale_amount &&
              formik.touched.pre_sale_amount ? (
                <span className='error-msg'>
                  {formik.errors.pre_sale_amount}
                </span>
              ) : null}
              <p className='text-success-diff-c text-bold'>
                Total amount of tokens for hyper launch
              </p>
            </div>
            <div className='col'>
              <label className='form-label'>
                Dex listing Rate / Presale Rate (%)
              </label>
              <input
                type='number'
                name='dex_listing_rate'
                onKeyDown={blockInvalidChar}
                placeholder
                // onChange={formik.handleChange}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters except dot (.)
                  formik.handleChange({
                    target: {
                      name: 'dex_listing_rate',
                      value: numericValue,
                    },
                  });
                }}
                value={formik.values.dex_listing_rate}
                className={
                  formik.errors.dex_listing_rate &&
                  formik.touched.dex_listing_rate &&
                  'invalid'
                }
              />
              {formik.errors.dex_listing_rate &&
              formik.touched.dex_listing_rate ? (
                <span className='error-msg'>
                  {formik.errors.dex_listing_rate}
                </span>
              ) : null}
              <p className='text-success-diff-c text-bold'>
                Ex; 100 = sale rate and dex listing is the same price, 50 = sale
                rate is 2x better than dex listing price
              </p>
            </div>
            <div className='col'>
              <label className='form-label'>Dex Liquidity(%)</label>
              <input
                type='number'
                name='dex_liquidity'
                onKeyDown={blockInvalidChar}
                placeholder
                onWheel={(e) => e.target.blur()}
                // onChange={formik.handleChange}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters except dot (.)
                  formik.handleChange({
                    target: {
                      name: 'dex_liquidity',
                      value: numericValue,
                    },
                  });
                }}
                value={formik.values.dex_liquidity}
                className={
                  formik.errors.dex_liquidity &&
                  formik.touched.dex_liquidity &&
                  'invalid'
                }
              />

              {formik.errors.dex_liquidity && formik.touched.dex_liquidity ? (
                <span className='error-msg'>
                  {formik.errors.dex_liquidity}
                </span>
              ) : null}
            </div>
            <div className='col'>
              <label className='form-label'>Liquidity Lockup (days)</label>
              <input
                type='number'
                onKeyDown={blockInvalidChar}
                name='liquidity_lookup'
                placeholder
                onChange={formik.handleChange}
                onWheel={(e) => e.target.blur()}
                value={formik.values.liquidity_lookup}
                className={
                  formik.errors.liquidity_lookup &&
                  formik.touched.liquidity_lookup &&
                  'invalid'
                }
              />

              {formik.errors.liquidity_lookup &&
              formik.touched.liquidity_lookup ? (
                <span className='error-msg'>
                  {formik.errors.liquidity_lookup}
                </span>
              ) : null}
            </div>
            <div className='col'>
              <label className='form-label'>
                Select what DEX will be used to add liquidity.
              </label>
              <select
                className='form-select whitelist-form-select'
                name='router'
                onChange={formik.handleChange}
                value={formik.values.router}
              >
                <option>PancakeSwap</option>
              </select>

              {formik.errors.router && formik.touched.router ? (
                <span className='error-msg'>{formik.errors.router}</span>
              ) : null}
            </div>
            <div className='col'>
              <label className='form-label'>
                Soft Cap (
                {formData?.fund_releasing_token == 'Custom'
                  ? formData?.custom_fund_raising_token_symbol
                  : formData?.fund_releasing_token}
                )
              </label>
              <input
                type='text'
                name='soft_cap'
                placeholder
                // onChange={formik.handleChange}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except dot (.)
                  formik.handleChange({
                    target: {
                      name: 'soft_cap',
                      value: numericValue,
                    },
                  });
                }}
                value={formik.values.soft_cap}
                className={
                  formik.errors.soft_cap && formik.touched.soft_cap && 'invalid'
                }
              />

              {formik.errors.soft_cap && formik.touched.soft_cap ? (
                <span className='error-msg'>{formik.errors.soft_cap}</span>
              ) : null}
            </div>
            <div className='col'>
              <div className='col-md-12 mb-2 material-switch pull-left d-flex align-items-center'>
                <p className='mb-0 me-3'>Add Hard Cap Limit</p>
                <input
                  id='isHardCap'
                  name='isHardCap'
                  type='checkbox'
                  checked={formik.values.isHardCap}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      formik.setFieldValue('hard_cap', 0);
                      formik.validateForm();
                    }
                    formik.handleChange(e);
                  }}
                  value={formik.values.isHardCap}
                />

                <label htmlFor='isHardCap' className='label-default' />
              </div>
              {/* <br/> */}
            </div>
            {formik.values.isHardCap && (
              <div className='col'>
                <label className='form-label'>
                  Hard Cap (
                  {formData?.fund_releasing_token == 'Custom'
                    ? formData?.custom_fund_raising_token_symbol
                    : formData?.fund_releasing_token}
                  )
                </label>
                <input
                  type='text'
                  name='hard_cap'
                  placeholder
                  // onChange={(e) => {
                  //   formik.handleChange(e);
                  //   setFormData({ type: "hard_cap", value: e.target.value });
                  // }}
                  // onChange={formik.handleChange}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const numericValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except dot (.)
                    formik.handleChange({
                      target: {
                        name: 'hard_cap',
                        value: numericValue,
                      },
                    });
                  }}
                  value={formik.values.hard_cap}
                  className={
                    formik.errors.hard_cap &&
                    formik.touched.hard_cap &&
                    'invalid'
                  }
                />

                {formik.errors.hard_cap && formik.touched.hard_cap ? (
                  <span className='error-msg'>{formik.errors.hard_cap}</span>
                ) : null}
              </div>
            )}

            <div className='col'>
              <label className='form-label'>Max Contrubutors </label>
              <input
                type='text'
                name='max_contributors'
                placeholder
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except dot (.)
                  formik.handleChange({
                    target: {
                      name: 'max_contributors',
                      value: numericValue,
                    },
                  });
                }}
                value={formik.values.max_contributors}
                className={
                  formik.errors.max_contributors &&
                  formik.touched.max_contributors &&
                  'invalid'
                }
              />

              {formik.errors.max_contributors &&
              formik.touched.max_contributors ? (
                <span className='error-msg'>
                  {formik.errors.max_contributors}
                </span>
              ) : null}
            </div>

            <div className='col'>
              <label className='form-label'>
                Minimum Buy (
                {formData?.fund_releasing_token == 'Custom'
                  ? formData?.custom_fund_raising_token_symbol
                  : formData?.fund_releasing_token}
                )
              </label>
              <input
                type='text'
                name='minimum_buy'
                placeholder
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except dot (.)
                  formik.handleChange({
                    target: {
                      name: 'minimum_buy',
                      value: numericValue,
                    },
                  });
                }}
                value={formik.values.minimum_buy}
                className={
                  formik.errors.minimum_buy &&
                  formik.touched.minimum_buy &&
                  'invalid'
                }
              />

              {formik.errors.minimum_buy && formik.touched.minimum_buy ? (
                <span className='error-msg'>{formik.errors.minimum_buy}</span>
              ) : null}
            </div>
            <div className='col'>
              <div className='col-md-12 mb-2 material-switch pull-left d-flex align-items-center'>
                <p className='mb-0 me-3'>Add maximum buy limit</p>
                <input
                  id='isMaxBuy'
                  name='isMaxBuy'
                  type='checkbox'
                  checked={formik.values.isMaxBuy}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      formik.setFieldValue('maximum_buy', 0);
                    }
                    formik.handleChange(e);
                  }}
                  value={formik.values.isMaxBuy}
                />

                <label htmlFor='isMaxBuy' className='label-default' />
              </div>
              <br />
            </div>

            {formik.values.isMaxBuy && (
              <div className='col'>
                <label className='form-label'>
                  Maximum Buy (
                  {formData?.fund_releasing_token == 'Custom'
                    ? formData?.custom_fund_raising_token_symbol
                    : formData?.fund_releasing_token}
                  )
                </label>

                <input
                  type='text'
                  name='maximum_buy'
                  placeholder
                  // onChange={formik.handleChange}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const numericValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except dot (.)
                    formik.handleChange({
                      target: {
                        name: 'maximum_buy',
                        value: numericValue,
                      },
                    });
                  }}
                  value={formik.values.maximum_buy}
                  className={
                    formik.errors.maximum_buy &&
                    formik.touched.maximum_buy &&
                    'invalid'
                  }
                />

                {formik.errors.maximum_buy && formik.touched.maximum_buy ? (
                  <span className='error-msg'>
                    {formik.errors.maximum_buy}
                  </span>
                ) : null}
              </div>
            )}

            {/* <div className="col">
              <label className="form-label">Select what happens to Unsold Tokens</label>

              <select
                className="form-select whitelist-form-select mb-4"
                name="burnTokens"
                onChange={handleChangeBurnTokens}
                // value={formik.values.burnTokens}
                value={formik.values.burnTokens}
              >
                <option value={false}>Refund</option>
                <option value={true}>Burn</option>
              </select>


            </div> */}
          </div>
          {/* <hr /> */}
          <div
            className='row row-cols-1 row-cols-sm-2 mt-4'
            hidden={formData?.sale_without_token}
          >
            <div className='col' hidden={formData?.launch_stealth_sale}>
              {/* <p className="mb-0 me-3">Marketing fee in BNB instead of token</p> */}
              <div className='material-switch d-flex align-items-center'>
                <p className='mb-0 me-3'>
                  Set Sale Time (Uncheck to add it anytime after Sale Listing)
                </p>
                <input
                  id='someSwitchOption_set_sale_time'
                  name='set_sale_time'
                  type='checkbox'
                  onChange={formik.handleChange}
                  checked={formik.values.set_sale_time}
                  value={formik.values.set_sale_time}
                  // disabled={deploying}
                />
                <label
                  htmlFor='someSwitchOption_set_sale_time'
                  className='label-default'
                />
              </div>
            </div>
            {formik.values.set_sale_time && (
              <>
                <div className='col'>
                  <label className='form-label'>Start Date (IST)</label>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={[
                        'MobileDateTimePicker',
                        'MobileDateTimePicker',
                      ]}
                      PopperProps={{
                        sx: popperSx,
                      }}
                    >
                      <MobileDateTimePicker
                        className='form-control'
                        // label={'Start Date (UTC)'}
                        openTo='hours'
                        name='dexlistiongrate'
                        onChange={handleDateChangeStart}
                        defaultValue={startTime}
                        PopperProps={{
                          sx: popperSx,
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>

                <div className='col'>
                  <label className='form-label'>End Date (IST)</label>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={[
                        'MobileDateTimePicker',
                        'MobileDateTimePicker',
                      ]}
                    >
                      <MobileDateTimePicker
                        className='form-control'
                        // label={'End Date (UTC)'}
                        openTo='hours'
                        name='dexlistiongrate'
                        onChange={handleDateChangeEnd}
                        defaultValue={endTime}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>

                <div className='col mt-4'>
                  <label className='form-label'>
                    Estimated Dex Listing Date (Local)
                  </label>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={[
                        'MobileDateTimePicker',
                        'MobileDateTimePicker',
                      ]}
                    >
                      <MobileDateTimePicker
                        className='form-control'
                        // label={'Estimated Dex Listing Date (Local)'}
                        openTo='hours'
                        name='dexlistiongrate'
                        defaultValue={estimatedDexListingTime}
                        onChange={handleDateChangeEstDexList}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </>
            )}
          </div>

          {/* <hr hidden={formData?.sale_without_token} /> */}
          <div className='row row-cols-1 row-cols-sm-2 mt-4'>
            <div className='col mb-4'>
              <label className='form-label'>Presale Type</label>
              <select
                className='form-select whitelist-form-select'
                name='presale_type'
                onChange={(e) => {
                  formik.handleChange(e);
                  setSelectedOption(e.target.value);
                }}
                value={formik.values.presale_type}
              >
                {/* <option>Tiered Whitelist</option> */}
                <option>Whitelist</option>
                <option>Public</option>
              </select>
            </div>
            {formik.values.presale_type != 'Public' && (
              <div className='col'>
                <label className='form-label'>Whitelist Timer</label>
                <input
                  type='number'
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={blockInvalidChar}
                  name='whitelist_timer'
                  placeholder
                  onChange={(e) => {
                    formik.handleChange(e);
                    setWhitelistTimer(e.target.value);
                    setWhitelistTimerError('');
                  }}
                  value={formik.values.whitelist_timer}
                />

                {(formik.errors.whitelist_timer &&
                  formik.touched.whitelist_timer) ||
                WhitelistTimerError ? (
                  <span className='error-msg'>
                    {WhitelistTimerError}
                    {formik.errors.whitelist_timer}
                  </span>
                ) : null}
              </div>
            )}

            <div className='col' hidden={formData?.launch_stealth_sale != true}>
              <label className='form-label'>Stealth Wallet</label>

              <input
                type='text'
                name='stealth_wallet'
                placeholder
                onChange={formik.handleChange}
                value={formik.values.stealth_wallet}
                className={
                  formik.errors.stealth_wallet &&
                  formik.touched.stealth_wallet &&
                  'invalid'
                }
              />

              {formik.errors.stealth_wallet && formik.touched.stealth_wallet ? (
                <span className='error-msg'>
                  {formik.errors.stealth_wallet}
                </span>
              ) : null}
            </div>

            <div className='col presale_optional_features mt-4'>
              <label className='form-label text-white w-100 fs-57 fw-medium'>
                Optional Features
              </label>
              {/* <div className="material-switch pull-left d-flex align-items-center">
              <p className="mb-0 me-3">Anti Sniper Protection</p>
              <input
                id="AddTeamTokenVesting"
                name="anti_sniper_protection"
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.anti_sniper_protection}
              />
              <label htmlFor="AddTeamTokenVesting" className="label-default" />
            </div> */}
              <div className='col-md-12 mb-2 material-switch pull-left d-flex align-items-center'>
                <p className='mb-0 me-3'>Bonus Sale</p>

                <input
                  id='bonus_sale'
                  name='bonus_sale'
                  type='checkbox'
                  checked={formik.values.bonus_sale}
                  onChange={(e) => {
                    formik.handleChange(e);
                    if (!e.target.checked) {
                      formik.setFieldValue('min_bonus_buy_amount', 0);
                      formik.setFieldValue('bonus_received', 0);
                      formik.setFieldValue('bonus_spots_available', 0);
                    }
                  }}
                  value={formik.values.bonus_sale}
                />

                <label htmlFor='bonus_sale' className='label-default' />
              </div>
              <br />

              {/* Bonus sale */}

              {formik.values.bonus_sale && (
                <>
                  <div className='col mt-4'>
                    <label className='form-label'>Min Bonus Buy Amount</label>

                    <input
                      type='number'
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={blockInvalidChar}
                      name='min_bonus_buy_amount'
                      placeholder
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except dot (.)
                        formik.handleChange({
                          target: {
                            name: 'min_bonus_buy_amount',
                            value: numericValue,
                          },
                        });
                      }}
                      value={formik.values.min_bonus_buy_amount}
                      className={
                        formik.errors.min_bonus_buy_amount &&
                        formik.touched.min_bonus_buy_amount &&
                        'invalid'
                      }
                    />

                    {formik.errors.min_bonus_buy_amount &&
                    formik.touched.min_bonus_buy_amount ? (
                      <span className='error-msg'>
                        {formik.errors.min_bonus_buy_amount}
                      </span>
                    ) : null}
                  </div>

                  <div className='col mt-4'>
                    <label className='form-label'>Bonus Received(%)</label>

                    <input
                      type='number'
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={blockInvalidChar}
                      name='bonus_received'
                      placeholder
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/[^0-9]/g, '');
                        formik.handleChange({
                          target: {
                            name: 'bonus_received',
                            value: numericValue,
                          },
                        });
                      }}
                      value={formik.values.bonus_received}
                      className={
                        formik.errors.bonus_received &&
                        formik.touched.bonus_received &&
                        'invalid'
                      }
                    />
                  </div>

                  {formik.errors.bonus_received &&
                  formik.touched.bonus_received ? (
                    <span className='error-msg'>
                      {formik.errors.bonus_received}
                    </span>
                  ) : null}

                  <div className='col mt-4'>
                    <label className='form-label'>Bonus Spots Available</label>

                    <input
                      type='number'
                      onWheel={(e) => e.target.blur()}
                      name='bonus_spots_available'
                      onKeyDown={blockInvalidChar}
                      placeholder
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except dot (.)
                        formik.handleChange({
                          target: {
                            name: 'bonus_spots_available',
                            value: numericValue,
                          },
                        });
                      }}
                      value={formik.values.bonus_spots_available}
                      className={
                        formik.errors.bonus_spots_available &&
                        formik.touched.bonus_spots_available &&
                        'invalid'
                      }
                    />
                  </div>

                  {formik.errors.bonus_spots_available &&
                  formik.touched.bonus_spots_available ? (
                    <span className='error-msg'>
                      {formik.errors.bonus_spots_available}
                    </span>
                  ) : null}

                  <div className='col my-4'>
                    <label className='form-label'>
                      Select what happens to Unsold Tokens
                    </label>
                    <select
                      className='form-select whitelist-form-select'
                      name='isBurn'
                      onChange={formik.handleChange}
                      value={formik.values.isBurn}
                    >
                      {/* <option>Tiered Whitelist</option> */}
                      <option value={false}>Refund</option>
                      <option value={true}>Burn</option>
                    </select>
                  </div>
                </>
              )}

              {/* End bonus sale */}

              <div className='col-md-12 mb-2 material-switch pull-left d-flex align-items-center'>
                <p className='mb-0 me-3'>Add Team Token Vesting</p>

                <input
                  id='add_team_token_vesting'
                  name='add_team_token_vesting'
                  type='checkbox'
                  onChange={(e) => {
                    formik.handleChange(e);
                    if (!e.target.checked) {
                      formik.setFieldValue('token_team_vesting_tokens', 0);
                      formik.setFieldValue('first_token_release', 0);
                      formik.setFieldValue(
                        'first_token_release_after_listing',
                        0
                      );
                      formik.setFieldValue('first_token_release', 0);
                      formik.setFieldValue('team_token_released_each_cycle', 0);
                      formik.setFieldValue('vesting_period_each_cycle', 0);
                    }
                  }}
                  value={formik.values.add_team_token_vesting}
                  checked={formik.values.add_team_token_vesting}
                />

                <label
                  htmlFor='add_team_token_vesting'
                  className='label-default'
                />
              </div>

              {/* Add Team Token Vesting */}
              {formik.values.add_team_token_vesting && (
                <>
                  <div className='col mt-4'>
                    <label className='form-label'>
                      Total team vesting tokens
                    </label>

                    <input
                      type='number'
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={blockInvalidChar}
                      name='token_team_vesting_tokens'
                      placeholder
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except dot (.)
                        formik.handleChange({
                          target: {
                            name: 'token_team_vesting_tokens',
                            value: numericValue,
                          },
                        });
                      }}
                      value={formik.values.token_team_vesting_tokens}
                      className={
                        formik.errors.token_team_vesting_tokens &&
                        formik.touched.token_team_vesting_tokens &&
                        'invalid'
                      }
                    />

                    {formik.errors.token_team_vesting_tokens &&
                    formik.touched.token_team_vesting_tokens ? (
                      <span className='error-msg'>
                        {formik.errors.token_team_vesting_tokens}
                      </span>
                    ) : null}
                  </div>

                  <div className='col mt-4'>
                    <label className='form-label'>
                      First token release (percent)
                    </label>

                    <input
                      type='number'
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={blockInvalidChar}
                      name='first_token_release'
                      placeholder
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except dot (.)
                        formik.handleChange({
                          target: {
                            name: 'first_token_release',
                            value: numericValue,
                          },
                        });
                      }}
                      value={formik.values.first_token_release}
                      className={
                        formik.errors.first_token_release &&
                        formik.touched.first_token_release &&
                        'invalid'
                      }
                    />
                  </div>

                  {formik.errors.first_token_release &&
                  formik.touched.first_token_release ? (
                    <span className='error-msg'>
                      {formik.errors.first_token_release}
                    </span>
                  ) : null}

                  <div className='col mt-4'>
                    <label className='form-label'>
                      First token release after listing(days)
                    </label>

                    <input
                      type='number'
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={blockInvalidChar}
                      name='first_token_release_after_listing'
                      placeholder
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except dot (.)
                        formik.handleChange({
                          target: {
                            name: 'first_token_release_after_listing',
                            value: numericValue,
                          },
                        });
                      }}
                      value={formik.values.first_token_release_after_listing}
                      className={
                        formik.errors.first_token_release_after_listing &&
                        formik.touched.first_token_release_after_listing &&
                        'invalid'
                      }
                    />

                    {formik.errors.first_token_release_after_listing &&
                    formik.touched.first_token_release_after_listing ? (
                      <span className='error-msg'>
                        {formik.errors.first_token_release_after_listing}
                      </span>
                    ) : null}
                  </div>

                  <div className='col mt-4'>
                    <label className='form-label'>
                      Team token release each cycle(percent)
                    </label>

                    <input
                      type='number'
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={blockInvalidChar}
                      name='team_token_released_each_cycle'
                      placeholder
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except dot (.)
                        formik.handleChange({
                          target: {
                            name: 'team_token_released_each_cycle',
                            value: numericValue,
                          },
                        });
                      }}
                      value={formik.values.team_token_released_each_cycle}
                      className={
                        formik.errors.team_token_released_each_cycle &&
                        formik.touched.team_token_released_each_cycle &&
                        'invalid'
                      }
                    />

                    {formik.errors.team_token_released_each_cycle &&
                    formik.touched.team_token_released_each_cycle ? (
                      <span className='error-msg'>
                        {formik.errors.team_token_released_each_cycle}
                      </span>
                    ) : null}
                  </div>

                  <div className='col mt-4 mb-4'>
                    <label className='form-label'>
                      Vesting period each cycle (days)
                    </label>

                    <input
                      type='number'
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={blockInvalidChar}
                      name='vesting_period_each_cycle'
                      placeholder
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except dot (.)
                        formik.handleChange({
                          target: {
                            name: 'vesting_period_each_cycle',
                            value: numericValue,
                          },
                        });
                      }}
                      value={formik.values.vesting_period_each_cycle}
                      className={
                        formik.errors.vesting_period_each_cycle &&
                        formik.touched.vesting_period_each_cycle &&
                        'invalid'
                      }
                    />

                    {formik.errors.vesting_period_each_cycle &&
                    formik.touched.vesting_period_each_cycle ? (
                      <span className='error-msg'>
                        {formik.errors.vesting_period_each_cycle}
                      </span>
                    ) : null}
                  </div>
                </>
              )}
              {/*End Add Team Token Vesting */}

              <div className='col-md-12 mb-2 material-switch pull-left d-flex align-items-center'>
                <p className='mb-0 me-3'>Add Presale Vesting</p>

                <input
                  id='add_presale_vesting'
                  name='add_presale_vesting'
                  type='checkbox'
                  onChange={(e) => {
                    formik.handleChange(e);
                    if (!e.target.checked) {
                      formik.setFieldValue('first_token_release_presale', 0);
                      formik.setFieldValue('cliff', 0);
                      formik.setFieldValue(
                        'toke_released_each_cycle_presale',
                        0
                      );
                      formik.setFieldValue(
                        'vesting_period_each_cycle_presale',
                        0
                      );
                    }
                  }}
                  checked={formik.values.add_presale_vesting}
                  value={formik.values.add_presale_vesting}
                />

                <label
                  htmlFor='add_presale_vesting'
                  className='label-default'
                />
              </div>
              {/* Add Presale Vesting */}
              {formik.values.add_presale_vesting && (
                <>
                  <div className='col mt-4'>
                    <label className='form-label'>
                      First token release (percent)
                    </label>

                    <input
                      type='number'
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={blockInvalidChar}
                      name='first_token_release_presale'
                      placeholder
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except dot (.)
                        formik.handleChange({
                          target: {
                            name: 'first_token_release_presale',
                            value: numericValue,
                          },
                        });
                      }}
                      value={formik.values.first_token_release_presale}
                      className={
                        formik.errors.first_token_release_presale &&
                        formik.touched.first_token_release_presale &&
                        'invalid'
                      }
                    />

                    {formik.errors.first_token_release_presale &&
                    formik.touched.first_token_release_presale ? (
                      <span className='error-msg'>
                        {formik.errors.first_token_release_presale}
                      </span>
                    ) : null}
                  </div>

                  <div className='col mt-4'>
                    <label className='form-label'>Cliff(days)</label>

                    <input
                      type='number'
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={blockInvalidChar}
                      name='cliff'
                      placeholder
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except dot (.)
                        formik.handleChange({
                          target: {
                            name: 'cliff',
                            value: numericValue,
                          },
                        });
                      }}
                      value={formik.values.cliff}
                      className={
                        formik.errors.cliff && formik.touched.cliff && 'invalid'
                      }
                    />

                    {formik.errors.cliff && formik.touched.cliff ? (
                      <span className='error-msg'>{formik.errors.cliff}</span>
                    ) : null}
                  </div>

                  <div className='col mt-4'>
                    <label className='form-label'>
                      Token release each cycle (percent)
                    </label>

                    <input
                      type='number'
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={blockInvalidChar}
                      name='toke_released_each_cycle_presale'
                      placeholder
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except dot (.)
                        formik.handleChange({
                          target: {
                            name: 'toke_released_each_cycle_presale',
                            value: numericValue,
                          },
                        });
                      }}
                      value={formik.values.toke_released_each_cycle_presale}
                      className={
                        formik.errors.toke_released_each_cycle_presale &&
                        formik.touched.toke_released_each_cycle_presale &&
                        'invalid'
                      }
                    />

                    {formik.errors.toke_released_each_cycle_presale &&
                    formik.touched.toke_released_each_cycle_presale ? (
                      <span className='error-msg'>
                        {formik.errors.toke_released_each_cycle_presale}
                      </span>
                    ) : null}
                  </div>

                  <div className='col mt-4'>
                    <label className='form-label'>
                      Vesting period each cycle (days)
                    </label>

                    <input
                      type='number'
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={blockInvalidChar}
                      name='vesting_period_each_cycle_presale'
                      placeholder
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except dot (.)
                        formik.handleChange({
                          target: {
                            name: 'vesting_period_each_cycle_presale',
                            value: numericValue,
                          },
                        });
                      }}
                      value={formik.values.vesting_period_each_cycle_presale}
                      className={
                        formik.errors.vesting_period_each_cycle_presale &&
                        formik.touched.vesting_period_each_cycle_presale &&
                        'invalid'
                      }
                    />

                    {formik.errors.vesting_period_each_cycle_presale &&
                    formik.touched.vesting_period_each_cycle_presale ? (
                      <span className='error-msg'>
                        {formik.errors.vesting_period_each_cycle_presale}
                      </span>
                    ) : null}
                  </div>
                </>
              )}
              {/*End Add Presale Vesting */}
            </div>
          </div>
          <hr />
          {Number(formData?.approvedToken) < Number(requiredToken) &&
          formData?.launch_stealth_sale == false &&
          formData?.sale_without_token == false ? (
            <div className='container d-flex align-items-center justify-content-center'>
              <div className='row'>
                <div className='col-12'>
                  <div
                    className='alert alert-danger alert-dismissible fade show'
                    role='alert'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      xmlnsXlink='http://www.w3.org/1999/xlink'
                      aria-hidden='true'
                      role='img'
                      width='1em'
                      height='1em'
                      preserveAspectRatio='xMidYMid meet'
                      viewBox='0 0 24 24'
                    >
                      <g id='iconifyReact327'>
                        <g id='iconifyReact328'>
                          <path
                            id='iconifyReact329'
                            fill='currentColor'
                            d='M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2Zm1 14a1 1 0 0 1-2 0v-5a1 1 0 0 1 2 0Zm-1-7a1 1 0 1 1 1-1a1 1 0 0 1-1 1Z'
                          />
                        </g>
                      </g>
                    </svg>{' '}
                    {requiredToken} {formData?.token_name} needed to create a
                    pool! <br />({Number(formData?.approvedToken)}{' '}
                    {formData?.token_name} allowed! <br />
                    {Number(requiredToken) -
                      Number(formData?.approvedToken)}{' '}
                    {formData?.token_name} needed more!)
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='row row-cols-1 row-cols-sm-2 mt-3'>
              <div className='col'>
                <p className='text-white mb-0 line-height-normal'>
                  Total <span className='text-blue'>{requiredToken}</span>{' '}
                  {formData?.token_name} needed to create a pool!. <br />
                  {/* Estimated Market cap: <span className="text-blue">$ 3,000</span> */}
                </p>
              </div>
            </div>
          )}
          <hr />
          <div style={{ overflow: 'auto' }}>
            <div className='d-flex gap-3 py-2 justify-content-center'>
              <button
                type='button'
                // id="prevBtn"
                onclick='nextPrev(-1)'
                className='m-0 btn btn-outline-primary btn-lg text-white min-w-btn'
                onClick={prev}
              >
                Back
              </button>
              <button
                type='submit'
                // id="nextBtn"
                className='m-0 btn btn-primary btn-lg min-w-btn'
                onClick={(event) => {
                  event.preventDefault();
                  formik.handleSubmit();
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
        {/* </form> */}
      </div>
    </>
  );
}

function ProjectInformation({ next, prev, setFormData, formData }) {
  const { chain, chains } = getNetwork();
  const FEE_MANAGER_ADDRESS = FEE_MANAGER_ADDRESSESS[chain?.network];
  const ActiveChainSymbol = config.SUPPORTED_NETWORKS.find(
    (network) => network.id === chain?.id
  )?.symbol;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      logo_url: '',
      website_url: '',
      twitter: '',
      github: '',
      telegram: '',
      discord: '',
      youtube: '',
      whitelist_link: '',
      description: '',
      cover_photo: '',
      tier: '0',
      bannerUrl: '',
      redditUrl: '',
    },
    validationSchema: projectInfoSchema,
    onSubmit: async (data) => {
      try {
        for (let key in data) {
          setFormData({ type: key, value: data[key] });
        }

        stepTrack[1] = true;
        next();
      } catch (error) {
      } finally {
      }
    },
  });

  const { data: perkFees } = useContractReads({
    contracts: [
      {
        address: FEE_MANAGER_ADDRESS,
        abi: FeeManager.abi,
        functionName: 'feeOfPerk',
        args: ['0'],
      },
      {
        address: FEE_MANAGER_ADDRESS,
        abi: FeeManager.abi,
        functionName: 'feeOfPerk',
        args: ['1'],
      },
      {
        address: FEE_MANAGER_ADDRESS,
        abi: FeeManager.abi,
        functionName: 'feeOfPerk',
        args: ['2'],
      },
      {
        address: FEE_MANAGER_ADDRESS,
        abi: FeeManager.abi,
        functionName: 'feeOfPerk',
        args: ['3'],
      },
    ],
  });

  return (
    <div className='tab'>
      <h4 className='form-card-heading'>Project Information</h4>
      <div className='approve_token_card text-start'>
        <div className='row row-cols-1 row-cols-sm-2 g-3 Project_Information'>
          <div className='col'>
            <label className='form-label' style={{ position: 'relative' }}>
              Logo url
              {/* <i class="fa fa-info-circle">
          <span className="tooltip-text">URL must end with a supported image extension png, jpg, jpeg or gif and dimensions of exactly 50x50 pixels.</span>
              </i>  */}
              <Tooltip
                arrow
                title='URL must end with a supported image extension png, jpg, jpeg or gif and dimensions of exactly 50x50 pixels.'
                placement='top-start'
                enterTouchDelay={false}
              >
                <InfoIcon style={{ marginLeft: '2px', width: '19' }} />
              </Tooltip>
              <span
                className='error-msg'
                style={{ fontSize: '1.5rem', position: 'absolute', top: -7 }}
              >
                *
              </span>{' '}
            </label>
            <div
              className={`${
                formik.errors.logo_url && formik.touched.logo_url
                  ? 'input_invalid'
                  : ''
              } input-group`}
            >
              <div className='input-group-text'>
                <i className='fa fa-picture-o' aria-hidden='true'></i>
              </div>
              <input
                className='form-control'
                type='text'
                name='logo_url'
                placeholder='Ex: https://...'
                onChange={formik.handleChange}
                value={formik.values.logo_url}
              />
            </div>

            {formik.errors.logo_url && formik.touched.logo_url ? (
              <span className='error-msg'>{formik.errors.logo_url}</span>
            ) : null}
          </div>
          <div className='col'>
            <label className='form-label'>
              Banner url
              {/* <i class="fa fa-info-circle">
            <span className="tooltip-text">Banner url with dimensions of exactly 286x110 pixels.</span> </i> */}
              <Tooltip
                arrow
                title='Banner url with dimensions of exactly 286x110 pixels. supported extention png, jpg, jpeg, gif, mp4, webm, ogg'
                placement='top-start'
                enterTouchDelay={false}
              >
                <InfoIcon style={{ marginLeft: '2px', width: '19' }} />
              </Tooltip>
            </label>
            <div
              className={`${
                formik.errors.bannerUrl && formik.touched.bannerUrl
                  ? 'input_invalid'
                  : ''
              } input-group`}
            >
              <div className='input-group-text'>
                <i class='fa fa-external-link' aria-hidden='true'></i>
              </div>
              <input
                className='form-control'
                type='text'
                name='bannerUrl'
                placeholder='Ex: https://...'
                onChange={formik.handleChange}
                value={formik.values.bannerUrl}
              />
            </div>

            {formik.errors.bannerUrl && formik.touched.bannerUrl ? (
              <span className='error-msg'>{formik.errors.bannerUrl}</span>
            ) : null}
          </div>

          <div className='col'>
            <label className='form-label'>Website url</label>

            <div
              className={`${
                formik.errors.website_url && formik.touched.website_url
                  ? 'input_invalid'
                  : ''
              } input-group`}
            >
              <div className='input-group-text'>
                <i class='fa fa-globe' aria-hidden='true'></i>
              </div>
              <input
                className='form-control'
                type='text'
                name='website_url'
                placeholder='Ex: https://...'
                onChange={formik.handleChange}
                value={formik.values.website_url}
              />
            </div>

            {formik.errors.website_url && formik.touched.website_url ? (
              <span className='error-msg'>{formik.errors.website_url}</span>
            ) : null}
          </div>
          <div className='col'>
            <label className='form-label'>Twitter</label>

            <div
              className={`${
                formik.errors.twitter && formik.touched.twitter
                  ? 'input_invalid'
                  : ''
              } input-group`}
            >
              <div className='input-group-text'>
                <i class='fa fa-twitter' aria-hidden='true'></i>
              </div>
              <input
                className='form-control'
                type='text'
                name='twitter'
                placeholder='Ex: https://twitter.com/...'
                onChange={formik.handleChange}
                value={formik.values.twitter}
              />
            </div>

            {formik.errors.twitter && formik.touched.twitter ? (
              <span className='error-msg'>{formik.errors.twitter}</span>
            ) : null}
          </div>
          <div className='col'>
            <label className='form-label'>Github</label>

            <div
              className={`${
                formik.errors.github && formik.touched.github
                  ? 'input_invalid'
                  : ''
              } input-group`}
            >
              <div className='input-group-text'>
                <i class='fa fa-github' aria-hidden='true'></i>
              </div>
              <input
                className='form-control'
                type='text'
                name='github'
                placeholder='Ex: https://github.com/...'
                onChange={formik.handleChange}
                value={formik.values.github}
              />
            </div>

            {formik.errors.github && formik.touched.github ? (
              <span className='error-msg'>{formik.errors.github}</span>
            ) : null}
          </div>
          <div className='col'>
            <label className='form-label'>Telegram</label>
            <div
              className={`${
                formik.errors.telegram && formik.touched.telegram
                  ? 'input_invalid'
                  : ''
              } input-group`}
            >
              <div className='input-group-text'>
                <i class='fa fa-telegram' aria-hidden='true'></i>
              </div>
              <input
                className='form-control'
                type='text'
                name='telegram'
                placeholder='Ex: https://t.me/...'
                onChange={formik.handleChange}
                value={formik.values.telegram}
              />
            </div>

            {formik.errors.telegram && formik.touched.telegram ? (
              <span className='error-msg'>{formik.errors.telegram}</span>
            ) : null}
          </div>
          <div className='col'>
            <label className='form-label'>Discord</label>
            <div
              className={`${
                formik.errors.discord && formik.touched.discord
                  ? 'input_invalid'
                  : ''
              } input-group`}
            >
              <div className='input-group-text'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='#90a3b7'
                  height='1em'
                  viewBox='0 0 640 512'
                >
                  <path d='M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z' />
                </svg>
              </div>
              <input
                className='form-control'
                type='text'
                name='discord'
                placeholder='Ex: https://discord.gg/...'
                onChange={formik.handleChange}
                value={formik.values.discord}
              />
            </div>

            {formik.errors.discord && formik.touched.discord ? (
              <span className='error-msg'>{formik.errors.discord}</span>
            ) : null}
          </div>
          <div className='col'>
            <label className='form-label'>
              Youtube{' '}
              {/* <i class="fa fa-info-circle">
            <span className="tooltip-text">Input YouTube URL or YouTube video ID. Make sure video link doesn't include a timestamp.</span> </i> */}
              <Tooltip
                arrow
                title="Input YouTube URL or YouTube video ID. Make sure video link doesn't include a timestamp."
                placement='top-start'
                enterTouchDelay={false}
              >
                <InfoIcon style={{ width: '19' }} />
              </Tooltip>
            </label>
            <div
              className={`${
                formik.errors.youtube && formik.touched.youtube
                  ? 'input_invalid'
                  : ''
              } input-group`}
            >
              <div className='input-group-text'>
                <i class='fa fa-youtube-play' aria-hidden='true'></i>
              </div>
              <input
                className='form-control'
                type='text'
                name='youtube'
                placeholder='Ex:https://youtube.com/watch?v=75h4tgshg3458i'
                onChange={formik.handleChange}
                value={formik.values.youtube}
              />
            </div>

            {formik.errors.youtube && formik.touched.youtube ? (
              <span className='error-msg'>{formik.errors.youtube}</span>
            ) : null}
          </div>
          <div className='col'>
            <label className='form-label'>Whitelist Link</label>
            <div
              className={`${
                formik.errors.whitelist_link && formik.touched.whitelist_link
                  ? 'input_invalid'
                  : ''
              } input-group`}
            >
              <div className='input-group-text'>
                <i class='fa fa-bolt' aria-hidden='true'></i>
              </div>
              <input
                className='form-control'
                type='text'
                name='whitelist_link'
                placeholder='Ex.https://...'
                onChange={formik.handleChange}
                value={formik.values.whitelist_link}
              />
            </div>

            {formik.errors.whitelist_link && formik.touched.whitelist_link ? (
              <span className='error-msg'>
                {formik.errors.whitelist_link}
              </span>
            ) : null}
          </div>
          <div className='col'>
            <label className='form-label'>Reddit</label>
            <div
              className={`${
                formik.errors.redditUrl && formik.touched.redditUrl
                  ? 'input_invalid'
                  : ''
              } input-group`}
            >
              <div className='input-group-text'>
                <i class='fa fa-reddit' aria-hidden='true'></i>
              </div>
              <input
                className='form-control'
                type='text'
                name='redditUrl'
                placeholder='Ex.https://reddit.com/...'
                onChange={formik.handleChange}
                value={formik.values.redditUrl}
              />
            </div>

            {formik.errors.redditUrl && formik.touched.redditUrl ? (
              <span className='error-msg'>{formik.errors.redditUrl}</span>
            ) : null}
          </div>

          <div className='col-sm-12'>
            <label className='form-label'>
              Description{' '}
              <span className='error-msg' style={{ fontSize: '1.5rem' }}>
                *
              </span>
            </label>

            <textarea
              className={`form-control ${
                formik.errors.description && formik.touched.description
                  ? 'input_invalid'
                  : ''
              }`}
              rows={3}
              id='comment'
              defaultValue={''}
              name='description'
              onChange={formik.handleChange}
              value={formik.values.description}
            />

            {formik.errors.description && formik.touched.description ? (
              <span className='error-msg'>{formik.errors.description}</span>
            ) : null}
          </div>
          {/* <div className="col-md-4">
            <label className="form-label">Cover Photo ( 1024*150)</label>
            <div className="cover_photo_upload text-center mt-1">
              <input
                className="form-control form-control-sm"
                id="formFileSm"
                type="file"
              />
              <i className="fa fa-upload" aria-hidden="true" />
              <p className="mb-0">Upload</p>
            </div>
          </div> */}
        </div>
        <hr />
        <div className='row row-cols-1 row-cols-sm-2 row-cols-md-4 gap-y-3'>
          <div className='col-sm-12 col-md-12'>
            <label className='form-label'>Select Tier</label>
          </div>
          <div className='col'>
            <input
              type='radio'
              className='btn-check'
              name='tier'
              id='StandardTier'
              autoComplete='off'
              value={'0'}
              defaultChecked
              onChange={formik.handleChange}
            />
            <label
              className='btn btn-secondary select_tier_card standard_card_bg'
              htmlFor='StandardTier'
            >
              <div className='select_tier_inner text-start'>
                <div className='tier_option position-relative pb-5'>
                  <p>Standard</p>
                </div>
                <p>Standard Tier without any special perks</p>
                <div className='tier_fees_bottom'>
                  <p className='text-white mb-0'>
                    Fee :{' '}
                    {ethers.utils
                      .formatUnits(String(perkFees?.[0]?.result ?? 0), 18)
                      .toString()}{' '}
                    {ActiveChainSymbol}
                  </p>
                </div>
              </div>
            </label>
          </div>
          <div className='col'>
            <input
              type='radio'
              className='btn-check'
              name='tier'
              id='goldTier'
              autoComplete='off'
              value={'1'}
              onChange={formik.handleChange}
            />
            <label
              className='btn btn-secondary select_tier_card gold_card_bg'
              htmlFor='goldTier'
            >
              <div className='select_tier_inner text-start'>
                <div className='tier_option position-relative pb-5'>
                  <p>Gold</p>
                </div>
                <ul>
                  <li>Gold Border</li>
                  <li>Audit Option</li>
                  <li>KYC Option</li>
                </ul>
                <div className='tier_fees_bottom'>
                  <p className='text-white mb-0'>
                    Fee :{' '}
                    {ethers.utils
                      .formatUnits(String(perkFees?.[1]?.result ?? 0), 18)
                      .toString()}{' '}
                    {ActiveChainSymbol}
                  </p>
                </div>
              </div>
            </label>
          </div>
          <div className='col'>
            <input
              type='radio'
              className='btn-check'
              name='tier'
              id='PlatinumTier'
              autoComplete='off'
              value={'2'}
              onChange={formik.handleChange}
            />
            <label
              className='btn btn-secondary select_tier_card platinum_card_bg'
              htmlFor='PlatinumTier'
            >
              <div className='select_tier_inner text-start'>
                <div className='tier_option position-relative pb-5'>
                  <p>Platinum</p>
                </div>
                <ul>
                  <li>Platinum Border</li>
                  <li>KYC Included</li>
                  <li>AMA Included</li>
                  <li>Audit Option &amp; More!</li>
                </ul>
                <div className='tier_fees_bottom'>
                  <p className='text-white mb-0'>
                    Fee :{' '}
                    {ethers.utils
                      .formatUnits(String(perkFees?.[2]?.result ?? 0), 18)
                      .toString()}{' '}
                    {ActiveChainSymbol}
                  </p>
                </div>
              </div>
            </label>
          </div>
          <div className='col'>
            <input
              type='radio'
              className='btn-check'
              name='tier'
              id='DiamondTier'
              autoComplete='off'
              value={'3'}
              onChange={formik.handleChange}
            />
            <label
              className='btn btn-secondary select_tier_card diamond_card_bg'
              htmlFor='DiamondTier'
            >
              <div className='select_tier_inner text-start'>
                <div className='tier_option position-relative pb-5'>
                  <p>Diamond</p>
                </div>
                <ul>
                  <li>Diamond Border</li>
                  <li>KYC Included</li>
                  <li>AMA Included</li>
                  <li>Cheap Audit Rate</li>
                  <li>CMC &amp; CG Listing</li>
                  <li>Call By Partners &amp; More!</li>
                </ul>
                <div className='tier_fees_bottom'>
                  <p className='text-white mb-0'>
                    Fee :{' '}
                    {ethers.utils
                      .formatUnits(String(perkFees?.[3]?.result ?? 0), 18)
                      .toString()}{' '}
                    {ActiveChainSymbol}
                  </p>
                </div>
              </div>
            </label>
          </div>
        </div>
        <hr />
        <div style={{ overflow: 'auto' }}>
          <div className='d-flex gap-3 py-2 justify-content-center'>
            <button
              type='button'
              // id='prevBtn'
              // onclick="nextPrev(-1)"
              onClick={prev}
              className='m-0 btn btn-outline-primary btn-lg text-white min-w-btn'
            >
              Back
            </button>
            <button
              type='submit'
              // id='nextBtn'
              className='m-0 btn btn-primary btn-lg min-w-btn'
              onClick={(event) => {
                event.preventDefault();
                formik.handleSubmit();
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Confirmation({ next, prev, setFormData, formData }) {
  const { chain, chains } = getNetwork();
  const routerName = routerAddresses[chain?.id]?.routerName;
  const routerAddress = routerAddresses[chain?.id]?.routerAddress;
  const pairTokens = routerAddresses[chain?.id]?.pairTokens;

  const account = getAccount();

  const navigate = useNavigate();

  const [presaleInfo, _presaleInfo] = useState();
  const supportedChainSymbols = config.SUPPORTED_NETWORKS.map(
    (network) => network.symbol
  );
  const ActiveChainSymbol = config.SUPPORTED_NETWORKS.find(
    (network) => network.id === chain?.id
  )?.symbol;

  const currentTokenPrice = async () => {
    if (
      buyWithETH == true &&
      supportedChainSymbols.includes(ActiveChainSymbol)
    ) {
      const data = await getCurrentBnbPriceAction({
        symbol: ActiveChainSymbol,
      });
      setFormData({
        type: 'current_token_price',
        value: data?.data?.price || 0,
      });
    } else {
      const data = await getCurrentBnbPriceAction({
        symbol: formData?.custom_fund_raising_token_symbol,
      });
      setFormData({
        type: 'current_token_price',
        value: data?.data?.price || 0,
      });
    }
  };

  useEffect(() => {
    currentTokenPrice();
  }, [formData?.custom_fund_raising_token_symbol]);

  const [creatig, _creating] = useState(false);
  /* Project Details  */
  let {
    logo_url,
    website_url,
    telegram,
    github,
    twitter,
    discord,
    youtube,
    whitelist_link,
    description,
    bannerUrl,
    redditUrl,
  } = formData;

  /* Pool */

  let {
    dex_liquidity,
    whitelist_timer,
    start_date,
    end_date,
    estimated_dex_listing_date,
    hard_cap,
    dex_listing_rate,
    pre_sale_amount,
    liquidity_lookup,
    soft_cap,
    minimum_buy,
    maximum_buy,
    router,
    token,
    buyToken,
    presale_type,
    buyWithETH,
    fund_releasing_token,
    dynamicDecimal,
    set_sale_time,
    sale_without_token,
    isHardCap,
    isMaxBuy,
  } = formData;

  //For Disply Time
  const Sdate = new Date(formData?.start_date * 1000);
  const Edata = new Date(formData?.end_date * 1000);
  const e_dex_li_date = new Date(formData?.estimated_dex_listing_date * 1000);

  const ProjectDetails = {
    logoUrl: logo_url,
    bannerUrl: bannerUrl,
    websiteUrl: website_url,
    telegramUrl: telegram,
    githubUrl: github,
    twitterUrl: twitter,
    discordUrl: discord,
    youtubePresentationVideoUrl: youtube,
    whitelistContestUrl: whitelist_link,
    redditUrl: redditUrl,
    projectDescription: description,
  };

  const AdditionalDetails = {
    isHardCap: isHardCap, //fair Boolean
    isMaxBuy: isMaxBuy, //fair Boolean
    buyWithETH: buyWithETH,
    isWhiteList: presale_type == 'Whitelist' ? true : false,
    setSaleTime: formData.sale_without_token ? false : set_sale_time,
    router: router,
    buyToken: buyToken,
    preSaleToken:
      formData?.sale_without_token == true || formData?.launch_stealth_sale
        ? supportedChainSymbols.includes(ActiveChainSymbol)
          ? pairTokens[ActiveChainSymbol]
          : ''
        : token,
  };

  const dexListingRateAmt = Number(
    Number(pre_sale_amount * dex_listing_rate) / Number(soft_cap * 100) || 0
  )?.toFixed(formData?.token_decimal);
  const presaleRateAmt = Number(
    Number(pre_sale_amount) / Number(soft_cap) || 0
  )?.toFixed(formData?.token_decimal);
  const Pool = {
    dexLiquidityPercentage: dex_liquidity,
    preSaleRatePercentage: dex_listing_rate,
    preSaleAmount: ethers.utils
      .parseUnits(
        String(pre_sale_amount || 0),
        Number(formData?.token_decimal || 0)
      )
      .toString(),
    whiteListTimer: presale_type == 'Whitelist' ? whitelist_timer : 0,
    startTime: set_sale_time ? (sale_without_token ? 0 : start_date) : 0,
    endTime: set_sale_time ? (sale_without_token ? 0 : end_date) : 0,
    estimatedDexListingTime: set_sale_time
      ? sale_without_token
        ? 0
        : estimated_dex_listing_date
      : 0,
    liquidityLockupDays: liquidity_lookup,
    hardCap: ethers.utils
      .parseUnits(String(hard_cap || 0), dynamicDecimal || 0)
      .toString(),
    softCap: ethers.utils
      .parseUnits(String(soft_cap || 0), dynamicDecimal || 0)
      .toString(),
    maxBuy: ethers.utils
      .parseUnits(String(maximum_buy || 0), dynamicDecimal || 0)
      .toString(),
    minBuy: ethers.utils
      .parseUnits(String(minimum_buy || 0), dynamicDecimal || 0)
      .toString(), //Hyper
    dexListingRate: ethers.utils
      .parseUnits(
        String(dexListingRateAmt),
        Number(formData?.token_decimal || 0)
      )
      .toString(),
    preSaleRate: ethers.utils
      .parseUnits(String(presaleRateAmt), Number(formData?.token_decimal || 0))
      .toString(),
    maxContributors: formData?.max_contributors,

    // minBuy: ethers.utils.parseUnits(String(minimum_buy || 0), dynamicDecimal || 0).toString(),
    // burnTokens: Boolean(formData?.burnTokens),
  };

  // const PoolDbData = {
  //   dexLiquidityPercentage: dex_liquidity,
  //   dexListingRate: ethers.utils.parseUnits(String(((pre_sale_amount* dex_listing_rate)/(soft_cap * 100)) || 0), Number(formData?.token_decimal || 0)).toString(),
  //   preSaleRatePercentage: dex_listing_rate, // fair
  //   preSaleAmount:ethers.utils
  //   .parseUnits(String(Number(pre_sale_amount)/Number(soft_cap) || 0), Number(formData?.token_decimal || 0))
  //   .toString(), //fair
  //   whiteListTimer: presale_type == "Whitelist" ? whitelist_timer : 0,
  //   startTime: set_sale_time ? (sale_without_token ? 0 : start_date) : 0,
  //   endTime: set_sale_time ? (sale_without_token ? 0 : end_date) : 0,
  //   estimatedDexListingTime: set_sale_time ? (sale_without_token ? 0 : estimated_dex_listing_date) : 0,
  //   liquidityLockupDays: liquidity_lookup,
  //   hardCap: ethers.utils.parseUnits(String(hard_cap || 0), dynamicDecimal || 0).toString(),
  //   softCap: ethers.utils.parseUnits(String(soft_cap || 0), dynamicDecimal || 0).toString(),
  //   maxBuy: ethers.utils.parseUnits(String(maximum_buy || 0), dynamicDecimal || 0).toString(),

  //   preSaleRate: ethers.utils
  //     .parseUnits(String(pre_sale_amount*soft_cap || 0), Number(formData?.token_decimal || 0))
  //     .toString(),
  //   // minBuy: ethers.utils.parseUnits(String(minimum_buy || 0), dynamicDecimal || 0).toString(),
  //   // burnTokens: Boolean(formData?.burnTokens),
  // };
  let {
    bonus_sale,
    bonus_received,
    min_bonus_buy_amount,
    bonus_spots_available,
    isBurn,
  } = formData;
  const Bonus = {
    isBonusSale: bonus_sale,
    isBurn: Boolean(isBurn), //fair boolean
    bonusReceivedPercentage: (bonus_sale && bonus_received) || 0,
    minBonusBuyAmount:
      (bonus_sale &&
        ethers.utils
          .parseUnits(String(min_bonus_buy_amount), dynamicDecimal)
          .toString()) ||
      0,
    noOfBonusEligibleInvestors: (bonus_sale && bonus_spots_available) || 0,
  };

  let {
    add_team_token_vesting,
    token_team_vesting_tokens,
    first_token_release,
    first_token_release_after_listing,
    team_token_released_each_cycle,
    vesting_period_each_cycle,
  } = formData;
  const TeamVestingDetails = {
    isTeamVesting: add_team_token_vesting,
    firstReleasePercent: (add_team_token_vesting && first_token_release) || 0,
    releaseCyclePercentage:
      (add_team_token_vesting && team_token_released_each_cycle) || 0,
    vestingAmount:
      (add_team_token_vesting &&
        ethers.utils
          .parseUnits(
            String(token_team_vesting_tokens),
            Number(formData?.token_decimal)
          )
          .toString()) ||
      0,
    firstReleaseAfter:
      (add_team_token_vesting && first_token_release_after_listing) || 0,
    releaseCycleDays:
      (add_team_token_vesting && vesting_period_each_cycle) || 0,
  };

  // New Added
  let {
    add_presale_vesting,
    first_token_release_presale,
    toke_released_each_cycle_presale,
    vesting_period_each_cycle_presale,
    cliff,
  } = formData;
  const PreSaleVestingDetails = {
    isPreSaleVesting: add_presale_vesting,
    firstReleasePercent:
      (add_presale_vesting && first_token_release_presale) || 0,
    releaseCyclePercentage:
      (add_presale_vesting && toke_released_each_cycle_presale) || 0,
    firstReleaseAfter: (add_presale_vesting && cliff) || 0,
    releaseCycleDays:
      (add_presale_vesting && vesting_period_each_cycle_presale) || 0,
  };

  const PreSaleVestingDetailsDB = {
    isPreSaleVesting: add_presale_vesting,
    firstReleasePercent_presale:
      (add_presale_vesting && first_token_release_presale) || 0,
    firstReleaseAfter_presale: (add_presale_vesting && cliff) || 0,
    releaseCyclePercentage_presale:
      (add_presale_vesting && toke_released_each_cycle_presale) || 0,
    releaseCycleDays_presale:
      (add_presale_vesting && vesting_period_each_cycle_presale) || 0,
  };

  const onCreatePreSaleError = (error) => {
    PostErrorLogAction({
      address: account?.address || 0,
      other_detail: { error },
    });
    let errStr = error.toString().slice(0, 25);
    console.log('Error 57', error);

    if (errStr === 'TransactionExecutionError') {
      toast.error('User Rejected Transaction');
    } else if (error.toString().includes('InvalidTimeForPreSaleToStart')) {
      toast.error('StartTime should be > currentTime', {
        toastId: 'start_time_>_current_time',
      });
    } else if (
      error
        .toString()
        .includes('PreSaleEndTimeShouldBeMoreThanOrEqualToWhiteListTimer')
    ) {
      toast.error(
        'Presale EndTime should be More Than or equal to whiteListTimer!',
        { toastId: 'end_time_morethen' }
      );
    } else if (error.toString().includes('insufficient allowance')) {
      toast.error('Insufficient allowance', {
        toastId: 'insufficient_allowace',
      });
    } else if (
      error.toString().includes('The total cost (gas * gas fee + value)')
    ) {
      toast.error(TRANSACTIONMSGS.INSUFFICIENT_FUND, {
        toastId: 'insufficient_fundTid',
      });
    } else {
      toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG);
    }

    _creating(false);
  };

  const onSuccessCreatePreSale = (data) => {
    // console.log("onSuccessCreatePreSale Data", data)
  };

  const onSuccessWaitForTransaction = async ({ logs }) => {
    // console.log("logs",logs)

    let lastIndex;
    if (chain?.id == bscTestnet.id) {
      //DynamicChain
      lastIndex = logs.length - 1;
    } else {
      lastIndex = logs.length - 2;
    }
    _creating(true);
    // const web3 = new Web3(window.ethereum);

    // const data = web3.eth.abi.decodeLog(
    //   [
    //     {
    //       type: 'address',
    //       name: 'preSale',
    //       indexed: false,
    //     },
    //     {
    //       type: 'address',
    //       name: 'preSaleOwner',
    //       indexed: false,
    //     },
    //     {
    //       type: 'uint256',
    //       name: 'id',
    //       indexed: false,
    //     },
    //   ],
    //   logs[lastIndex]?.data,
    //   logs[lastIndex]?.topics[0],
    // );

    const { args } = decodeEventLog({
      abi: parseAbi([
        'event PreSaleCreated(address preSale, address preSaleOwner, uint256 id)',
      ]),
      // `data` should be 64 bytes, but is only 32 bytes.
      data: logs[lastIndex]?.data,
      topics: [logs[lastIndex]?.topics[0]],
    });
    // console.log("DATA ->>", args);

    _presaleInfo(args);

    let argsObj = {
      id: String(args.id),
      preSale: args?.preSale,
      preSaleOwner: args?.preSaleOwner,
    };

    const PreSaleCreated = {
      address: logs[lastIndex]?.address,
      blockNumber: Number(logs[lastIndex]?.blockNumber),
      transactionHash: logs[lastIndex]?.transactionHash,
      transactionIndex: Number(logs[lastIndex]?.transactionIndex),
      blockHash: logs[lastIndex]?.blockHash,
      logIndex: Number(logs[lastIndex]?.logIndex),
      id: String(args?.id),
      returnValues: argsObj,
      event: 'PreSaleCreated',
      saleType: 'Hyper Launch',
      signature: logs[lastIndex]?.topics[0],
      raw: { data: logs[lastIndex]?.data },
    };

    if (args) {
      const currentPresaleRate = await readContract({
        address: args?.preSale,
        abi: FairLaunch.abi,
        functionName: 'currentPreSaleRate',
      });

      const currentDEXRate = await readContract({
        address: args?.preSale,
        abi: FairLaunch.abi,
        functionName: 'currentDEXRate',
      });

      try {
        const response = await PostPresaleAction({
          PreSaleCreated: PreSaleCreated,
          additionalDetails: {
            ...argsObj,
            preSaleOwner: args?.preSaleOwner,
            chain: chain?.id, // Assuming 'chain' is defined
            ...AdditionalDetails,
            ...{
              dexLiquidityPercentage: Pool.dexLiquidityPercentage,
              preSaleRatePercentage: Pool.preSaleRatePercentage,
              preSaleAmount: Pool.preSaleAmount,
              whiteListTimer: Pool.whiteListTimer,
              startTime: Pool.startTime,
              endTime: Pool.endTime,
              estimatedDexListingTime: Pool.estimatedDexListingTime,
              liquidityLockupDays: Pool.liquidityLockupDays,
              hardCap: Pool.hardCap,
              softCap: Pool.softCap,
              maxBuy: Pool.maxBuy,
              minBuy: Pool.minBuy, //Hyper
              dexListingRate: String(currentDEXRate),
              preSaleRate: String(currentPresaleRate),
              maxContributors: Pool.maxContributors,
            },
            ...ProjectDetails,
            ...Bonus,
            ...TeamVestingDetails,
            ...PreSaleVestingDetailsDB,
            name: formData?.token_name, // Assuming 'formData' is defined
            token_symbol: formData?.token_symbol,
            supply: formData?.token_supply,
            decimal: formData?.token_decimal,
            fund_releasing_token: formData?.fund_releasing_token,
            custom_fund_token_decimal: dynamicDecimal, // Assuming 'dynamicDecimal' is defined
            custom_fund_token_name: formData?.custom_fund_raising_token_name,
            custom_fund_raising_token_symbol:
              formData?.custom_fund_raising_token_symbol || '',
            launch_stealth_sale: formData?.launch_stealth_sale,
            sale_without_token: formData?.sale_without_token,
            stealth_wallet: formData?.stealth_wallet,
            sale_title: formData?.sale_title,
            perk: formData?.tier,
            estimated_mc:
              Number(hard_cap) * Number(formData?.current_token_price),
          },
        });

        if (response) {
          // toast.success(SUCCESSMSGS.PRESALE_LISTED)
          let button = document.getElementById('openSuccessModel');
          button.click();
        }
      } catch (error) {
        console.log('error: ' + error);
      } finally {
        _creating(false);
      }
    }
  };

  const FEE_MANAGER_ADDRESS = FEE_MANAGER_ADDRESSESS[chain?.network];

  const { data: perkFees } = useContractReads({
    contracts: [
      {
        address: FEE_MANAGER_ADDRESS,
        abi: FeeManager.abi,
        functionName: 'feeOfPerk',
        args: ['0'],
      },
      {
        address: FEE_MANAGER_ADDRESS,
        abi: FeeManager.abi,
        functionName: 'feeOfPerk',
        args: ['1'],
      },
      {
        address: FEE_MANAGER_ADDRESS,
        abi: FeeManager.abi,
        functionName: 'feeOfPerk',
        args: ['2'],
      },
      {
        address: FEE_MANAGER_ADDRESS,
        abi: FeeManager.abi,
        functionName: 'feeOfPerk',
        args: ['3'],
      },
    ],
  });

  const PRESALE_MANAGER_ADDRESS = PRESALE_MANAGER_ADDRESSESS[chain?.network];

  const {
    data: dataCreateStealthPreSale,
    isLoading: isLoadingCreateStealthPresale,
    isSuccess: isSuccessCteateStralthPresale,
    isError: isErrorCreateStealthPresale,
    write: createStealthFairSale,
  } = useContractWrite({
    address: PRESALE_MANAGER_ADDRESS,
    abi: PreSaleManager.abi,
    functionName: 'createStealthHyperSale',
    args: [
      AdditionalDetails,
      Pool,
      Bonus,
      TeamVestingDetails,
      PreSaleVestingDetails,
      ProjectDetails,
      formData?.stealth_wallet,
      Number(formData?.tier),
    ],
    onError: onCreatePreSaleError,
    value: perkFees?.[formData?.tier]?.result ?? 0,
    // onSuccess:onSuccessCreatePreSale
  });

  const {
    data: dataCreateStealthPresaleWaitFor,
    isError: isErrorCreateStealthPresaleWaitFor,
    isLoading: isLoadingCreateStealthPresaleWaitFor,
  } = useWaitForTransaction({
    hash: dataCreateStealthPreSale?.hash,
    onSuccess: onSuccessWaitForTransaction,
  });

  const {
    data: dataCreatePresale,
    isLoading: isLoadingCreatePresale,
    isSuccess: isSuccessCteatePresale,
    isError: isErrorCreatePresale,
    write: createFairSale,
  } = useContractWrite({
    address: PRESALE_MANAGER_ADDRESS,
    abi: PreSaleManager.abi,
    functionName: 'createHyperSale',
    args: [
      AdditionalDetails,
      Pool,
      Bonus,
      TeamVestingDetails,
      PreSaleVestingDetails,
      ProjectDetails,
      formData?.sale_without_token,
      Number(formData?.tier),
    ],
    onError: onCreatePreSaleError,
    onSuccess: onSuccessCreatePreSale,
    value: perkFees?.[formData?.tier]?.result ?? 0,
  });

  const {
    data: dataCreatePresaleWaitFor,
    isError: isErrorCreatePresaleWaitFor,
    isLoading: isLoadingCreatePresaleWaitFor,
  } = useWaitForTransaction({
    hash: dataCreatePresale?.hash,
    onSuccess: onSuccessWaitForTransaction,
  });

  const doTransaction = async () => {
    if (!account.isConnected) {
      toast.error('Please connect to wallet to create a presale!', {
        toastId: 'pcwTId',
      });
    } else if (!supportedChainSymbols.includes(ActiveChainSymbol)) {
      toast.error('Wrong Network!', { toastId: 'WrongNetworkTId' });
    } else {
      _creating(true);
      try {
        if (formData?.launch_stealth_sale == true) {
          createStealthFairSale();
        } else {
          createFairSale();
        }
      } catch (error) {
        console.log(error);
        toast.error(error.message);
        _creating(false);
      }
    }
  };

  const [descriptionDisp, _descriptionDisp] = useState(false);

  return (
    <div className='tab'>
      <h4 className='form-card-heading'>Confirmation</h4>
      <div className='approve_token_card text-start col-md-8 mx-auto'>
        <div className='row row-cols-1'>
          <div className='col confirmation_tab_section'>
            <ul>
              {formData?.launch_stealth_sale == true ? (
                <li className='d-flex flex-wrap justify-content-between text-break'>
                  Launch Type <span className='text-white'>Stealth Launch</span>
                </li>
              ) : formData?.sale_without_token == true ? (
                <li className='d-flex flex-wrap justify-content-between text-break'>
                  Launch Type{' '}
                  <span className='text-white'>Launch without Token</span>
                </li>
              ) : (
                ''
              )}
              {formData?.launch_stealth_sale == true ||
              formData?.sale_without_token == true ? (
                <li className='d-flex flex-wrap justify-content-between text-break'>
                  Sale Title{' '}
                  <span className='text-white'>{formData?.sale_title}</span>
                </li>
              ) : (
                ''
              )}

              {formData?.sale_without_token != true && (
                <li className='d-flex flex-wrap justify-content-between text-break'>
                  Total Supply{' '}
                  <span className='text-white'>
                    {/* {formData?.token_supply} */}
                    {sale_without_token == true
                      ? 'Unknown'
                      : formData?.launch_stealth_sale == true
                      ? formData?.token_supply
                      : formData?.token_supply &&
                        ethers.utils
                          .formatUnits(
                            String(formData?.token_supply),
                            Number(formData?.token_decimal)
                          )
                          .toString()}
                  </span>
                </li>
              )}
              {formData?.launch_stealth_sale == false &&
                formData?.sale_without_token == false && (
                  <>
                    <li className='d-flex flex-wrap justify-content-between text-break'>
                      Token Name{' '}
                      <span className='text-white'>
                        {sale_without_token == true ||
                        formData?.launch_stealth_sale
                          ? 'Unknown'
                          : formData?.token_name}
                      </span>
                    </li>
                    <li className='d-flex flex-wrap justify-content-between text-break'>
                      Token Symbol{' '}
                      <span className='text-white'>
                        {sale_without_token == true ||
                        formData?.launch_stealth_sale
                          ? 'Unknown'
                          : formData?.token_symbol}
                      </span>
                    </li>
                    <li className='d-flex flex-wrap justify-content-between text-break'>
                      Token Decimal{' '}
                      <span className='text-white'>
                        {sale_without_token == true ||
                        formData?.launch_stealth_sale
                          ? 'Unknown'
                          : formData?.token_decimal}
                      </span>
                    </li>
                  </>
                )}
            </ul>
            <hr />
            <ul>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Presale Amount{' '}
                <span className='text-white'>{formData?.pre_sale_amount}</span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Max Contributors{' '}
                <span className='text-white'>{formData?.max_contributors}</span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Listing Rate{' '}
                <span className='text-white'>
                  {Number(pre_sale_amount * dex_listing_rate) /
                    Number(soft_cap * 100)}
                </span>
              </li>
              {/* 
              <li className="d-flex flex-wrap justify-content-between text-break">
                Presale Rate(%){" "}
                <span className="text-white">{formData?.dex_listing_rate}</span>
              </li>
               */}

              <li className='d-flex flex-wrap justify-content-between text-break'>
                what DEX will be used to add liquidity{' '}
                <span className='text-white'>PancakeSwap</span>
              </li>

              <li className='d-flex flex-wrap justify-content-between text-break'>
                SoftCap{' '}
                <span className='text-white'>
                  {formData?.soft_cap}{' '}
                  {formData?.custom_fund_raising_token_symbol}
                </span>
              </li>
              {formData?.isHardCap && (
                <li className='d-flex flex-wrap justify-content-between text-break'>
                  HardCap{' '}
                  <span className='text-white'>
                    {formData?.hard_cap}{' '}
                    {formData?.custom_fund_raising_token_symbol}
                  </span>
                </li>
              )}
              {
                <li className='d-flex flex-wrap justify-content-between text-break'>
                  Minimum Buy{' '}
                  <span className='text-white'>
                    {formData?.minimum_buy}{' '}
                    {formData?.custom_fund_raising_token_symbol}
                  </span>
                </li>
              }
              {formData?.isMaxBuy && (
                <li className='d-flex flex-wrap justify-content-between text-break'>
                  Maximum Buy{' '}
                  <span className='text-white'>
                    {formData?.maximum_buy}{' '}
                    {formData?.custom_fund_raising_token_symbol}
                  </span>
                </li>
              )}
              {/* <li className="d-flex flex-wrap justify-content-between text-break">
                What happens to Unsold Tokens
                <span className="text-white">{formData?.burnTokens == 'true' ? 'Burn' : 'Refund'}</span>
              </li> */}
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Fund Raising Token{' '}
                <span className='text-white'>
                  {formData?.fund_releasing_token}
                </span>
              </li>

              <li className='d-flex flex-wrap justify-content-between text-break'>
                Liquidity{' '}
                <span className='text-white'>{formData?.dex_liquidity}%</span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Start Time{' '}
                <span className='text-white'>
                  {formData?.set_sale_time && !formData?.sale_without_token
                    ? moment(Sdate).format('YYYY-MM-DD HH:mm')
                    : 'TBA'}
                </span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                End Time{' '}
                <span className='text-white'>
                  {formData?.set_sale_time && !formData?.sale_without_token
                    ? moment(Edata).format('YYYY-MM-DD HH:mm')
                    : 'TBA'}
                </span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Estimated DEX Listing Time{' '}
                <span className='text-white'>
                  {formData?.set_sale_time && !formData?.sale_without_token
                    ? moment(e_dex_li_date).format('YYYY-MM-DD HH:mm')
                    : 'TBA'}
                </span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Liquidity Lock Time Period{' '}
                <span className='text-white'>{formData?.liquidity_lookup}</span>
              </li>
            </ul>
            <hr />
            <ul>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Website{' '}
                <span className='text-white'>{formData?.website_url}</span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Twitter <span className='text-white'>{formData?.twitter}</span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Telegram{' '}
                <span className='text-white'>{formData?.telegram}</span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Github <span className='text-white'>{formData?.github}</span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Discord <span className='text-white'>{formData?.discord}</span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Youtube <span className='text-white'>{formData?.youtube}</span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Whitelist Link{' '}
                <span className='text-white'>{formData?.whitelist_link}</span>
              </li>

              <li className='d-flex flex-wrap justify-content-between text-break'>
                Banner URL
                <span className='text-white'>{formData?.bannerUrl}</span>
              </li>

              <li className='d-flex flex-wrap justify-content-between text-break'>
                Logo URL{' '}
                <span className='text-white'>{formData?.logo_url}</span>
              </li>

              <li className='d-flex flex-wrap justify-content-between text-break'>
                Reddit <span className='text-white'>{formData?.redditUrl}</span>
              </li>

              <li className='d-flex flex-wrap justify-content-between text-break'>
                Description
                <span>
                  <a href className='text-white'>
                    Read{' '}
                    {descriptionDisp ? (
                      <i
                        className='fa fa-chevron-left ms-2'
                        aria-hidden='true'
                        onClick={() => {
                          _descriptionDisp(false);
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    ) : (
                      <i
                        className='fa fa-chevron-right ms-2'
                        aria-hidden='true'
                        onClick={() => {
                          _descriptionDisp(true);
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    {/* <i
                      className="fa fa-chevron-right ms-2"
                      aria-hidden="true"
                      onClick={()=>{_descriptionDisp(true)}}
                    /> */}
                  </a>
                </span>
              </li>
              {descriptionDisp && (
                <li className='d-flex flex-wrap justify-content-between text-break'>
                  <span>{formData?.description}</span>
                </li>
              )}

              <li className='d-flex flex-wrap justify-content-between text-break'>
                Tier
                <span className='text-white'>
                  {formData?.tier == 0
                    ? 'Standard'
                    : formData?.tier == 1
                    ? 'Gold'
                    : formData?.tier == 2
                    ? 'Platinum'
                    : 'Diamond'}
                </span>
              </li>
              <li className='d-flex flex-wrap justify-content-between text-break'>
                Presale type
                <span className='text-white'>{formData?.presale_type}</span>
              </li>
              {formData?.presale_type == 'Whitelist' && (
                <li className='d-flex flex-wrap justify-content-between text-break'>
                  Whitelist Timer
                  <span className='text-white'>
                    {formData?.whitelist_timer}
                  </span>
                </li>
              )}

              {formData?.bonus_sale == true && (
                <>
                  <hr />
                  <label className='form-label text-white w-100 fs-5'>
                    Bonus Sale
                  </label>
                  <li className='d-flex flex-wrap justify-content-between text-break'>
                    Min Bonus Buy Amount
                    <span className='text-white'>
                      {formData?.min_bonus_buy_amount}
                    </span>
                  </li>
                  <li className='d-flex flex-wrap justify-content-between text-break'>
                    Bonus Received (%)
                    <span className='text-white'>
                      {formData?.bonus_received}
                    </span>
                  </li>
                  <li className='d-flex flex-wrap justify-content-between text-break'>
                    Bonus Spots Available
                    <span className='text-white'>
                      {formData?.bonus_spots_available}
                    </span>
                  </li>
                  <li className='d-flex flex-wrap justify-content-between text-break'>
                    Select what happens to Unsold Tokens
                    <span className='text-white'>
                      {formData?.isBurn == true ? 'Burn' : 'Refund'}
                    </span>
                  </li>
                </>
              )}
              {formData?.add_team_token_vesting == true && (
                <>
                  <hr />
                  <label className='form-label text-white w-100 fs-5'>
                    Add Team Token Vesting
                  </label>
                  <li className='d-flex flex-wrap justify-content-between text-break'>
                    Team Vesting Amount
                    <span className='text-white'>
                      {formData?.token_team_vesting_tokens} {formData?.name}
                    </span>
                  </li>
                  <li className='d-flex flex-wrap justify-content-between text-break'>
                    First token release
                    <span className='text-white'>
                      {formData?.first_token_release}
                    </span>
                  </li>
                  <li className='d-flex flex-wrap justify-content-between text-break'>
                    First token period
                    <span className='text-white'>
                      {formData?.first_token_release_after_listing} days
                    </span>
                  </li>
                  <li className='d-flex flex-wrap justify-content-between text-break'>
                    Each token percent
                    <span className='text-white'>
                      {formData?.team_token_released_each_cycle}
                    </span>
                  </li>

                  <li className='d-flex flex-wrap justify-content-between text-break'>
                    Each token period
                    <span className='text-white'>
                      {formData?.vesting_period_each_cycle} days
                    </span>
                  </li>
                </>
              )}

              {formData?.add_presale_vesting == true && (
                <>
                  <hr />
                  <label className='form-label text-white w-100 fs-5'>
                    Add Presale Vesting
                  </label>
                  <li className='d-flex flex-wrap justify-content-between text-break'>
                    First token release
                    <span className='text-white'>
                      {formData?.first_token_release_presale}
                    </span>
                  </li>

                  <li className='d-flex flex-wrap justify-content-between text-break'>
                    Each token percent
                    <span className='text-white'>
                      {formData?.toke_released_each_cycle_presale}
                    </span>
                  </li>

                  <li className='d-flex flex-wrap justify-content-between text-break'>
                    Each token period
                    <span className='text-white'>
                      {formData?.vesting_period_each_cycle_presale} days
                    </span>
                  </li>
                  <li className='d-flex flex-wrap justify-content-between text-break'>
                    Each Cliff
                    <span className='text-white'>{formData?.cliff} days</span>
                  </li>
                </>
              )}

              {/* <li className="d-flex flex-wrap justify-content-between">
                Tier <span className="text-white">{formData?.tier}</span>
              </li> */}
            </ul>
          </div>
        </div>
        <hr />
        <div style={{ overflow: 'auto' }}>
          <div className='d-flex gap-3 py-2 justify-content-center'>
            <button
              type='button'
              id='prevBtn'
              onClick={prev}
              className='m-0 btn btn-outline-primary btn-lg text-white min-w-btn'
              disabled={
                creatig ||
                isLoadingCreatePresale ||
                isLoadingCreateStealthPresale
              }
            >
              Back
            </button>
            <button
              type='button'
              // id="nextBtn"
              // onclick="nextPrev(1)"
              className='m-0 btn btn-primary btn-lg min-w-btn'
              // data-bs-toggle="modal"
              // data-bs-target="#listedSuccessfullyModal"
              disabled={
                creatig ||
                isLoadingCreatePresale ||
                isLoadingCreateStealthPresale
              }
              onClick={() => {
                doTransaction();
              }}
            >
              {creatig ||
              isLoadingCreateStealthPresale ||
              isLoadingCreatePresale
                ? 'Creating...'
                : 'Complete'}
            </button>
          </div>
        </div>
      </div>
      <button
        type='button'
        hidden={true}
        id='openSuccessModel'
        name='openSuccessModel'
        class='m-0'
        data-bs-toggle='modal'
        data-bs-target='#listedSuccessfullyModal'
      >
        Next
      </button>
      {/* The Modal */}
      <div
        className='modal'
        id='listedSuccessfullyModal'
        data-bs-backdrop='static'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            {/* Modal body */}
            <div className='modal-body p-2 p-md-5 text-center'>
              <img
                src='images/listed_successfully.svg'
                alt='listed successfully'
              />
              <h3 className='my-3'>Good Job!</h3>
              <p>Your project is now listed!</p>
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labor.</p> */}
              <button
                type='button'
                className='btn btn-primary'
                data-bs-dismiss='modal'
                onClick={() => {
                  navigate(
                    `${BASE_URL}presale?presale_id=${presaleInfo?.preSale}&chainId=${chain?.id}`
                  );
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateHypersLaunch;
