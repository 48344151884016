import { useMediaQuery } from '@mui/material';
import React, { useState, useEffect } from 'react';

const PersaleCountDown = ({
  unixTime,
  gap = 1,
  isEndedCountDown = false,
  className = '',
  style = {},
}) => {
  // const matches = useMediaQuery('(max-width: 576px)');
  const matches = false;

  const [countdown, setCountdown] = useState({
    days: null,
    hours: null,
    minutes: null,
    seconds: null,
  });

  useEffect(() => {
    if (isEndedCountDown === false) {
      const intervalId = setInterval(() => {
        const now = Math.floor(Date.now() / 1000);
        const difference = unixTime - now;
        if (difference < 0) {
          clearInterval(intervalId);
          setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
          return;
        }

        const days = Math.floor(difference / (24 * 60 * 60));
        const hours = Math.floor((difference % (24 * 60 * 60)) / (60 * 60));
        const minutes = Math.floor((difference % (60 * 60)) / 60);
        const seconds = Math.floor(difference % 60);

        setCountdown({ days, hours, minutes, seconds });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [unixTime]);

  // if (
  //   countdown.days === null &&
  //   countdown.hours === null &&
  //   countdown.minutes === null &&
  //   countdown.seconds === null
  // ) {
  //   return <div>00:00:00:00</div>;
  // } else if (
  //   countdown.days === 0 &&
  //   countdown.hours === 0 &&
  //   countdown.minutes === 0 &&
  //   countdown.seconds === 0
  // ) {
  //   // console.log("expired")
  //   // if (setSaleStatus) {
  //   //   setSaleStatus(Math.floor(Math.random() * 17))
  //   // }

  //   // return null
  //   return <div>00:00:00:00</div>;
  // }

  return (
    <ul
      className={`row row-cols-4 gx-2  countdown-container countdown-timer ${className}`}
      style={style}
      // className={`d-flex align-items-center justify-content-start gap-1 gap-sm-${gap} countdown-container`}
    >
      <li>
        <div>{isEndedCountDown ? 0 : countdown.days}</div>
        <p>{matches ? 'days' : 'days'}</p>
      </li>
      <li>
        <div>{isEndedCountDown ? 0 : countdown.hours}</div>
        <p>{matches ? 'hrs' : 'hours'}</p>
      </li>
      <li>
        <div>{isEndedCountDown ? 0 : countdown.minutes}</div>
        <p>{matches ? 'mins' : 'minutes'}</p>
      </li>
      <li>
        <div>{isEndedCountDown ? 0 : countdown.seconds}</div>
        <p>{matches ? 'secs' : 'seconds'}</p>
      </li>
    </ul>
  );
};

export default React.memo(PersaleCountDown);
