import React, { useEffect, useRef, useState } from 'react';
import { getHeighlightedPresalesAction } from '../../redux/apiActions/api.action';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../_constant';
import SubHeader from '../../Components/header/SubHeader';

const indexStyle = {
  // width: 'clamp(38px, 2.2vw ,2.813rem)',
  width: 'clamp(35px, 2.2vw ,2.813rem)',
  aspectRatio: 1,
  borderRadius: '50%',
};

const presaleLogoStyle = {
  borderRadius: '50%',
  width: 'clamp(36px, 2.2vw ,2.794rem)',
  height: 'clamp(36px, 2.2vw ,2.794rem)',
  aspectRatio: 1,
  // background: '#251b1b',
  // padding: '7px',
};

const TrendingPresaleItem = ({ presale, index }) => {
  const navigate = useNavigate();

  return (
    <li
      className='trending_card'
      onClick={() => {
        if (presale.link) window.open(presale.link, '_blank');
        else
          navigate(
            `${BASE_URL}presale?presale_id=${presale?.preSale}&chainId=${presale?.chain}`
          );
      }}
    >
      <div className='d-flex align-items-center gap-3'>
        <span
          className='d-flex justify-content-center align-items-center bg-primary text-white'
          style={indexStyle}
        >
          {index + 1}
        </span>
        <div className='d-flex gap-2 algin-items-center'>
          <img
            src={presale?.logoUrl || 'images/short_logo.png'}
            className=''
            alt='i'
            style={presaleLogoStyle}
          />{' '}
          <div className='d-flex flex-column justify-content-center'>
            {/* <h6 className='text-white font-semibold'>{presale.name}</h6> */}
            <h6 className='text-white fw-normal fs-6 text-truncate'>
              {presale.name}
            </h6>
            <p
              className='m-0 fs-8 fw-normal text-uppercase'
              style={{
                color: '#9A9CB8',
              }}
            >
              {presale.token_symbol}
            </p>
          </div>
        </div>
      </div>
      {/* graph */}
      {/* <div>
        <img alt='graph' className='w-100' src='images/graph.svg' />
      </div> */}
    </li>
  );
};

TrendingPresaleItem.Shimmer = () => {
  return (
    <div
      className='trending_card placeholder-glow'
      style={{
        width: '200px',
      }}
    >
      <p className='placeholder m-0 me-3' style={indexStyle}></p>
      <p className='placeholder m-0 me-2' style={presaleLogoStyle}></p>
      <div
        style={{
          flex: '1',
          display: 'flex',
          flexDirection: 'column',
          gap: '3px',
        }}
      >
        <p className='placeholder placeholder-sm col-12 m-0'></p>
        <p className='placeholder placeholder-sm col-6 m-0'></p>
      </div>
    </div>
  );
};

const Trending = () => {
  const [presales, setPresales] = useState([]);
  const containerRef = useRef(null); // Create a reference for the scrollable container
  const contentRef = useRef(null); // Create a reference for the scrollable container
  const [shouldScroll, setShouldScroll] = useState(false); // Track if scrolling is needed
  const [loading, setLoading] = useState(false);

  const getPresales = async () => {
    setLoading(true);
    const data = await getHeighlightedPresalesAction();
    const results = [...data?.data?.data];
    setPresales(
      results.map((item, idx) => ({
        ...item,
        // logoUrl: `images/icon/trending_icon${(idx % 2) + 1}.svg`,
      }))
    );
    setLoading(false);
  };

  useEffect(() => {
    getPresales();
  }, []);

  useEffect(() => {
    // Check if content is wider than the container
    if (containerRef.current && contentRef.current && presales.length > 0) {
      const containerWidth = containerRef.current.offsetWidth;
      const contentWidth = contentRef.current.scrollWidth;
      setShouldScroll(contentWidth > containerWidth);
    }
  }, [presales]);

  return (
    <div className='row mb-3 mb-sm-4'>
      <SubHeader title='Trending' />
      <div className='runing_bar d-flex align-items-center'>
        <div
          ref={containerRef}
          className='runing_bar_right d-flex justify-content-between align-items-center breaking-news '
        >
          {loading ? (
            <>
              <TrendingPresaleItem.Shimmer />
            </>
          ) : (
            <>
              {shouldScroll ? (
                <div className='marquee news-scroll'>
                  <ul ref={contentRef} className='marquee__item'>
                    {presales?.length > 0 &&
                      presales?.map((presale, index) => (
                        <TrendingPresaleItem
                          key={index}
                          index={index}
                          presale={presale}
                        />
                      ))}
                  </ul>
                  <ul ref={contentRef} className='marquee__item'>
                    {presales?.length > 0 &&
                      presales?.map((presale, index) => (
                        <TrendingPresaleItem
                          key={index}
                          index={index}
                          presale={presale}
                        />
                      ))}
                  </ul>
                </div>
              ) : (
                <ul
                  ref={contentRef}
                  className='not-sc d-flex align-items-center gap-2'
                >
                  {presales?.length > 0 &&
                    presales?.map((presale, index) => (
                      <TrendingPresaleItem
                        key={index}
                        presale={presale}
                        index={index}
                      />
                    ))}
                </ul>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Trending);
