import React, { useEffect, useMemo, useState } from 'react';
// import {
//   EthereumClient,
//   w3mConnectors,
//   w3mProvider,
// } from "@web3modal/ethereum";
import { Web3Modal, Web3Button, useWeb3Modal } from '@web3modal/react';
import {
  configureChains,
  createClient,
  WagmiConfig,
  useAccount,
  useNetwork,
  useBalance,
} from 'wagmi';
import {
  arbitrum,
  polygon,
  dogechain,
  bscTestnet,
  polygonMumbai,
} from 'wagmi/chains';
import {
  createMenusLeft,
  createMenusRight,
  networkImages,
  BASE_URL,
} from '../../_constant';
import { Link, useNavigate } from 'react-router-dom';
import { switchNetwork } from '@wagmi/core';
import { getNetwork } from '@wagmi/core';
import { checkSubscriptionAction } from '../../redux/apiActions/api.action';
import config from '../../config';
import { useMediaQuery } from '@mui/material';
import SubjectIcon from '@mui/icons-material/Subject';

// const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
// const wagmiClient = createClient({
//   autoConnect: true,
//   connectors: w3mConnectors({ projectId, version: 1, chains }),
//   provider,
// });
// const ethereumClient = new EthereumClient(wagmiClient, chains);

export default function Header({ handleSidebarToggle, isSidebarHalf }) {
  const { isDisconnected, isConnected } = useAccount();

  const { chain } = useNetwork();

  const Walletconnect = useMemo(() => {
    return (
      //     <>
      //       <WagmiConfig client={wagmiClient}>
      <HomePage />
      //       </WagmiConfig>

      //       <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
      //     </>
    );
  });

  const switchNetworkModel = useMemo(() => {
    if (chain?.id !== undefined) {
      // if(chain?.id ==bscTestnet.id || chain?.id == polygonMumbai.id){
      if (config.SUPPORTED_NETWORKS.find((item) => item.id === chain.id)) {
        return (
          <a
            className='px-4 align-items-center d-none d-sm-flex flex-nowrap binance_smart_chain modal-open btn btn-outline-light btn-lg btn-network-switch'
            href='#modal'
            data-bs-toggle='modal'
            data-bs-target='#switchNetworksModal'
          >
            {/* <img
              src={networkImages[chain?.network]}
              alt='binance_logo'
              className='img-fluid'
              width={20}
            /> */}
            <span
            // className='d-none d-md-block  ms-2'
            >
              {chain?.name}
            </span>
          </a>
        );
      } else {
        return (
          <a
            href='#modal'
            data-bs-toggle='modal'
            data-bs-target='#switchNetworksModal'
            className='align-items-center d-flex dropbtn  modal-open btn btn-danger btn-lg'
            style={{ backgroundColor: '#e74c3c' }}
          >
            {/* <img
              src={`${BASE_URL}images/icon/warning.png`}
              alt='Create'
              className='img-fluid me-2'
            /> */}
            {/* <span className='warning-text'> */}
            Wrong Network
            {/* </span> */}
          </a>
        );
      }
    }
  }, [chain, networkImages, isDisconnected, isConnected]);

  const navigate = useNavigate();

  const [subsDetails, _subsDetails] = useState();
  const checkSubscriptionAndRedirect = async () => {
    let data = { address: account?.address };
    const response = await checkSubscriptionAction(data);
    _subsDetails(response?.data?.data);
    const status = response?.data?.data?.status;
    if (account.isConnected && !status) {
      navigate(BASE_URL + 'subscription');
    }
  };

  const account = useAccount();
  // useEffect(() => {
  //   checkSubscriptionAndRedirect()
  // }, [account.isConnected,account?.address]);

  const matches = useMediaQuery('(max-width: 1261px)');

  return (
    <>
      <nav
        className='navbar navbar-default py-0'
        style={{
          left: matches
            ? '0'
            : isSidebarHalf
            ? 'var(--w-sidebar)'
            : 'var(--w-half-sidebar)',
          width: matches
            ? '100%'
            : isSidebarHalf
            ? 'calc(100% - var(--w-sidebar))'
            : 'calc(100% - var(--w-half-sidebar))',
        }}
      >
        <div className='container-fluid flex-nowrap px-2 px-md-3'>
          {/* Brand and toggle get grouped for better mobile display */}
          {/* mobile view */}

          <div
            style={{
              display: matches ? 'flex' : 'none',
            }}
            className='align-items-center justify-content-center position-relative'
          >
            <div
              // href="javascript:void(0);"
              data-bs-toggle='offcanvas'
              data-bs-target='#sidebar_offcanvas'
              onClick={handleSidebarToggle}
              className='nav_menu_btn fw-lighter side-bar d-flex justify-content-center align-items-center'
            >
              <SubjectIcon fontSize='large' fill='white' />
              {/* <i
                style={{ cursor: 'pointer' }}
                className='fa fa-bars ion-lg m-0'
              /> */}
            </div>
          </div>
          {/* <div
            style={{
              transition: 'all 0.4s',
              ...(matches
                ? {}
                : {
                    width: isSidebarHalf
                      ? 'var(--w-sidebar)'
                      : 'var(--w-half-sidebar)',
                    minWidth: isSidebarHalf
                      ? 'var(--w-sidebar)'
                      : 'var(--w-half-sidebar)',
                  }),
            }}
            className='d-none d-lg-flex navbar-header align-items-center justify-content-center position-relative'
          >
            <Link to={BASE_URL} className='d-none d-sm-flex'>
              <img
                style={{
                  // width: '45px',
                  aspectRatio: 1,
                  display: !isSidebarHalf ? 'block' : 'none',
                }}
                src={`${BASE_URL}images/short_logo.png`}
                alt='images'
              />
              <img
                style={{
                  display: isSidebarHalf ? 'block' : 'none',
                }}
                className='ms-2'
                src={`${BASE_URL}images/logo.png`}
                alt='images'
              />
            </Link>
            <div
              // href="javascript:void(0);"
              onClick={handleSidebarToggle}
              style={{
                transform: isSidebarHalf ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
              className='nav_menu_btn fw-lighter side-bar d-flex justify-content-center align-items-center'
            >
              <img
                alt='menu'
                className='w-100 pointer icon img-fluid p-0'
                src='images/icon/menu.svg'
              />
            </div>
          </div> */}
          {/* ======== Header Begin ======== */}
          <header className='w-100'>
            {/* <div className='container-fluid'>
              <div className='row'>
                <div className='col-12 d-flex flex-wrap justify-content-lg-between justify-content-end px-3'> */}
            {/* <div className='header_left_btn header_top_btn d-lg-flex d-none flex-wrap'> */}
            {/* Upcoming */}
            {/* <a
                      href={BASE_URL+'home'}
                      className="align-items-center d-flex"
                      onClick={(e)=>{
                        e.preventDefault();
                        navigate(BASE_URL+'home')
                      }}
                    >
                      <img
                        src={`${BASE_URL}images/icon/home.png`}
                        alt="home"
                        className="img-fluid me-2"
                      />
                      Home
                    </a> */}
            {/* <a
                      href={BASE_URL+'subscription'}
                      onClick={(e)=>{
                        e.preventDefault();
                        navigate(BASE_URL+'subscription')
                      }}
                      style={{cursor:'pointer'}}
                      className="align-items-center d-flex"
                    >
                      <i className="fa fa-usd me-2" />
                      Subscription
                    </a> */}
            {/* <a
                      href="javascript:void(0);"
                      className="align-items-center d-flex"
                    >
                      <img
                        src="images/icon/docs.png"
                        alt="Docs"
                        className="img-fluid me-2"
                      />
                      Docs
                    </a> */}
            {/* <a
                      href="javascript:void(0);"
                      className="align-items-center d-flex"
                    >
                      <img
                        src="images/icon/presale-alerts.png"
                        alt="Presale Alerts"
                        className="img-fluid me-2"
                      />
                      Presale Alerts
                    </a> */}
            {/* </div> */}
            <div className='header_right_btn header_top_btn d-flex flex-wrap justify-content-end align-items-center'>
              {/* <a
                      href='#modal'
                      data-bs-toggle='modal'
                      data-bs-target='#createModal'
                      className='align-items-center dropbtn  modal-open btn btn-primary btn-lg'
                    >
                      <div className='d-flex align-items-center'>
                        <img
                          src={`${BASE_URL}images/icon/create.png`}
                          alt='Create'
                          className='img-fluid me-2'
                        />
                        Create
                      </div>
                    </a> */}
              {/* <a
                      href="https://helpercrosschain.com/"
                      target="_blank"
                      className="align-items-center" rel="noreferrer"
                      style={{
                        
                      }}  
                    > */}
              <a
                href='https://helpercrosschain.com/'
                className='btn btn-outline-danger btn-lg text-white'
                target='_blank'
                rel='noreferrer'
              >
                {/* <div className='d-flex'> */}
                {/* <img src={`${BASE_URL}images/icon/create.png`} alt="Create" className="img-fluid me-2" /> */}
                Buy HLPR
                {/* </div> */}
              </a>
              {/* </a> */}
              <div
                id='myDropdown'
                // className={`dropdown-content px-2 py-4 ${showCreate ? 'show' : 'hide'}`}
                className='dropdown-content px-2 py-4'
              >
                <h2 className='text-center text-blue mb-4'>Create</h2>
                <div className='row row-cols-1 row-cols-sm-2'>
                  <div className='col'>
                    <ul className='gap-3 d-grid'>
                      {Object.keys(createMenusLeft).map((value, key) => (
                        <li key={value}>
                          <Link
                            to={createMenusLeft[value]}
                            aria-disabled={true}
                          >
                            {value}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className='col'>
                    <ul className='gap-3 d-grid'>
                      {Object.keys(createMenusRight).map((value, key) => (
                        <li key={value}>
                          <Link to={createMenusRight[value]}>{value}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              {/* <a href="javascript:void(0);" class="align-items-center d-md-flex d-none"><img src="images/icon/home.png" alt="Create" class="img-fluid me-2">Create</a> */}

              {/* {
                    acceptChains?.chain!=undefined &&
                    <a className="align-items-center d-flex binance_smart_chain modal-open" href="#modal" data-bs-toggle="modal" data-bs-target="#switchNetworksModal"><img src={networkImages[acceptChains.chain.network]} alt="binance_logo" className="img-fluid me-2" width={20} />{acceptChains?.chain?.name}</a>
                   } */}
              {switchNetworkModel}
              {subsDetails?.status == true && (
                <h6 className='me-2 ref_user_name_heading d-none d-lg-block'>
                  Username : {subsDetails?.username}
                </h6>
              )}

              {/* <button className="btn btn-primery btn-connect">{Walletconnect}</button> */}
              {Walletconnect}
            </div>
            {/* </div>
              </div>
            </div> */}
          </header>
          {/* ======== Header End ======== */}

          {/* ?end */}
          {/* The Modal */}
        </div>
        {/* /.container-fluid */}
      </nav>

      <div class='side-bar-overlay'></div>
    </>
  );
}

function HomePage() {
  // return <Web3Button />;
  const { isConnected, address } = useAccount();
  const { open, isOpen } = useWeb3Modal();
  const { data } = useBalance({
    address: address,
    enabled: isConnected,
  });

  return isConnected ? (
    <button
      className='btn btn-outline-primary btn-lg text-start d-flex align-items-center gap-2 p-1 wallet-btn'
      onClick={open}
    >
      <img
        // style={{
        //   height: '48px',
        // }}
        src='images/wallet.svg'
        alt='wallet'
        className='img-fluid h-100'
      />
      <div
        className='d-none d-md-block pe-2 fw-semibold text-primary'
        style={{
          // lineHeight: '24px',
          fontSize: 'var(--fs-6)',
        }}
      >
        {address.slice(0, 11)}...
        {/* {address.slice(0, 4)}...{address.slice(-4)} */}
        {data && (
          <p
            className='mb-0 fw-normal text-white'
            style={{
              // lineHeight: '21px',
              fontSize: 'var(--fs-7)',
            }}
          >
            {parseFloat(data.formatted).toFixed(6)} {data.symbol}
          </p>
        )}
      </div>
    </button>
  ) : (
    <button
      class='btn btn-primary btn-lg btn-connect'
      style={
        {
          // height: "35px"
        }
      }
      disabled={isOpen}
      onClick={() => {
        open();
      }}
    >
      {isOpen && !isConnected ? 'Connecting...' : 'Connect'}
    </button>
  );
}
