import React, { useState } from 'react';
import { useContractRead, useToken, useWaitForTransaction } from 'wagmi';
import { getAccount, getNetwork } from '@wagmi/core';
import SimpleToken from '../../_constant/SimpleToken.json';
import AirdropABI from '../../_constant/Airdrop.json';
import {
  AIRDROP_CONTRACT_ADDRESSS,
  PRESALE_MANAGER_ADDRESS,
  convertScientificToDecimal,
  isNumberKey,
} from '../../_constant';
import { constants, ethers } from 'ethers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useContractWrite } from 'wagmi';
import { toast } from 'react-toastify';
import { ERRORMSGS, TRANSACTIONMSGS } from '../../utils/messages';
import {
  DispSameTokToAddValidator,
  disperseDifferentTokenToAddressesValidator,
} from '../../validators/Airdrop/Airdrop.validation';
import { PostErrorLogAction } from '../../redux/apiActions/api.action';

function Tbnb({ preSaleToken }) {
  const [isChecked, setIsChecked] = useState(true);
  const [isCheckedBnb, setIsCheckedBnb] = useState(true);
  const account = getAccount();

  const [token, _token] = useState('');

  const AirDropValidationSchema = Yup.lazy(() => {
    if (isChecked) {
      return DispSameTokToAddValidator;
    } else {
      return disperseDifferentTokenToAddressesValidator;
    }
  });

  const formikAirdrop = useFormik({
    enableReinitialize: true,
    initialValues: {
      addresses: '',
      amount: '',
      diff_amount: '',
    },
    validationSchema: AirDropValidationSchema,
    onSubmit: async (data) => {
      try {
        if (!account.isConnected) {
          return toast.error(ERRORMSGS.LOGINWITHYOURWALLET, {
            toastId: 'connectWithTId',
          });
        }
        // const usersAmount = formikAirdrop.values.amount.toLocaleString(undefined, {minimumFractionDigits: 20}).replace(/\.?0+$/, '')
        const usersAmount = convertScientificToDecimal(data.amount);
        // disperseDifferentTokenToAddresses
        const parsedAmounts = formikAirdrop.values.diff_amount
          .replace(/\s+/g, '')
          .trim()
          .split(',')
          .map((element) => {
            if (
              Object.keys(formikAirdrop.errors).length == 0 &&
              element != ''
            ) {
              const parsedValue = ethers.utils.parseUnits(
                String(convertScientificToDecimal(element) || 0),
                18
              );
              return parsedValue.toString();
            } else {
              return 0;
            }
          });
        if (isChecked) {
          DispSameTokToAdd({
            args: [
              formikAirdrop.values.addresses
                .replace(/\s+/g, '')
                .trim()
                .split(','),
            ],
            value: ethers.utils.parseUnits(String(usersAmount), 18).toString(),
          });
        } else {
          DispDiffTokToAdd({
            value: parsedAmounts.reduce(
              (sum, value) => BigInt(sum) + BigInt(value)
            ),
            args: [
              formikAirdrop.values.addresses
                .replace(/\s+/g, '')
                .trim()
                .split(','),
              parsedAmounts,
            ],
          }); // eslint-disable-line no-undef
        }

        //   resetForm(); // Reset the form after submission
      } catch (error) {
        // Handle errors
      } finally {
        // Perform any necessary cleanup or actions
      }
    },
  });

  //   disperseSameTokenToAddresses

  const onErrorDispSameTokToAdd = (error) => {
    PostErrorLogAction({
      address: account?.address || 0,
      other_detail: { error },
    });
    console.log('4 Error', error);
    let errStr = error.toString().slice(0, 53);

    if (errStr === 'TransactionExecutionError: User rejected the request.') {
      toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT);
    } else if (
      error
        .toString()
        .includes('transaction exceeds the balance of the account')
    ) {
      toast.error(ERRORMSGS.NOTENOUGHBALALNCE);
    } else {
      toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG);
    }
  };

  const onSuccessDispSameTokToAdd = (error) => {};

  const {
    data: dataDispSameTokToAdd,
    write: DispSameTokToAdd,
    isLoading: isLoadingDispSameTokToAdd,
    isSuccess: isSuccessDispSameTokToAdd,
  } = useContractWrite({
    address: AIRDROP_CONTRACT_ADDRESSS,
    abi: AirdropABI.abi,
    functionName: 'disperseSameEtherToAddresses',
    // args: [formikAirdrop.values?.addresses?.replace(/\s+/g, '').trim().split(",")],
    // value:  ethers.utils.parseUnits(String(formikAirdrop.values.amount || 0), 18).toString(),
    onError: onErrorDispSameTokToAdd,
    onSuccess: onSuccessDispSameTokToAdd,
  });

  const onSuccessWaitForTransaction = (data) => {
    toast.success('Airdrop sent!');
    formikAirdrop.resetForm();
  };

  const {
    data,
    isError: isErrorWaitForTrans,
    isLoading: isLoadingWaitForTransDispSameTokToAdd,
  } = useWaitForTransaction({
    hash: dataDispSameTokToAdd?.hash,
    onSuccess: onSuccessWaitForTransaction,
  });

  const {
    data: dataDispDiffTokToAdd,
    write: DispDiffTokToAdd,
    isLoading: isLoadingDispDiffTokToAdd,
    isSuccess: isSuccessDispDiffTokToAdd,
  } = useContractWrite({
    address: AIRDROP_CONTRACT_ADDRESSS,
    abi: AirdropABI.abi,
    functionName: 'disperseDifferentEtherToAddresses',
    // args: [formikAirdrop.values.addresses.replace(/\s+/g, '').trim().split(","),parsedAmounts],
    onError: onErrorDispSameTokToAdd,
    onSuccess: onSuccessDispSameTokToAdd,
  });

  const onSuccessWaitForTransactionDiff = (data) => {
    toast.success('Airdrop sent!');
  };

  const {
    dataDiff,
    isError: isErrorWaitForTransDiff,
    isLoading: isLoadingWaitForTransDispDiffTokToAdd,
  } = useWaitForTransaction({
    hash: dataDispDiffTokToAdd?.hash,
    onSuccess: onSuccessWaitForTransactionDiff,
  });

  const [isFocusAddressList, _isFocusAddressList] = useState(false);

  const handleFocusAddressList = () => {
    _isFocusAddressList(true);
  };

  const handleBlurAddressList = () => {
    _isFocusAddressList(false);
  };

  const [isFocusAmountDiff, _isFocusAmountDiff] = useState(false);
  const handleFocusDiffAm = () => {
    _isFocusAmountDiff(true);
  };
  const handleBlurDiffAm = () => {
    _isFocusAmountDiff(false);
  };

  return (
    <div
      class='tab-pane fade'
      id='ex2-tabs-2'
      role='tabpanel'
      aria-labelledby='ex2-tab-2'
    >
      <div className='token_details_section_bottom'>
        <section className=''>
          <div className=''>
            {/* <div className='col col-md-8 mx-auto'> */}
            {/* <p className="text-center fw-bold" style={{color:"white"}}>You have tBNB</p> */}
            <div className='col-md-12 mb-3'>
              <div className='form-check ps-0'>
                {/* <input className="form-check-input" onChange={(event) => {
                                                          setIsChecked(event.target.checked);
                                                    }} type="checkbox" id="sameAmountCheckbox" defaultChecked />
                                                    <label className="form-check-label" htmlFor="sameAmountCheckbox">Same Amount for each address</label> */}
                <div className='material-switch d-flex align-items-center'>
                  <input
                    className='form-check-input'
                    onChange={(event) => {
                      setIsChecked(event.target.checked);
                    }}
                    type='checkbox'
                    id='tbnbSameAmtCheckbox'
                    defaultChecked
                  />
                  <label
                    htmlFor='tbnbSameAmtCheckbox'
                    className='label-default'
                  />
                  <p className='mb-0 me-3'>Same Amount for each address</p>
                </div>
              </div>
            </div>
            <br />
            <div className='col-md-12 mb-3'>
              {isChecked && (
                <div className='form-group mb-3'>
                  <label className='form-label' htmlFor='totalAmount'>
                    Total Amount for airdrop
                  </label>
                  <input
                    type='text'
                    className={`form-control ${
                      formikAirdrop.errors.amount &&
                      formikAirdrop.touched.amount &&
                      'invalid'
                    }`}
                    id='totalAmount'
                    name='amount'
                    value={formikAirdrop.values.amount}
                    //  onChange={formikAirdrop.handleChange}
                    onChange={(e) => {
                      const inputValue = isNumberKey(e.target.value);
                      // const numericValue = inputValue.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except dot (.)
                      const numericValue = inputValue; // Remove non-numeric characters except dot (.)
                      if (numericValue !== false) {
                        if (e.target.value.length < 21) {
                          formikAirdrop.handleChange({
                            target: {
                              name: 'amount',
                              value: numericValue,
                            },
                          });
                        }
                      }
                    }}
                  />
                  {formikAirdrop.errors.amount &&
                  formikAirdrop.touched.amount ? (
                    <span className='error-msg mt-0'>
                      {formikAirdrop.errors.amount}
                    </span>
                  ) : null}
                </div>
              )}
              <div className='form-group'>
                <div className='text-start approve_token_form'>
                  <label className='form-label'>Address list</label>

                  <textarea
                    rows={6}
                    className={`form-control ${
                      formikAirdrop.errors.addresses &&
                      formikAirdrop.touched.addresses
                        ? 'input_invalid'
                        : ''
                    }`}
                    id='addressList'
                    name='addresses'
                    value={formikAirdrop.values.addresses}
                    onChange={formikAirdrop.handleChange}
                    onFocus={handleFocusAddressList}
                    onBlur={handleBlurAddressList}
                    placeholder={
                      isFocusAddressList
                        ? '0x314ab97b76e39d63c78d5c86c2daf8eaa306b,\n0x271bffabd0f79b8bd4d7a1c245b7ec5b576ea,\n0x141ca95b6177615fb1417cf70e930e102bf8f'
                        : 'Address list'
                    }
                  />
                  {formikAirdrop.errors.addresses &&
                  formikAirdrop.touched.addresses ? (
                    <span className='error-msg'>
                      {formikAirdrop.errors.addresses}
                    </span>
                  ) : null}
                </div>
              </div>
              {!isChecked && (
                <div className='form-group mt-3'>
                  <div className='text-start approve_token_form'>
                    <label className='form-label'>Amount list</label>
                    <textarea
                      rows={6}
                      className={`form-control ${
                        formikAirdrop.errors.diff_amount &&
                        formikAirdrop.touched.diff_amount
                          ? 'input_invalid'
                          : ''
                      }`}
                      id='addressList'
                      name='diff_amount'
                      value={formikAirdrop.values.diff_amount}
                      onChange={formikAirdrop.handleChange}
                      placeholder={
                        isFocusAmountDiff ? '1.2345,45,0.112' : 'Amount'
                      }
                      onBlur={handleBlurDiffAm}
                      onFocus={handleFocusDiffAm}
                    />
                    {formikAirdrop.errors.diff_amount &&
                    formikAirdrop.touched.diff_amount ? (
                      <span className='error-msg'>
                        {formikAirdrop.errors.diff_amount}
                      </span>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
            <button
              type='button'
              className='btn btn-primary btn-lg mt-4 min-w-btn'
              style={{ width: '100px', margin: 'auto' }}
              onClick={(event) => {
                event.preventDefault();
                formikAirdrop.handleSubmit();
              }}
              disabled={
                isLoadingDispSameTokToAdd ||
                isLoadingWaitForTransDispSameTokToAdd ||
                isLoadingDispDiffTokToAdd ||
                isLoadingWaitForTransDispDiffTokToAdd
              }
            >
              {isLoadingDispSameTokToAdd ||
              isLoadingWaitForTransDispSameTokToAdd ||
              isLoadingDispDiffTokToAdd ||
              isLoadingWaitForTransDispDiffTokToAdd
                ? 'Sending...'
                : 'OK'}
            </button>
            {/* </div> */}
          </div>
        </section>
      </div>
    </div>
  );
}

export default Tbnb;
