const CrossGrid = ({ children, className = '', style = {} }) => {
  return (
    <div className={`presale-custom-table my-4 ${className}`} style={style}>
      {children}
    </div>
  );
};

export const CrossGridItem = ({
  children,
  label,
  value,
  className = '',
  style = {},
}) => {
  return (
    <div className={className} style={style}>
      {label && <CrossGrid.Label>{label}</CrossGrid.Label>}
      {value && <CrossGrid.Value>{value}</CrossGrid.Value>}
      {children}
    </div>
  );
};

const Label = ({ children, className = '', style = {} }) => {
  return (
    <p
      style={{
        lineHeight: '24px',
        ...style,
      }}
      className={`m-0 mb-2 fs-6 fw-normal text-gray2 ${className}`}
    >
      {children}
    </p>
  );
};

const Value = ({ children, className = '', style = {} }) => {
  return (
    <div
      style={{
        lineHeight: '27px',
        ...style,
      }}
      className={`text-white fs-55 fw-medium text-truncate ${className}`}
    >
      {children}
    </div>
  );
};

CrossGrid.Item = CrossGridItem;
CrossGrid.Label = Label;
CrossGrid.Value = Value;

export default CrossGrid;
