import React, { useEffect, useState } from 'react';
import {
  PostErrorLogAction,
  extendLockerTimeAction,
  getALockAction,
  getCurrentBnbPriceAction,
  unlockAction,
} from '../../redux/apiActions/api.action';
import queryString from 'query-string';
import moment from 'moment';
import {
  erc20ABI,
  useContractRead,
  useContractWrite,
  useNetwork,
  useToken,
  useWaitForTransaction,
  useAccount,
} from 'wagmi';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { LOCKERS_ADDRESSESS } from '../../_constant';
import LockerAddress from '../../_constant/Locker.json';
import { TRANSACTIONMSGS } from '../../utils/messages';
import { toast } from 'react-toastify';
import { ethers } from 'ethers';
import SimpleToken from '../../_constant/SimpleToken.json';
import { decodeEventLog, parseAbi } from 'viem';
import { bscTestnet } from 'viem/chains';
import { SyncLoader } from 'react-spinners';
import Container from '../../Components/Layouts/Container';
const currentTime = dayjs(); // Get the current time
const startTime = currentTime.add(1, 'month');

const Skeleton = ({ children, loading }) => {
  return loading ? <span class='placeholder col-2'></span> : children;
};

export default function TokenLockDetails() {
  const { chain } = useNetwork();

  const { address } = useAccount();

  const LOCKER_ADDRESS = LOCKERS_ADDRESSESS[chain?.network];

  const { search } = window.location;
  const { token } = queryString.parse(search);
  const [lock, setLock] = useState([]);
  const [lockId, _lockId] = useState();
  const [refreshFlag, setRefreshFlag] = useState(false);

  const [extendTime, setExtendTime] = useState(startTime);

  const [loading, _loading] = useState(false);
  async function getTokenLockDetail() {
    try {
      _loading(true);
      let { data } = await getALockAction({ token: token });
      // console.log("data",data?.dat)
      setLock(data?.data);
    } catch (error) {
    } finally {
      _loading(false);
    }
  }

  useEffect(() => {
    getTokenLockDetail();
  }, [refreshFlag]);

  const {
    data: tokenInfo,
    isSuccess: isTokenFetched,
    isError: isErrorTokenFetch,
    isLoading: isGettingTokenInfo,
    isFetching: isFetchingTokenInfo,
  } = useToken({
    address: token,
    // onError: onTokenFetchError,
    // onSuccess: onSuccessPresaleToken,
  });

  function handleDateChangeStart(dateString) {
    setExtendTime(moment(new Date(dateString)).unix());
    // formikStartPresale.setFieldValue("start_date", moment(new Date(dateString)).unix())
  }

  const popperSx = {
    '& .MuiPaper-root': {
      border: '1px solid black',
      padding: 2,
      marginTop: 1,
      backgroundColor: 'rgba(120, 120, 120, 0.2)',
      minWidth: '100%',
    },
    '& .MuiCalendarPicker-root': {
      backgroundColor: 'rgba(45, 85, 255, 0.4)',
    },
    '& .PrivatePickersSlideTransition-root': {},
    '& .MuiPickersDay-dayWithMargin': {
      color: 'rgb(229,228,226)',
      backgroundColor: 'rgba(50, 136, 153)',
    },
    '& .MuiTabs-root': { backgroundColor: 'rgba(120, 120, 120, 0.4)' },
  };

  const handleLockExtend = () => {
    const closeButton = document.getElementById('btn-close');
    closeButton.click();
    extendLock({ args: [lockId, extendTime * 1000] });
  };

  const onErrorExtendLock = (error) => {
    console.log('Error 76', error);
    PostErrorLogAction({ address: address || 0, other_detail: { error } });
    let errStr = error.toString().slice(0, 53);
    if (errStr === 'TransactionExecutionError: User rejected the request.') {
      toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT);
    } else if (error.toString().includes('InvalidExtendTime')) {
      toast.error('Wrong timer!');
    } else {
      toast.error(TRANSACTIONMSGS.WRONGADDRESS);
    }
  };

  const onSuccessExtendLock = () => {};

  const {
    data: dataExtLock,
    write: extendLock,
    isLoading: isLoadingLock,
    isSuccess: isSuccessLock,
  } = useContractWrite({
    address: LOCKER_ADDRESS,
    abi: LockerAddress.abi,
    functionName: 'extend',
    onError: onErrorExtendLock,
    // args: [token, lockUntil, amount],
    onSuccess: onSuccessExtendLock,
  });

  const onSuccessWFTExtLock = async ({ logs }) => {
    const lastIndex =
      chain?.id == bscTestnet.id ? logs.length - 1 : logs.length - 2; //DynamicChain

    const { args } = decodeEventLog({
      abi: parseAbi([
        'event Extended(uint256 lockId, uint256 oldEndTime, uint256 newEndTime)',
      ]),
      // `data` should be 64 bytes, but is only 32 bytes.
      data: logs[lastIndex]?.data,
      topics: [logs[lastIndex]?.topics[0]],
    });
    const data = await extendLockerTimeAction({
      id: Number(args?.lockId),
      newTime: Number(args?.newEndTime),
    });
    if (data.status == 200) {
      toast.success(data?.data?.message);
      setRefreshFlag(!refreshFlag);
    }
  };

  const { isLoading: isLoadingWFTLock } = useWaitForTransaction({
    hash: dataExtLock?.hash,
    // onSettled: onApproveReceipt,
    onSuccess: onSuccessWFTExtLock,
  });

  //     address:TOKEN_ADDRESS,
  // functionName: "balanceOf",
  // abi: erc20ABI,
  // args: [LOCKER_CONTRACT_ADDRESS]

  const { data: balanceOf } = useContractRead({
    /// PreSale token address
    // @ts-ignore
    address: token,
    abi: SimpleToken.abi,
    functionName: 'balanceOf',
    args: [LOCKER_ADDRESS],
    // onError: onTokenFetchError,
  });

  const { data: preSaleToken } = useToken({
    address: token,
    // onError: onTokenFetchError,
    // onSuccess: onSuccessPresaleToken,
  });

  const [currentMarketPrice, _currentMarketPrice] = useState(0);
  const currentMarketValue = async () => {
    const data = await getCurrentBnbPriceAction({
      symbol: preSaleToken?.symbol,
    });
    _currentMarketPrice(data?.data?.price || 0);
  };

  useEffect(() => {
    currentMarketValue();
  }, [preSaleToken?.symbol]);

  //   function unlock(uint256 _lockId)
  const [currUnLocking, _currunLocking] = useState('');
  const onUnlockError = (error) => {
    console.log('Error 58', error);
    PostErrorLogAction({ address: address || 0, other_detail: { error } });
    _currunLocking('');
    if (error.toString().includes(' User rejected the request')) {
      toast.error('User rejected transaction!');
    } else {
      toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG);
    }
  };

  const {
    data: dataUnlock,
    isLoading: isLoadingUnlock,
    isSuccess: isSuccessUnlock,
    isError: isErrorUnlock,
    write: unlock,
  } = useContractWrite({
    address: LOCKER_ADDRESS,
    abi: LockerAddress.abi,
    functionName: 'unlock',
    // args: [],
    onError: onUnlockError,
    // value: perkFees?.[formData?.tier]?.result ?? 0
    // onSuccess:onSuccessCreatePreSale
  });

  //   dataUnlock

  const onSuccessWFTUnLock = async (data) => {
    try {
      const reponse = await unlockAction({
        address: address,
        locker_id: currUnLocking,
      });
      if (reponse) {
        toast.success('Unlocked!');
        getTokenLockDetail();
      }
    } catch (err) {
    } finally {
      _currunLocking('');
    }
  };

  const { isLoading: isLoadingWFTUnLock } = useWaitForTransaction({
    hash: dataUnlock?.hash,
    // onSettled: onApproveReceipt,
    onSuccess: onSuccessWFTUnLock,
  });
  const unlockFunction = (id) => {
    _currunLocking(id);
    unlock({ args: [Number(id)] });
  };

  document.title = tokenInfo?.name
    ? `${tokenInfo?.name} Token Lock`
    : 'Token Locks';

  function sisMillisecondTime(timestamp) {
    // Check if the timestamp is greater than or equal to 10^12 (1 trillion).
    // If it is, it's likely in milliseconds; otherwise, it's likely in seconds.
    return timestamp >= 1e12;
  }

  return (
    <div>
      <Container className='lock-info'>
        <>
          <h4 className='form-card-heading'>Lock info</h4>
          <div className='card'>
            <div className='card-body'>
              <div className='mb-3 d-flex justify-content-between gap-4 align-items-start'>
                <span className='card-text fs-5 fw-medium'>
                  Total Amount Locked
                </span>
                <Skeleton loading={loading}>
                  <p className='fs-6 fw-medium overflow-hidden m-0'>
                    {preSaleToken &&
                      lock &&
                      ethers.utils.formatUnits(
                        String(lock[0]?.total_amount.split('.')[0] || 0),
                        preSaleToken?.decimals || 0
                      )}
                  </p>{' '}
                </Skeleton>
              </div>
              <hr className='table-border' />
              <div className='mb-1 d-flex justify-content-between gap-4 align-items-start'>
                <span className='card-text fs-5 fw-medium'>
                  Total Values Locked
                </span>
                <Skeleton loading={!(balanceOf && preSaleToken)}>
                  <p className='fs-6 fw-medium overflow-hidden m-0'>
                    {(balanceOf &&
                      preSaleToken &&
                      Number(
                        ethers.utils.formatUnits(
                          balanceOf,
                          preSaleToken?.decimals
                        )
                      ) * currentMarketPrice) ||
                      0}{' '}
                  </p>
                </Skeleton>
              </div>
              <hr className='table-border' />
              <div className='mb-1 d-flex justify-content-between gap-4 align-items-start'>
                <span className='card-text fs-5 fw-medium'>Token Address</span>
                <Skeleton loading={isGettingTokenInfo || isFetchingTokenInfo}>
                  <p className='fs-6 fw-medium overflow-hidden m-0'>
                    {tokenInfo?.address}
                  </p>
                </Skeleton>
              </div>
              <hr className='table-border' />
              <div className='mb-1 d-flex justify-content-between gap-4 align-items-start'>
                <span className='card-text fs-5 fw-medium'>Token Name</span>
                <Skeleton loading={isGettingTokenInfo || isFetchingTokenInfo}>
                  <p className='fs-6 fw-medium overflow-hidden m-0'>
                    {tokenInfo?.name}
                  </p>
                </Skeleton>
              </div>
              <hr className='table-border' />
              <div className='mb-1 d-flex justify-content-between gap-4 align-items-start'>
                <span className='card-text fs-5 fw-medium'>Token Symbol</span>
                <Skeleton loading={isGettingTokenInfo || isFetchingTokenInfo}>
                  <p className='fs-6 fw-medium overflow-hidden m-0'>
                    {tokenInfo?.symbol}
                  </p>
                </Skeleton>
              </div>
              <hr className='table-border' />
              <div className='mb-1 d-flex justify-content-between gap-4 align-items-start'>
                <span className='card-text fs-5 fw-medium'>Token Decimals</span>
                <Skeleton loading={isGettingTokenInfo || isFetchingTokenInfo}>
                  <p className='fs-6 fw-medium overflow-hidden m-0'>
                    {tokenInfo?.decimals}
                  </p>
                </Skeleton>
              </div>
              <hr className='table-border' />
              <div className='mb-1 d-flex justify-content-between gap-4 align-items-start'>
                <span className='card-text fs-5 fw-medium'>Owner</span>
                <Skeleton loading={loading}>
                  <p className='fs-6 fw-medium overflow-hidden m-0'>
                    {lock[0]?.address}
                  </p>
                </Skeleton>
              </div>
              <hr className='table-border' />
            </div>
          </div>

          <div className='mt-5'>
            <h4 className='form-card-heading'>Lock Records</h4>
            <div className='card'>
              <div className='table-responsive'>
                <table class='table view_table'>
                  <thead>
                    <tr>
                      <th scope='col' className='col-4 text-start'>
                        Token
                      </th>
                      <th scope='col' className='col-4'>
                        Lock time
                      </th>
                      <th scope='col' className='col-4'>
                        Unlock time
                      </th>
                      {/* { lock?.map((data) => (  data?.address===address&&(<> <th>Unlock</th>
                                                    <th>Extend</th></>)))} */}
                    </tr>
                  </thead>
                  <tbody>
                    {loading || isGettingTokenInfo || isFetchingTokenInfo ? (
                      new Array(5).fill(0).map((item, idx) => (
                        <tr key={idx}>
                          <td className='col-4 placeholder-glow'>
                            <span class='placeholder w-100'></span>
                          </td>
                          <td className='col-4 placeholder-glow'>
                            <span class='placeholder w-100'></span>
                          </td>
                          <td className='col-4 placeholder-glow'>
                            <span class='placeholder w-100'></span>
                          </td>
                        </tr>
                      ))
                    ) : lock && lock?.length > 0 ? (
                      lock?.map((data) => (
                        <tr>
                          <td>
                            <span className='fs-6 fw-medium'>
                              {ethers.utils.formatUnits(
                                String(data?.amount.split('.')[0]),
                                JSON.parse(data.token_info)?.decimal
                              )}
                            </span>
                          </td>
                          {/* <td>{moment.unix(data?.startedAt).format('YYYY-MM-DD HH:mm')}</td> */}
                          <td className='text-nowrap'>
                            <span className='fs-6 fw-medium text-nowrap'>
                              {sisMillisecondTime(data?.endAt)
                                ? moment
                                    .unix(data?.endAt / 1000)
                                    .format('YYYY-MM-DD HH:mm')
                                : moment
                                    .unix(Number(data?.endAt))
                                    .format('YYYY-MM-DD HH:mm')}
                            </span>
                          </td>
                          {/* <td className='btn-hide-fix-width'> */}
                          <td className=''>
                            <div className='d-flex align-items-center gap-5'>
                              {data?.address === address && (
                                <button
                                  type='button'
                                  class='m-1 btn btn-primary btn-sm'
                                  disabled={
                                    data?.endAt > moment().unix()
                                      ? true
                                      : false ||
                                        isLoadingUnlock ||
                                        isLoadingWFTUnLock
                                  }
                                  onClick={() => {
                                    unlockFunction(data?.locker_id);
                                  }}
                                >
                                  {currUnLocking == data?.locker_id
                                    ? 'Unlocking...'
                                    : 'Unlock'}
                                </button>
                              )}
                              {data?.address === address && (
                                <button
                                  type='button'
                                  className='m-1 btn btn-primary btn-sm'
                                  href='#modal'
                                  data-bs-toggle='modal'
                                  data-bs-target='#extendLockModel'
                                  onClick={() => {
                                    _lockId(data?.locker_id);
                                  }}
                                  disabled={isLoadingLock || isLoadingWFTLock}
                                >
                                  {(data?.locker_id == lockId &&
                                    isLoadingLock) ||
                                  isLoadingWFTLock
                                    ? 'Extending...'
                                    : 'Extend'}
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3} className='text-center'>
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      </Container>

      <div className='modal' id='extendLockModel'>
        <button
          type='button'
          className='btn-close'
          id='btn-close'
          data-bs-dismiss='modal'
          hidden={false}
        />
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-body'>
              <div id='myDropdown' className='dropdown-content px-2 py-4'>
                <h5 className='text-center text-blue mb-4'>Extend Lock</h5>
                <div className='row row-cols-1 row-cols-sm-2'>
                  <div className='col'>
                    {/* <label className="fieldlabels">This pool will be cancelled.</label> */}
                    <div className='approve_token_form'>
                      {/* <label htmlFor="liquidity-lock form-label">Sale Start Time:</label> */}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={[
                            'MobileDateTimePicker',
                            'MobileDateTimePicker',
                          ]}
                          PopperProps={{
                            sx: popperSx,
                          }}
                        >
                          <MobileDateTimePicker
                            className='form-control'
                            // label={'Start Date (UTC)'}
                            openTo='hours'
                            name='startTime'
                            onChange={handleDateChangeStart}
                            defaultValue={startTime}
                            PopperProps={{
                              sx: popperSx,
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div style={{ overflow: 'auto' }}>
                    <div className='d-flex gap-3 pt-4 py-2 justify-content-center'>
                      <button
                        type='button'
                        id='prevBtnClose'
                        className='btn btn-danger m-0 btn-lg'
                        data-bs-dismiss='modal'
                        disabled={isLoadingLock || isLoadingWFTLock}
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        id='nextBtn'
                        className='btn btn-primary m-0 btn-lg'
                        onClick={() => {
                          handleLockExtend();
                        }}
                        disabled={isLoadingLock || isLoadingWFTLock}
                      >
                        {isLoadingLock || isLoadingWFTLock
                          ? 'Extending...'
                          : 'Ok'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
