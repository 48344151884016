import React from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  PostErrorLogAction,
  cancelPresaleAction,
  deleteContributionAction,
  getInvestDetailsAction,
  getPresaleDetailAction,
  getSpecialDetailAction,
  postInvestAction,
  updatePresaleAction,
  updatePresaleRDexLRAction,
} from '../../redux/apiActions/api.action';
import { useState } from 'react';
import { ethers } from 'ethers';
import queryString from 'query-string';
import {
  useNetwork,
  useAccount,
  useContractWrite,
  useContractRead,
  useWaitForTransaction,
  useBalance,
  useContractReads,
} from 'wagmi';
import { switchNetwork, fetchBalance } from '@wagmi/core';
import {
  PRESALE_MANAGER_ADDRESSESS,
  PerkCategories,
  SALE_TYPE,
  blockInvalidChar,
  networkImages,
  networkImagesByChain,
  networkLinks,
} from '../../_constant';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import PreSale from '../../_constant/PreSale.json';
import FairLaunch from '../../_constant/FairLaunch.json';
import Web3 from 'web3';
import YouTube, { YouTubeProps } from 'react-youtube';
import { useMemo } from 'react';
import Countdown from '../../Components/CountDown/CountDown';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { readContract } from '@wagmi/core';
import PreSaleManager from '../../_constant/PreSaleManager.json';
import PreSaleManagerEth from '../../_constant/PreSaleManagerEth.json';
import { getAccount } from '@wagmi/core';
import { formatToken } from '../../utils';
import { SUCCESSMSGS, TRANSACTIONMSGS } from '../../utils/messages';
import { ManagePool } from './ManagePoolSP';
import { TokenInfo } from './TokenInfo';
import { useConnect } from 'wagmi';
import {
  projectInfoSchema,
  updateProjectDetailsSchema,
} from '../../validators/Presale/special';
import discordSvg from './discord.svg';
import { BASE_URL } from '../../_constant';
import { Claim } from './Claim';
import { VestingScheduke } from './VestingSchedule';
import { useWeb3Modal } from '@web3modal/react';
import CnbtPresaleDetails from './CnbtSpecialDetails';
import { SyncLoader } from 'react-spinners';
import Banner from '../PresaleDetails/Banner';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import config from '../../config';
import SubHeader from '../../Components/header/SubHeader';
import PresaleDetailShimmer from '../../Components/shimmers/PresaleDetailShimmer';

export default function SpecialDetails() {
  const { chain, chains } = useNetwork();

  const account = getAccount();

  const currentUnixTime = moment().unix();

  const PRESALE_MANAGER_ADDRESS = PRESALE_MANAGER_ADDRESSESS[chain?.network];

  const { isDisconnected, isConnected, address } = useAccount();

  const navigate = useNavigate();
  // console.log(navigate())

  const [presaleDetails, setPresaleDetails] = useState([]);

  const [loading, setLoading] = useState(true);

  const { search } = window.location;
  const { presale_id } = queryString.parse(search);

  const [tokenForPresale, setTokenForPresale] = useState();
  const [bonusSpotLeft, setBonusSpotLeft] = useState(0);

  const [tokenForLiqidity, setTokenForLiquidity] = useState();

  const [tflFairm, _tflFair] = useState();

  const [Sdate, setSdate] = useState(0);
  const [Edata, setEdata] = useState(0);

  const [videoId, setVideoId] = useState('');

  const not_custom_then_symbol = config.SUPPORTED_NETWORKS.find(
    (network) => network.id == presaleDetails?.chain
  )?.symbol;
  const fundRaisingTokenDecimal = presaleDetails?.custom_fund_token_decimal;
  const fundRaisingTokenSymbol =
    presaleDetails?.fund_releasing_token == 'Custom' ||
    presaleDetails?.fund_releasing_token == 'BUSD' ||
    presaleDetails?.fund_releasing_token == 'USDT'
      ? presaleDetails?.custom_fund_raising_token_symbol
      : not_custom_then_symbol || '';

  const getPresaleFunc = async () => {
    try {
      setLoading(true);
      const { data } = await getSpecialDetailAction({ id: presale_id });

      // calculate tokenfor Presale

      const hardCap = BigInt(data?.hardCap); // eslint-disable-line no-undef
      const rate = BigInt(data?.preSaleRate); // eslint-disable-line no-undef
      const amountActual =
        (hardCap * rate) /
        BigInt(10) ** BigInt(data?.custom_fund_token_decimal); // eslint-disable-line no-undef
      const amountFormatted = ethers.utils.formatUnits(
        amountActual.toString(),
        data?.token_decimal
      );
      setTokenForPresale(amountFormatted);

      // calculate Tokens For Liquidity
      // const dexLiquidityPercentage = BigInt(data?.dexLiquidityPercentage); // eslint-disable-line no-undef
      // const dexListingRate = BigInt(data?.dexListingRate); // eslint-disable-line no-undef
      // const amountActualToLiq =
      //   (hardCap * dexListingRate * dexLiquidityPercentage) /
      //   BigInt(10) ** BigInt(Number(data?.custom_fund_token_decimal) + 2);  // eslint-disable-line no-undef

      // const amountFormattedToLiq = ethers.utils.formatUnits(
      //   amountActualToLiq.toString(),
      //   Number(data?.token_decimal)
      // );

      //  setTokenForLiquidity(amountFormattedToLiq)
      // presaledata store main state
      setPresaleDetails(data);

      // Dates
      setSdate(new Date(data?.startTime * 1000));
      setEdata(new Date(data?.endTime * 1000));

      const videoId = extractVideoId(data?.youtubePresentationVideoUrl);

      setVideoId(videoId);
    } catch (error) {
      // alert("error")
      navigate('/404');
    } finally {
      setLoading(false);
    }
  };

  const getPresaleFuncWithoutLoading = async () => {
    const { data } = await getSpecialDetailAction({ id: presale_id });

    if (data) {
      // calculate tokenfor Presale

      const hardCap = BigInt(data?.hardCap); // eslint-disable-line no-undef
      const rate = BigInt(data?.preSaleRate); // eslint-disable-line no-undef
      const amountActual =
        (hardCap * rate) /
        BigInt(10) ** BigInt(data?.custom_fund_token_decimal); // eslint-disable-line no-undef
      const amountFormatted = ethers.utils.formatUnits(
        amountActual.toString(),
        data?.token_decimal
      );
      setTokenForPresale(amountFormatted);

      // // calculate Tokens For Liquidity
      // const dexLiquidityPercentage = BigInt(data?.dexLiquidityPercentage); // eslint-disable-line no-undef
      // const dexListingRate = BigInt(data?.dexListingRate); // eslint-disable-line no-undef
      // const amountActualToLiq =
      //   (hardCap * dexListingRate * dexLiquidityPercentage) /
      //   BigInt(10) ** BigInt(Number(data?.custom_fund_token_decimal) + 2);  // eslint-disable-line no-undef

      // const amountFormattedToLiq = ethers.utils.formatUnits(
      //   amountActualToLiq.toString(),
      //   Number(data?.token_decimal)
      // );
      // setTokenForLiquidity(amountFormattedToLiq)

      // presaledata store main state
      setPresaleDetails(data);

      // Dates
      setSdate(new Date(data?.startTime * 1000));
      setEdata(new Date(data?.endTime * 1000));

      const videoId = extractVideoId(data?.youtubePresentationVideoUrl);

      setVideoId(videoId);
    }
  };

  useEffect(() => {
    getPresaleFunc();
  }, []);

  const handleSwitchNetwork = async () => {
    try {
      if (presaleDetails?.chain != chain?.id) {
        // _isConfirm({id:chain,isConfirming:true})
        const network = await switchNetwork({
          chainId: Number(presaleDetails?.chain),
        });
      }
    } catch (error) {
      console.log('error', error);
      // _isConfirm({id:'',isConfirming:false})
    }
  };

  const [progreeBar, _progressBar] = useState(0);

  const onSuccessFundRaised = (data) => {
    if (presaleDetails?.hardCap) {
      const fundRaised = ethers.utils.formatUnits(
        String(data || 0),
        Number(fundRaisingTokenDecimal)
      );
      const hardCap = ethers.utils.formatUnits(
        String(presaleDetails?.hardCap || 0),
        Number(fundRaisingTokenDecimal)
      );
      const progress = (fundRaised * 100) / hardCap;
      _progressBar(progress);
    } else {
    }
  };

  const {
    data: isDataFundRaised,
    isError: isErrorFundRaised,
    isLoading: isLoadingFundRaised,
    refetch: referchFundraise,
  } = useContractRead({
    address: presaleDetails.preSale,
    abi: SALE_TYPE.isPresaleOrPrivateSale(presaleDetails?.saleType) ? PreSale.abi : FairLaunch.abi,
    functionName: 'fundRaised',
    onSuccess: onSuccessFundRaised,
    chainId: presaleDetails?.chain,
  });

  const startTime = Number(presaleDetails?.startTime);
  const endTime = Number(presaleDetails?.endTime);
  let classNameD = 'sale_upcoming';
  let statusText = 'Upcoming';
  let startEndIn = 'TBA';

  if (presaleDetails?.isCanceled == 1) {
    statusText = 'Sale cancelled';
  } else if (
    currentUnixTime > endTime &&
    isDataFundRaised &&
    Number(ethers.utils.formatUnits(String(isDataFundRaised || 0))) ==
      Number(
        ethers.utils.formatUnits(
          String(presaleDetails?.hardCap || 0),
          Number(presaleDetails?.custom_fund_token_decimal)
        )
      ) &&
    presaleDetails?.isAllowclaim == 0
  ) {
    classNameD = 'sale_finished';
    statusText = 'Sale Finished';
    startEndIn = 'Sale Finished';
  } else if (presaleDetails?.isAllowclaim == 1) {
    // if(currentUnixTime >= presaleDetails?.estimatedClaimTime){
    statusText = 'Claim Allowed';
    classNameD = 'liq_unlocked';
    // }else{
    //   statusText = 'Listed on dex'
    //   classNameD = 'listed_on_dex'
    // }
  } else if (currentUnixTime >= startTime && endTime > currentUnixTime) {
    classNameD = 'sale_live';
    statusText = 'Sale Live';
    startEndIn = 'Sale Ends In';
  } else if (currentUnixTime < startTime) {
    classNameD = 'sale_upcoming';
    statusText = 'Upcoming';
    startEndIn = 'Sale Start In';
  } else if (currentUnixTime > endTime) {
    statusText = 'Sale Ended';
    classNameD = 'sale_end';
    startEndIn = 'Sale Ended';
  }

  const onPlayerReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const opts = {
    height: '350',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      quality: 'highres',
    },
  };

  function extractVideoId(url) {
    const regExp =
      /^(?:(?:https?:)?\/\/)?(?:(?:www|m)\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/|v\/|youtu\.be\/|\/v\/|user\/[^#]*#([^\/]*?\/)*)?([^\?&"'>]+)/i;
    const match = url.match(regExp);

    if (match && match[2]) {
      return match[2];
    }

    return null;
  }

  // const [amount,_amount]=useState()
  // const [amoutError,setAmountError] =useState(false)

  // let onChangeAmount=({target})=>{
  //   let {value}=target;
  //   if(!value){
  //     setAmountError(false)
  //     _amount(value)
  //   }else{
  //     let dynamicDecimal = presaleDetails?.fund_releasing_token=="BNB"?18:presaleDetails?.custom_fund_token_decimal;
  //     let minBuy = ethers.utils.formatUnits(String(presaleDetails?.minBuy), dynamicDecimal).toString()
  //     let maxBuy = ethers.utils.formatUnits(String(presaleDetails?.maxBuy), dynamicDecimal).toString()
  //     if(value>= minBuy && value<= maxBuy){
  //       setAmountError(false)
  //       _amount(value)
  //     }else{
  //       setAmountError(true)
  //       _amount(value)
  //     }
  //   }

  // }

  async function getInvestDetailsFunc() {
    if (presaleDetails?.preSale) {
      let body = {
        preSale: presaleDetails.preSale,
        address: address,
        chain: chain?.id,
      };
      let { data } = await getInvestDetailsAction(body);
      setInvestDetails(data);
    }
  }

  // console.log("dynamicAmountPass",dynamicAmountPass)

  // useEffect(() => {
  //   if (dynamicAmountPass !== 0) {
  //     alert("calle")
  //     if(presaleDetails?.buyWithETH=="true") {
  //       investIntoPreSaleEth();
  //     }else{

  //     }
  //   }
  // }, [dynamicAmountPass]);

  // investCOnt = custom_fund_token_decimal

  // receiveTOken = token_decimal

  // console.log("investorContribution",formatToken(investorContribution,presaleDetails?.custom_fund_token_decimal))
  // console.log("investorReceivedTokens",formatToken(investorReceivedTokens,dynamicDecimal))

  const refetchInFBC = () => {};

  let [investDetails, setInvestDetails] = useState();

  useEffect(() => {
    getInvestDetailsFunc();
  }, [presaleDetails, address]);

  const onemergencyWithdrawError = (error) => {
    PostErrorLogAction({
      address: account?.address || 0,
      other_detail: { error },
    });
    console.log('23 Error', error);
    let errStr = error.toString().slice(0, 25);
    if (errStr === 'TransactionExecutionError') {
      toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT);
    } else {
      toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG);
    }
  };

  const onSuccessEmw = async (data) => {
    // Api call
    let response = await deleteContributionAction({
      preSale: presaleDetails?.preSale,
      address: address,
    });
    if (response) {
      getInvestDetailsFunc();
      toast.success(SUCCESSMSGS.EMERGENCY_WITHDRAWAL_SUCCESS);
    }
  };

  /**
   * todo : Emergency withdraw contribution
   */
  const {
    data: dataEmgcyWithDraw,
    isLoading: isLoadingEmgcyWithDraw,
    write: emergencyWithdraw,
  } = useContractWrite({
    address: PRESALE_MANAGER_ADDRESS,
    abi: PreSaleManager.abi,
    functionName: 'emergencyWithdrawContribution',
    args: [presaleDetails?.preSale],
    onError: onemergencyWithdrawError,
    onSuccess: onSuccessEmw,
  });

  const onSuccesEmwWft = (receipt) => {
    // console.log("receipt", receipt)
    updateDexAndPresaleRateDB();
    referchFundraise();
  };

  const onSettledEmwWft = (receipt) => {
    referchFundraise();
    // updateDexAndPresaleRateDB()
  };

  const {
    data: dataEmwWft,
    isError: isErrorEmWithWft,
    isLoading: isLoadingEmwWft,
  } = useWaitForTransaction({
    hash: dataEmgcyWithDraw?.hash,
    onSettled: onSettledEmwWft,
    onSuccess: onSuccesEmwWft,
  });

  // Update presale Details
  const formikUpdatePool = useFormik({
    enableReinitialize: true,
    initialValues: {
      logoUrl: presaleDetails?.logoUrl,
      websiteUrl: presaleDetails?.websiteUrl,
      twitterUrl: presaleDetails?.twitterUrl,
      githubUrl: presaleDetails?.githubUrl,
      telegramUrl: presaleDetails?.telegramUrl,
      discordUrl: presaleDetails?.discordUrl,
      youtubePresentationVideoUrl: presaleDetails?.youtubePresentationVideoUrl,
      whitelistContestUrl: presaleDetails?.whiteListContestUrl,
      projectDescription: presaleDetails?.projectDescription,
      bannerUrl: presaleDetails?.bannerUrl,
      sale_title: presaleDetails?.sale_title,
      dex_listing_rate: ethers.utils
        .formatUnits(
          String(presaleDetails?.dexListingRate || 0),
          presaleDetails?.token_decimal || 0
        )
        .toString(),
      preSaleRate: ethers.utils
        .formatUnits(
          String(presaleDetails?.preSaleRate || 0),
          presaleDetails?.token_decimal || 0
        )
        .toString(),
      redditUrl: presaleDetails?.redditUrl,
    },
    validationSchema: updateProjectDetailsSchema,
    onSubmit: async (data) => {
      try {
        var button = document.getElementById('prevBtnCloseUpdate');
        button.click();
        updatePresale();
      } catch (error) {
      } finally {
      }
    },
  });

  const connectNetwork = () => {};

  const onErrorUpdatePresale = (error) => {
    PostErrorLogAction({
      address: account?.address || 0,
      other_detail: { error },
    });
    console.log('24 Error', error);
    let errStr = error.toString().slice(0, 25);
    if (errStr === 'TransactionExecutionError') {
      toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT);
    } else {
      toast.err(TRANSACTIONMSGS.SOMETHINGWENTWRONG);
    }
  };

  const onSuccessUpdatePresale = async () => {
    let updateDb = {
      logoUrl: formikUpdatePool.values?.logoUrl,
      websiteUrl: formikUpdatePool.values?.websiteUrl,
      twitterUrl: formikUpdatePool.values?.twitterUrl,
      githubUrl: formikUpdatePool.values?.githubUrl,
      telegramUrl: formikUpdatePool.values?.telegramUrl,
      discordUrl: formikUpdatePool.values?.discordUrl,
      youtubePresentationVideoUrl:
        formikUpdatePool.values?.youtubePresentationVideoUrl,
      whitelistContestUrl: formikUpdatePool.values?.whitelistContestUrl || '',
      projectDescription: formikUpdatePool.values?.projectDescription,
      redditUrl: formikUpdatePool.values?.redditUrl,
      bannerUrl: formikUpdatePool.values?.bannerUrl,
      address: address,
      preSale: presaleDetails?.preSale,
      sale_title: formikUpdatePool.values?.sale_title,
      presaleRate: ethers.utils
        .parseUnits(
          String(formikUpdatePool?.values.preSaleRate || 0),
          presaleDetails?.token_decimal || 0
        )
        .toString(),
      dexListingRate: ethers.utils
        .parseUnits(
          String(formikUpdatePool?.values.dex_listing_rate || 0),
          presaleDetails?.token_decimal || 0
        )
        .toString(),
      presaleType: 'Special',
    };

    let response = await updatePresaleAction(updateDb);
    if (response) {
      toast.success(SUCCESSMSGS.PRESALE_UPDATED);
      getPresaleFuncWithoutLoading();
    }
  };

  const {
    data: dataUpdatePresale,
    isLoading: isLoadingUpdatePresale,
    isSuccess: isSuccessUpdatePresale,
    isError: isErrorUpdatePresale,
    write: updatePresale,
  } = useContractWrite({
    address: PRESALE_MANAGER_ADDRESS,
    abi: PreSaleManager.abi,
    functionName: 'updateProjectDetails',
    args: [
      presaleDetails?.preSale,
      {
        logoUrl: formikUpdatePool.values?.logoUrl,
        websiteUrl: formikUpdatePool.values?.websiteUrl,
        twitterUrl: formikUpdatePool.values?.twitterUrl,
        githubUrl: formikUpdatePool.values?.githubUrl,
        telegramUrl: formikUpdatePool.values?.telegramUrl,
        discordUrl: formikUpdatePool.values?.discordUrl,
        youtubePresentationVideoUrl:
          formikUpdatePool.values?.youtubePresentationVideoUrl,
        whitelistContestUrl: formikUpdatePool.values?.whitelistContestUrl,
        projectDescription: formikUpdatePool.values?.projectDescription,
        bannerUrl: formikUpdatePool.values?.bannerUrl,
      },
    ],
    onError: onErrorUpdatePresale,
    onSuccess: onSuccessUpdatePresale,
  });

  const onErrorCancelPresale = (error) => {
    PostErrorLogAction({
      address: account?.address || 0,
      other_detail: { error },
    });
    console.log('25 Error', error);
    let errStr = error.toString().slice(0, 25);
    if (errStr === 'TransactionExecutionError') {
      toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT);
    } else {
      toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG);
    }
  };

  const onSuccessCancelPresale = async () => {
    let bodyData = {
      preSale: presaleDetails?.preSale,
      address: address,
      presaleType: 'Special',
    };
    let response = await cancelPresaleAction(bodyData);
    if (response) {
      var button = document.getElementById('prevBtnClose');
      button.click();

      toast.success(SUCCESSMSGS.PRESALE_CANCELED);
      getPresaleFuncWithoutLoading();
    }
  };

  const {
    data: dataCancelPresale,
    isLoading: isLoadingCancelPresale,
    isSuccess: isSuccessCancelPresale,
    isError: isErrorCancelPresale,
    write: cancelPresaleWrite,
  } = useContractWrite({
    address: PRESALE_MANAGER_ADDRESS,
    abi: PreSaleManager.abi,
    functionName: 'cancelPreSale',
    args: [presaleDetails?.preSale],
    onError: onErrorCancelPresale,
    onSuccess: onSuccessCancelPresale,
  });

  const onErrorStealthCancelPresale = (error) => {
    // console.log("onErrorStealthCancelPresale", error)
  };

  const onSuccessStealthCancelPresale = () => {};

  const {
    data: dataCancelStealthPresale,
    isLoading: isLoadingCancelStealthPresale,
    isSuccess: isSuccessCancelStealthPresale,
    isError: isErrorCancelStealthPresale,
    write: cancelPresaleStealthWrite,
  } = useContractWrite({
    address: PRESALE_MANAGER_ADDRESS,
    abi: PreSaleManager.abi,
    functionName: 'cancelStealthPreSale',
    args: [presaleDetails?.preSale],
    onError: onErrorCancelPresale,
    onSuccess: onSuccessCancelPresale,
  });

  const cancelPresale = () => {
    var button = document.getElementById('prevBtnClose');
    button.click();
    cancelPresaleWrite();
  };

  const cancelPresaleStealth = () => {
    var button = document.getElementById('prevBtnClose');
    button.click();
    cancelPresaleStealthWrite();
  };

  // Live Sale Status Update
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [targetReached, setTargetReached] = useState(false);
  const [targetEnd, setTargetEnd] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000); // Update the current time every second (adjust as needed)

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  useEffect(() => {
    if (
      !targetReached &&
      currentTime >= presaleDetails?.startTime &&
      currentTime < presaleDetails?.endTime
    ) {
      setTargetReached(true);
      // Call your function here
      getPresaleFuncWithoutLoading();
    }
  }, [currentTime, targetReached, presaleDetails]);

  useEffect(() => {
    if (!targetEnd && currentTime >= presaleDetails?.endTime) {
      setTargetEnd(true);
      // Call your function here
      getPresaleFuncWithoutLoading();
    }
  }, [currentTime, targetEnd, presaleDetails]);
  // End Time end

  const onSuccessWithdraw = async (data) => {
    // Api call
    let response = await deleteContributionAction({
      preSale: presaleDetails?.preSale,
      address: address,
    });
    if (response) {
      getInvestDetailsFunc();
      toast.success(SUCCESSMSGS.WITHDTAWAL_SUCCESS);
    }
  };

  const {
    data: dataWithdraw,
    isLoading: isLoadingWithdraw,
    write: withdraw,
  } = useContractWrite({
    address: PRESALE_MANAGER_ADDRESS,
    abi: PreSaleManager.abi,
    functionName: 'withdrawRefundContribution',
    args: [presaleDetails?.preSale],
    onError: onemergencyWithdrawError,
    onSuccess: onSuccessWithdraw,
  });

  const {
    data: data_Presale_Dex,
    isError: isErrorPresale_Dex,
    isLoading: isLoading_Presale_Dex,
    refetch: refetchPresale_Dex,
  } = useContractReads({
    contracts: [
      {
        address: config.SUPPORTED_NETWORKS.some(
          (network) => network.id === chain?.id
        )
          ? presaleDetails?.preSale
          : undefined,
        abi: FairLaunch.abi,
        functionName: 'currentPreSaleRate',
      },
      {
        address: config.SUPPORTED_NETWORKS.some(
          (network) => network.id === chain?.id
        )
          ? presaleDetails?.preSale
          : undefined,
        abi: FairLaunch.abi,
        functionName: 'currentDEXRate',
      },
    ],
  });

  const updateDexAndPresaleRateDB = async () => {
    let refetch_data_Presale_Dex = await refetchPresale_Dex();
    refetch_data_Presale_Dex = refetch_data_Presale_Dex?.data;

    if (
      !SALE_TYPE.isPresaleOrPrivateSale(presaleDetails?.saleType) &&
      refetch_data_Presale_Dex
    ) {
      let data = {
        presaleOwner: account.address,
        preSale: presaleDetails?.preSale,
        preSaleRate: String(refetch_data_Presale_Dex[0].result),
        dexListingRate: String(refetch_data_Presale_Dex[1].result),
      };
      await updatePresaleRDexLRAction(data);
      getPresaleFuncWithoutLoading();
    }
  };

  // const [progressBarFair,_progressBarFair]=useState(0)
  // useEffect(()=>{
  //   alert("hello",isDataFundRaised)
  //   if(presaleDetails && isDataFundRaised){
  //     if (presaleDetails?.isHardCap==1 && presaleDetails?.hardCap!=undefined) {
  //       const fundRaised = Number(formatUnits(isDataFundRaised, presaleDetails?.token_decimals));
  //       const hardCap = Number(formatUnits(presaleDetails?.hardCap, presaleDetails?.custom_fund_token_decimal));
  //       const progress = (fundRaised * 100) / hardCap;
  //       _progressBarFair(progress)
  //     }
  //     else if(BigInt(isDataFundRaised) > BigInt(presaleDetails?.softCap)){  // eslint-disable-line no-undef
  //           const fundRaised = Number(formatUnits(isDataFundRaised, presaleDetails?.token_decimals));
  //           const progress = (fundRaised * 100) / fundRaised;
  //           _progressBarFair(progress)
  //     } else{
  //           const fundRaised = Number(formatUnits(isDataFundRaised, presaleDetails?.token_decimals));
  //           const softCap = Number(formatUnits(presaleDetails?.softCap, presaleDetails?.custom_fund_token_decimal));
  //           const progress = (fundRaised * 100) / softCap;
  //           _progressBarFair(progress)
  //     }
  //   }
  // },[presaleDetails])

  // console.log("progressBarFair",progressBarFair)

  const Walletconnect = useMemo(() => {
    return (
      //     <>
      //       <WagmiConfig client={wagmiClient}>
      <HomePage presale_chain={presaleDetails?.chain} />
      //       </WagmiConfig>

      //       <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
      //     </>
    );
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  document.title = presaleDetails?.sale_title
    ? `${presaleDetails?.sale_title} Special Sale`
    : 'Presale Page';

  return (
    <>
      <div className='container-fluid px-lg-4'>
        <div className='row mt-4'>
          <SubHeader isBack />
          {/* <div className="d-flex align-items-center tnbc_back_section">
                <a
                  onClick={() => {
                    { navigate(-1) ? navigate(-1) : navigate(BASE_URL) };
                  }}
                  // href={BASE_URL}
                  className="me-2"
                  style={{ cursor: 'pointer' }}
                >
                  <i className="fa fa-arrow-left" aria-hidden="true" />
                </a>
                <p className="mb-0">Back</p>
              </div> */}
        </div>

        {loading ? (
          <PresaleDetailShimmer />
        ) : (
          <div className='row mt-4 g-4'>
            <div className='col-xxl-8 col-lg-8 col-md-12'>
              <div className='tnbc_custom_card'>
                <div className='custom_card'>
                  <div
                    className='card-top-img tnbc-card-top-img presale-detail-banner-image-section'
                    // style={{
                    //   backgroundImage: `url(${presaleDetails.bannerUrl != ""
                    //     ? presaleDetails?.bannerUrl
                    //     : "https://testnet.gempad.app/static/bg4.png"
                    //     })`,
                    // }}
                  >
                    <Banner url={presaleDetails.bannerUrl} />
                    <div className='p-3'>
                      <div className={classNameD}>
                        {!presaleDetails ? (
                          'Loading...'
                        ) : (
                          <p className='mb-0'>{statusText}</p>
                        )}
                      </div>
                      <div className='audi_kyc_btn d-flex gap-1'>
                        {presaleDetails?.isTeamVesting == 1 && (
                          <button
                            type='btn'
                            className='btn audit-btn'
                            onClick={() => {
                              if (
                                presaleDetails?.vetted != '' &&
                                presaleDetails?.vetted != null
                              ) {
                                window.open(presaleDetails?.vetted);
                              }
                            }}
                          >
                            Vetted
                          </button>
                        )}
                        {presaleDetails?.perk != PerkCategories.STANDARD && (
                          <>
                            <button
                              type='btn'
                              className='btn audit-btn'
                              onClick={() => {
                                if (
                                  presaleDetails?.audit != '' &&
                                  presaleDetails?.audit != null
                                ) {
                                  window.open(presaleDetails?.audit);
                                }
                              }}
                            >
                              Audit
                            </button>
                            <button
                              type='btn'
                              className='btn kyc-btn'
                              onClick={() => {
                                if (
                                  presaleDetails?.kyc != '' &&
                                  presaleDetails?.kyc != null
                                ) {
                                  window.open(presaleDetails?.kyc);
                                }
                              }}
                            >
                              KYC+
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='p-3'>
                    <div
                      style={{ position: 'relative' }}
                      className='card_second_section d-flex justify-content-between align-items-end'
                    >
                      <div className='card_second_section_text card-inner-page_section_text w-100'>
                        <div className='d-flex align-items-center'>
                          <h5>{presaleDetails?.sale_title}</h5>

                          <ul className='ms-4 presale_social_icons_section d-flex align-items-center gap-2'>
                            {presaleDetails?.twitterUrl != '' && (
                              <li
                                onClick={() => {
                                  window.open(presaleDetails.twitterUrl);
                                }}
                              >
                                <span className='text-blue fs-5'>
                                  <i
                                    className='fa fa-twitter'
                                    aria-hidden='true'
                                  />
                                </span>
                              </li>
                            )}
                            {presaleDetails?.telegramUrl != '' && (
                              <li
                                onClick={() => {
                                  window.open(presaleDetails.telegramUrl);
                                }}
                              >
                                <span className='text-blue fs-5'>
                                  <i
                                    className='fa fa-telegram'
                                    aria-hidden='true'
                                  />
                                </span>
                              </li>
                            )}
                            {presaleDetails?.githubUrl != '' && (
                              <li
                                onClick={() => {
                                  window.open(presaleDetails.githubUrl);
                                }}
                              >
                                <span className='text-blue fs-5'>
                                  <i
                                    className='fa fa-github'
                                    aria-hidden='true'
                                  />
                                </span>
                              </li>
                            )}
                            {presaleDetails?.websiteUrl != '' && (
                              <li
                                onClick={() => {
                                  window.open(presaleDetails.websiteUrl);
                                }}
                              >
                                <span className='text-blue fs-5'>
                                  <i
                                    className='fa fa-globe'
                                    aria-hidden='true'
                                  />
                                </span>
                              </li>
                            )}

                            {presaleDetails?.discordUrl !== '' && (
                              // <li onClick={() => { window.open(presaleDetails.discordUrl) }}>
                              //   <a href="#" className="text-blue fs-5">
                              //     <i className="" aria-hidden="true">
                              //       <img src={DiscordSVG} alt="Discord" />
                              //     </i>
                              //   </a>
                              // </li>
                              <img
                                src={discordSvg}
                                alt='Discord'
                                height={25}
                                width={25}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  window.open(presaleDetails.discordUrl);
                                }}
                              />
                            )}
                          </ul>
                        </div>
                        <p
                          className='mt-3'
                          style={{ whiteSpace: 'break-spaces' }}
                        >
                          {presaleDetails?.projectDescription}
                        </p>
                      </div>
                      <div
                        style={{
                          position: 'absolute',
                          top: '0px',
                          right: '0px',
                        }}
                      >
                        <div className='position-absolute card_second_section_img position'>
                          <img
                            src={presaleDetails?.logoUrl}
                            alt='logo'
                            onError={(event) => {
                              event.target.src =
                                'images/stablz_stablz_icon.png';
                              event.onerror = null;
                            }}
                          />
                          <span>
                            <img
                              src={networkImagesByChain[presaleDetails?.chain]}
                              alt='icon'
                              width={28}
                            />
                          </span>
                        </div>
                        {/* <div className="card_second_section_img2" style={{ width: "50px", height: "50px", borderRadius: "50%" }}>
                            <img src={presaleDetails?.logoUrl}
                              style={{ width: "50px", height: "50px", borderRadius: "50%", objectFit: "cover" }}
                              alt="logo"
                              onError={(event) => {
                                event.target.src = "images/stablz_stablz_icon.png";
                                event.onerror = null;
                              }}
                            />
                            <div className="secondImage">
                              <img style={{ padding: '3px' }} src="images/icon/binance_logo.png" alt="icon" width={28} />
                            </div>
                          </div> */}
                      </div>
                    </div>
                    {/* 
                      {
                        presaleDetails?.youtubePresentationVideoUrl != "" &&
                        <div className="text-center">
                          <YouTube videoId={videoId} opts={opts} onReady={onPlayerReady} />
                        </div>
                      } */}
                    {presaleDetails?.youtubePresentationVideoUrl != '' && (
                      <div className='css-1on8bub mt-4'>
                        <div className='video-responsive'>
                          <iframe
                            width='853'
                            height='480'
                            src={`https://www.youtube.com/embed/${videoId}`}
                            frameBorder='0'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen
                            title='Embedded youtube'
                          />
                        </div>
                      </div>
                    )}
                    <ul className='presale_address_ui mt-5'>
                      <li className='d-flex flex-wrap justify-content-between'>
                        <p>Special Sale Address</p>
                        <div className='div_media_screen w_mob_presale_address'>
                          <p
                            className='mb-0 text-blue media_screen'
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              window.open(
                                `${
                                  networkLinks[presaleDetails?.chain]
                                }/address/${presaleDetails?.preSale}`
                              )
                            }
                            onMouseEnter={(e) => {
                              e.target.style.textDecoration = 'underline';
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.textDecoration = 'none';
                            }}
                          >
                            {presaleDetails?.preSale}
                          </p>
                          <span>
                            {`Do not send ${fundRaisingTokenSymbol} directly to the presale address!`}
                          </span>
                        </div>
                      </li>
                      <li className='d-flex justify-content-between'>
                        <p>Sale Type</p>
                        <p>
                          {presaleDetails?.launch_stealth_sale == 1
                            ? `Stealth ${presaleDetails?.saleType}`
                            : presaleDetails?.sale_without_token == 1
                            ? 'Without Token'
                            : presaleDetails?.saleType + ' sale'}
                        </p>
                      </li>
                      <li className='d-flex justify-content-between'>
                        <p>Total Supply</p>
                        <p>
                          {Number(
                            ethers.utils
                              .formatUnits(
                                String(presaleDetails?.supply),
                                Number(presaleDetails?.token_decimal)
                              )
                              .toString()
                          )?.toLocaleString()}
                        </p>
                      </li>
                      <li className='d-flex justify-content-between'>
                        <p>Token Name</p>
                        <p>{presaleDetails?.name}</p>
                      </li>
                      <li className='d-flex justify-content-between'>
                        <p>Token Symbol</p>
                        <p>{presaleDetails?.token_symbol}</p>
                      </li>
                      <li className='d-flex justify-content-between'>
                        <p>Tokens For Special Sale</p>
                        <p>
                          {ethers.utils.formatUnits(
                            String(presaleDetails?.hardCap),
                            Number(fundRaisingTokenDecimal)
                          ) *
                            ethers.utils.formatUnits(
                              String(presaleDetails?.specialSaleRate),
                              Number(presaleDetails?.token_decimal)
                            )}{' '}
                          {presaleDetails?.token_symbol}
                        </p>
                      </li>
                      <li className='d-flex justify-content-between'>
                        <p>Soft Cap</p>
                        <p>
                          {' '}
                          {ethers.utils.formatUnits(
                            String(presaleDetails?.softCap || 0),
                            Number(fundRaisingTokenDecimal)
                          )}{' '}
                          {fundRaisingTokenSymbol}
                        </p>
                      </li>
                      <li className='d-flex justify-content-between'>
                        <p>Hard Cap</p>
                        <p>
                          {ethers.utils.formatUnits(
                            String(presaleDetails?.hardCap || 0),
                            Number(fundRaisingTokenDecimal)
                          )}{' '}
                          {fundRaisingTokenSymbol}
                        </p>
                      </li>
                      <li className='d-flex justify-content-between'>
                        <p>Special Sale Start Time (IST)</p>
                        <p>
                          {presaleDetails?.startTime == '0'
                            ? 'TBA'
                            : moment(Sdate).format('YYYY-MM-DD HH:mm')}
                        </p>
                      </li>
                      <li className='d-flex justify-content-between'>
                        <p>Special Sale End Time (IST)</p>
                        <p>
                          {presaleDetails?.endTime == '0'
                            ? 'TBA'
                            : moment(Edata).format('YYYY-MM-DD HH:mm')}
                        </p>
                      </li>

                      <li className='d-flex justify-content-between'>
                        <p>Estimated Token Distribution (IST)</p>
                        <p>
                          {moment(
                            presaleDetails?.estimatedClaimTime * 1000
                          ).format('YYYY-MM-DD HH:mm')}
                        </p>
                      </li>

                      {presaleDetails?.isbonusSale == 1 && (
                        <>
                          <li className='d-flex justify-content-between'>
                            <p>Min Bonus Buy Amount</p>
                            <p>
                              {ethers.utils.formatUnits(
                                String(presaleDetails?.minBonusBuyAmount),
                                Number(fundRaisingTokenDecimal)
                              )}{' '}
                              {fundRaisingTokenSymbol}
                            </p>
                          </li>

                          <li className='d-flex justify-content-between'>
                            <p>Bonus Received(%)</p>
                            <p>{presaleDetails?.bonusReceivedPercentage} % </p>
                          </li>

                          <li className='d-flex justify-content-between'>
                            <p>Bonus Spots Available</p>
                            <p>
                              {presaleDetails?.noOfBonusEligibleInvestors}{' '}
                              People
                            </p>
                          </li>
                        </>
                      )}

                      {/*  */}
                      {presaleDetails?.isPreSaleVesting == 1 && (
                        <>
                          <li className='d-flex justify-content-between'>
                            <p>First token release</p>
                            <p>{presaleDetails?.firstReleasePercent_presale}</p>
                          </li>

                          <li className='d-flex justify-content-between'>
                            <p>Each token percent</p>
                            <p>
                              {presaleDetails?.releaseCyclePercentage_presale}
                            </p>
                          </li>

                          <li className='d-flex justify-content-between'>
                            <p>Each token period</p>
                            <p>{presaleDetails?.releaseCycleDays_presale}</p>
                          </li>

                          <li className='d-flex justify-content-between'>
                            <p>Cliff(days)</p>
                            <p>
                              {presaleDetails?.firstReleaseAfter_presale} days
                            </p>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className='row'>
                {presaleDetails?.isTeamVesting == 1 && (
                  <VestingScheduke presaleDetails={presaleDetails} />
                )}
                {presaleDetails?.isWhiteList == 'true' &&
                  presaleDetails?.preSaleOwner == account.address && (
                    <div className='col-md-12'>
                      <div className='custom_card mt-4 p-3'>
                        <h5>Whitelisted Addresses</h5>
                        <hr />
                        <ul className='presale_address_ui mt-2 '>
                          {presaleDetails?.whiteListed
                            ?.split(',')
                            ?.map((addr, key) => {
                              return (
                                <li
                                  className='d-flex justify-content-between'
                                  key={key}
                                >
                                  {addr}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  )}
                {presaleDetails?.preSaleOwner == account.address &&
                  chain?.id == presaleDetails?.chain && (
                    <div className='col-md-12'>
                      <div className='custom_card mt-4 p-3'>
                        <h5>Contributors</h5>
                        <hr />
                        <ul className='presale_address_ui mt-2'>
                          {investDetails?.contributors?.map((addr, key) => {
                            return (
                              <li
                                className='d-flex justify-content-between'
                                key={key}
                              >
                                {addr}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className='col-xxl-4 col-lg-4 col-md-12'>
              {presaleDetails?.chain != chain?.id && isConnected == true ? (
                <div className='custom_card text-center connect_network_presale h-auto mb-4'>
                  <button
                    type='button'
                    className='btn text-white'
                    onClick={() => {
                      handleSwitchNetwork();
                    }}
                  >
                    <span>Switch Network to</span>
                    <span className='ms-2 me-1'>
                      <img
                        onClick={() => {
                          handleSwitchNetwork();
                        }}
                        src={
                          networkImages[
                            chains.find(
                              (network) => network.id == presaleDetails?.chain
                            )?.network
                          ]
                        }
                        alt='icon'
                        width='25'
                      />
                    </span>
                    <span className='text-blue'>
                      {
                        config.SUPPORTED_NETWORKS.find(
                          (network) => network.id === presaleDetails?.chain
                        )?.switch_show_name
                      }
                    </span>
                  </button>
                </div>
              ) : (
                //   <div className="custom_card text-center connect_network_presale">
                //   <button
                //     type="button"
                //     className="btn text-white"
                //     onClick={() => {
                //       connectNetwork();
                //     }}
                //   >
                //     <span>
                //       Connect Network to
                //     </span>
                //     <span className="ms-2 me-1">
                //       <img
                //         onClick={() => { connectNetwork() }}
                //         src={networkImages["bsc-testnet"]}
                //         alt="icon"
                //         width="25"
                //       />
                //     </span>
                //     <span className="text-blue">BSC Testnet</span>
                //   </button>
                // </div>
                isConnected != true && Walletconnect
              )}
              {presaleDetails?.isWhiteList == 'true' ? (
                <>
                  <section>
                    <div className='custom_card mb-4 p-3'>
                      {/* <h5 className="text-center">{currentUnixTime < startTime?'Whitelist Sale Starts In :':currentUnixTime > endTime ?'Whitelist Sale End  :' :'Whitelist Sale Ends In '}
                            <div className="d-flex justify-content-center">
                              {
                                presaleDetails?.setSaleTime == 0 ? 'TBA' :
                                  <Countdown
                                    unixTime={currentTime < presaleDetails?.startTime ? Number(presaleDetails?.startTime) :currentUnixTime > endTime  ?  Number(presaleDetails?.endTime) :''}
                                  />
                              }
                            </div>
                          </h5> */}
                      <div>
                        <div className='text-center mt-1'>
                          <div className='text-center mt-2'>
                            <span>
                              {presaleDetails?.whiteListed
                                ?.split(',')
                                ?.includes(account.address)
                                ? 'You are whitelisted'
                                : "You aren't whitelisted"}
                            </span>
                            <br />
                          </div>
                          <span>
                            {presaleDetails?.whiteListed != null &&
                            presaleDetails?.whiteListed !== ''
                              ? presaleDetails?.whiteListed
                                  ?.replace(/,$/, '')
                                  .split(',').length
                              : 0}{' '}
                            whitelisted addresses.
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              ) : (
                ''
              )}

              {/* 
{
  SALE_TYPE.isPresaleOrPrivateSale(presaleDetails?.saleType) ? */}
              <CnbtPresaleDetails
                presaleDetails={presaleDetails}
                startEndIn={startEndIn}
                progreeBar={progreeBar}
                isDataFundRaised={isDataFundRaised}
                investDetails={investDetails}
                getInvestDetailsFunc={getInvestDetailsFunc}
                referchFundraise={referchFundraise}
                chainId={presaleDetails?.chain}
                bonusSpotLeft={bonusSpotLeft}
                loading={loading}
                withdraw={withdraw}
                isLoadingWithdraw={isLoadingWithdraw}
                emergencyWithdraw={emergencyWithdraw}
                isLoadingEmgcyWithDraw={isLoadingEmgcyWithDraw}
                refetchInFBC={refetchInFBC}
              />
              {/* :
  <CnbtFairLaunchDetails
  presaleDetails={presaleDetails} 
  startEndIn={startEndIn} 
  progreeBar={progreeBar}
  isDataFundRaised={isDataFundRaised}
  investDetails={investDetails}
  getInvestDetailsFunc={getInvestDetailsFunc}
  referchFundraise={referchFundraise}
  chainId={presaleDetails?.chain}
  bonusSpotLeft={bonusSpotLeft}
  loading={loading}
  withdraw={withdraw}
  isLoadingWithdraw={isLoadingWithdraw}
  emergencyWithdraw={emergencyWithdraw}
  isLoadingEmgcyWithDraw={isLoadingEmgcyWithDraw}
  getPresaleFuncWithoutLoading={getPresaleFuncWithoutLoading}
  updateDexAndPresaleRateDB={updateDexAndPresaleRateDB}
  refetchInFBC={refetchInFBC}
  />
} */}

              <div>
                <div className='contribute_r_section mt-4'>
                  <TokenInfo
                    estimatedDexListingTime={presaleDetails?.estimatedClaimTime}
                    preSaleToken={presaleDetails?.preSaleToken}
                    launch_stealth_sale={presaleDetails?.launch_stealth_sale}
                    setSaleTime={presaleDetails?.setSaleTime}
                    isFinalized={presaleDetails?.isFinalized}
                    presale_chain={presaleDetails?.chain}
                    buyToken={presaleDetails?.buyToken}
                    isCanceled={presaleDetails?.isCanceled}
                    isAllowclaim={presaleDetails?.isAllowclaim}
                  />
                </div>

                {presaleDetails?.isAllowclaim == 0 && (
                  <div className='row'>
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                      {(presaleDetails?.preSaleOwner == address ||
                        presaleDetails?.stealth_wallet == address) &&
                        presaleDetails?.chain == chain?.id &&
                        presaleDetails?.isCanceled == 0 && (
                          <div className='card px-0 mt-4 text-center'>
                            <h7>Manage Pool</h7>
                            <hr />
                            <ManagePool
                              preSale={presaleDetails?.preSale}
                              isCanceled={presaleDetails?.isCanceled}
                              isLoadingUpdatePresale={isLoadingUpdatePresale}
                              isLoadingCancelPresale={isLoadingCancelPresale}
                              isLoadingCancelStealthPresale={
                                isLoadingCancelStealthPresale
                              }
                              liquidityLockupDays={
                                presaleDetails?.liquidityLockupDays
                              }
                              launch_stealth_sale={
                                presaleDetails?.launch_stealth_sale
                              }
                              presaleDetails={presaleDetails}
                              getPresaleFuncWithoutLoading={
                                getPresaleFuncWithoutLoading
                              }
                              isDataFundRaised={isDataFundRaised}
                              fundRaisingTokenDecimal={fundRaisingTokenDecimal}
                            />
                          </div>
                        )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Update Pool Model */}
      <div className='paresale_module'>
        <div
          className='modal fade '
          id='updatePoolModal'
          tabIndex='99999'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              {/* Modal Header */}
              <div className='modal-header'>
                <h5 className='text-center text-blue'>
                  Update Sale Information
                </h5>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  hidden={false}
                />
              </div>
              {/* Modal body */}
              <div className='modal-body pr-4'>
                <div
                  id='myDropdown'
                  // className={`dropdown-content px-2 py-4 ${showCreate ? 'show' : 'hide'}`}
                  className='dropdown-content'
                >
                  <div className='row g-3'>
                    <div className='col-12'>
                      <label
                        className='fieldlabels'
                        style={{ position: 'relative' }}
                      >
                        Name
                      </label>
                      <div
                        className={`${
                          formikUpdatePool.errors.websiteUrl &&
                          formikUpdatePool.touched.sale_title
                            ? 'input_invalid'
                            : ''
                        } input-group`}
                      >
                        <div className='input-group-text'>
                          <i className='fa fa-globe' aria-hidden='true'></i>
                        </div>
                        <input
                          type='text'
                          className={`form-control autofill`}
                          name='sale_title'
                          // placeholder="Ex: https://..."
                          onChange={formikUpdatePool.handleChange}
                          value={formikUpdatePool.values.sale_title}
                          aria-describedby='basic-addon1'
                        />
                      </div>

                      {formikUpdatePool.errors.sale_title &&
                      formikUpdatePool.touched.sale_title ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.sale_title}
                        </span>
                      ) : null}
                    </div>

                    <div className='col-12'>
                      <label
                        className='fieldlabels'
                        style={{ position: 'relative' }}
                      >
                        Logo url
                        {/* <i class="fa fa-info-circle">
                          <span className="tooltip-text">URL must end with a supported image extension png, jpg, jpeg or gif and dimensions of exactly 50x50 pixels.</span>
                        </i>  */}
                        <Tooltip
                          arrow
                          title='URL must end with a supported image extension png, jpg, jpeg or gif and dimensions of exactly 50x50 pixels.'
                          placement='top-start'
                          enterTouchDelay={false}
                        >
                          <InfoIcon
                            style={{ marginLeft: '2px', width: '19' }}
                          />
                        </Tooltip>
                        <span
                          className='text-danger'
                          style={{
                            fontSize: '1.5rem',
                            position: 'absolute',
                            top: -7,
                          }}
                        >
                          *
                        </span>{' '}
                      </label>
                      <div
                        className={`${
                          formikUpdatePool.errors.logoUrl &&
                          formikUpdatePool.touched.logoUrl
                            ? 'input_invalid'
                            : ''
                        } input-group`}
                      >
                        <div className='input-group-text'>
                          <i className='fa fa-picture-o' aria-hidden='true'></i>
                        </div>
                        <input
                          type='text'
                          className={`form-control`}
                          name='logoUrl'
                          placeholder='Ex: https://...'
                          onChange={formikUpdatePool.handleChange}
                          value={formikUpdatePool.values.logoUrl}
                          aria-describedby='basic-addon1'
                        />
                      </div>

                      {formikUpdatePool.errors.logoUrl &&
                      formikUpdatePool.touched.logoUrl ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.logoUrl}
                        </span>
                      ) : null}
                    </div>

                    <div className='col-12'>
                      <label
                        className='fieldlabels'
                        style={{ position: 'relative' }}
                      >
                        Banner url
                        {/* <i class="fa fa-info-circle">
                      <span className="tooltip-text">Banner url with dimensions of exactly 286x110 pixels.</span>
                        </i>   */}
                        <Tooltip
                          arrow
                          title='Banner url with dimensions of exactly 286x110 pixels. supported extention png, jpg, jpeg, gif, mp4, webm, ogg'
                          placement='top-start'
                          enterTouchDelay={false}
                        >
                          <InfoIcon
                            style={{ marginLeft: '2px', width: '19' }}
                          />
                        </Tooltip>
                      </label>
                      <div
                        className={`${
                          formikUpdatePool.errors.bannerUrl &&
                          formikUpdatePool.touched.bannerUrl
                            ? 'input_invalid'
                            : ''
                        } input-group`}
                      >
                        <div className='input-group-text'>
                          <i className='fa fa-picture-o' aria-hidden='true'></i>
                        </div>
                        <input
                          type='text'
                          className={`form-control`}
                          name='bannerUrl'
                          placeholder='Ex: https://...'
                          onChange={formikUpdatePool.handleChange}
                          value={formikUpdatePool.values.bannerUrl}
                          aria-describedby='basic-addon1'
                        />
                      </div>
                      {formikUpdatePool.errors.bannerUrl &&
                      formikUpdatePool.touched.bannerUrl ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.bannerUrl}
                        </span>
                      ) : null}
                    </div>

                    <div className='col-12'>
                      <label
                        className='fieldlabels'
                        style={{ position: 'relative' }}
                      >
                        Website url
                      </label>
                      <div
                        className={`${
                          formikUpdatePool.errors.websiteUrl &&
                          formikUpdatePool.touched.websiteUrl
                            ? 'input_invalid'
                            : ''
                        } input-group`}
                      >
                        <div className='input-group-text'>
                          <i className='fa fa-globe' aria-hidden='true'></i>
                        </div>
                        <input
                          type='text'
                          className={`form-control autofill`}
                          name='websiteUrl'
                          placeholder='Ex: https://...'
                          onChange={formikUpdatePool.handleChange}
                          value={formikUpdatePool.values.websiteUrl}
                          aria-describedby='basic-addon1'
                        />
                      </div>

                      {formikUpdatePool.errors.websiteUrl &&
                      formikUpdatePool.touched.websiteUrl ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.websiteUrl}
                        </span>
                      ) : null}
                    </div>

                    <div className='col-12'>
                      <label
                        className='fieldlabels'
                        style={{ position: 'relative' }}
                      >
                        Twitter
                      </label>
                      <div
                        className={`${
                          formikUpdatePool.errors.twitterUrl &&
                          formikUpdatePool.touched.twitterUrl
                            ? 'input_invalid'
                            : ''
                        } input-group`}
                      >
                        <div className='input-group-text'>
                          <i className='fa fa-twitter' aria-hidden='true'></i>
                        </div>
                        <input
                          type='text'
                          className={`form-control`}
                          name='twitterUrl'
                          placeholder='Ex: https://twitter.com/...'
                          onChange={formikUpdatePool.handleChange}
                          value={formikUpdatePool.values.twitterUrl}
                          aria-describedby='basic-addon1'
                        />
                      </div>
                      {formikUpdatePool.errors.twitterUrl &&
                      formikUpdatePool.touched.twitterUrl ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.twitterUrl}
                        </span>
                      ) : null}
                    </div>

                    <div className='col-12'>
                      <label
                        className='fieldlabels'
                        style={{ position: 'relative' }}
                      >
                        Github
                      </label>
                      <div
                        className={`${
                          formikUpdatePool.errors.githubUrl &&
                          formikUpdatePool.touched.githubUrl
                            ? 'input_invalid'
                            : ''
                        } input-group`}
                      >
                        <div className='input-group-text'>
                          <i className='fa fa-github' aria-hidden='true'></i>
                        </div>
                        <input
                          type='text'
                          className={`form-control`}
                          name='githubUrl'
                          placeholder='Ex: https://github.com/...'
                          onChange={formikUpdatePool.handleChange}
                          value={formikUpdatePool.values.githubUrl}
                          aria-describedby='basic-addon1'
                        />
                      </div>
                      {formikUpdatePool.errors.githubUrl &&
                      formikUpdatePool.touched.githubUrl ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.githubUrl}
                        </span>
                      ) : null}
                    </div>

                    <div className='col-12'>
                      <label
                        className='fieldlabels'
                        style={{ position: 'relative' }}
                      >
                        Telegram
                      </label>
                      <div
                        className={`${
                          formikUpdatePool.errors.telegramUrl &&
                          formikUpdatePool.touched.telegramUrl
                            ? 'input_invalid'
                            : ''
                        } input-group`}
                      >
                        <div className='input-group-text'>
                          <i className='fa fa-telegram' aria-hidden='true'></i>
                        </div>
                        <input
                          type='text'
                          className={`form-control`}
                          name='telegramUrl'
                          placeholder='Ex: https://t.me/...'
                          onChange={formikUpdatePool.handleChange}
                          value={formikUpdatePool.values.telegramUrl}
                          aria-describedby='basic-addon1'
                        />
                      </div>
                      {formikUpdatePool.errors.telegramUrl &&
                      formikUpdatePool.touched.telegramUrl ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.telegramUrl}
                        </span>
                      ) : null}
                    </div>

                    <div className='col-12'>
                      <label
                        className='fieldlabels'
                        style={{ position: 'relative' }}
                      >
                        Discord
                      </label>
                      <div
                        className={`${
                          formikUpdatePool.errors.discordUrl &&
                          formikUpdatePool.touched.discordUrl
                            ? 'input_invalid'
                            : ''
                        } input-group`}
                      >
                        <div className='input-group-text'>
                          {/* <span className="input-group-text" id="basic-addon1"> */}
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='#90a3b7'
                            height='1em'
                            viewBox='0 0 640 512'
                          >
                            <path d='M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z' />
                          </svg>
                          {/* </span> */}
                        </div>
                        <input
                          type='text'
                          name='discordUrl'
                          className={`form-control`}
                          placeholder='Ex: https://discord.gg/...'
                          onChange={formikUpdatePool.handleChange}
                          value={formikUpdatePool.values.discordUrl}
                          aria-describedby='basic-addon1'
                        />
                      </div>
                      {formikUpdatePool.errors.discordUrl &&
                      formikUpdatePool.touched.discordUrl ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.discordUrl}
                        </span>
                      ) : null}
                    </div>

                    <div className='col-12'>
                      <label className='fieldlabels'>
                        Youtube{' '}
                        {/* <i class="fa fa-info-circle">
                    <span className="tooltip-text">Input YouTube URL or YouTube video ID. Make sure video link doesn't include a timestamp.</span> </i> */}
                        <Tooltip
                          arrow
                          title="Input YouTube URL or YouTube video ID. Make sure video link doesn't include a timestamp."
                          placement='top-start'
                          enterTouchDelay={false}
                        >
                          <InfoIcon style={{ width: '19' }} />
                        </Tooltip>
                      </label>
                      <div
                        className={`${
                          formikUpdatePool.errors.youtubePresentationVideoUrl &&
                          formikUpdatePool.touched.youtubePresentationVideoUrl
                            ? 'input_invalid'
                            : ''
                        } input-group`}
                      >
                        <div className='input-group-text'>
                          <i
                            className='fa fa-youtube-play'
                            aria-hidden='true'
                          ></i>
                        </div>
                        <input
                          type='text'
                          className={`form-control autofill`}
                          name='youtubePresentationVideoUrl'
                          placeholder='Ex:https://youtube.com/watch?v=75h4tgshg3458i'
                          onChange={formikUpdatePool.handleChange}
                          value={
                            formikUpdatePool.values.youtubePresentationVideoUrl
                          }
                          aria-describedby='basic-addon1'
                        />
                      </div>
                      {formikUpdatePool.errors.youtubePresentationVideoUrl &&
                      formikUpdatePool.touched.youtubePresentationVideoUrl ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.youtubePresentationVideoUrl}
                        </span>
                      ) : null}
                    </div>

                    <div className='col-12'>
                      <label
                        className='fieldlabels'
                        style={{ position: 'relative' }}
                      >
                        Whitelist
                      </label>
                      <div
                        className={`${
                          formikUpdatePool.errors.whitelistContestUrl &&
                          formikUpdatePool.touched.whitelistContestUrl
                            ? 'input_invalid'
                            : ''
                        } input-group`}
                      >
                        <div className='input-group-text'>
                          <i className='fa fa-bolt' aria-hidden='true'></i>
                        </div>
                        <input
                          type='text'
                          className={`form-control autofill`}
                          name='whitelistContestUrl'
                          placeholder='Ex.https://...'
                          onChange={formikUpdatePool.handleChange}
                          value={formikUpdatePool.values.whitelistContestUrl}
                          aria-describedby='basic-addon1'
                        />
                      </div>
                      {formikUpdatePool.errors.whitelistContestUrl &&
                      formikUpdatePool.touched.whitelistContestUrl ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.whitelistContestUrl}
                        </span>
                      ) : null}
                    </div>

                    <div className='col-12'>
                      <label
                        className='fieldlabels'
                        style={{ position: 'relative' }}
                      >
                        Reddit
                      </label>
                      <div
                        className={`${
                          formikUpdatePool.errors.redditUrl &&
                          formikUpdatePool.touched.redditUrl
                            ? 'input_invalid'
                            : ''
                        } input-group`}
                      >
                        <div className='input-group-text'>
                          <i className='fa fa-reddit' aria-hidden='true'></i>
                        </div>
                        <input
                          type='text'
                          className={`form-control autofill`}
                          name='redditUrl'
                          placeholder='Ex.https://reddit.com/...'
                          onChange={formikUpdatePool.handleChange}
                          value={formikUpdatePool.values.redditUrl}
                          aria-describedby='basic-addon1'
                        />
                      </div>
                      {formikUpdatePool.errors.redditUrl &&
                      formikUpdatePool.touched.redditUrl ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.redditUrl}
                        </span>
                      ) : null}
                    </div>

                    <div className='col-12'>
                      <label
                        className='fieldlabels'
                        style={{ position: 'relative' }}
                      >
                        Presale Rate
                      </label>
                      <div
                        className={`${
                          formikUpdatePool.errors.preSaleRate &&
                          formikUpdatePool.touched.preSaleRate
                            ? 'input_invalid'
                            : ''
                        } input-group`}
                      >
                        <div className='input-group-text'>
                          <i
                            className='fa fa-credit-card'
                            aria-hidden='true'
                          ></i>
                        </div>
                        <input
                          type='text'
                          className={`form-control autofill`}
                          name='preSaleRate'
                          onKeyDown={blockInvalidChar}
                          onWheel={(e) => e.target.blur()}
                          //  placeholder="Ex.https://..."
                          //  onChange={formikUpdatePool.handleChange}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const numericValue = inputValue.replace(
                              /[^0-9.]/g,
                              ''
                            ); // Remove non-numeric characters except dot (.)
                            if (
                              e.target.value.length <=
                              presaleDetails?.token_decimal + 2
                            ) {
                              formikUpdatePool.handleChange({
                                target: {
                                  name: 'preSaleRate',
                                  value: numericValue,
                                },
                              });
                            }
                          }}
                          value={formikUpdatePool.values.preSaleRate}
                          aria-describedby='basic-addon1'
                        />
                      </div>
                      {formikUpdatePool.errors.preSaleRate &&
                      formikUpdatePool.touched.preSaleRate ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.preSaleRate}
                        </span>
                      ) : null}
                    </div>

                    <div className='col-12'>
                      <label
                        className='fieldlabels'
                        style={{ position: 'relative' }}
                      >
                        Dex Rate
                      </label>
                      <div
                        className={`${
                          formikUpdatePool.errors.dex_listing_rate &&
                          formikUpdatePool.touched.dex_listing_rate
                            ? 'input_invalid'
                            : ''
                        } input-group`}
                      >
                        <div className='input-group-text'>
                          <i
                            className='fa fa-credit-card'
                            aria-hidden='true'
                          ></i>
                        </div>
                        <input
                          type='text'
                          onKeyDown={blockInvalidChar}
                          onWheel={(e) => e.target.blur()}
                          className={`form-control autofill`}
                          name='dex_listing_rate'
                          //  placeholder="Ex.https://..."
                          //  onChange={formikUpdatePool.handleChange}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const numericValue = inputValue.replace(
                              /[^0-9.]/g,
                              ''
                            ); // Remove non-numeric characters except dot (.)
                            if (
                              e.target.value.length <=
                              presaleDetails?.token_decimal + 2
                            ) {
                              formikUpdatePool.handleChange({
                                target: {
                                  name: 'dex_listing_rate',
                                  value: numericValue,
                                },
                              });
                            }
                          }}
                          value={formikUpdatePool.values.dex_listing_rate}
                          aria-describedby='basic-addon1'
                        />
                      </div>
                      {formikUpdatePool.errors.dex_listing_rate &&
                      formikUpdatePool.touched.dex_listing_rate ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.dex_listing_rate}
                        </span>
                      ) : null}
                    </div>

                    <div className='col-sm-12'>
                      <label className='fieldlabels'>
                        Description{' '}
                        <span
                          className='text-danger'
                          style={{ fontSize: '1.5rem' }}
                        >
                          *
                        </span>
                      </label>

                      <textarea
                        className={`form-control autofill ${
                          formikUpdatePool.errors.projectDescription &&
                          formikUpdatePool.touched.projectDescription &&
                          'input_invalid'
                        }`}
                        rows={3}
                        id='comment'
                        defaultValue={''}
                        name='projectDescription'
                        onChange={formikUpdatePool.handleChange}
                        value={formikUpdatePool.values.projectDescription}
                      />

                      {formikUpdatePool.errors.projectDescription &&
                      formikUpdatePool.touched.projectDescription ? (
                        <span className='error-msg'>
                          {formikUpdatePool.errors.projectDescription}
                        </span>
                      ) : null}
                    </div>

                    <div style={{ overflow: 'auto' }}>
                      <div className='d-flex gap-3 py-2 justify-content-end'>
                        <button
                          type='button'
                          id='prevBtnCloseUpdate'
                          // onclick="nextPrev(-1)"
                          // onClick={prev}
                          className='btn btn-danger m-0'
                          data-bs-dismiss='modal'
                          disabled={isLoadingUpdatePresale}
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          id='nextBtn'
                          className='btn btn-primary m-0'
                          disabled={isLoadingUpdatePresale}
                          onClick={(event) => {
                            event.preventDefault();
                            formikUpdatePool.handleSubmit();
                          }}
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Cancel Model */}
      <div className='modal' id='cancelPresaleModal'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            {/* Modal Header */}
            <div className='modal-header'>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                hidden={false}
              />
            </div>
            {/* Modal body */}
            <div className='modal-body'>
              <div id='myDropdown' className='dropdown-content px-2 py-4'>
                <h5 className='text-center text-blue mb-4'>Are you sure?</h5>
                <div className='row row-cols-1 row-cols-sm-2'>
                  <div className='col'>
                    <label className='fieldlabels'>
                      This pool will be cancelled.
                    </label>
                  </div>
                  <div style={{ overflow: 'auto' }}>
                    <div className='d-flex gap-3 pt-4 py-2 justify-content-center'>
                      <button
                        type='button'
                        id='prevBtnClose'
                        className='btn btn-danger m-0'
                        data-bs-dismiss='modal'
                        disabled={
                          isLoadingCancelPresale ||
                          isLoadingCancelStealthPresale
                        }
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        id='nextBtn'
                        className='btn btn-primary m-0'
                        disabled={
                          isLoadingCancelPresale ||
                          isLoadingCancelStealthPresale
                        }
                        onClick={() => {
                          cancelPresale();
                        }}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function HomePage({ presale_chain }) {
  // return <Web3Button />;
  const { isDisconnected, isConnected, address, isConnecting } = useAccount();
  const { open, close, isOpen } = useWeb3Modal();

  // return <button class="btn btn-primery btn-connect" disabled={isOpen} onClick={() => { open() }}>{isOpen && !isConnected ? 'Connecting...' : isConnected ? `${address.slice(0, 4)}...${address.slice(-4)}` : 'Connect'}</button>
  return (
    <div className='custom_card text-center connect_network_presale h-auto mb-4'>
      <button
        type='button'
        className='btn text-white'
        onClick={() => {
          open();
        }}
      >
        <span>Connect Network to</span>
        <span className='ms-2 me-1'>
          <img
            onClick={() => {
              open();
            }}
            src={
              networkImages[
                config.SUPPORTED_NETWORKS.find(
                  (network) => network.id == presale_chain
                )?.network
              ]
            }
            alt='icon'
            width='25'
          />
        </span>
        <span className='text-blue'>
          {
            config.SUPPORTED_NETWORKS.find(
              (network) => network.id === presale_chain
            )?.switch_show_name
          }
        </span>
      </button>
    </div>
  );
}
