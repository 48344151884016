import React, { useState } from 'react';
import Sidebar from '../sidebar/Sidebar';
import Header from '../header/header';
import { useMediaQuery } from '@mui/material';
// import Footer from "../footer/footer";

export default function Layout(props) {
  const [isSidebarHalf, setIsSidebarHalf] = useState(true);

  const handleSidebarToggle = (e) => {
    setIsSidebarHalf(!isSidebarHalf);
  };

  const matches = useMediaQuery('(max-width: 1261px)');

  return (
    <>
      {matches ? (
        // render when need offcanvas sidebar
        <div
          className='offcanvas offcanvas-start'
          style={{
            width: 'var(--w-sidebar)',
          }}
          tabindex='-1'
          id='sidebar_offcanvas'
          aria-labelledby='sidebar_offcanvasLabel'
        >
          <Sidebar
            isSidebarHalf={isSidebarHalf}
            handleSidebarToggle={handleSidebarToggle}
          />
        </div>
      ) : (
        <Sidebar
          isSidebarHalf={isSidebarHalf}
          handleSidebarToggle={handleSidebarToggle}
        />
      )}
      <div
        className={`body-content side-bar  ${
          isSidebarHalf ? 'side-bar-half' : ''
        }`}
      >
        <Header
          handleSidebarToggle={handleSidebarToggle}
          isSidebarHalf={isSidebarHalf}
        />
        <div className='container-xxxl px-0'>{props.children}</div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
