import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { BASE_URL } from '../../_constant';
import { checkSubscriptionAction } from '../../redux/apiActions/api.action';
import {
  configureChains,
  createClient,
  WagmiConfig,
  useAccount,
  useNetwork,
} from 'wagmi';
import { Tooltip, useMediaQuery } from '@mui/material';

const sidebarItems = [
  {
    id: 1,
    title: 'Home',
    icon: 'images/nav-icon/1.svg',
    link: `${BASE_URL}home`,
  },
  {
    id: 2,
    title: 'Presales',
    icon: 'images/nav-icon/2.svg',
    link: `${BASE_URL}presales`,
    activeMatch: 'presale',
  },
  {
    id: 3,
    title: 'Special Sales',
    icon: 'images/nav-icon/3.svg',
    link: `${BASE_URL}specials`,
    activeMatch: 'special',
  },
  {
    id: 4,
    title: 'Staking Pools',
    icon: 'images/nav-icon/4.svg',
    link: `${BASE_URL}stakes`,
    activeMatch: 'stake',
  },
  {
    id: 5,
    title: 'Token Locks',
    icon: 'images/nav-icon/5.svg',
    link: `${BASE_URL}token-locks`,
    activeMatch: 'token-lock-detail',
  },
  {
    id: 6,
    title: 'Liquidity Locks',
    icon: 'images/nav-icon/6.svg',
    link: `${BASE_URL}liquidity-locks`,
    activeMatch: 'liquidity-lock-detail',
  },
];

const developerItems = [
  {
    id: 1,
    title: 'Create & Launch',
    icon: 'images/nav-icon/5.svg',
    children: [
      {
        id: 1,
        title: 'Presale',
        link: `${BASE_URL}create-presale`,
      },
      {
        id: 2,
        title: 'Hyper Launch',
        link: `${BASE_URL}create-hyper-launch`,
      },
      {
        id: 3,
        title: 'Fair Launch',
        link: `${BASE_URL}create-fair-launch`,
      },
      {
        id: 4,
        title: 'Special Sale',
        link: `${BASE_URL}create-special`,
      },
      {
        id: 5,
        title: 'Token',
        link: `${BASE_URL}create-token`,
      },
      {
        id: 6,
        title: 'Staking Pool',
        link: `${BASE_URL}create-stake`,
      },
      {
        id: 7,
        title: 'Lock',
        link: `${BASE_URL}create-lock`,
      },
    ],
  },
  {
    id: 2,
    title: 'Utility & Tools',
    icon: 'images/nav-icon/6.svg',
    children: [
      {
        id: 1,
        title: 'Airdrop',
        link: `${BASE_URL}airdrop`,
      },
      {
        id: 2,
        title: 'Presale Calculator',
        link: `${BASE_URL}presale-calculator`,
      },
      {
        id: 3,
        title: 'Create Token',
        link: `${BASE_URL}create-token`,
      },
      // {
      //   id: 4,
      //   title: 'Multichain Bridge',
      //   link: 'https://test.multichain.org/#/router',
      //   external: true,
      // },
    ],
  },
];

export default function Sidebar({
  isSidebarHalf,
  handleSidebarToggle,
  className,
}) {
  const account = useAccount();
  const [subsDetails, _subsDetails] = useState();
  const checkSubscriptionAndRedirect = async () => {
    let data = { address: account?.address };
    const response = await checkSubscriptionAction(data);
    _subsDetails(response?.data?.data);
    const status = response?.data?.data?.status;
  };

  useEffect(() => {
    checkSubscriptionAndRedirect();
  }, [account.isConnected, account?.address]);

  const matches = useMediaQuery('(max-width: 1261px)');

  const homeTooltipAttributes = isSidebarHalf
    ? {}
    : {
        'data-bs-toggle': 'tooltip',
        'data-bs-title': 'Default tooltip',
        'data-bs-placement': 'right',
      };

  return (
    <div
      id='sidebar'
      className={`nav-sidebar side-bar ${
        isSidebarHalf || matches ? 'side-bar-half' : ''
      } ${className}`}
      // style={
      //   matches
      //     ? {
      //         transform: isSidebarHalf ? 'translateX(0%)' : 'translateX(-100%)',
      //       }
      //     : {}
      // }
    >
      <div
        // href="javascript:void(0);"
        onClick={handleSidebarToggle}
        style={{
          display: matches ? 'none' : 'flex',
          transform: isSidebarHalf ? 'rotate(0deg)' : 'rotate(180deg)',
        }}
        className='nav_menu_btn fw-lighter side-bar justify-content-center align-items-center'
      >
        <img
          alt='menu'
          className='w-100 pointer icon img-fluid p-0'
          src='images/icon/menu.svg'
        />
      </div>
      <div
        style={{
          transition: 'all 0.4s',
          // ...(matches
          //   ? {}
          //   : {
          width: isSidebarHalf ? 'var(--w-sidebar)' : 'var(--w-half-sidebar)',
          minWidth: isSidebarHalf
            ? 'var(--w-sidebar)'
            : 'var(--w-half-sidebar)',
          // }),
          borderBottom: '2.7px solid #161D26',
          height: 'var(--h-header)',
        }}
        className='w-100 flex navbar-header align-items-center position-relative py-2'
      >
        <Link
          to={BASE_URL}
          className='d-flex justify-content-start w-100 h-100'
          style={{
            margin: isSidebarHalf ? '0' : 'auto',
          }}
        >
          <img
            className='short_logo img-fluid h-100'
            style={{
              // width: '52px',
              // aspectRatio: 1,
              display: matches || isSidebarHalf ? 'none' : 'block',
              margin: 'auto',
            }}
            src={`${BASE_URL}images/short-logo.png`}
            alt='images'
          />
          <img
            style={{
              // width: 'clamp(200px, 14vw, 252px)',
              display: matches || isSidebarHalf ? 'block' : 'none',
              paddingLeft: 'calc(var(--px-sidebar) - 20px)',
            }}
            className='long_logo img-fluid h-100'
            src={`${BASE_URL}images/logo.png`}
            alt='images'
          />
        </Link>
      </div>

      {/* <div className="brand-logo d-block d-sm-none">
  <a href="index.html"><img src="images/logo.png" className="img-fluid" alt="logo"></a>
    </div> */}
      <div
        className='sidebar list-group'
        style={{
          // paddingTop: '2.25rem',
          paddingTop: '1.5rem',
          ...(isSidebarHalf || matches ? {} : { overflow: 'unset' }),
        }}
      >
        {/* <li> */}
        {subsDetails?.status == true && (
          <h6
            className='mb-2 ref_user_name_heading d-block d-lg-none'
            style={{
              marginLeft: 'var(--px-sidebar)',
              marginRight: 'var(--px-sidebar)',
            }}
          >
            Username : {subsDetails?.username}
          </h6>
        )}
        {/* </li> */}
        {/* <h6 className="text-white list-group-item mb-0">
          <img
            src={BASE_URL+`images/dots.svg`}
            alt="dots"
            className="explore_dot mx-auto"
          />{" "}
          <span>EXPLORE</span>
        </h6> */}
        <ul className='list-group gap-2'>
          {sidebarItems.map((item) => (
            <li key={item.id} className='list-group-item'>
              {isSidebarHalf || matches ? (
                <NavLink
                  to={item.link}
                  className={({ isActive, isPending, isTransitioning }) => {
                    const currentLocation = window.location.pathname;
                    if (
                      isActive ||
                      item.activeMatch ===
                        currentLocation.split('/')[
                          currentLocation.split('/').length - 1
                        ]
                    ) {
                      return 'navlink active';
                    }
                    return 'navlink';
                  }}
                  {...homeTooltipAttributes}
                >
                  <span>
                    <img
                      src={BASE_URL + item.icon}
                      className='img-fluid'
                      alt={item.title}
                    />
                  </span>
                  <p>{item.title}</p>
                </NavLink>
              ) : (
                <Tooltip title={item.title} placement='right' arrow>
                  {/* below empty div is needed for work of active NavLink className */}
                  <>
                  <NavLink
                    to={item.link}
                      className={({ isActive }) => {
                        const currentLocation = window.location.pathname;
                        if (
                          isActive ||
                          item.activeMatch ===
                            currentLocation.split('/')[
                              currentLocation.split('/').length - 1
                            ]
                        )
                          return 'navlink active';
                        return 'navlink';
                      }}
                    {...homeTooltipAttributes}
                  >
                    <span>
                      <img
                        src={BASE_URL + item.icon}
                        className='img-fluid'
                        alt={item.title}
                      />
                    </span>
                    <p>{item.title}</p>
                  </NavLink>
                  </>
                </Tooltip>
              )}
            </li>
          ))}
          {/* <li className='list-group-item'>
            <NavLink
              to={BASE_URL + `home`}
              className='navlink'
              {...homeTooltipAttributes}
            >
              <span>
                <img
                  src={BASE_URL + `images/nav-icon/1.svg`}
                  className='img-fluid'
                  alt='home'
                />
              </span>
              <p>Home</p>
            </NavLink>
          </li>
          <li className='list-group-item'>
            <NavLink
              to={BASE_URL + `presales`}
              className='navlink'
              data-bs-toggle='tooltip'
              data-bs-title='Default tooltip'
              data-bs-placement='right'
            >
              <span>
                <img
                  src={BASE_URL + `images/nav-icon/2.svg`}
                  className='img-fluid'
                  alt='presales'
                />
              </span>
              <p>Presales</p>
            </NavLink>
          </li>
          <li className='list-group-item'>
            <NavLink to={BASE_URL + 'specials'} className='navlink'>
              <span>
                <img
                  src='images/nav-icon/3.svg'
                  className='img-fluid'
                  alt='special sales'
                />
              </span>
              <p>Special Sales</p>
            </NavLink>
          </li>
          <li className='list-group-item'>
            <NavLink to={BASE_URL + `stakes`} className='navlink'>
              <span className=''>
                <img
                  src={BASE_URL + `images/nav-icon/4.svg`}
                  className='img-fluid'
                  alt='staking pools'
                />
              </span>
              <p>Staking Pools</p>
            </NavLink>
          </li>
          <li className='list-group-item'>
            <NavLink to={`${BASE_URL}token-locks`} className='navlink'>
              <span>
                <img
                  src={BASE_URL + 'images/nav-icon/5.svg'}
                  className='img-fluid'
                  alt='token locks'
                />
              </span>
              <p>Token Locks</p>
            </NavLink>
          </li>
          <li className='list-group-item'>
            <NavLink to={`${BASE_URL}liquidity-locks`} className='navlink'>
              <span>
                <img
                  src={BASE_URL + 'images/nav-icon/6.svg'}
                  className='img-fluid'
                  alt='liquidity locks'
                />
              </span>
              <p>Liquidity Locks</p>
            </NavLink>
          </li> */}

          {/* <li className="list-group-item">
    <NavLink to={`${BASE_URL}subsc-cashback`} className="navlink">
      <span>
        <div className="presales_par" id="tipRight" data-bs-toggle="tooltip" title="Subscription & Cashback" />
        <img src={BASE_URL+"images/nav-icon/8.svg"} className="img-fluid" alt="liquidity locks" />
      </span>
      <p>Subscriptions & Cashback</p>
    </NavLink>
  </li> */}

          {/* 
  <li className="list-group-item">
    <NavLink to={`${BASE_URL}refferal-cashback`} className="navlink">
      <span>
        <div className="presales_par" id="tipRight" data-bs-toggle="tooltip" title="Subscription & Cashback" />
        <img src={BASE_URL+"images/nav-icon/9.svg"} className="img-fluid" alt="liquidity locks" />
      </span>
      <p>Referrals & Cashback</p>
    </NavLink>
  </li> */}
        </ul>
        <hr />
        <h6
          className='text-gray list-group-item mb-2 mt-5 fs-5 fw-normal'
          style={{
            lineHeight: '30px',
          }}
        >
          {/* <img
            src={BASE_URL + 'images/dots.svg'}
            alt='dots'
            className='explore_dot mx-auto'
          />{' '} */}
          Developers
        </h6>

        {isSidebarHalf || matches ? (
          <div
            className='accordion developers_dropdown_menu'
            id='accordionExample'
          >
            {developerItems.map((item) => (
              <div key={item.id} className='list-group-item accordion-item'>
                <h2 className='accordion-header' id={`heading${item.id}`}>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target={`#collapse${item.id}`}
                    aria-expanded='false'
                    aria-controls={`collapse${item.id}`}
                  >
                    {isSidebarHalf ? (
                      <NavLink
                        to=''
                        className='px-0'
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                      >
                        <span>
                          <img
                            src={BASE_URL + item.icon}
                            className='img-fluid'
                            alt={item.title}
                          />
                        </span>
                        <p className=''>{item.title}</p>
                      </NavLink>
                    ) : (
                      <Tooltip title={item.title} placement='right' arrow>
                        <NavLink
                          to=''
                          className='px-0'
                          onClick={(event) => {
                            event.preventDefault();
                          }}
                        >
                          <span>
                            <img
                              src={BASE_URL + item.icon}
                              className='img-fluid'
                              alt={item.title}
                            />
                          </span>
                          <p>{item.title}</p>
                        </NavLink>
                      </Tooltip>
                    )}
                  </button>
                </h2>

                <div
                  id={`collapse${item.id}`}
                  className='accordion-collapse collapse'
                  aria-labelledby={`heading${item.id}`}
                  data-bs-parent='#accordionExample'
                >
                  <div className='accordion-body pt-0 ps-5'>
                    {item.children.map((child) => (
                      <NavLink
                        key={child.id}
                        to={child.link}
                        className={({ isActive }) =>
                          [isActive ? 'activedot' : ''].join(' ')
                        }
                        target={child.external ? '_blank' : '_self'}
                      >
                        {child.title}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          developerItems.map((item) => (
            <div className='btn-group dropend'>
              {/*  */}
              <button
                className='accordion-button collapsed list-group-item'
                type='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                <Tooltip title={item.title} placement='right' arrow>
                  <NavLink
                    to=''
                    className='px-0'
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                  >
                    <span>
                      <img
                        src={BASE_URL + item.icon}
                        className='img-fluid'
                        alt={item.title}
                      />
                    </span>
                  </NavLink>
                </Tooltip>
              </button>
              {/*  */}
              <ul className='dropdown-menu'>
                {item.children.map((child) => (
                  <li>
                    <NavLink
                      key={child.id}
                      to={child.link}
                      className={({ isActive }) =>
                        [isActive ? 'activedot' : ''].join(' ')
                      }
                      target={child.external ? '_blank' : '_self'}
                    >
                      {child.title}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          ))
        )}

        {/* {true ? (
          <div
            className='accordion developers_dropdown_menu'
            id='accordionExample'
          >
            <div className='list-group-item accordion-item'>
              <h2 className='accordion-header' id='headingTwo'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseTwo'
                  aria-expanded='false'
                  aria-controls='collapseTwo'
                >
                  <NavLink
                    to=''
                    // to={`${BASE_URL}create-presale`}
                    // to="#"
                    // className="active"
                    className='px-0'
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                  >
                    <span>
                      <img
                        src={BASE_URL + 'images/nav-icon/5.svg'}
                        className='img-fluid'
                        alt='presales'
                      />
                    </span>{' '}
                    <p>Create &amp; Launch</p>
                  </NavLink>
                </button>
              </h2>
              <div
                id='collapseTwo'
                className='accordion-collapse collapse'
                aria-labelledby='headingTwo'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body pt-0 ps-5'>
                  <NavLink
                    to={`${BASE_URL}create-presale`}
                    className={({ isActive }) =>
                      [isActive ? 'activedot' : ''].join(' ')
                    }
                  >
                    <div
                      // className="presales_par"
                      id='tipRight'
                      data-bs-toggle='tooltip'
                      title='Presale'
                    />
                    Presale
                  </NavLink>
                  <NavLink
                    to={`${BASE_URL}create-hyper-launch`}
                    className={({ isActive }) =>
                      [isActive ? 'activedot' : ''].join(' ')
                    }
                  >
                    <div
                      className='presales_par'
                      id='tipRight'
                      data-bs-toggle='tooltip'
                      title='Hyper Launch'
                    />
                    Hyper Launch
                  </NavLink>
                  <NavLink
                    to={BASE_URL + `create-fair-launch`}
                    className={({ isActive }) =>
                      [isActive ? 'activedot' : ''].join(' ')
                    }
                  >
                    <div
                      className='presales_par'
                      id='tipRight'
                      data-bs-toggle='tooltip'
                      title='Fair Launch'
                    />
                    Fair Launch
                  </NavLink>
                  <NavLink
                    to={BASE_URL + `create-special`}
                    className={({ isActive }) =>
                      [isActive ? 'activedot' : ''].join(' ')
                    }
                  >
                    <div
                      className='presales_par'
                      id='tipRight'
                      data-bs-toggle='tooltip'
                      title='Special Sale'
                    />
                    Special Sale
                  </NavLink>
                  <NavLink
                    to={BASE_URL + `create-token`}
                    className={({ isActive }) =>
                      [isActive ? 'activedot' : ''].join(' ')
                    }
                  >
                    <div
                      className='presales_par'
                      id='tipRight'
                      data-bs-toggle='tooltip'
                      title='Token'
                    />
                    Token
                  </NavLink>
                  <NavLink
                    to={BASE_URL + `create-stake`}
                    className={({ isActive }) =>
                      [isActive ? 'activedot' : ''].join(' ')
                    }
                  >
                    <div
                      className='presales_par'
                      id='tipRight'
                      data-bs-toggle='tooltip'
                      title='Staking Pool'
                    />
                    Staking Pool
                  </NavLink>
                  <NavLink
                    to={BASE_URL + `create-lock`}
                    className={({ isActive }) =>
                      [isActive ? 'activedot' : ''].join(' ')
                    }
                  >
                    <div
                      className='presales_par'
                      id='tipRight'
                      data-bs-toggle='tooltip'
                      title='Lock'
                    />
                    Lock
                  </NavLink>
                </div>
              </div>
            </div>
            <div className='list-group-item accordion-item'>
              <h2 className='accordion-header' id='headingThree'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseThree'
                  aria-expanded='false'
                  aria-controls='collapseThree'
                >
                  <NavLink
                    to=''
                    className='px-0'
                    // to="#" activeClassName="nonactive"
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                  >
                    <span>
                      <img
                        src={BASE_URL + 'images/nav-icon/6.svg'}
                        className='img-fluid'
                        alt='presales'
                      />
                    </span>{' '}
                    <p>Utility &amp; Tools</p>
                  </NavLink>
                </button>
              </h2>
              <div
                id='collapseThree'
                className='accordion-collapse collapse'
                aria-labelledby='headingThree'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body pt-0 ps-5'>
                  <NavLink
                    to={`${BASE_URL}airdrop`}
                    className={({ isActive }) =>
                      [isActive ? 'activedot' : ''].join(' ')
                    }
                  >
                    <div
                      className='presales_par'
                      id='tipRight'
                      data-bs-toggle='tooltip'
                      title='Airdrop'
                    />
                    Airdrop
                  </NavLink>
                  <NavLink
                    to={BASE_URL + `presale-calculator`}
                    className={({ isActive }) =>
                      [isActive ? 'activedot' : ''].join(' ')
                    }
                  >
                    <div
                      className='presales_par'
                      id='tipRight'
                      data-bs-toggle='tooltip'
                      title='Presale Calculator'
                    />
                    Presale Calculator
                  </NavLink>
                  <NavLink
                    to={BASE_URL + `create-token`}
                    className={({ isActive }) =>
                      [isActive ? 'activedot' : ''].join(' ')
                    }
                  >
                    <div
                      className='presales_par'
                      id='tipRight'
                      data-bs-toggle='tooltip'
                      title='Create Token'
                    />
                    Create Token
                  </NavLink>
                  <NavLink
                    to='https://test.multichain.org/#/router'
                    target='_blank'
                    className={({ isActive }) =>
                      [isActive ? 'activedot' : ''].join(' ')
                    }
                  >
                    <div
                      className='presales_par'
                      id='tipRight'
                      data-bs-toggle='tooltip'
                      title='Multichain Bridge'
                    />
                    Multichain Bridge
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className='btn-group dropend'>
              <button
                type='button'
                className='btn btn-secondary dropdown-toggle'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                <img
                  src={BASE_URL + 'images/nav-icon/5.svg'}
                  className='img-fluid'
                  alt='presales'
                />
              </button>
              <ul className='dropdown-menu'>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <NavLink
                  to={`${BASE_URL}create-presale`}
                  className={({ isActive }) =>
                    [isActive ? 'activedot' : ''].join(' ')
                  }
                >
                  Presale
                </NavLink>
                <NavLink
                  to={`${BASE_URL}create-hyper-launch`}
                  className={({ isActive }) =>
                    [isActive ? 'activedot' : ''].join(' ')
                  }
                >
                  Hyper Launch
                </NavLink>
                <NavLink
                  to={BASE_URL + `create-fair-launch`}
                  className={({ isActive }) =>
                    [isActive ? 'activedot' : ''].join(' ')
                  }
                >
                  <div
                    className='presales_par'
                    id='tipRight'
                    data-bs-toggle='tooltip'
                    title='Fair Launch'
                  />
                  Fair Launch
                </NavLink>
                <NavLink
                  to={BASE_URL + `create-special`}
                  className={({ isActive }) =>
                    [isActive ? 'activedot' : ''].join(' ')
                  }
                >
                  <div
                    className='presales_par'
                    id='tipRight'
                    data-bs-toggle='tooltip'
                    title='Special Sale'
                  />
                  Special Sale
                </NavLink>
                <NavLink
                  to={BASE_URL + `create-token`}
                  className={({ isActive }) =>
                    [isActive ? 'activedot' : ''].join(' ')
                  }
                >
                  <div
                    className='presales_par'
                    id='tipRight'
                    data-bs-toggle='tooltip'
                    title='Token'
                  />
                  Token
                </NavLink>
                <NavLink
                  to={BASE_URL + `create-stake`}
                  className={({ isActive }) =>
                    [isActive ? 'activedot' : ''].join(' ')
                  }
                >
                  <div
                    className='presales_par'
                    id='tipRight'
                    data-bs-toggle='tooltip'
                    title='Staking Pool'
                  />
                  Staking Pool
                </NavLink>
                <NavLink
                  to={BASE_URL + `create-lock`}
                  className={({ isActive }) =>
                    [isActive ? 'activedot' : ''].join(' ')
                  }
                >
                  <div
                    className='presales_par'
                    id='tipRight'
                    data-bs-toggle='tooltip'
                    title='Lock'
                  />
                  Lock
                </NavLink>
              </ul>
            </div>
          </div>
        )} */}

        {/* create presale button */}
        <div className='sidebar_create_div'>
          <img alt='rocket' src='images/rocket.png' />
          <p>
            Click here to explore all available options: Token Creation, Token
            Lock, Airdrop, and Liquidity Calculator.
          </p>
          <button
            type='button'
            className='btn btn-primary mt-3'
            data-bs-toggle='modal'
            data-bs-target='#createModal'
            style={{
              justifyContent: 'center',
              background: 'var(--clr-secondary)',
            }}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
}
