import { switchNetwork } from '@wagmi/core';
import React from 'react';
import { useAccount, useNetwork } from 'wagmi';
import { networkImages } from '../../_constant';
import config from '../../config';
import { useWeb3Modal } from '@web3modal/react';

const ConnectOrSwitchNetwork = ({ requiredChain }) => {
  const { isConnected } = useAccount();
  const { chain } = useNetwork();
  const { open } = useWeb3Modal();

  const handleSwitchNetwork = async () => {
    try {
      if (requiredChain !== chain?.id) {
        // _isConfirm({id:chain,isConfirming:true})
        await switchNetwork({
          chainId: Number(requiredChain),
        });
      }
    } catch (error) {
      console.log('error', error);
      // _isConfirm({id:'',isConfirming:false})
    }
  };

  return (
    <div>
      {requiredChain !== chain?.id ? (
        <div
          // className='custom_card text-center connect_network_presale h-auto mb-4'
          className='text-center'
        >
          <div
            className='mb-4 fs-55 fw-normal'
            style={{
              lineHeight: '27px',
            }}
          >
            {isConnected ? 'Switch Network to' : 'Connect Network to'}
          </div>
          <button
            type='button'
            className='btn btn-outline-warning ff-popins rounded-4 btn-lg'
            onClick={() => (isConnected ? handleSwitchNetwork() : open())}
          >
            <span className='me-2'>
              <img
                src={
                  networkImages[
                    config.SUPPORTED_NETWORKS.find(
                      (network) => network.id === requiredChain
                    )?.network
                  ]
                }
                alt='icon'
                width='32'
              />
            </span>
            <span>
              {
                config.SUPPORTED_NETWORKS.find(
                  (network) => network.id === requiredChain
                )?.switch_show_name
              }
            </span>
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default ConnectOrSwitchNetwork;
